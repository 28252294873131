import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Typeahead } from 'react-bootstrap-typeahead';
import useTranslation from '../../../../../util/hooks/useTranslation';

const ASSET_NAME_PAGE_SIZE = 20;
const WrappedTypeahead = styled.div`
    width: 20%;

    @media (max-width: 690px) {
        & #targetName {
            left: 24%;
            top: 44%;
            width: 50%;
        }
    }

    & .form-control {
        font-size: 0.8rem;
        height: 38px;
        border: 2px solid orange;
    }

    & .rbt-input-main.form-control.rbt-input {
        height: 38px;
        border-radius: 5px;
    }

    .pnt-input--group & span {
        display: inline;
        position: relative;
        opacity: 1;
        transform: initial;
        top: initial;
    }
`;

const SearchAutocomplete = ({ options, value, handleSelectedKeyword }) => {
    const initialState = [
        {
            label: '',
            value: '',
        },
    ];
    const t = useTranslation('SearchAutoComplete');
    const [itemList, setItemList] = useState([]);
    const [selected, setSelected] = useState(initialState);

    useEffect(() => {
        setItemList(options);
        if (value && options.length) {
            setSelected([options.find(item => value === item.value)]);
        }

        return () => {
            setItemList([]);
            setSelected(initialState);
        };
    }, [options, value]);

    return (
        <WrappedTypeahead>
            <Typeahead
                id="targetId"
                className="typeahead-custom"
                multiple={false}
                allowNew={false}
                options={itemList}
                //onInputChange={onInputChange}
                placeholder={t('Input asset ID or name')}
                emptyLabel={t('No matches found')}
                paginationText={t('Display additional results...')}
                maxResults={ASSET_NAME_PAGE_SIZE - 1}
                filterBy={['label', 'value']}
                onChange={handleSelectedKeyword}
                selected={selected}
            />
        </WrappedTypeahead>
    );
};

export default SearchAutocomplete;
