import React, { useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { FiClock } from 'react-icons/fi';
import useInterval from '../../../../util/hooks/useInterval';

const INTERVAL_TIME = 1000;

const HeaderTimer = () => {
    const { lang } = useSelector(state => state.UserInfo);
    const [clock, setClock] = useState(moment().locale(lang).format('hh:mm a'));
    const [calendar, setCalendar] = useState(moment().format('YYYY/MM/DD'));

    useInterval({
        callback: () => {
            setClock(moment().locale(lang).format('hh:mm a'));
            setCalendar(moment().format('YYYY/MM/DD'));
        },
        delay: INTERVAL_TIME,
    });

    return (
        <div className="header-timer-container">
            <div className="header-timer">
                <FiClock className="timer-icon" />
                <div className="clock">{clock}</div>
                <div className="calendar">{`(${calendar})`}</div>
            </div>
        </div>
    );
};

export default HeaderTimer;
