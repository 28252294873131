import React, { useEffect, useState } from 'react';
import { CardHeader, CardBody } from 'reactstrap';
import Card from '../../../../Common/Card';
import useTranslation from '../../../../../util/hooks/useTranslation';
import Label from '../../../../Common/Label';
import Switch from 'react-switch';
import useAsync from '../../../../../util/hooks/useAsync';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../Common/Button';
import TreeSelect from '../../../../Common/Select/TreeSelect';
import useConfirmModal from '../../../../Common/ConfirmModal/useConfirmModal';
import styled from 'styled-components';
import {
    getFlowSchemeInfoApi,
    getStatusSchemeInfoApi,
    getStatusFlowCategoryMapInfoApi,
    getMappedStatusListInfoApi,
    getStatusSchemeListInfoApi,
    createStatusFlowCategoryMapInfoApi,
    deleteStatusFlowCategoryMapInfoApi,
} from '../../../../../api/status';
import { fetchCategory } from '../../../../../api/asset';
import {
    setMappedStatusListInfo,
    setStatusFlowCategoryMapInfo,
    setStatusSchemeListInfo,
} from '../../../../../reducers/Common/StatusInfo';
import { setCategoryList } from '../../../../../reducers/Common/CategoryInfo';
import { StyledStatusLabel } from '../../../Components/Grid/column';

const StyledSwitch = styled(Switch)`
    .react-switch-bg {
        border-radius: 7px !important;
    }

    .react-switch-handle {
        border-radius: 7px !important;
    }
`;

const AllocateAssetsView = () => {
    const dispatch = useDispatch();
    const t = useTranslation('Status');
    const { statusSchemeListInfo, flowCategoryMappedStatusInfo } = useSelector(state => state.StatusInfo);
    const { serviceCategory } = useSelector(state => state.CategoryInfo);
    const [categoryList, setCategoryList] = useState([]);
    const [options, setOptions] = useState([]);
    const [mappedSelectedList, setMappedSelectedList] = useState([]);
    const [selected, setSelected] = useState([]);
    const [viewData, setViewData] = useState({
        flowName: '',
        description: '',
        isActive: 'N',
        assignedCount: 0,
        schemeNum: 0,
        schemeName: '',
        flowDrawJson: '',
        schemeDescription: '',
    });
    const addIcon = icon => {
        const handle = document.querySelector('.react-switch-handle');
        if (icon) {
            handle.innerHTML = '<span class="icon-check icon-filter-white ml-1 mt-1"/>';
        } else {
            handle.innerHTML = '<span class="icon-close icon-filter-white ml-1 mt-1"/>';
        }
    };

    const history = useHistory();
    const params = useParams();

    const { flowNum } = params;

    const {
        flowName,
        description,
        isActive,
        assignedCount,
        schemeNum,
        schemeName,
        flowDrawJson,
        schemeDescription,
    } = viewData;

    useEffect(() => {
        addIcon(isActive === 'Y');
    }, [isActive]);
    const { promise: getSelectedTarget } = useAsync({
        promise: getFlowSchemeInfoApi,
        resolve: ({ flowName, description, isActive, scheme, schemeNum, flowDrawJson }) => {
            setViewData(prevState => {
                return { ...prevState, flowName, description, isActive, scheme, schemeNum, flowDrawJson };
            });
        },
    });

    const { promise: getStatusFlowCategoryMapInfo } = useAsync({
        promise: getStatusFlowCategoryMapInfoApi,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            const { rows } = res;
            const assignedCount = rows
                .filter(({ flowNum: resFlowNum }) => {
                    return resFlowNum ? resFlowNum.toString() === flowNum : null;
                })
                .reduce((acc, cur) => {
                    return acc + cur.itemCount;
                }, 0);
            setViewData(prevState => {
                return { ...prevState, assignedCount };
            });
            dispatch(setStatusFlowCategoryMapInfo(res));
        },
    });

    const { promise: getStatusScheme } = useAsync({
        promise: getStatusSchemeInfoApi,
        resolve: ({ schemeName, description }) => {
            setViewData(prevState => {
                return { ...prevState, schemeName, schemeDescription: description };
            });
        },
    });

    const { promise: getFetchCategory } = useAsync({
        promise: fetchCategory,
        // fixedParam: { parentCode: serviceCategory },
        resolve: res => {
            setCategoryList(res.rows);
            // dispatch(setCategoryList(res.rows));
        },
    });

    const { promise: getStatusSchemeListInfo } = useAsync({
        promise: getStatusSchemeListInfoApi,
        resolve: res => {
            dispatch(setStatusSchemeListInfo(res));
        },
    });

    const { promise: getMappedStatusListInfo } = useAsync({
        promise: getMappedStatusListInfoApi,
        resolve: res => {
            dispatch(setMappedStatusListInfo(res));
        },
    });

    const { promise: deleteStatusFlowCategory } = useAsync({
        promise: deleteStatusFlowCategoryMapInfoApi,
    });

    useEffect(() => {
        getSelectedTarget({ flowNum });
    }, [flowNum]);

    useEffect(() => {
        getStatusScheme({ schemeNum });
    }, [schemeNum]);

    const getApis = () => {
        getStatusFlowCategoryMapInfo({ isAll: 'Y' });
        getFetchCategory({ isAll: 'Y' });
        getMappedStatusListInfo({ isAll: 'Y' });
        getStatusSchemeListInfo({ isAll: 'Y' });
    };

    const { promise: postStatusFlowCategory } = useAsync({
        promise: createStatusFlowCategoryMapInfoApi,
        resolve: () => {
            toggleSaveConfirmModal();
            getApis();
        },
        reject: () => {
            toggleSaveRejectModal();
            getApis();
        },
    });

    useEffect(() => {
        getApis();
    }, []);

    useEffect(() => {
        const selectedList = [];

        flowCategoryMappedStatusInfo.rows
            .filter(category => category.flowNum === parseInt(flowNum))
            .forEach(v => selectedList.push(v.categoryCode));

        setMappedSelectedList(selectedList);

        function findChildrenCategory(categoryList, findCategory, parentCode) {
            const children = categoryList.filter(v => v.parentCode === parentCode);
            findCategory.push(...children);
            children.forEach(category => findChildrenCategory(categoryList, findCategory, category.categoryCode));
        }

        if (serviceCategory && categoryList.length) {
            const filteredCategoryList = [categoryList.find(category => category.categoryCode === serviceCategory)];
            findChildrenCategory(categoryList, filteredCategoryList, serviceCategory);
            setOptions(filteredCategoryList);
        } else {
            setOptions(categoryList);
        }
    }, [serviceCategory, categoryList, flowCategoryMappedStatusInfo.rows]);

    useEffect(() => {
        setSelected(mappedSelectedList);
    }, [mappedSelectedList]);

    const { toggleModal: toggleSaveModal, Modal: SaveModal } = useConfirmModal({
        confirmText: t('Do you want to save?'),
        okCallback: () => {
            const intFlowNum = parseInt(flowNum);
            const categoryCodes = selected
                .reduce((acc, cur) => {
                    return acc + cur + ',';
                }, '')
                .slice(0, -1);
            if (!categoryCodes) {
                deleteStatusFlowCategory({ flowNums: flowNum });
            } else {
                postStatusFlowCategory({ categoryCodes, flowNum: intFlowNum });
            }
        },
    });

    const { toggleModal: toggleSaveConfirmModal, Modal: SaveConfirmModal } = useConfirmModal({
        confirmText: t('ConfirmModal;Save has been completed.'),
        okCallback: () => {
            getApis();
        },
        cancelCallback: () => {
            getApis();
        },
    });

    const { toggleModal: toggleSaveRejectModal, Modal: SaveRejectModal } = useConfirmModal({
        header: { title: t('ConfirmModal;Notification') },
        confirmText: t('ConfirmModal;The category is already mapping with other Flow Scheme.'),
    });

    const handleSaveButton = () => {
        toggleSaveModal();
    };

    const handleSelectChange = list => {
        setSelected(list);
    };

    const singleStatusScheme = statusSchemeListInfo.rows.find(el => el.schemeNum === schemeNum);

    return (
        <>
            <Card
                header={{
                    titleIcon: 'icon-view',
                    title: `${t('Allocate Assets')} - ${t('View')}`,
                    action: (
                        <>
                            <Button
                                className={'btn-gray flex-center-1'}
                                onClick={() => history.push('/status/allocate')}
                            >
                                {t('Cancel')}
                            </Button>
                            <Button
                                className={'btn-brand flex-center'}
                                iconClassName={'icon-save'}
                                style={{ fontSize: '1rem' }}
                                onClick={handleSaveButton}
                            >
                                {t('Save')}
                            </Button>
                        </>
                    ),
                }}
            >
                <div
                    style={{
                        height: '43rem',
                    }}
                >
                    <div className="mb-3 font-weight-bold font-size-lg">{`${t('Status')} ${t('Detail')}`}</div>
                    <div>
                        <div className="p-0 d-flex row pnt-label-7">
                            <Label
                                name={t('Name')}
                                labelValueClassName={'label-dot color-brand'}
                                labelGroupClassName={'col-xl-6 mb-3 col-lg-12'}
                                value={<>{flowName}</>}
                            />
                            <Label
                                name={t('Status Scheme')}
                                labelValueClassName={'label-dot color-brand'}
                                labelGroupClassName={'col-xl-6 mb-3 col-lg-12'}
                                value={<>{schemeName ? schemeName : t("There's no Status Scheme.")}</>}
                            />
                            <Label
                                name={t('Filter;Activation')}
                                labelValueClassName={'label-dot color-brand'}
                                labelGroupClassName={'col-xl-6 mb-3 col-lg-12'}
                                value={
                                    <StyledSwitch
                                        checked={isActive === 'Y'}
                                        borderRadius={1}
                                        disabled={true}
                                        onChange={() => {}}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        offHandleColor={'#cccccc'}
                                        onHandleColor={'#fca558'}
                                        onColor={'#FF7800'}
                                    />
                                }
                            />
                            <Label
                                name={t('assigned assets')}
                                labelValueClassName={'label-dot label-main'}
                                labelGroupClassName={'col-xl-6 mb-3 col-lg-12'}
                                value={<div>{assignedCount}</div>}
                            />
                            <Label
                                name={t('Description')}
                                labelValueClassName={'label-dot label-main'}
                                labelGroupClassName={'col-xl-6 mb-3 col-lg-12'}
                                value={<div>{description ? description : t("There's no description.")}</div>}
                            />
                        </div>
                    </div>
                    <div className="pnt-border border-w" />
                    <div className="mt-4 mb-3 font-weight-bold font-size-lg">{t('Set Assets')}</div>
                    <div className="p-0 d-flex row pnt-label-7">
                        <Label
                            name={t('Description')}
                            labelValueClassName={'label-dot label-main'}
                            labelGroupClassName={'col-xl-6 mb-3 col-lg-12'}
                            value={<div>{schemeDescription ? schemeDescription : t("There's no description.")}</div>}
                        />
                        <Label
                            name={t('Status scheme')}
                            labelValueClassName={'label-dot label-main'}
                            labelGroupClassName={'col-xl-6 mb-3 col-lg-12'}
                            value={
                                <div>
                                    {singleStatusScheme && singleStatusScheme.mappedStatus
                                        ? singleStatusScheme.mappedStatus.map((v, i) => (
                                              <StyledStatusLabel
                                                  key={v.flowNum + i}
                                                  statusName={v.statusName}
                                                  displayColor={v.displayColor}
                                                  className={'table-cell-middle'}
                                              />
                                          ))
                                        : null}
                                </div>
                            }
                        />
                        <Label
                            name={t('Category')}
                            labelValueClassName={'label-dot label-main'}
                            labelGroupClassName={'col-xl-6 mb-3 col-lg-12'}
                            value={
                                <TreeSelect
                                    data={options ? options : []}
                                    title={t('Category')}
                                    valueKey={'categoryCode'}
                                    parentKey={'parentCode'}
                                    labelKey={'categoryNamePath'}
                                    emptyText={t('Not Selected')}
                                    selected={selected}
                                    onChange={handleSelectChange}
                                />
                            }
                        />
                    </div>
                </div>
            </Card>
            <SaveModal />
            <SaveConfirmModal />
            <SaveRejectModal />
        </>
    );
};

export default AllocateAssetsView;
