import React, { useEffect, useRef } from 'react';
import { Route, Redirect, withRouter, useRouteMatch } from 'react-router-dom';
import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';
import AppFooter from './AppFooter';
import { ErrorHandleSwitch } from '../MainPages/Components/Router';
import Dashboards from '../MainPages/Dashboards';
import { getLsUserInfo, getUuid } from '../../util/common/util';
import { useDispatch, useSelector } from 'react-redux';
import { setAdditionalInfo, setOauthInfo, setOauthStatusModal, logOut, setLanguage } from '../../reducers/UserInfo';
import { useUserTokenCheck } from '../../util/hooks/utilHooks';
import ConfirmModal from '../Common/ConfirmModal';
import Modules from '../Common/Modules';
import useTranslation from '../../util/hooks/useTranslation';
import Report from '../MainPages/Report';
import useSocket from '../../util/socket/hooks/useSocket';
import useAsync from '../../util/hooks/useAsync';
import { fetchCompany } from '../../api/login';
import { setMarkerConfig } from '../../reducers/Common/AppConfig';
import { setAlert } from '../../reducers/Notification';
import LocationPopup from './AppHeader/Components/LocationPopup';
import { Portal } from 'react-overlays';
import { fetchCategory } from '../../api/asset';
import { setAllCategoryList, setCategoryPropertiesList } from '../../reducers/Common/CategoryInfo';
import { fetchFloorList, getCategoryPropertiesListApi } from '../../api/common';
import { setFloorList } from '../../reducers/Common/FloorInfo';
import { getApiURL } from '../../api';
import socketio from 'socket.io-client';
import { setSocket } from '../../reducers/SocketInfo';
import { SOCKET_CONNECTING } from '../../util/symbol/window';
import Filter from '../MainPages/Filter';
import Settings from '../MainPages/Settings';
import Assets from '../MainPages/Assets';
import AssetInspectionManagement from '../MainPages/AssetInspectionManagement';
import AssetRentalManagement from '../MainPages/AssetRentalManagement';
import AssetAccessSystem from '../MainPages/AssetAccessSystem';
import MedicalEngineering from '../MainPages/MedicalEngineering';
import Status from '../MainPages/Status';
import cx from 'classnames';

const AppMain = () => {
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const { userInfo, modal } = useSelector(state => state.UserInfo);
    const { fullScreen } = useSelector(state => state.DashboardFrame);

    const t = useTranslation('Login');
    const appMainOuterRef = useRef();
    const portal = document.body;

    const { socket, setSocketEvent, closeSocket } = useSocket();

    const { promise: getMoreUserInfo } = useAsync({
        promise: fetchCompany,
        resolve: companyInfo => {
            dispatch(
                setMarkerConfig({
                    markerTransitionActive: companyInfo.markerTransitionActive,
                    markerPulseActive: companyInfo.markerPulseActive,
                    markerBorderRadius: companyInfo.markerBorderRadius,
                    markerPulseAnimation: companyInfo.markerPulseAnimation,
                    markerPulseRssi: companyInfo.markerPulseRssi,
                    markerPulseColor: companyInfo.markerPulseColor,
                }),
            );
        },
    });

    // 화면 리프레쉬 후 유저의 정보가 사라졌을때
    // 로컬스토리지에 저장된 정보 호출 후 저장
    useEffect(() => {
        if (!userInfo.userName) {
            const { oAuthInfo, userInfo } = getLsUserInfo();
            dispatch(setOauthInfo(oAuthInfo));
            if (userInfo) {
                const { lang, ...restUserInfo } = userInfo;
                dispatch(setAdditionalInfo(restUserInfo));
                dispatch(setLanguage(lang));
            }
            // dispatch(setAdditionalInfo(getLsUserInfo()));
        }
    }, [userInfo]);

    // refresh token의 에러가 발생했을 시 토글 메뉴 실행 후 유저인포 초기화
    // 유저인포의 초기화로 인하여 로그인 페이지로 이동
    const toggleOauthStatus = () => {
        dispatch(logOut());
        dispatch(setOauthStatusModal(!modal.modalOauthStatusOpen));
    };

    useUserTokenCheck();

    useEffect(() => {
        getMoreUserInfo();
        if (!window[SOCKET_CONNECTING]) {
            window[SOCKET_CONNECTING] = true;
            getApiURL().then(({ wmsSocketUrl }) => {
                const uuid = getUuid();
                const ws = socketio(wmsSocketUrl, {
                    transports: ['websocket'],
                    forceNew: true,
                    reconnection: true,
                    reconnectionAttempts: 5,
                    reconnectionDelay: 5000,
                });
                ws.on('connect', function () {
                    console.log('SOCKET_CONNECTED : ', wmsSocketUrl);
                    ws.emit('join', `presence-${uuid}`);
                    dispatch(setSocket(ws));
                });
                ws.on('disconnect', function () {
                    console.log('SOCKET_DISCONNECT : ', wmsSocketUrl);
                    dispatch(setSocket(ws));
                });
                ws.on('reconnect', function () {
                    console.log('SOCKET_RECONNECTED : ', wmsSocketUrl);
                    ws.emit('join', `presence-${uuid}`);
                    dispatch(setSocket(ws));
                });
            });
        }

        return () => {
            closeSocket(socket);
        };
    }, []);

    useEffect(() => {
        if (socket) {
            setSocketEvent('notification', data => {
                // console.log('noti', { data });
                dispatch(setAlert(data));
            });
        }
        return () => {
            setSocketEvent('notification');
        };
    }, [socket]);

    // FloorList 받아서 Redux에 저장
    useAsync({
        promise: fetchFloorList,
        param: { isAll: 'Y' },
        resolve: response => {
            dispatch(setFloorList(response));
        },
        immediate: true,
        deps: [match.params.menuNum],
    });

    // CategoryList 받아서 Redux에 저장
    useAsync({
        promise: fetchCategory,
        param: { isAll: 'Y' },
        resolve: ({ rows }) => {
            dispatch(setAllCategoryList(rows));
        },
        immediate: true,
        deps: [match.params.menuNum],
    });

    useAsync({
        promise: getCategoryPropertiesListApi,
        param: { isAll: 'Y' },
        resolve: ({ rows }) => {
            dispatch(setCategoryPropertiesList(rows));
        },
        immediate: true,
        deps: [match.params.menuNum],
    });

    return (
        <>
            {/*<ThemeOptions />*/}
            <AppHeader />
            <div className="app-main">
                <AppSidebar />
                <div className="app-main__outer" ref={appMainOuterRef}>
                    <div className="app-main__inner">
                        <div className={cx('app-page-content', fullScreen && 'dashboard-fullscreen')}>
                            <ErrorHandleSwitch>
                                <Route
                                    exact
                                    path={'/'}
                                    render={() => {
                                        return <Redirect to={'/dashboards'} />;
                                    }}
                                />

                                <Route path={'/dashboards'} component={Dashboards} />
                                <Route path={'/filter'} component={Filter} />
                                <Route path={'/report'} component={Report} />
                                <Route path={'/setting'} component={Settings} />
                                <Route exact path={'/modules'} component={Modules} />
                                <Route path={'/assets'} component={Assets} />
                                <Route path={'/asset-inspection'} component={AssetInspectionManagement} />
                                <Route path={'/asset-rental'} component={AssetRentalManagement} />
                                <Route path={'/status'} component={Status} />
                                <Route path={'/asset-access'} component={AssetAccessSystem} />
                                <Route path={'/medical-engineering'} component={MedicalEngineering} />
                            </ErrorHandleSwitch>

                            <ConfirmModal
                                initModal={modal.modalOauthStatusOpen}
                                toggleModal={toggleOauthStatus}
                                header={{ title: t('Authentication Error') }}
                                confirmText={
                                    <span>
                                        {t('The authentication information is incorrect. Please log in again.')}
                                    </span>
                                }
                                okCallback={toggleOauthStatus}
                                removeCancel={true}
                            />
                            <Portal container={portal}>
                                <LocationPopup appMainOuterRef={appMainOuterRef} />
                            </Portal>
                        </div>
                    </div>
                    <AppFooter />
                </div>
                {fullScreen && <div id={'selectContainer'} />}
            </div>
            {/*<ToastContainer />*/}
            {!fullScreen && <div id={'selectContainer'} />}
        </>
    );
};

export default withRouter(AppMain);
