import React, { useEffect, useState } from 'react';
import CustomRadio from '../../Components/CustomRadio';
import { useRecoilState } from 'recoil';
import { equipmentEvaluationState } from '../../../atoms';
import {
    NOT_USE_EVERYDAY,
    NUM_OF_MONTHS,
    OPERATION_HOUR_PER_CASE,
    USE_EVERY_ONCE_A_DAY,
} from '../../../../util/sheetOption';
import ScoreSheet from '../../../../../Common/ScoreSheet';
import useTranslation from '../../../../../../util/hooks/useTranslation';

const iconObj = {
    A030101: <span className={'icon-check icon-filter-brand'} />,
    A030201: <span className={'icon-check icon-filter-brand'} />,
    A030202: <span className={'icon-check icon-filter-brand'} />,
    A030203: <span className={'icon-check icon-filter-brand'} />,
    A030204: <span className={'icon-check icon-filter-brand'} />,
    A030205: <span className={'icon-check icon-filter-brand'} />,
};
//
// const Data: { [key: string]: string } = {
//     A030101: 'A030101',
//     A030205: 'A030205',
//     A030201: 'A030201',
//     A030202: 'A030202',
//     A030203: 'A030203',
//     A030204: 'A030204',
// };
//
// // as const 읽기전용 속성으로
// const ECD_TYPE_ARR = ['A030201', 'A030202', 'A030203', 'A030204'] as const;
// type AppEcdArrType = typeof ECD_TYPE_ARR[number] | string;
// type AppEcdDataType = { title: string; score: number; value: string };
// type EcdArrDataType = { [key in AppEcdArrType]: AppEcdDataType[] };
// const ArrData: EcdArrDataType = {
//     A030201: NUM_OF_MONTHS,
//     A030202: NOT_USE_EVERYDAY,
//     A030203: OPERATION_HOUR_PER_CASE,
//     A030204: USE_EVERY_ONCE_A_DAY,
// };
//
// const ECD_TYPE_STR = ['A030101', 'A030205'] as const;
// type AppEcdStrType = typeof ECD_TYPE_STR[number] | string;
// type AppEcdNumDataType = number;
// type EcdNumDataType = { [key in AppEcdStrType]: AppEcdNumDataType };
// const NumData: EcdNumDataType = {
//     A030101: 0,
//     A030205: 90,
// };

const Utilization = () => {
    const t = useTranslation('Equipment evaluation');
    const [score, setScore] = useState(0);
    const [evaluationInfo, setEvaluationInfo] = useRecoilState(equipmentEvaluationState);
    const { appPcd, appEcd, profitability1 } = evaluationInfo;

    useEffect(() => {
        let calculatedScore = 0;
        if (appEcd === 'A030101') {
            calculatedScore = 90;
        } else if (appEcd === 'A030201') {
            NUM_OF_MONTHS.map(item => {
                if (item.value === parseInt(appPcd)) {
                    calculatedScore = item.score;
                }
            });
        } else {
            if (appEcd === 'A030202') {
                USE_EVERY_ONCE_A_DAY.map(item => {
                    if (item.value === parseInt(appPcd)) {
                        calculatedScore = item.score;
                    }
                });
            } else if (appEcd === 'A030203') {
                NOT_USE_EVERYDAY.map(item => {
                    if (item.value === parseInt(appPcd)) {
                        calculatedScore = item.score;
                    }
                });
            } else if (appEcd === 'A030204') {
                OPERATION_HOUR_PER_CASE.map(item => {
                    if (item.value === parseInt(appPcd)) {
                        calculatedScore = item.score;
                    }
                });
            } else if (appEcd === 'A030205') {
                calculatedScore = 10;
            }
        }
        //let calculatedScore = 0;
        // if (NumData[appEcd]) {
        //     calculatedScore = NumData[appEcd];
        // }
        // if (ArrData[appEcd]) {
        //     ArrData[appEcd].map(item => {
        //         if (Number(item.value) === Number(appPcd)) {
        //             calculatedScore = item.score;
        //         }
        //     });
        // }
        setScore(calculatedScore);
    }, [evaluationInfo]);

    useEffect(() => {
        setEvaluationInfo({
            ...evaluationInfo,
            appPoint: score,
        });
    }, [score]);

    // const handleClick = e => {
    //     let result = '';
    //     const { checked, value } = e.currentTarget;
    //
    //     //appEcd !== 'A030101'
    //     const key = appEcd;
    //     if (checked && (!appEcd || appPcd === '10')) {
    //         result = value; // Data[appEcd];
    //     }
    //     setEvaluationInfo({
    //         ...evaluationInfo,
    //         appEcd: result,
    //         appPcd: result === 'A030101' ? '10' : '',
    //     });
    // };
    return (
        <ScoreSheet tableClassName="w-100" tbodyClassName="utilization-table">
            <tr className="tr">
                <td rowSpan={6} className="td">
                    11. {t('Utilization')} (*)
                </td>
                <td className="td">{appEcd === 'A030101' && iconObj[appEcd]}</td>
                <td className="td">{t('Prioritized reflection')}</td>
                <td className="td">
                    <CustomRadio
                        text={t('Unrepairable equipment due to hospital policy discontinued equipment and parts')}
                        checked={evaluationInfo.appEcd === 'A030101'}
                        handleChange={e => {
                            const { checked } = e.currentTarget;
                            const { appEcd, appPcd } = evaluationInfo;
                            let result = '';
                            if (checked && (!appEcd || appEcd !== 'A030101' || appPcd === '10')) {
                                result = 'A030101';
                            }
                            setEvaluationInfo({
                                ...evaluationInfo,
                                appEcd: result,
                                appPcd: result === 'A030101' ? '10' : '',
                            });
                        }}
                    />
                    {/*<Label*/}
                    {/*    // id={value}*/}
                    {/*    title="병원 정책적 장비 및 부품단종으로 수리불가 장비(90)"*/}
                    {/*    className="mr-2"*/}
                    {/*    id="test"*/}
                    {/*>*/}
                    {/*    <RadioButton*/}
                    {/*    //    //key={value}*/}
                    {/*    //     //value={value}*/}
                    {/*    //     checked={utilityEcd2 === 'A040301' && utilityPcd2 === value}*/}
                    {/*    //     onChange={e => {*/}
                    {/*    //         const { checked } = e.currentTarget;*/}
                    {/*    //         let result = '';*/}
                    {/*    //         if (*/}
                    {/*    //             checked &&*/}
                    {/*    //             (!utilityPcd2 || utilityPcd2 !== value || utilityEcd2 !== 'A040301')*/}
                    {/*    //         ) {*/}
                    {/*    //             result = value;*/}
                    {/*    //         }*/}
                    {/*    //         setEvaluationInfo({*/}
                    {/*    //             ...evaluationInfo,*/}
                    {/*    //             utilityPcd2: result,*/}
                    {/*    //             utilityEcd2: result ? 'A040301' : '',*/}
                    {/*    //         });*/}
                    {/*    //     }}*/}
                    {/*    />*/}
                    {/*</Label>*/}
                    {/*<CustomRadio*/}
                    {/*    text={'병원 정책적 장비 및 부품단종으로 수리불가 장비(90)'}*/}
                    {/*    checked={NumData[appEcd]}*/}
                    {/*    handleClick={handleClick}*/}
                    {/*/>*/}
                </td>
                <td rowSpan={6} className="td score">
                    {score}
                </td>
            </tr>
            <tr className="tr">
                <td className="td">
                    {evaluationInfo.appEcd === 'A030201' ? <span className="icon-check icon-filter-brand" /> : ''}
                </td>
                <td className="td">{t('Number of cases per month')}</td>
                <td className="td">
                    <div className="align-flex-start-div">
                        <div>
                            <div className="d-block">
                                <span className="font-weight-bold">{t('Average number of cases per month')} </span>
                                <span className="font-weight-bold">{profitability1}</span>
                            </div>
                            <div>
                                {NUM_OF_MONTHS.map(item => {
                                    const { title, value } = item;
                                    const { appEcd, appPcd } = evaluationInfo;
                                    return (
                                        <CustomRadio
                                            key={value}
                                            text={t(title)}
                                            value={value}
                                            checked={appEcd === 'A030201' && parseInt(appPcd) === value}
                                            handleChange={e => {
                                                const { checked } = e.currentTarget;
                                                let result = '';
                                                if (
                                                    checked &&
                                                    (!appPcd || parseInt(appPcd) !== value || appEcd !== 'A030201')
                                                ) {
                                                    result = value;
                                                }
                                                setEvaluationInfo({
                                                    ...evaluationInfo,
                                                    appPcd: result,
                                                    appEcd: result ? 'A030201' : '',
                                                });
                                            }}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr className="tr">
                <td className="td" rowSpan={4}>
                    {['A030202', 'A030203', 'A030204', 'A030205'].includes(appEcd) && iconObj[appEcd]}
                </td>
                <td className="td" rowSpan={4}>
                    {t('Hours of use per incident (not calculated per month)')}
                </td>
                <td className="td">
                    <div className="align-flex-start-div">
                        <div>
                            <div>
                                <span>
                                    {t(
                                        'Default/required roses (used at least once daily) with daily hours of operation of',
                                    )}
                                </span>
                            </div>
                            <div>
                                {USE_EVERY_ONCE_A_DAY.map(item => {
                                    const { title, value } = item;
                                    return (
                                        <CustomRadio
                                            key={value}
                                            text={t(title)}
                                            value={value}
                                            checked={appEcd === 'A030202' && parseInt(appPcd) === value}
                                            handleChange={e => {
                                                const { checked } = e.currentTarget;
                                                let result = '';
                                                if (
                                                    checked &&
                                                    (!appPcd || parseInt(appPcd) !== value || appEcd !== 'A030202')
                                                ) {
                                                    result = value;
                                                }
                                                setEvaluationInfo({
                                                    ...evaluationInfo,
                                                    appPcd: result,
                                                    appEcd: result ? 'A030202' : '',
                                                });
                                            }}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr className="tr">
                <td className="td">
                    <div className="align-flex-start-div">
                        <div>
                            <div>
                                <span>{t('A basic/essential rose (not used every day) with hours per call of')}</span>
                            </div>
                            <div>
                                {NOT_USE_EVERYDAY.map(item => {
                                    const { title, value } = item;
                                    return (
                                        <CustomRadio
                                            key={value}
                                            text={t(title)}
                                            value={value}
                                            checked={appEcd === 'A030203' && parseInt(appPcd) === value}
                                            handleChange={e => {
                                                const { checked } = e.currentTarget;
                                                let result = '';
                                                if (
                                                    checked &&
                                                    (!appPcd || parseInt(appPcd) !== value || appEcd !== 'A030203')
                                                ) {
                                                    result = value;
                                                }
                                                setEvaluationInfo({
                                                    ...evaluationInfo,
                                                    appPcd: result,
                                                    appEcd: result ? 'A030203' : '',
                                                });
                                            }}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr className="tr">
                <td className="td">
                    <div className="align-flex-start-div">
                        <div>
                            <div>
                                <span>{t('Hours of operation per case')}</span>
                            </div>
                            <div>
                                {OPERATION_HOUR_PER_CASE.map(item => {
                                    const { title, value } = item;
                                    return (
                                        <CustomRadio
                                            key={value}
                                            text={t(title)}
                                            value={value}
                                            checked={appEcd === 'A030204' && parseInt(appPcd) === value}
                                            handleChange={e => {
                                                const { checked } = e.currentTarget;
                                                let result = '';
                                                if (
                                                    checked &&
                                                    (!appPcd || parseInt(appPcd) !== value || appEcd !== 'A030204')
                                                ) {
                                                    result = value;
                                                }
                                                setEvaluationInfo({
                                                    ...evaluationInfo,
                                                    appPcd: result,
                                                    appEcd: result ? 'A030204' : '',
                                                });
                                            }}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr className="tr">
                <td className="td">
                    <CustomRadio
                        id="A030205"
                        text={t('Equipment other than aerials')}
                        checked={appEcd === 'A030205'}
                        handleChange={e => {
                            const { checked, value } = e.currentTarget;
                            let result = '';
                            if (checked && (!appEcd || appEcd !== value)) {
                                result = 'A030205';
                            }

                            setEvaluationInfo({
                                ...evaluationInfo,
                                appEcd: result,
                                appPcd: result === 'A030205' ? '' : '10',
                            });
                        }}
                    />
                </td>
            </tr>
        </ScoreSheet>
    );
};

export default Utilization;
