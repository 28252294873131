import React, { useState, useEffect, useMemo } from 'react';
import { Modal, ModalBody, ModalFooter, Button as ModalButton } from 'reactstrap';
import DetailModalHeader from '../../Status/Components/DetailModalHeader';
import useTranslation from '../../../../util/hooks/useTranslation';
import ReactSelect from '../../../Common/Select';
import Button from '../../../Common/Button';
import Table from '../../../Common/Table';
import ConditionLabel from './Components/ConditionLabel';
import TextInput from '../../../Common/Input/TextInput';
import useColumns from '../../../../util/hooks/useColumns';
import cx from 'classnames';
import useAsync from '../../../../util/hooks/useAsync';
import { getAssetListApi } from '../../../../api/asset';
import { useSelector } from 'react-redux';

const falsyToHyphen = ({ value }) => {
    return value ? value : '-';
};

const AssetSearchModal = ({
    initModal = false,
    toggleModal = function () {
        initModal = !initModal;
    },
    removeCancel,
    cancelCallback,
    okCallback,
    removeOk,
    okClassName,
    callbackParam,
    className,

    ...restProps
}) => {
    const t = useTranslation('AssetSearch');

    const searchOptions = useMemo(() => {
        return [
            {
                value: 'targetId',
                label: t('Asset Number'),
            },
            {
                value: 'own_dep_nm',
                label: t('Affiliation'),
            },
            {
                value: 'targetName',
                label: t('Modal Name'),
            },
        ];
    }, [t]);

    const [selectedOption, setSelectedOption] = useState(searchOptions[0]);
    const [assetList, setAssetList] = useState({ rows: [] });
    const [condition, setCondition] = useState('');
    const [checked, setChecked] = useState({});
    const [searchedOption, setSearchedOption] = useState(null);
    const [tableKey, setTableKey] = useState(0);

    const { serviceCategory, serviceCategoryCodes } = useSelector(state => state.CategoryInfo);

    const [categoryCodes, setCategoryCodes] = useState(serviceCategory);

    useEffect(() => {
        if (serviceCategoryCodes) {
            setCategoryCodes(`${serviceCategory},${serviceCategoryCodes}`);
        }
    }, [serviceCategoryCodes]);

    const { promise: getAssetList } = useAsync({
        promise: getAssetListApi,
        fixedParam: {
            pageSize: 10,
            categoryCodes,
        },
        immediate: true,
        deps: [categoryCodes],
        resolve: res => {
            setAssetList(res);
        },
    });

    useEffect(() => {
        if (selectedOption) {
            setSelectedOption(searchOptions.find(option => option.value === selectedOption.value));
        }
    }, [searchOptions]);

    const assetListColumns = useColumns(
        [
            {
                Header: 'Select',
                width: 50,
                accessor: 'radio',
                Cell: ({ row: { original } }) => {
                    return (
                        <input
                            style={{ marginLeft: '0.5rem' }}
                            type="radio"
                            name={'categoryCode'}
                            onClick={e => {
                                e.stopPropagation();
                                setChecked(original);
                            }}
                        />
                    );
                },
            },
            {
                Header: 'Asset Number',
                accessor: 'targetId',
                Cell: falsyToHyphen,
            },
            {
                Header: 'Affiliation',
                accessor: 'properties.own_dep_nm',
                Cell: falsyToHyphen,
            },
            {
                Header: 'Modal Name',
                accessor: 'targetName',
                Cell: falsyToHyphen,
            },
        ],
        t,
    );

    const handleSelect = select => {
        setSelectedOption(select);
    };

    const handleCondition = e => {
        setCondition(e.target.value);
    };

    const handleModal = () => {
        toggleModal();
        setChecked({});
    };

    const handlePageChange = pageIndex => {
        if (searchedOption) {
            getAssetList({ page: pageIndex, ...searchedOption });
        } else {
            getAssetList({ page: pageIndex });
        }
    };

    const handleSearchClick = () => {
        const { value } = selectedOption;
        if (['targetName', 'targetId'].includes(value)) {
            const searchOpt = {
                opt: value,
                keyword: condition,
            };
            getAssetList(searchOpt);
            setSearchedOption(searchOpt);
        } else {
            // 소속검색
            if (condition) {
                const searchOpt = {
                    'targetPropertiesSearch.categoryCode': 'ASSET',
                    [`targetPropertiesSearch.propertySearchs[0].propertyId`]: value,
                    [`targetPropertiesSearch.propertySearchs[0].values`]: condition,
                };
                getAssetList(searchOpt);
                setSearchedOption(searchOpt);
            } else {
                getAssetList();
                setSearchedOption(null);
            }
        }
        setTableKey(tableKey + 1);
    };

    return (
        <>
            <Modal
                container={document.getElementsByClassName('app-container')[0]}
                className={cx('pnt-modal', className)}
                isOpen={initModal}
                toggle={handleModal}
                style={{ width: '600px', maxWidth: '600px' }}
                {...restProps}
            >
                <DetailModalHeader headerTitleStyle={{ maxWidth: '100%' }} title={t('Search Asset')} />
                <ModalBody style={{ display: 'grid', rowGap: '5px' }}>
                    <ConditionLabel
                        name={t('Condition', 'AccessSearch')}
                        valueClassName="condition-container"
                        value={
                            <>
                                <ReactSelect
                                    name={'Condition Select'}
                                    value={selectedOption}
                                    options={searchOptions}
                                    onChange={handleSelect}
                                />
                                <TextInput
                                    className="ml-1"
                                    name={'Condition Input'}
                                    placeholder={t('Condition Content')}
                                    handleChange={handleCondition}
                                    value={condition}
                                />
                                <Button className="btn-secondary" onClick={handleSearchClick}>
                                    {t('Search', 'Button')}
                                </Button>
                            </>
                        }
                    />
                    <div className={'mt-3'} style={{ height: 400 }}>
                        <Table
                            key={tableKey}
                            data={{ ...assetList, pageSize: 10 }}
                            textAlign={'center'}
                            columns={assetListColumns}
                            onPageChange={handlePageChange}
                        />
                    </div>
                </ModalBody>
                <ModalFooter className={cx(!removeCancel && 'justify-content-between')}>
                    {!removeCancel && (
                        <ModalButton
                            color="secondary"
                            onClick={e => {
                                if (typeof cancelCallback === 'function') {
                                    cancelCallback(callbackParam);
                                }
                                handleModal();
                            }}
                        >
                            {t('Cancel', 'Button')}
                        </ModalButton>
                    )}
                    {!removeOk && (
                        <>
                            <ModalButton
                                className={okClassName}
                                color="primary"
                                onClick={e => {
                                    if (typeof okCallback === 'function') {
                                        okCallback(checked);
                                    }
                                    handleModal();
                                }}
                            >
                                {t('Select', 'Button')}
                            </ModalButton>
                        </>
                    )}
                </ModalFooter>
            </Modal>
        </>
    );
};

export default AssetSearchModal;
