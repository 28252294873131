import React, { useEffect, useState } from 'react';
import Page from '../../../Common/Page';
import useTranslation from '../../../../util/hooks/useTranslation';
import useColumns from '../../../../util/hooks/useColumns';
import * as column from '../../util/grid/column';
import Table from '../../../Common/Table';
import Card from '../../../Common/Card';
import useAsync from '../../../../util/hooks/useAsync';
import { fetchIotItemList, getStatusInfoApi } from '../../../../api/asset';
import { useHistory } from 'react-router-dom';
import DatePicker from '../../../Common/DatePicker';
import moment from 'moment';
import InspectionCategorySearchableSelect from '../FilterOption/InspectionCategorySearchableSelect';
import InspectionOwnershipSearchableSelect from '../FilterOption/InspectionOwnershipSearchableSelect';
import Search from '../../Components/Search';

const AssetInspectionScheduled = () => {
    const t = useTranslation('Asset Inspection Scheduled');
    const history = useHistory();
    const [tableKey, setTableKey] = useState(0);
    const [assetList, setAssetList] = useState({
        totalCount: 0,
        totalPage: 1,
        rows: [],
    });

    const [selectedSearchOption, setSelectedSearchOption] = useState({
        usingDepartmentName: [],
        categoryCodes: [],
    });
    const [startDate, setStartDate] = useState(moment().startOf('isoweek').unix() * 1000);
    const [endDate, setEndDate] = useState(moment().endOf('isoweek').unix() * 1000);
    const [filterParam, setFilterParam] = useState({});
    const [assetStatusList, setAssetStatusList] = useState();
    const { promise: getStatusInfo } = useAsync({
        promise: getStatusInfoApi,
        resolve: res => {
            setAssetStatusList(res);
        },
    });

    const handleSearchClick = keyword => {
        const param = {
            ...filterParam,
            'targetPropertiesSearch.categoryCode': 'ASSET',
            [`targetPropertiesSearch.propertySearchs[0].propertyId`]: 'scheduled_inspection_date',
            [`targetPropertiesSearch.propertySearchs[0].values[0]`]: startDate / 1000,
            [`targetPropertiesSearch.propertySearchs[0].values[1]`]: endDate / 1000,
        };
        if (keyword) {
            getAssetInspectionList({ ...param, opt: 'targetSearch', keyword: keyword });
        } else {
            getAssetInspectionList({ ...param });
        }
    };

    const handleRefreshClick = e => {
        setTableKey(prevState => prevState + 1);
        let startDate = moment().startOf('isoweek').unix() * 1000;
        let endDate = moment().endOf('isoweek').unix() * 1000;
        setSelectedSearchOption([]);
        setStartDate(startDate);
        setEndDate(endDate);
        const initialFilter = {
            'targetPropertiesSearch.categoryCode': 'ASSET',
            [`targetPropertiesSearch.propertySearchs[0].propertyId`]: 'scheduled_inspection_date',
            [`targetPropertiesSearch.propertySearchs[0].values[0]`]: startDate / 1000,
            [`targetPropertiesSearch.propertySearchs[0].values[1]`]: endDate / 1000,
        };
        setFilterParam(initialFilter);
        getAssetInspectionList(initialFilter);
    };

    const handlePageChange = pageIndex => {
        if (assetListInfo.request) {
            getAssetInspectionList({ ...assetListInfo.request, page: pageIndex });
        }
    };

    const handleChangeStartDate = selected => {
        setStartDate(selected);
    };
    const handleChangeEndDate = selected => {
        setEndDate(selected);
    };

    const { promise: getAssetInspectionList, state: assetListInfo } = useAsync({
        promise: fetchIotItemList,
        fixedParam: { pageSize: 20 },
        keepState: true,
        resolve: res => {
            setAssetList(res);
        },
    });

    const columns = useColumns(
        [
            column.assetCategorization(),
            column.assetName(),
            column.assetTargetNumber(),
            column.ownDepartmentName(),
            column.assetLocation(),
            column.scheduledInspectionDate(),
            column.assetStatus({ assetStatusList }),
        ],
        null,
        [assetStatusList],
    );

    useEffect(() => {
        getStatusInfo();
        if (startDate && endDate) {
            let dateParam = {
                'targetPropertiesSearch.categoryCode': 'ASSET',
                [`targetPropertiesSearch.propertySearchs[0].propertyId`]: 'scheduled_inspection_date',
                [`targetPropertiesSearch.propertySearchs[0].values[0]`]: startDate / 1000,
                [`targetPropertiesSearch.propertySearchs[0].values[1]`]: endDate / 1000,
            };
            setFilterParam(dateParam);
            getAssetInspectionList({ ...dateParam });
        }
    }, []);

    return (
        <Page menuPath={[t('Asset Inspection Management', 'Menu'), t('Asset Inspection Scheduled', 'Menu')]}>
            <div className="grid-layout-container">
                {/*<SearchWithFilter handleSearchClick={handleSearchClick} handleRefreshClick={handleRefreshClick}>*/}
                <Search
                    searchInputPlaceholder={t('Please enter the asset name or asset id.')}
                    handleSearchClick={handleSearchClick}
                    handleRefreshClick={handleRefreshClick}
                >
                    <InspectionOwnershipSearchableSelect
                        handleChange={selected => {
                            let propertySearches = {};
                            if (selected.length > 0) {
                                selected.map((groupNum, index) => {
                                    propertySearches[
                                        `targetPropertiesSearch.propertySearchs[${index + 1}].propertyId`
                                    ] = 'own_dep_id';
                                    propertySearches[`targetPropertiesSearch.propertySearchs[${index + 1}].values`] =
                                        groupNum;
                                });
                            }

                            if (selected.length > 0) {
                                setFilterParam({
                                    'targetPropertiesSearch.categoryCode': 'ASSET',
                                    ...propertySearches,
                                });
                            } else {
                                setFilterParam({
                                    'targetPropertiesSearch.categoryCode': 'ASSET',
                                });
                            }

                            setSelectedSearchOption(prevState => {
                                return { ...prevState, usingDepartmentName: selected };
                            });
                        }}
                        className={'m-0 mr-2'}
                        selected={selectedSearchOption.usingDepartmentName}
                    />
                    <InspectionCategorySearchableSelect
                        handleChange={selected => {
                            setFilterParam({
                                ...filterParam,
                                categoryCodes: selected.map(v => v.categoryCode).join(','),
                            });

                            setSelectedSearchOption(prevState => {
                                return { ...prevState, categoryCodes: selected.map(v => v.categoryCode) };
                            });
                        }}
                        changeable={'Y'}
                        className={'m-0'}
                        selected={selectedSearchOption.categoryCodes}
                        hideOption
                    />
                    <DatePicker
                        value={startDate}
                        valueType={'ms'}
                        handleChange={handleChangeStartDate}
                        maxDate={endDate}
                        withoutTime
                    />
                    <DatePicker
                        value={endDate}
                        valueType={'ms'}
                        minDate={startDate}
                        handleChange={handleChangeEndDate}
                        withoutTime
                    />
                </Search>
                {/*</SearchWithFilter>*/}
                <Card
                    header={{
                        title: t('Expect to be checked asset list', 'Menu'),
                    }}
                >
                    <div className={'table-container'}>
                        <Table
                            key={tableKey}
                            data={{ ...assetList, pageSize: 20 }}
                            columns={columns}
                            onPageChange={handlePageChange}
                            onTrClick={info => history.push(`/asset-inspection/detail/${info.targetNum}`)}
                            loading={assetListInfo.isLoading}
                        />
                    </div>
                </Card>
            </div>
        </Page>
    );
};

export default AssetInspectionScheduled;
