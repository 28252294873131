import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import Tab from '../../../Common/Tab';
import { useHistory, useParams } from 'react-router-dom';
import useAsync from '../../../../util/hooks/useAsync';
import {
    getAssetStatusLogApi,
    getRealtimePresenceInfoApi,
    getSelectedAssetInfoApi,
    getStatusInfoApi,
} from '../../../../api/asset';
import moment from 'moment';
import { fetchGeofenceList, getCategoryPropertyInfoApi, getSelectedFloorInfoApi } from '../../../../api/common';
import { getTransitionInfoByCategoryApi } from '../../../../api/status';
import { updateAssetStatusApi } from '../../../../api/asset';
import { Popup } from 'react-leaflet';
import styled from 'styled-components';
import { returnCategoryProperty } from '../../../../util/common/util';
import { useSelector } from 'react-redux';
import AssetDetailInformation from './Components/AssetDetailInformation';
import LocationTab from './Components/LocationTab';
import StatusJournalTab from './Components/StatusJournalTab';

const StyledPop = styled(Popup)`
    .leaflet-popup-content-wrapper {
        border-radius: 0;
        background-color: #525252;
        color: white;
        width: 200px;
        height: 150px;
    }

    .leaflet-popup-tip {
        background-color: #525252;
    }
`;

function getCategoryPropertyValue(assetDataList, propertyInfoList) {
    let matchedArr = [];
    let arr = Object.keys(assetDataList).map(key => [key, assetDataList[key]]);
    propertyInfoList.map(prop => {
        let foundObj;
        let resArr;
        let value;
        switch (prop.inputType) {
            case 'checkbox':
                resArr = [];
                foundObj = arr.find(v => v[0] === prop.propertyId);
                let foundValue = JSON.parse(foundObj[1]);

                foundValue.map(value => {
                    let temp = prop.inputValues.find(v => v.value === value);
                    resArr.push(temp.name);
                });

                matchedArr.push([prop.displayName, resArr]);

                break;
            case 'radio' || 'select':
                foundObj = arr.find(v => v[0] === prop.propertyId);
                prop.inputValues.map(v => {
                    if (v.value === foundObj[1]) value = v.name;
                });
                matchedArr.push([prop.displayName, value]);

                break;
            case 'date':
                foundObj = arr.find(v => v[0] === prop.propertyId);
                foundObj &&
                    matchedArr.push([
                        prop.displayName,
                        foundObj[1] ? moment(foundObj[1] * 1000).format('YYYY-MM-DD') : '',
                    ]);
                break;
            default:
                foundObj = arr.find(v => v[0] === prop.propertyId);
                foundObj && matchedArr.push([prop.displayName, foundObj[1]]);
                break;
        }
    });

    return matchedArr;
}

const AssetDetail = ({ quickSearch = false, givenTargetNum }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { targetNum } = useParams();

    const [open, setOpen] = useState(false);
    const [assetInfo, setAssetInfo] = useState({});
    const [floorInfo, setFloorInfo] = useState([]);
    const [geofenceInfo, setGeofenceInfo] = useState([]);
    const [categoryPropertyInfo, setCategoryPropertyInfo] = useState([]);
    const [locationInfo, setLocationInfo] = useState([]);
    const [statusLogInfo, setStatusLogInfo] = useState([]);
    //const [selectedLogInfo, setSelectedLogInfo] = useState({});
    const [statusColorInfo, setStatusColorInfo] = useState({});
    const [assetCategoryPropertyInfo, setAssetCategoryPropertyInfo] = useState([]);
    const [selectedAssetStatus, setSelectedAssetStatus] = useState([]);

    const { promise: getSelectedAssetInfo } = useAsync({
        promise: getSelectedAssetInfoApi,
        resolve: res => {
            const result = res ? res : {};
            setAssetInfo(result);
        },
        reject: err => console.error(err),
    });

    const { promise: getSelectedFloorInfo } = useAsync({
        promise: getSelectedFloorInfoApi,
        resolve: res => {
            setFloorInfo(res);
        },
        reject: err => console.error(err),
    });
    const { promise: getGeofenceInfo } = useAsync({
        promise: fetchGeofenceList,
        resolve: res => {
            setGeofenceInfo(res);
        },
        reject: err => console.error(err),
    });

    const { promise: getRealtimePresenceInfo } = useAsync({
        promise: getRealtimePresenceInfoApi,
        resolve: res => {
            if (!res) {
                setLocationInfo({ ...locationInfo, tagName: '-', tagGeofence: '-' });
            } else {
                setLocationInfo({
                    ...locationInfo,
                    tagName: res.beacon.beaconName,
                    tagGeofence: res.geofenceInOutState ? res.geofenceInOutState.name : '-',
                });
            }
        },
        reject: err => console.error(err),
    });

    const { promise: getCategoryPropertyInfo } = useAsync({
        promise: getCategoryPropertyInfoApi,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            const filteredCategoryPropertyList = res.rows.filter(prop => prop.isListProperty === 'Y');
            setCategoryPropertyInfo(filteredCategoryPropertyList);
        },
        reject: err => console.error(err),
    });

    const { promise: getStatusInfo } = useAsync({
        promise: getStatusInfoApi,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            const statusToColor = {};
            if (res.rows) {
                res.rows.forEach(v => {
                    statusToColor[v.statusNum] = v.displayColor;
                });
                setStatusColorInfo(statusToColor);
            }
        },
        reject: err => console.error(err),
    });
    const { promise: getTransitionInfoByCategory } = useAsync({
        promise: getTransitionInfoByCategoryApi,
        resolve: res => {
            const tempArr = [];
            res.rows.map(action => {
                tempArr.push({
                    ...action,
                    endStatusName: action.statusName,
                });
            });
            setSelectedAssetStatus(tempArr);
        },
        reject: err => console.error(err),
    });

    const { promise: getAssetStatusLog } = useAsync({
        promise: getAssetStatusLogApi,
        resolve: res => {
            if (res.rows.length > 0) {
                setStatusLogInfo(res.rows);
            }
        },
        reject: err => console.error(err),
    });

    const { promise: updateAssetStatus } = useAsync({
        promise: updateAssetStatusApi,
        resolve: res => {
            if (targetNum) {
                getSelectedAssetInfo({ targetKey: Number(targetNum) });
            }
        },
        reject: err => console.error(err),
    });

    // const handleLogClick = e => {
    //     let foundOne = statusLogInfo.find(log => log.logNum === e.logNum);
    //     setSelectedLogInfo(foundOne);
    // };

    useEffect(() => {
        if (givenTargetNum) {
            getSelectedAssetInfo({ targetKey: Number(givenTargetNum) });
        }
    }, [givenTargetNum]);

    useEffect(() => {
        if (targetNum) {
            getSelectedAssetInfo({ targetKey: Number(targetNum) });
        }
    }, [targetNum]);

    useEffect(() => {
        setCategoryPropertyInfo([]);
        setGeofenceInfo([]);
        setFloorInfo([]);
        if (assetInfo && assetInfo.categoryCode) {
            getTransitionInfoByCategory({ statusNum: assetInfo.statusNum, categoryCode: assetInfo.categoryCode });
        }
        if (assetInfo && assetInfo.floorId) {
            getSelectedFloorInfo({ floorId: assetInfo.floorId });
            getGeofenceInfo({ floor: assetInfo.floorId });
            getCategoryPropertyInfo({ categoryCode: assetInfo.categoryCode });
            getAssetStatusLog({ targetNum: assetInfo.targetNum });
            getStatusInfo();
        }
    }, [assetInfo]);

    useEffect(() => {
        if (assetInfo && assetInfo.properties && categoryPropertyInfo.length > 0) {
            let result_map = Object.keys(assetInfo.properties).map(function (key) {
                return { id: String(key), value: assetInfo.properties[key] };
            });
            const tempList = returnCategoryProperty(result_map, categoryPropertyInfo);
            setAssetCategoryPropertyInfo(tempList);
        }
    }, [categoryPropertyInfo]);

    const click = e => {
        setOpen(!open);
    };
    const handleActionBtnClick = action => {
        updateAssetStatus({
            targetNum: assetInfo.targetNum,
            actionNum: action.actionNum,
            statusNum: action.endStatusNum,
        });
    };

    return (
        <>
            {!quickSearch && (
                <div className={'mb-3'}>
                    <div className="card-header ">
                        <div className="card-header__title">
                            <h3 className="title__main">{t('Asset;Asset List')}</h3>
                        </div>
                        <div className="card-header__function">
                            <button
                                className="pnt-btn btn-gray"
                                onClick={e => {
                                    history.goBack();
                                }}
                            >
                                {t('Button;Back')}
                            </button>
                            {selectedAssetStatus && selectedAssetStatus.length > 0 && (
                                <Dropdown isOpen={open} toggle={click}>
                                    <DropdownToggle className="pnt-btn btn-darkgray btn-icon btn-long btn-dropdown pr-3">
                                        {t('Asset;Action')}
                                        <span className="ml-2 icon-small icon-down m-0" />
                                    </DropdownToggle>
                                    <DropdownMenu right className={'dropdown-menu-sm'}>
                                        {selectedAssetStatus.map(status => (
                                            <DropdownItem
                                                key={status.actionNum}
                                                onClick={e => {
                                                    handleActionBtnClick(status);
                                                }}
                                            >
                                                {status.actionName}
                                            </DropdownItem>
                                        ))}
                                    </DropdownMenu>
                                </Dropdown>
                            )}
                        </div>
                    </div>
                </div>
            )}
            <div className={'grid-layout-container-for-assetDetail w-100'}>
                <div className={'margin-bottom-under-770'}>
                    <AssetDetailInformation
                        assetInfo={assetInfo}
                        assetCategoryPropertyInfo={assetCategoryPropertyInfo}
                    />
                </div>
                <div>
                    <Tab
                        activeTabId={'location'}
                        styles={{ header: { justifyContent: 'center' }, headerItem: { width: '50%' } }}
                        tabs={[
                            {
                                tabId: 'location',
                                title: t('Asset;Location'),
                                content: (
                                    <LocationTab
                                        assetInfo={assetInfo}
                                        floorInfo={floorInfo}
                                        locationInfo={locationInfo}
                                        geofenceInfo={geofenceInfo}
                                    />
                                ),
                            },
                            {
                                tabId: 'statusJournal',
                                title: t('Asset;Status journal'),
                                content: (
                                    <StatusJournalTab statusLogInfo={statusLogInfo} statusColorInfo={statusColorInfo} />
                                ),
                            },
                        ]}
                    />
                </div>
            </div>
        </>
    );
};

export default AssetDetail;
