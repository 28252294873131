import React from 'react';
import TextInput from '../../../../Common/Input/TextInput';
import Button from '../../../../Common/Button';
import useTranslation from '../../../../../util/hooks/useTranslation';
import cx from 'classnames';
const Search = ({
    name,
    inputDisabled,
    inputClassName,
    buttonName,
    onClick,
    value,
    onChange,
    readOnly,
    placeholder,
}) => {
    const t = useTranslation('Equipment evaluation');
    const placeHolder = t('Enter equipment name');
    return (
        <div className="d-flex ">
            <TextInput
                name={name}
                readOnly={readOnly}
                placeholder={placeHolder}
                inputGroupClassName={cx(inputClassName && inputClassName)}
                value={value}
                handleChange={onChange}
                disabled={inputDisabled}
            />
            <Button className="btn-gray mr-2" onClick={onClick}>
                {t(buttonName, 'Button')}
            </Button>
        </div>
    );
};

export default Search;
