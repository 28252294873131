import React, { useState, useRef, useEffect } from 'react';
import cx from 'classnames';
import useTranslation from '../../../../util/hooks/useTranslation';

const SingleSelect = ({
    valueKey = 'value',
    labelKey = 'label',
    options,
    value,
    className,
    onChange,
    iconClassName = '',
    placeholder = 'Select...',
    listStyle,
    customSelectedValue = false,
    disabled,
}) => {
    const t = useTranslation('Select');
    const ref = useRef(null);
    const [openSelect, setOpenSelect] = useState(false);

    const handleSelectedClick = e => {
        const { value } = e.currentTarget;
        const selected = options[value];

        if (!customSelectedValue) {
            onChange(selected[valueKey]);
        } else {
            onChange(selected);
        }
        setOpenSelect(!openSelect);
    };

    useEffect(() => {
        const checkIfClickedOutside = e => {
            if (openSelect && ref.current && !ref.current.contains(e.target)) {
                setOpenSelect(false);
            }
        };

        document.addEventListener('mousedown', checkIfClickedOutside);

        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [openSelect]);

    return (
        <div
            className={cx(
                'pnt-select--group',
                openSelect && className !== 'form-disable' && 'on',
                iconClassName && 'icon-on-left',
                className,
            )}
            ref={ref}
        >
            <button disabled={disabled} className={'select__btn'} onClick={() => setOpenSelect(!openSelect)}>
                <div className="cont-wrap">
                    <span className={iconClassName} />
                    {(value ? options.find(v => v[valueKey] === value)[labelKey] : placeholder) || t('Select')}
                </div>
                <span className="icon-down" />
            </button>
            <ul className={'select__options'} style={listStyle}>
                {options.map((list, index) => {
                    return (
                        <li
                            key={list[valueKey]}
                            value={index}
                            className={cx(value === list[valueKey] && 'on', 'p-0')}
                            onClick={handleSelectedClick}
                        >
                            <a style={{ cursor: 'pointer' }}>{list[labelKey]}</a>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default SingleSelect;
