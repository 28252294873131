import React, { useEffect, useRef, useState } from 'react';
import Card from '../../../../Common/Card';
import useTranslation from '../../../../../util/hooks/useTranslation';
import Button from '../../../../Common/Button';
import { useHistory, useParams } from 'react-router-dom';
import Label from '../../../../Common/Label';
import TextInput from '../../../../Common/Input/TextInput';
import ConfirmModal from '../../../../Common/ConfirmModal';
import ValidationModal from '../../../Components/Modal/ValidationModal';
import ReactSelect from '../../../../Common/Select';
import LocationTab from '../../../Assets/AssetDetail/Components/LocationTab';
import UserSearchModal from '../../../Components/Modal/UserSearchModal';
import {
    getRealtimePresenceInfoApi,
    getRepairTakeoverApi,
    getSelectedAssetInfoApi,
    updateCancelRepairTakeoverApi,
    updateRepairTakeoverApi,
} from '../../../../../api/asset';
import { fetchGeofenceList, getSelectedFloorInfoApi } from '../../../../../api/common';
import useConfirmModal from '../../../../Common/ConfirmModal/useConfirmModal';
import useAsync from '../../../../../util/hooks/useAsync';
import moment from 'moment';

const RepairDetail = () => {
    const t = useTranslation('Transfer Detail');
    const history = useHistory();
    const params = useParams();

    const prevStatus = useRef(null);

    const searchOptions = [
        { value: 'REPAIR_COMPLETE', label: t('REPAIR_COMPLETE', 'Asset') },
        { value: 'TAKEOVER_COMPLETE', label: t('TAKEOVER_COMPLETE', 'Asset') },
        { value: 'TAKEOVER_WAIT', label: t('TAKEOVER_WAIT', 'Asset') },
        { value: 'TAKEOVER_BEFORE', label: t('TAKEOVER_BEFORE', 'Asset') },
        { value: 'IN_USE', label: t('IN_USE', 'Asset') },
    ];

    // 모달 상태들
    const [cancelRegisterModal, setCancelRegisterModal] = useState(false);
    const [validationModal, setValidationModal] = useState(false);
    const [saveModal, setSaveModal] = useState(false);
    const [userSearchModal, setUserSearchModal] = useState(false);

    // 유효성 검사
    const [validationModalText, setValidationModalText] = useState([]);

    // input 상태들
    const [inputs, setInputs] = useState({
        accepterCode: '',
        accepter: '',
        affiliationCode: '',
        deptName: '',
        assetCode: '',
        asset: '',
        note: '',
        registrationDate: '',
    });

    const { accepterCode, accepter, affiliationCode, deptName, assetCode, asset, note, registrationDate } = inputs;

    // select box
    const [selectedOption, setSelectedOption] = useState(searchOptions[0]);

    // assetDetail 상태
    const [assetInfo, setAssetInfo] = useState({});
    const [locationInfo, setLocationInfo] = useState([]);
    const [geofenceInfo, setGeofenceInfo] = useState([]);
    const [floorInfo, setFloorInfo] = useState([]);

    // 유저 정보
    const [userInfo, setUserInfo] = useState({});

    // 자산 정보
    const [receptionInfo, setReceptionInfo] = useState({});

    const { targetId, statusType } = receptionInfo;

    const { receptionNum } = params;

    useEffect(() => {
        if (userInfo.targetNum) {
            const { targetId, targetName, properties } = userInfo;
            const { deptCode, deptName } = properties;

            const affiliationCode = properties.hasOwnProperty('deptCode') ? deptCode : '';
            const isDeptName = properties.hasOwnProperty('deptName') ? deptName : '';

            setInputs(prevState => ({
                ...prevState,
                accepterCode: targetId,
                accepter: targetName,
                affiliationCode: affiliationCode,
                deptName: isDeptName,
            }));

            // 초기화
            setUserInfo({});
        }
    }, [userInfo.targetNum]);

    // 현재 인계상태로 바꾸기
    useEffect(() => {
        if (receptionInfo.statusType) {
            const foundOption = searchOptions.find(option => option.value === receptionInfo.statusType);
            if (foundOption) {
                setSelectedOption(foundOption);
            }
        }
    }, [receptionInfo.statusType]);

    const { promise: getRepairTakeover } = useAsync({
        promise: getRepairTakeoverApi,
        fixedParam: {
            receptionNum,
        },
        immediate: true,
        deps: [receptionNum],
        resolve: res => {
            setReceptionInfo(res);
            const { targetName, targetId, regDate, groupId, groupName, userId, userName, description, statusType } =
                res;
            setInputs(prevState => ({
                ...prevState,
                accepterCode: userId,
                accepter: userName,
                affiliationCode: groupId,
                deptName: groupName,
                asset: targetName,
                assetCode: targetId,
                note: description,
                registrationDate: moment(regDate * 1000).format('YYYY/MM/DD HH:mm:ss'),
            }));
            prevStatus.current = statusType;
        },
    });

    const { promise: getSelectedAssetInfo } = useAsync({
        promise: getSelectedAssetInfoApi,
        resolve: res => {
            if (res) {
                setAssetInfo(res);
            } else {
                setAssetInfo({});
            }
        },
    });

    useEffect(() => {
        if (targetId) {
            getSelectedAssetInfo({ targetKey: targetId, keyType: 'targetId' });
        }
    }, [targetId]);

    const { promise: updateCancelRepairTakeover } = useAsync({
        promise: updateCancelRepairTakeoverApi,
        fixedParam: { description: note, receptionNum },
        resolve: res => {
            getRepairTakeover();
            toggleCancelTransferCompleteModal();
            prevStatus.current = 'TAKEOVER_BEFORE';
        },
        reject: err => console.error(err),
    });

    const { promise: updateRepairTakeover } = useAsync({
        promise: updateRepairTakeoverApi,
        fixedParam: { receptionNum },
        resolve: res => {
            toggleSaveCompleteModal();
            getRepairTakeover();
            prevStatus.current = 'TAKEOVER_COMPLETE';
        },
        reject: err => console.error(err),
    });

    const { promise: getGeofenceInfo } = useAsync({
        promise: fetchGeofenceList,
        resolve: res => {
            setGeofenceInfo(res);
        },
    });

    const { promise: getRealtimePresenceInfo } = useAsync({
        promise: getRealtimePresenceInfoApi,
        resolve: res => {
            if (!res) {
                setLocationInfo({ ...locationInfo, tagName: '-', tagGeofence: '-' });
            } else {
                let geofenceState;
                if (res.geofencesInOutState) {
                    geofenceState = res.geofencesInOutState[Object.keys(res.geofencesInOutState)[0]];
                }
                setLocationInfo({
                    ...locationInfo,
                    tagName: res.beacon.beaconName,
                    tagGeofence: geofenceState ? geofenceState.name : '-',
                });
            }
        },
    });

    const { promise: getSelectedFloorInfo } = useAsync({
        promise: getSelectedFloorInfoApi,
        resolve: res => {
            setFloorInfo(res);
        },
        reject: err => console.error(err),
    });

    useEffect(() => {
        setGeofenceInfo([]);
        setFloorInfo([]);

        if (assetInfo && assetInfo.floorId) {
            const { floorId, categoryCode, targetNum } = assetInfo;
            getSelectedFloorInfo({ floorId });
            getGeofenceInfo({ floor: floorId });
            getRealtimePresenceInfo({ floorIds: floorId, targetNum, categoryCodes: categoryCode });
        }
    }, [assetInfo]);

    useEffect(() => {
        if (selectedOption) {
            setSelectedOption(searchOptions.find(option => option.value === selectedOption.value));
        }
    }, [searchOptions[0].label]);

    useEffect(() => {
        const text = [];
        if (!accepter || !accepterCode) {
            text.push('Accepter information is required.');
        }

        if (!asset || !assetCode) {
            text.push('Transfer asset information is required.');
        }
        setValidationModalText(text);
    }, [accepter, accepterCode, affiliationCode, asset, assetCode]);

    const handleSelectChange = list => {
        setSelectedOption(list);
    };

    // input 함수들
    const onChange = ({ target: { value, name } }) => {
        setInputs({
            ...inputs,
            [name]: value,
        });
    };

    // 모달 함수들
    const handleCancelRegisterModal = () => {
        setCancelRegisterModal(!cancelRegisterModal);
    };

    const handleValidationModal = () => {
        setValidationModal(!validationModal);
    };

    const handleSaveModal = () => {
        if (['REPAIR_COMPLETE', 'TAKEOVER_BEFORE'].includes(selectedOption.value)) {
            toggleCantChangeModal();
        } else if (prevStatus.current === selectedOption.value) {
            toggleReRegisterModal();
        } else {
            setSaveModal(!saveModal);
        }
    };

    const handleUserSearchModal = () => {
        setUserSearchModal(!userSearchModal);
    };

    // useConfirmModal
    const { toggleModal: toggleCancelTransferCompleteModal, Modal: CancelTransferCompleteModal } = useConfirmModal({
        header: { title: t('Notification') },
        confirmText: t('Cancel Transfer Completed.'),
        removeCancel: true,
    });

    const { toggleModal: toggleCantChangeModal, Modal: CantChangeModal } = useConfirmModal({
        header: { title: t('Notification') },
        confirmText: t("You cannot change to 'REPAIR_COMPLETE' or 'TAKEOVER_BEFORE' with Save button."),
        removeCancel: true,
    });

    const { toggleModal: toggleSaveCompleteModal, Modal: SaveCompleteModal } = useConfirmModal({
        confirmText: t('The save has been successfully completed.'),
        removeCancel: true,
        okCallback: () => {
            history.push('/asset-access/transfer-manage');
        },
    });

    const { toggleModal: toggleReRegisterModal, Modal: ReRegisterModal } = useConfirmModal({
        header: { title: t('Notification') },
        confirmText: t('Unable to re-register as before.', 'Register Detail'),
        removeCancel: true,
    });

    // 버튼
    const handleClickSave = () => {
        if (validationModalText.length) {
            handleValidationModal();
        } else {
            handleSaveModal();
        }
    };

    const handleUpdateRepairTakeover = () => {
        updateRepairTakeover({
            description: note,
            targetId,
            groupId: affiliationCode,
            statusType: selectedOption.value,
            userName: accepter,
            userId: accepterCode,
        });
    };

    // 유저 선택
    const handleUserSelect = userInfo => {
        setUserInfo(userInfo);
    };

    return (
        <>
            <Card
                className="single-card-container"
                header={{
                    title: t('Repair Transfer Detail'),
                    action: (
                        <>
                            <Button
                                className={'btn-gray'}
                                onClick={() => {
                                    history.push('/asset-access/transfer-manage');
                                }}
                            >
                                {t('Back', 'Button')}
                            </Button>
                            <>
                                {statusType === 'REPAIR_COMPLETE' ? null : (
                                    <Button className={'btn-warning'} onClick={handleCancelRegisterModal}>
                                        {t('Cancel Transfer')}
                                    </Button>
                                )}
                            </>
                            {/*<Button className={'btn-danger'}>{t('Delete', 'Button')}</Button>*/}
                            <Button className={'btn-brand'} onClick={handleClickSave}>
                                {t('Save', 'Button')}
                            </Button>
                        </>
                    ),
                }}
            >
                <div className="h-100 w-100">
                    <div className="p-0 d-flex row pnt-label-7">
                        <Label
                            name={t('Accepter')}
                            labelValueClassName={'label-dot color-brand'}
                            labelGroupClassName="mb-3 col-lg-12"
                            value={
                                <div className="d-flex w-100">
                                    <TextInput
                                        inputGroupClassName="col-sm-8 flex2 form-disable"
                                        type="text"
                                        maxlength={100}
                                        name="accepterCode"
                                        handleChange={onChange}
                                        value={accepterCode}
                                        placeholder={t('Accepter Code', 'Transfer Repair')}
                                    />
                                    <TextInput
                                        inputGroupClassName={'col-sm-8 flex2 form-disable'}
                                        type={'text'}
                                        maxlength={50}
                                        name={'accepter'}
                                        handleChange={onChange}
                                        value={accepter}
                                        placeholder={t('Accepter Name', 'Transfer Repair')}
                                    />
                                    <div className="flex1 d-flex justify-content-center  d-flex justify-content-center">
                                        <Button
                                            className="btn-secondary w-100 max-w-125"
                                            onClick={handleUserSearchModal}
                                        >
                                            {t('Search Accepter', 'Transfer Repair')}
                                        </Button>
                                    </div>
                                </div>
                            }
                        />
                    </div>
                    <div className="p-0 d-flex row pnt-label-7">
                        <Label
                            name={t('Accepter Affiliation')}
                            labelValueClassName={'label-dot'}
                            labelGroupClassName="mb-3 col-lg-12"
                            value={
                                <div className="d-flex w-100 ml-15 pr-45">
                                    <TextInput
                                        inputGroupClassName={'flex2 form-disable'}
                                        type={'text'}
                                        maxlength={50}
                                        name={'deptName'}
                                        handleChange={onChange}
                                        value={deptName}
                                        placeholder={t('Affiliation Name', 'Transfer Repair')}
                                    />
                                    <div className="flex3" />
                                </div>
                            }
                        />
                    </div>
                    <div className="p-0 d-flex row pnt-label-7">
                        <Label
                            name={t('Accepter Asset')}
                            labelValueClassName={'label-dot color-brand'}
                            labelGroupClassName="mb-3 col-lg-12"
                            value={
                                <div className="d-flex w-100">
                                    <TextInput
                                        inputGroupClassName={'col-sm-8 flex2 form-disable'}
                                        type={'text'}
                                        maxlength={50}
                                        name={'assetCode'}
                                        handleChange={onChange}
                                        value={assetCode}
                                        disabled
                                        placeholder={t('Asset Code', 'Transfer Repair')}
                                    />
                                    <TextInput
                                        inputGroupClassName={'col-sm-8 flex2 form-disable'}
                                        type={'text'}
                                        maxlength={50}
                                        name={'asset'}
                                        handleChange={onChange}
                                        value={asset}
                                        disabled
                                        placeholder={t('Asset Name', 'Transfer Repair')}
                                    />
                                    <div className="flex1 min-w-95" />
                                </div>
                            }
                        />
                    </div>
                    <div className="p-0 d-flex row pnt-label-7">
                        <Label
                            name={t('Transfer Status')}
                            labelValueClassName={'label-dot color-brand'}
                            labelGroupClassName="mb-3 col-lg-12"
                            value={
                                <div className="w-100">
                                    <ReactSelect
                                        className={'col-sm-8'}
                                        name={'Status Select'}
                                        value={selectedOption}
                                        options={searchOptions}
                                        onChange={handleSelectChange}
                                        customControlStyles={{ width: '200px' }}
                                        customMenuStyles={{ width: '200px' }}
                                        customOptionStyles={{ width: '200px' }}
                                    />
                                </div>
                            }
                        />
                    </div>
                    <div className="p-0 d-flex row pnt-label-7">
                        <Label
                            name={t('Transfer Date')}
                            labelValueClassName={'label-dot label-main'}
                            labelGroupClassName="mb-3 col-lg-12"
                            value={
                                <div className="d-flex w-100 ml-15 pr-45">
                                    <TextInput
                                        inputGroupClassName={'flex2 form-disable'}
                                        name={'registrationDate'}
                                        value={registrationDate}
                                        disabled
                                        placeholder={t('Registration Date')}
                                    />
                                    <div className="flex3" />
                                </div>
                            }
                        />
                    </div>
                    <div className="p-0 d-flex row pnt-label-7">
                        <Label
                            name={t('Asset Location')}
                            labelValueClassName={'label-dot label-main'}
                            labelGroupClassName="mb-3 col-lg-12"
                            value={
                                <div className={'ml-15 w-100'}>
                                    <LocationTab
                                        assetInfo={assetInfo}
                                        floorInfo={floorInfo}
                                        locationInfo={locationInfo}
                                        geofenceInfo={geofenceInfo}
                                        cardHeader={false}
                                    />
                                </div>
                            }
                        />
                    </div>
                    <div className="p-0 d-flex row pnt-label-7">
                        <Label
                            name={t('Note', 'Registration Repair')}
                            labelValueClassName={'label-dot label-main'}
                            labelGroupClassName="mb-3 col-lg-12"
                            value={
                                <div className={'ml-15 pnt-input--group col-sm-12'}>
                                    <textarea
                                        name={'note'}
                                        className={'pnt-input w-100 resize-none font-size-md pt-2 note-height'}
                                        onChange={onChange}
                                        value={note}
                                    />
                                </div>
                            }
                        />
                    </div>
                </div>
            </Card>
            <ConfirmModal
                header={{ title: t('Cancel Transfer') }}
                initModal={cancelRegisterModal}
                toggleModal={handleCancelRegisterModal}
                confirmText={
                    <div>
                        <div>{t('The asset will be changed to a repaired status.')}</div>
                        <div>{t('Would you like to cancel the transfer?')}</div>
                    </div>
                }
                okCallback={updateCancelRepairTakeover}
            />
            <ConfirmModal
                header={{ title: t('Registration Modification') }}
                initModal={saveModal}
                toggleModal={handleSaveModal}
                confirmText={t('Would you like to revise the repair transfer?')}
                okCallback={handleUpdateRepairTakeover}
            />
            <ValidationModal
                initModal={validationModal}
                toggleModal={handleValidationModal}
                validationText={validationModalText}
            />
            <UserSearchModal
                initModal={userSearchModal}
                toggleModal={handleUserSearchModal}
                okCallback={handleUserSelect}
            />
            <CancelTransferCompleteModal />
            <CantChangeModal />
            <SaveCompleteModal />
            <ReRegisterModal />
        </>
    );
};

export default RepairDetail;
