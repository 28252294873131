import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { equipmentEvaluationState } from '../../../atoms';
import { ABS_EVALUATION, TRUST } from '../../../../util/sheetOption';
import CustomRadio from '../../Components/CustomRadio';
import useTranslation from '../../../../../../util/hooks/useTranslation';

const GainLossAndReliability = () => {
    const t = useTranslation('Equipment evaluation');
    const [gainAndLossScore, setGainAndLossScore] = useState(0);
    const [reliabilityScore, setReliabilityScore] = useState(0);
    const [evaluationInfo, setEvaluationInfo] = useRecoilState(equipmentEvaluationState);
    const { deptCostLossEcd, deptCostLossPcd, deptCostLossPoint, trustEcd, trustPcd, trustPoint } = evaluationInfo;

    useEffect(() => {
        let _gainAndLossScore = 0;
        let _reliabilityScore = 0;

        if (deptCostLossEcd === 'A060101') {
            ABS_EVALUATION.map(item => {
                if (item.value === deptCostLossPcd) {
                    _gainAndLossScore = item.score;
                }
            });
        }

        if (trustEcd === 'A070101') {
            TRUST.map(item => {
                if (item.value === trustPcd) {
                    _reliabilityScore = item.score;
                }
            });
        }
        setGainAndLossScore(_gainAndLossScore);
        setReliabilityScore(_reliabilityScore);
    }, [evaluationInfo]);

    useEffect(() => {
        setEvaluationInfo({
            ...evaluationInfo,
            deptCostLossPoint: gainAndLossScore,
        });
    }, [gainAndLossScore]);

    useEffect(() => {
        setEvaluationInfo({
            ...evaluationInfo,
            trustPoint: reliabilityScore,
        });
    }, [reliabilityScore]);

    return (
        <div className={'pnt-table'} style={{ height: 'auto' }}>
            <div className={'border-box'} style={{ overflowX: 'auto', overflowY: 'hidden' }}>
                <table className={'w-100'}>
                    <tbody className="tbody priority-table">
                        <tr className="tr">
                            <td className="td">13. {t('Departmental costing')}</td>
                            <td className="td">{t('ABS Valuation Basis')}</td>
                            <td className="td">
                                <div className={'align-flex-start-div'}>
                                    <div>
                                        <div>
                                            <span>
                                                {t(
                                                    'Percentage of total revenue by department that the difference between total revenue by department and cost by department accounts for',
                                                )}
                                            </span>
                                        </div>
                                        <div>
                                            {ABS_EVALUATION.map(item => {
                                                const { title, value } = item;

                                                return (
                                                    <CustomRadio
                                                        key={value}
                                                        text={t(title)}
                                                        checked={deptCostLossEcd && parseInt(deptCostLossPcd) === value}
                                                        handleChange={e => {
                                                            const { checked } = e.currentTarget;
                                                            let result = '';
                                                            if (
                                                                checked &&
                                                                (!deptCostLossPcd ||
                                                                    deptCostLossPcd !== value ||
                                                                    deptCostLossEcd !== 'A060101')
                                                            ) {
                                                                result = value;
                                                            }

                                                            setEvaluationInfo({
                                                                ...evaluationInfo,
                                                                deptCostLossPcd: result,
                                                                deptCostLossEcd: result ? 'A060101' : '',
                                                            });
                                                        }}
                                                    />
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className="td score">{deptCostLossPoint}</td>
                        </tr>
                        <tr className="tr">
                            <td className="td">14. {t('Trust')}</td>
                            <td className="td">{t('Utilization')}</td>
                            <td className="td">
                                <div>
                                    <div>
                                        <div>
                                            <span>
                                                {t(
                                                    'The utilization evaluation (profit and loss) result for the last three years of the device subject to utilization evaluation is calculated for each device, and the average value of the utilization evaluation for each device in the department is applied to the department. applied to the department',
                                                )}
                                            </span>
                                        </div>
                                        <div>
                                            {TRUST.map(item => {
                                                const { title, value } = item;
                                                return (
                                                    <CustomRadio
                                                        key={value}
                                                        text={t(title)}
                                                        checked={trustEcd && parseInt(trustPcd) === value}
                                                        handleChange={e => {
                                                            const { checked } = e.currentTarget;
                                                            let result = '';
                                                            if (
                                                                checked &&
                                                                (!trustPcd ||
                                                                    trustPcd !== value ||
                                                                    trustEcd !== 'A070101')
                                                            ) {
                                                                result = value;
                                                            }

                                                            setEvaluationInfo({
                                                                ...evaluationInfo,
                                                                trustPcd: result,
                                                                trustEcd: result ? 'A070101' : '',
                                                            });
                                                        }}
                                                    />
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className="td score">{trustPoint}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default GainLossAndReliability;
