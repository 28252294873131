import React, { useEffect, useState } from 'react';
import Card from '../../../../Common/Card';
import useTranslation from '../../../../../util/hooks/useTranslation';
import Button from '../../../../Common/Button';
import { useHistory } from 'react-router-dom';
import Label from '../../Components/CustomLabel';
import TextInput from '../../../../Common/Input/TextInput';
import ConfirmModal from '../../../../Common/ConfirmModal';
import ValidationModal from '../../../Components/Modal/ValidationModal';
import UserSearchModal from '../../../Components/Modal/UserSearchModal';
import AssetSearchModal from '../../../Components/Modal/AssetSearchModal';
import useAsync from '../../../../../util/hooks/useAsync';
import { createRepairReceptionApi } from '../../../../../api/asset';
import { Col, Row } from 'reactstrap';

const RepairRegister = () => {
    const t = useTranslation('Registration Repair');
    const history = useHistory();

    // 모달 상태들
    const [validationModal, setValidationModal] = useState(false);
    const [validationModalText, setValidationModalText] = useState([]);
    const [registrationModal, setRegistrationModal] = useState(false);
    const [userSearchModal, setUserSearchModal] = useState(false);
    const [assetSearchModal, setAssetSearchModal] = useState(false);
    const [alreadyRegisteredModal, setAlreadyRegisteredModal] = useState(false);
    const [completeAlertModal, setCompleteAlertModal] = useState(false);
    const [informationErrModal, setInformationErrModal] = useState(false);

    // input 상태들
    const [inputs, setInputs] = useState({
        receptionistCode: '',
        receptionist: '',
        affiliationCode: '',
        deptName: '',
        assetCode: '',
        asset: '',
        note: '',
    });

    const { receptionistCode, receptionist, affiliationCode, deptName, assetCode, asset, note } = inputs;

    const [userInfoSelect, setUserInfoSelect] = useState(false);
    const [assetInfoSelect, setAssetInfoSelect] = useState(false);

    // 유저 정보
    const [userInfo, setUserInfo] = useState({});

    // 자산정보
    const [assetInfo, setAssetInfo] = useState({});

    useEffect(() => {
        if (userInfo.targetNum) {
            setUserInfoSelect(true);
            const {
                targetId,
                targetName,
                properties: { deptCode, deptName },
            } = userInfo;
            setInputs(prevState => ({
                ...prevState,
                receptionistCode: targetId,
                receptionist: targetName,
                affiliationCode: deptCode,
                deptName,
            }));

            // 초기화
            setUserInfo({});
        }
    }, [userInfo.targetNum]);

    useEffect(() => {
        if (assetInfo.targetNum) {
            setAssetInfoSelect(true);
            const { targetId, targetName } = assetInfo;
            setInputs(prevState => ({
                ...prevState,
                assetCode: targetId,
                asset: targetName,
            }));

            setAssetInfo({});
        }
    }, [assetInfo.targetNum]);

    // useAsync
    const { promise: postRepairReception } = useAsync({
        promise: createRepairReceptionApi,
        resolve: res => {
            handleCompleteAlertModal();
        },
        reject: err => {
            // 이미 등록
            if (err.data && err.data.errorResponse === 'Already Registered Repair Reception') {
                handleAlreadyRegisteredModal();
            } else if (err.data && err.data.code === 500) {
                // 유효한 정보가 아님
                handleInformationErrModal();
            }
        },
    });

    // input 함수들
    const onChange = ({ target: { value, name } }) => {
        setInputs({
            ...inputs,
            [name]: value,
        });
    };

    // 유효성검사 모달 body 내용
    useEffect(() => {
        const text = [];
        if (!receptionist || !receptionistCode) {
            text.push('Receptionist information is required.');
        }
        if (!affiliationCode) {
            text.push('Receptionist affiliation information is required.');
        }
        if (!asset || !assetCode) {
            text.push('Register asset information is required.');
        }
        setValidationModalText(text);
    }, [receptionist, receptionistCode, affiliationCode, asset, assetCode]);

    // 모달 함수들
    const handleValidationModal = () => {
        setValidationModal(!validationModal);
    };

    const handleRegistrationModal = () => {
        setRegistrationModal(!registrationModal);
    };

    const handleUserSearchModal = () => {
        setUserSearchModal(!userSearchModal);
    };

    const handleAssetSearchModal = () => {
        setAssetSearchModal(!assetSearchModal);
    };

    const handleClickSave = () => {
        if (validationModalText.length) {
            handleValidationModal();
        } else {
            handleRegistrationModal();
        }
    };

    const handleAlreadyRegisteredModal = () => {
        setAlreadyRegisteredModal(!alreadyRegisteredModal);
    };

    const handleCompleteAlertModal = () => {
        setCompleteAlertModal(!completeAlertModal);
    };

    const handleInformationErrModal = () => {
        setInformationErrModal(!informationErrModal);
    };

    // 유저 정보 가져오기
    const handleUserInfo = userInfo => {
        setUserInfo(userInfo);
    };

    // 자산 정보 가져오기
    const handleAssetInfo = assetInfo => {
        setAssetInfo(assetInfo);
    };

    const handleCompleteRegister = () => {
        handleCompleteAlertModal();
        history.push('/asset-access/register-manage');
    };

    // 기타 함수
    const handleRegistration = () => {
        postRepairReception({
            description: note,
            groupId: affiliationCode,
            targetName: asset,
            targetId: assetCode,
            userName: receptionist,
            userId: receptionistCode,
        });
    };

    return (
        <>
            <Card
                className="single-card-container d-flex"
                header={{
                    title: t('Registration Repair'),
                    action: (
                        <>
                            <Button
                                className={'btn-gray'}
                                onClick={() => {
                                    history.push('/asset-access/register-manage');
                                }}
                            >
                                {t('Back', 'Button')}
                            </Button>
                            <Button className={'btn-brand'} iconClassName="icon-save" onClick={handleClickSave}>
                                {t('Save', 'Button')}
                            </Button>
                        </>
                    ),
                }}
            >
                <div className="w-100">
                    <div className="p-0 d-flex row pnt-label-7 mb-1">
                        <Label
                            name={t('Receptionist', 'AccessSearch')}
                            labelValueClassName={'label-dot color-brand'}
                            labelGroupClassName="mb-3 col-lg-12"
                            value={
                                <Row>
                                    <Col md="5" lg="3">
                                        <TextInput
                                            inputGroupClassName={'w-100 mb-2 form-must form-disable'}
                                            type={'text'}
                                            name={'receptionistCode'}
                                            handleChange={onChange}
                                            value={receptionistCode}
                                            placeholder={t('Receptionist Code')}
                                        />
                                    </Col>
                                    <Col md="5" lg="3">
                                        <TextInput
                                            inputGroupClassName={'w-100 mb-2 form-must form-disable'}
                                            type={'text'}
                                            name={'receptionist'}
                                            handleChange={onChange}
                                            value={receptionist}
                                            placeholder={t('Receptionist Name')}
                                        />
                                    </Col>
                                    <Col md="2" lg="3">
                                        <Button
                                            className={'btn-secondary w-100 max-w-125'}
                                            onClick={handleUserSearchModal}
                                        >
                                            {t('Search Receptionist')}
                                        </Button>
                                    </Col>
                                </Row>
                            }
                        />
                    </div>
                    <div className="p-0 d-flex row pnt-label-7 mb-1">
                        <Label
                            name={t('Receptionist Affiliation')}
                            labelValueClassName={'label-dot'}
                            labelGroupClassName="mb-3 col-lg-12"
                            value={
                                <Row>
                                    <Col md="5" lg="3">
                                        <TextInput
                                            inputGroupClassName={'w-100 form-disable'}
                                            type={'text'}
                                            name={'deptName'}
                                            handleChange={onChange}
                                            value={deptName}
                                            placeholder={t('Affiliation Name')}
                                        />
                                    </Col>
                                </Row>
                            }
                        />
                    </div>
                    <div className="p-0 d-flex row pnt-label-7 mb-1">
                        <Label
                            name={t('Register Asset')}
                            labelValueClassName={'label-dot color-brand'}
                            labelGroupClassName="mb-3 col-lg-12"
                            value={
                                <Row>
                                    <Col md="5" lg="3">
                                        <TextInput
                                            inputGroupClassName={'w-100 mb-2 form-must form-disable'}
                                            type={'text'}
                                            maxlength={50}
                                            minlength={100}
                                            name={'assetCode'}
                                            handleChange={onChange}
                                            value={assetCode}
                                            placeholder={t('Asset Code')}
                                        />
                                    </Col>
                                    <Col md="5" lg="3">
                                        <TextInput
                                            inputGroupClassName={'w-100 mb-2 form-must form-disable'}
                                            type={'text'}
                                            maxlength={50}
                                            name={'asset'}
                                            handleChange={onChange}
                                            value={asset}
                                            placeholder={t('Asset Name')}
                                        />
                                    </Col>
                                    <Col md="2" lg="3">
                                        <Button
                                            className="btn-secondary w-100 max-w-125"
                                            onClick={handleAssetSearchModal}
                                        >
                                            {t('Search Asset')}
                                        </Button>
                                    </Col>
                                </Row>
                            }
                        />
                    </div>
                    <div className="p-0 d-flex row pnt-label-7 mb-1">
                        <Label
                            name={t('Note')}
                            labelValueClassName={'label-dot'}
                            labelGroupClassName="mb-3 col-lg-12 note-height"
                            value={
                                <Row>
                                    <Col className={'pnt-input--group col-sm-12'} md="10" lg="6">
                                        <textarea
                                            name={'note'}
                                            className={'pnt-input note-height resize-none font-size-md pt-2'}
                                            onChange={onChange}
                                            value={note}
                                        />
                                    </Col>
                                </Row>
                            }
                        />
                    </div>
                </div>
            </Card>
            <ValidationModal
                initModal={validationModal}
                toggleModal={handleValidationModal}
                validationText={validationModalText}
            />
            <ConfirmModal
                initModal={registrationModal}
                header={{ title: t('Registration') }}
                toggleModal={handleRegistrationModal}
                confirmText={t('Would you like to register for repair?')}
                okCallback={handleRegistration}
            />
            <ConfirmModal
                initModal={alreadyRegisteredModal}
                header={{ title: t('Notification') }}
                toggleModal={handleAlreadyRegisteredModal}
                confirmText={t('Already Registered Repair Reception')}
                removeCancel
            />
            <ConfirmModal
                initModal={completeAlertModal}
                toggleModal={handleCompleteRegister}
                confirmText={t('The registered has been completed.')}
                removeCancel
            />
            <ConfirmModal
                initModal={informationErrModal}
                toggleModal={handleInformationErrModal}
                header={{ title: t('Notification') }}
                confirmText={t('This information is not valid.')}
                removeCancel
            />
            <UserSearchModal
                initModal={userSearchModal}
                toggleModal={handleUserSearchModal}
                okCallback={handleUserInfo}
            />
            <AssetSearchModal
                initModal={assetSearchModal}
                toggleModal={handleAssetSearchModal}
                okCallback={handleAssetInfo}
            />
        </>
    );
};

export default RepairRegister;
