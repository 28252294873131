import React, { useEffect, useState } from 'react';
import Card from '../../../../Common/Card';
import Button from '../../../../Common/Button';
import useTranslation from '../../../../../util/hooks/useTranslation';
import Table from '../../../../Common/Table';
import useAsync from '../../../../../util/hooks/useAsync';
import { getStatusListInfoApi } from '../../../../../api/status';
import useColumns from '../../../../../util/hooks/useColumns';
import { StyledStatusLabel } from '../../../Components/Grid/column';
import Label from '../../../../Common/Label';
import StatusDetail from './StatusDetail';
import { useDispatch, useSelector } from 'react-redux';
import { setStatusListInfo } from '../../../../../reducers/Common/StatusInfo';

const Status = () => {
    const dispatch = useDispatch();
    const t = useTranslation('Status');
    const { statusListInfo } = useSelector(state => state.StatusInfo);
    const [detailModal, setDetailModal] = useState({ isOpen: false, statusInfo: null });

    const toggleDetailModal = statusInfo => {
        setDetailModal({
            isOpen: !detailModal.isOpen,
            statusInfo,
        });
    };

    const statusColumns = useColumns(
        [
            {
                Header: 'Name',
                accessor: 'statusName',
                className: 'flex-center',
                Cell: ({ value, row: { original } }) => {
                    return (
                        <div
                            style={{
                                display: 'grid',
                                rowGap: '5px',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <StyledStatusLabel statusName={t(value)} displayColor={original.displayColor} />
                            <span
                                className={'text-ellipsis'}
                                style={{ fontSize: '.7rem' }}
                                title={original.description}
                            >
                                {original.description}
                            </span>
                        </div>
                    );
                },
            },
            {
                Header: 'ID',
                accessor: 'statusId',
                className: 'flex-center',
                width: 80,
            },
            {
                Header: 'Status Category',
                accessor: 'statusCategoryName',
                className: 'flex-center',
            },
            {
                Header: 'Status Scheme',
                accessor: 'schemeMaps',
                className: 'flex-center',
                Cell: ({ value }) => {
                    return (
                        <div>
                            {value.map(v => (
                                <Label key={v.schemeNum} name={v.schemeName} labelValueClassName={'label-dot'} />
                            ))}
                        </div>
                    );
                },
            },
            {
                Header: 'Edit',
                accessor: 'statusNum',
                className: 'flex-center',
                width: 40,
                Cell: ({ value, row: { original } }) => {
                    return (
                        <Button
                            className={'btn-icon-only btn-brand scale-08'}
                            iconClassName={'icon-edit'}
                            onClick={e => {
                                toggleDetailModal(original);
                            }}
                        />
                    );
                },
            },
        ],
        t,
    );
    const { promise: getStatusListInfo } = useAsync({
        promise: getStatusListInfoApi,
        resolve: res => {
            dispatch(setStatusListInfo(res));
        },
    });

    useEffect(() => {
        getStatusListInfo({ isAll: 'Y' });
    }, []);

    return (
        <>
            <Card
                header={{
                    title: `${t('List')} : ${t('Status')}`,
                    subTitle: t('This is the asset status list inquiry function.'),
                    action: (
                        <>
                            <Button
                                className={'btn-brand flex-center font-1rem'}
                                iconClassName={'icon-plus mr-2'}
                                onClick={() => {
                                    toggleDetailModal(null);
                                }}
                            >
                                {t('Add Status')}
                            </Button>
                        </>
                    ),
                }}
            >
                <div
                    style={{
                        minHeight: '300px',
                        maxHeight: 'calc(100vh - 340px)',
                        height: 'calc(100vh - 340px)',
                        display: 'grid',
                        gridTemplateRows: 'min',
                    }}
                >
                    <Table
                        data={{ ...statusListInfo, pageSize: statusListInfo.totalCount }}
                        columns={statusColumns}
                        paging={false}
                        dynamicRowHeight
                    />
                </div>
            </Card>
            <StatusDetail isOpen={detailModal.isOpen} statusInfo={detailModal.statusInfo} toggle={toggleDetailModal} />
        </>
    );
};

export default Status;
