import React, { useCallback, useEffect, useMemo } from 'react';
import * as XLSX from 'xlsx';
import * as XlsxPopulate from 'xlsx-populate/browser/xlsx-populate';
import Button from '../../../../../Common/Button';
import useConfirmModal from '../../../../../Common/ConfirmModal/useConfirmModal';
import useTranslation from '../../../../../../util/hooks/useTranslation';

const exeConvertPercent = data => {
    return data ? `${data}%` : null;
};

const convertPercent = ({ cost, budget }) => {
    let num = '';
    if (cost && budget) {
        num = (cost / budget).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
    } else if (!cost && !budget) {
        num = '0';
    } else {
        num = (cost / budget).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
    }
    return `${num}%`;
};
const ExcelExport = ({ data, getAccountSettleExportExcel, searchMonth }) => {
    const t = useTranslation('ExcelExport');

    const [excelYear, excelMonth] = useMemo(() => {
        if (searchMonth) {
            return searchMonth.split('-');
        }
        return ['', ''];
    }, [searchMonth]);

    const deptCharge = useMemo(() => {
        return {
            10: t('Hospital'),
            20: t('Medical school/basics'),
            30: t('Research institute'),
        };
    }, [t]);

    const makeTotal = useCallback(
        ({ rowName, table, data, totalSum }) => {
            table.push({
                A: totalSum && t(rowName),
                B: totalSum ? null : t(rowName),
                C: '',
                D: '',
                E: data.repairCost,
                F: data.billCost,
                G: data.sumRepairBillCost,
                H: data.repairCost,
                I: data.totalRepairCost,
                J: data.repairBudget,
                K: exeConvertPercent(data.repairExeRate),
                L: data.serviceCnt,
                M: data.serviceCost,
                N: data.totalServiceCost,
                O: data.serviceBudget,
                P: convertPercent({ cost: data.totalServiceCost, budget: data.serviceBudget }),
                Q: data.thisMonSavingCnt,
                R: data.thisMonSavingCost,
                S: data.preMonSavingCnt,
                T: data.preMonSavingCost,
                U: data.totalSavingCnt,
                V: data.totalSavingCost,
                W: data.savingTarget,
                X: exeConvertPercent(data.performance),
            });
        },
        [t],
    );

    const makeTableRow = useCallback(
        ({ rowName, table, data }) => {
            data.forEach(row => {
                table.push({
                    A: t(rowName),
                    B: row.empName,
                    C: deptCharge[row.charge],
                    D: row.repairCnt.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ','),
                    E: row.repairCost.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ','),
                    F: row.billCost.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ','),
                    G: row.sumRepairBillCost.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ','),
                    H: row.repairCost.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ','),
                    I: row.totalRepairCost.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ','),
                    J: row.repairBudget.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ','),
                    K: exeConvertPercent(row.repairExeRate),
                    L: row.serviceCnt.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ','),
                    M: row.serviceCost.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ','),
                    N: row.totalServiceCost.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ','),
                    O: row.serviceBudget.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ','),
                    P: convertPercent({ cost: row.totalServiceCost, budget: row.serviceBudget }),
                    Q: row.thisMonSavingCnt.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ','),
                    R: row.thisMonSavingCost.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ','),
                    S: row.preMonSavingCnt.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ','),
                    T: row.preMonSavingCost.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ','),
                    U: row.totalSavingCnt.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ','),
                    V: row.totalSavingCost.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ','),
                    W: row.savingTarget,
                    X: exeConvertPercent(row.performance),
                });
            });
        },
        [t],
    );

    // 다운로드 하기
    const createDownloadData = () => {
        if (!data) {
            return;
        }
        handleExport().then(url => {
            const downloadAnchorNode = document.createElement('a');
            downloadAnchorNode.setAttribute('href', url);
            downloadAnchorNode.setAttribute(
                'download',
                `${excelYear}${t('Year')}_${excelMonth}${t('Month')} ${t(
                    'Medical engineering team settlement details',
                )}`,
            );
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        });
    };

    const s2ab = s => {
        const buf = new ArrayBuffer(s.length);

        const view = new Uint8Array(buf);

        for (let i = 0; i !== s.length; ++i) {
            view[i] = s.charCodeAt(i);
        }

        return buf;
    };

    const workbook2blob = workbook => {
        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
        return new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
    };

    const handleExport = () => {
        let settlement = [
            {
                A: `${t('Medical engineering team settlement details')} ${excelYear}${t('Year')} ${excelMonth}${t(
                    'Month',
                )}`,
            },
            {
                A: '',
                B: '',
                C: '',
                D: '',
                E: '',
                F: '',
                G: '',
                H: '',
                I: '',
                J: '',
                K: '',
                L: '',
                M: '',
                N: '',
                O: '',
                P: '',
                Q: '',
                R: '',
                S: '',
                T: '',
                U: '',
                V: '',
                W: '',
                X: `(${t('Unit')}: ${t('count, won')})`,
            },
            {
                A: t('In charge of'),
                B: '',
                C: '',
                D: t('Repair costs header'),
                E: '',
                F: '',
                G: '',
                H: '',
                I: '',
                J: '',
                K: '',
                L: '',
                M: '',
                N: '',
                O: '',
                P: '',
                Q: t('Cost reduction'),
                R: '',
                S: '',
                T: '',
                U: '',
                V: '',
                W: '',
                X: '',
            },
            {
                A: '',
                B: t('Name'),
                C: t('In charge of'),
                D: t('Count'),
                E: t('Repair cost'),
                F: t('Billing inventory'),
                G: t('Repair cost for this month + total inventory claimed'),
                H: t('Total repair costs for this month'),
                I: t('Total cumulative'),
                J: t('Budget allocation of repair costs'),
                K: t('Repair cost execution rate'),
                L: t('Count'),
                M: t("This month's service repair cost"),
                N: t('Accumulated services'),
                O: t('Service budget allocation'),
                P: t('Service execution rate'),
                Q: t('Number of savings this month'),
                R: t('Savings for this month'),
                S: t('Cumulative savings from the previous month'),
                T: t('Cumulative total of cost savings from the previous month'),
                U: t('Cumulative number of savings'),
                V: t('Accumulated cost reduction'),
                W: t('Target reduction amount'),
                X: t('Performance'),
            },
        ];
        if (data.settleMent) {
            data.settleMent.equipH &&
                makeTableRow({ rowName: 'Equipment operation', table: settlement, data: data.settleMent.equipH });

            data.settleMent.equipHSum &&
                makeTotal({ rowName: 'Subtotal', table: settlement, data: data.settleMent.equipHSum });

            data.settleMent.equipMR &&
                makeTableRow({ rowName: 'Equipment operation', table: settlement, data: data.settleMent.equipMR });

            data.settleMent.equipMRSum &&
                makeTotal({ rowName: 'Subtotal', table: settlement, data: data.settleMent.equipMRSum });

            data.settleMent.equipSum &&
                makeTotal({ rowName: 'Total equipment operation', table: settlement, data: data.settleMent.equipSum });

            data.settleMent.homeH &&
                makeTableRow({ rowName: 'Home appliances', table: settlement, data: data.settleMent.homeH });

            data.settleMent.homeHSum &&
                makeTotal({ rowName: 'Home appliances subtotal', table: settlement, data: data.settleMent.homeHSum });

            data.settleMent.homeMR &&
                makeTableRow({ rowName: 'Home appliances', table: settlement, data: data.settleMent.homeMR });

            data.settleMent.homeMRSum &&
                makeTotal({ rowName: 'Home appliances subtotal', table: settlement, data: data.settleMent.homeMRSum });

            data.settleMent.homeSum &&
                makeTotal({ rowName: 'Total home appliances', table: settlement, data: data.settleMent.homeSum });

            data.settleMent.totalSum &&
                makeTotal({ rowName: 'TotalSum', table: settlement, data: data.settleMent.totalSum, totalSum: true });
        }
        settlement = settlement.concat([
            {},
            {
                A: t('Total repair costs for the current month'),
                D: data.settleMent && data.settleMent.sumThisMonRepair,
                G: t('Cumulative Total Repair Costs'),
                J: data.settleMent && data.settleMent.accumRepair,
                M: t('Total budget'),
                O: data.settleMent && data.settleMent.totalBudget,
                Q: t('Execution rate(%)'),
                S: data.settleMent && `${data.settleMent.exeRate}%`,
            },
        ]);

        let mainPoints = [
            { A: `${t('The main points')}` },
            {
                A: t('Number'),
                B: t('Billing department'),
                C: t('Name of the equipment'),
                D: t('Cost / service repair cost'),
            },
        ];

        data.mainPoint &&
            data.mainPoint.forEach((row, idx) => {
                mainPoints.push({
                    A: idx + 1,
                    B: row.billDep,
                    C: row.equipName,
                    D: `₩${row.reqCost.toLocaleString('ko-KR')} / ₩${row.repairCost.toLocaleString('ko-KR')}`,
                });
            });

        let costReduction = [
            {
                A: `${t('Cost reduction')}`,
                B: data && data.costSaving && data.costSaving.totalCostSaving ? data.costSaving.totalCostSaving : null,
            },
            {
                A: t('Number'),
                B: t('Asset name'),
                C: t('Action details'),
                D: t('Reduction details'),
                E: t('Cost Reduction'),
            },
        ];

        data.costSaving &&
            data.costSaving.costSaving &&
            data.costSaving.costSaving.forEach((row, idx) => {
                costReduction.push({
                    A: idx + 1,
                    B: row.equipName,
                    C: row.actionHis,
                    D: row.saveHis,
                    E: row.saveCost,
                });
            });

        // create a workbook
        const wb = XLSX.utils.book_new();

        // create a worksheet
        const sheet1 = XLSX.utils.json_to_sheet(settlement, {
            skipHeader: true,
        });
        const sheet2 = XLSX.utils.json_to_sheet(mainPoints, {
            skipHeader: true,
        });
        const sheet3 = XLSX.utils.json_to_sheet(costReduction, {
            skipHeader: true,
        });

        XLSX.utils.book_append_sheet(wb, sheet1, `${t('Settlement details')}`, true);
        XLSX.utils.book_append_sheet(wb, sheet2, `${t('The main points')}`, true);
        XLSX.utils.book_append_sheet(wb, sheet3, `${t('Cost reduction')}`, true);

        const workbookBlob = workbook2blob(wb);

        const dataInfo = [
            {
                titleRange: 'A1:G1',
                titleCell1: 'A3:C3',
                titleCell2: 'D3:P3',
                titleCell3: 'Q3:X3',
                titleRow: 'A4:X4',
                tableRange: `A4:X${settlement.length - 2}`,
                totalRange: `A${settlement.length}:T${settlement.length}`,
                totalRepairCost: `A${settlement.length}:C${settlement.length}`,
                sumThisMonRepair: `D${settlement.length}:F${settlement.length}`,
                cumulateTotal: `G${settlement.length}:I${settlement.length}`,
                accumRepair: `J${settlement.length}:L${settlement.length}`,
                totalBudget: `M${settlement.length}:N${settlement.length}`,
                totalBudgetSum: `O${settlement.length}:P${settlement.length}`,
                exeRate: `Q${settlement.length}:R${settlement.length}`,
                executionRate: `S${settlement.length}:T${settlement.length}`,
                savingMonthColumn: `Q3:Q${settlement.length - 2}`,
                totalSumTitle: `A${settlement.length - 2}:C${settlement.length - 2}`,
                totalSum: `A${settlement.length - 2}:X${settlement.length - 2}`,
                totalBudgetAllocation: `N${settlement.length - 2}:O${settlement.length - 2}`,
                tableDataArea: `E5:V${settlement.length - 2}`,
                unitTitle: 'X2:X2',
            },
            {
                titleCell: 'A2:D2',
                cellsRange: `A2:D${mainPoints.length}`,
            },
            {
                titleCell: 'A2:E2',
                cellsRange: `A2:E${costReduction.length}`,
                numberFormatRange: `B1:E${costReduction.length}`,
            },
        ];

        // 소계 Rows 위치 list 만들기
        const subTotalRows = settlement.reduce((acc, cur, idx) => {
            if (
                [
                    t('Subtotal'),
                    t('Total equipment operation'),
                    t('Home appliances subtotal'),
                    t('Total home appliances'),
                ].includes(cur['B'])
            ) {
                acc.push(idx + 1);
            }
            return acc;
        }, []);

        if (subTotalRows.length) {
            subTotalRows.forEach((subTotalRow, idx) => {
                dataInfo[0][`subTotalRow${idx}`] = `A${subTotalRow}:V${subTotalRow}`;
                dataInfo[0][`subTotalTitle${idx}`] = `B${subTotalRow}:C${subTotalRow}`;
            });
        }

        const nameIncludeRowsIdxList = settlement.reduce((acc, cur, idx) => {
            if ([t('Equipment operation'), t('Home appliances')].includes(cur['A'])) {
                acc.push(idx + 1);
            }
            return acc;
        }, []);

        if (data.settleMent && nameIncludeRowsIdxList.length) {
            nameIncludeRowsIdxList.forEach((nameIncludeRow, idx) => {
                dataInfo[0][`nameIncludeRow${idx}`] = `B${nameIncludeRow}:C${nameIncludeRow}`;
            });
        }

        data.settleMent &&
            ['H', 'L', 'M', 'Q', 'R'].forEach((column, idx) => {
                dataInfo[0][`backgroundColored${idx}`] = `${column}4:${column}${settlement.length - 2}`;
            });
        data.settleMent &&
            ['S', 'T'].forEach((column, idx) => {
                dataInfo[0][`secondbackgroundColored${idx}`] = `${column}4:${column}${settlement.length - 2}`;
            });
        // 전체합계 수선비 집행률, 실적 background-color
        data.settleMent &&
            data.settleMent.totalSum &&
            ['K', 'X'].forEach((column, idx) => {
                dataInfo[0][`exeRateBackgroundColored${idx}`] = `${column}${settlement.length - 2}:${column}${
                    settlement.length - 2
                }`;
            });

        return addStyles(workbookBlob, dataInfo, data);
    };

    const addStyles = (workbook2Blob, dataInfo, data) => {
        return XlsxPopulate.fromDataAsync(workbook2Blob).then(workbook => {
            workbook.sheets().forEach((sheet, idx) => {
                if (idx === 0) {
                    sheet.row(1).height(30);
                    sheet.row(4).height(35);

                    sheet.range(dataInfo[idx].titleRange).merged(true).style({
                        border: true,
                        horizontalAlignment: 'center',
                        verticalAlignment: 'center',
                        fontSize: 20,
                        fill: 'F2F2F2',
                    });

                    sheet.range(dataInfo[idx].titleCell1).merged(true).style({
                        border: true,
                        fontSize: 9,
                        horizontalAlignment: 'center',
                        verticalAlignment: 'center',
                    });
                    sheet.range(dataInfo[idx].titleCell2).merged(true).style({
                        border: true,
                        fontSize: 9,
                        horizontalAlignment: 'center',
                        verticalAlignment: 'center',
                    });
                    sheet.range(dataInfo[idx].titleCell3).merged(true).style({
                        border: true,
                        fontSize: 9,
                        horizontalAlignment: 'center',
                        verticalAlignment: 'center',
                        leftBorder: 'double',
                    });
                    sheet.range(dataInfo[idx].titleRow).style({
                        border: true,
                        bottomBorder: 'double',
                        horizontalAlignment: 'center',
                        verticalAlignment: 'center',
                        fontSize: 9,
                        wrapText: true,
                    });
                    sheet.range(dataInfo[idx].tableRange).style({
                        border: true,
                        fontSize: 9,
                        horizontalAlignment: 'center',
                        verticalAlignment: 'center',
                        numberFormat: '#,###;#,###;-',
                    });
                    sheet.range(dataInfo[idx].totalRange).style({
                        border: true,
                        bold: true,
                        verticalAlignment: 'center',
                        numberFormat: '#,###;#,###;-',
                    });
                    sheet.range(dataInfo[idx].totalRepairCost).merged(true).style({
                        horizontalAlignment: 'center',
                    });
                    sheet.range(dataInfo[idx].sumThisMonRepair).merged(true).style({
                        horizontalAlignment: 'right',
                        leftBorder: 'double',
                    });
                    sheet.range(dataInfo[idx].cumulateTotal).merged(true).style({
                        horizontalAlignment: 'center',
                    });
                    sheet.range(dataInfo[idx].accumRepair).merged(true).style({
                        horizontalAlignment: 'right',
                    });
                    sheet.range(dataInfo[idx].totalBudget).merged(true).style({
                        horizontalAlignment: 'center',
                    });
                    sheet.range(dataInfo[idx].totalBudgetSum).merged(true).style({
                        horizontalAlignment: 'right',
                    });
                    sheet.range(dataInfo[idx].exeRate).merged(true).style({
                        horizontalAlignment: 'center',
                    });
                    sheet.range(dataInfo[idx].executionRate).merged(true).style({
                        horizontalAlignment: 'right',
                    });
                    sheet.range(dataInfo[idx].savingMonthColumn).style({
                        leftBorder: 'double',
                    });
                    sheet.range(dataInfo[idx].totalSumTitle).merged(true);
                    sheet.range(dataInfo[idx].totalSum).style({
                        topBorder: 'double',
                    });
                    sheet.range(dataInfo[idx].unitTitle).style({
                        fontSize: 8,
                        horizontalAlignment: 'right',
                    });
                    data.settleMent &&
                        sheet.range(dataInfo[idx].totalBudgetAllocation).style({
                            bold: true,
                            underline: true,
                        });
                    data.settleMent &&
                        sheet.range(dataInfo[idx].tableDataArea).style({
                            horizontalAlignment: 'right',
                        });

                    // 배열 자료들 스타일링
                    Object.keys(dataInfo[idx]).forEach(key => {
                        // 전체줄
                        if (/^subTotalRow/.test(key)) {
                            sheet.range(dataInfo[idx][key]).style({
                                topBorder: 'double',
                            });
                        }
                        // subTotal 제목줄
                        if (/^subTotalTitle/.test(key)) {
                            sheet.range(dataInfo[idx][key]).merged(true).style({
                                horizontalAlignment: 'left',
                            });
                        }
                        if (/^backgroundColored/.test(key)) {
                            sheet.range(dataInfo[idx][key]).style({
                                fill: 'FFFFCC',
                            });
                        }
                        if (/^secondbackgroundColored/.test(key)) {
                            sheet.range(dataInfo[idx][key]).style({
                                fill: 'C5D9F1',
                            });
                        }
                        if (/^exeRateBackgroundColored/.test(key)) {
                            sheet.range(dataInfo[idx][key]).style({
                                fill: 'FFFF00',
                            });
                        }
                        if (/^nameIncludeRow/.test(key)) {
                            sheet.range(dataInfo[idx][key]).style({
                                fill: 'FFFF00',
                            });
                        }
                    });
                }

                if (idx === 1) {
                    sheet.column('B').width(20);
                    sheet.column('C').width(30);
                    sheet.column('D').width(50);

                    ['A', 'B', 'C'].forEach(column => {
                        sheet.column(column).style({
                            horizontalAlignment: 'center',
                            verticalAlignment: 'center',
                        });
                    });

                    sheet.range(dataInfo[idx].titleCell).style({
                        border: true,
                        fill: 'F2F2F2',
                    });

                    sheet.range(dataInfo[idx].cellsRange).style({
                        border: true,
                    });
                }

                if (idx === 2) {
                    sheet.column('B').width(30);
                    sheet.column('C').width(40);
                    sheet.column('D').width(20);
                    sheet.column('E').width(25);

                    ['A', 'B', 'C', 'D', 'E'].forEach(column => {
                        sheet.column(column).style({
                            horizontalAlignment: 'center',
                            verticalAlignment: 'center',
                        });
                    });
                    sheet.range(dataInfo[idx].titleCell).style({
                        border: true,
                        fill: 'F2F2F2',
                    });
                    sheet.range(dataInfo[idx].cellsRange).style({
                        border: true,
                    });
                    sheet.range(dataInfo[idx].numberFormatRange).style({
                        numberFormat: '₩#,###;₩#,###;₩0',
                    });
                }
            });
            return workbook.outputAsync().then(workbook2Blob => URL.createObjectURL(workbook2Blob));
        });
    };

    const { toggleModal: toggleExportModal, Modal: ExportModal } = useConfirmModal({
        header: { title: t('Export', 'Button') },
        confirmText: t('Would you like to export the settlement details?', 'Settlement'),
        okCallback: () => {
            createDownloadData();
        },
    });

    useEffect(() => {
        if (data) {
            toggleExportModal();
        }
    }, [data]);

    return (
        <>
            <Button
                className={'btn-brand btn-icon'}
                iconClassName={'icon-file'}
                onClick={() => {
                    getAccountSettleExportExcel();
                }}
            >
                {t('Export', 'Button')}
            </Button>
            <ExportModal />
        </>
    );
};

export default ExcelExport;
