import React from 'react';

const CustomCheckBox = ({ id, text, handleChange, checked, value, handleClick }) => {
    return (
        <label htmlFor={'high'} className={'mr-2'}>
            <input
                type={'checkbox'}
                id={id}
                value={value}
                checked={checked}
                onChange={handleChange}
                onClick={handleClick}
            />
            {text}
        </label>
    );
};

export default CustomCheckBox;
