import React from 'react';

const NumberInput = ({ disabled = false, name, value, onChange }) => {
    return (
        <div className="pnt-input--group">
            <input
                type="text"
                className="pnt-input"
                name={name}
                value={value}
                onChange={onChange}
                disabled={disabled}
            />
        </div>
    );
};

export default NumberInput;
