import React from 'react';
import cx from 'classnames';
import Label from '../../../../Common/Label';

const DetailLabel = ({ required, name, value, valueClassName }) => {
    return (
        <Label
            labelValueClassName={cx('label-dot', required && 'label-must')}
            name={name}
            value={<div className={`w-100 d-flex ${valueClassName}`}>{value}</div>}
        />
    );
};

export default DetailLabel;
