import React from 'react';
import useTranslation from '../../../../../util/hooks/useTranslation';

const CustomLabel = ({ name, children }) => {
    const t = useTranslation('Equipment evaluation');
    return (
        <div className="pnt-label--group">
            <div className="label-main">{t(name)}</div>
            <div className="label-options">{children}</div>
        </div>
    );
};

export default CustomLabel;
