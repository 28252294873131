import React from 'react';
import Marquee from 'react-fast-marquee';
import styled from 'styled-components';
import moment from 'moment';
import useTranslation from '../../../../util/hooks/useTranslation';
const TodayAssetList = ({ count, countTitle, list }) => {
    const t = useTranslation('TodayAssetList');
    return (
        <Container>
            <CountWrapper>
                <span
                    className={'text-ellipsis h5'}
                    title={`${countTitle} : ${count}`}
                >{`${countTitle} : ${count}`}</span>
            </CountWrapper>
            <Marquee pauseOnHover>
                {list.map(({ targetName, targetId, groupName, regDate }, idx) => (
                    <>
                        <Number>
                            <span title={idx + 1}>{`${idx + 1}-`}</span>
                        </Number>
                        <AssetWrapper key={`${targetId}-${idx}`}>
                            <span title={`${t('Product name')} : ${targetName}`}>{`${t(
                                'Product name',
                            )} : ${targetName}`}</span>
                            <span title={`${t('Asset code')} : ${targetId}`}>{`${t('Asset code')} : ${targetId}`}</span>
                            <span title={`${t('Department')} : ${groupName}`}>{`${t(
                                'Department',
                            )} : ${groupName}(${moment(regDate * 1000).format('HH:mm')})`}</span>
                        </AssetWrapper>
                        {list.length === idx + 1 && <Contour />}
                    </>
                ))}
            </Marquee>
        </Container>
    );
};

const AssetWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 4rem;
    span {
        font-size: 0.9rem;
    }
`;
const CountWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #dedede;
    height: 100%;
    width: 10%;
    span {
        font-size: 1.5rem;
    }
`;
const Container = styled.div`
    display: flex;
    flex-direction: row;
    margin-right: -1px;
    border: 1px solid #dedede;
    height: 10%;
    span {
        font-weight: bold;
    }
`;
const Number = styled.div`
    height: 67px;
    margin-right: 2px;
`;
const Contour = styled.div`
    border-right: 1px solid gray;
    margin-right: 30px;
    height: 3.25rem;
`;
export default TodayAssetList;
