import React, { useEffect, useState, useRef } from 'react';
import Card from '../../../Common/Card';
import useTranslation from '../../../../util/hooks/useTranslation';
import HeaderTimer from '../Components/HeaderTimer';
import StatusDashboard from '../Components/StatusDashboard';
import AlertModal from '../../Components/Modal/AlertModal';
import ErrorModal from '../../Components/Modal/ErrorModal';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { setFullScreen, setMode } from '../../../../reducers/Dashboards/DashboardFrame';
import cx from 'classnames';
import { Button } from 'reactstrap';
import useSocket from '../../../../util/socket/hooks/useSocket';
import useAsync from '../../../../util/hooks/useAsync';
import { getRepairTakeoverDashboard } from '../../../../api/asset';
import TodayAssetList from '../Components/TodayAssetList';

const ALERT_OPEN_TIMEOUT = 400;
const ALERT_TIMEOUT = 5000;
const STATUS_TYPES = 'TAKEOVER_COMPLETE';
const TransferredRepairStatus = ({ match }) => {
    const t = useTranslation('TransferredStatus');
    // const savedDate = useRef(moment().format('DD'));
    const savedInterval = useRef(null);

    const { socket, setSocketEvent } = useSocket();

    const dispatch = useDispatch();
    const { fullScreen } = useSelector(State => State.DashboardFrame);

    const modalOpenTimeout = useRef(null);
    const modalTimeout = useRef(null);

    const errModalOpenTimeout = useRef(null);
    const errModalTimeout = useRef(null);

    const [alertModal, setAlertModal] = useState(false);
    const [alertInfo, setAlertInfo] = useState({ message: {} });

    const [errInfo, setErrorInfo] = useState({ message: {} });
    // 에러 알람 모달들 상태
    const [takeOutModal, setTakeOutModal] = useState(false);
    const [duplicateModal, setDuplicateModal] = useState(false);
    const [transfereeErrorModal, setTransfereeErrorModal] = useState(false);
    const [assetErrorModal, setAssetErrorModal] = useState(false);

    const {
        eventTime,
        message: { totalCount, targetId, targetName, groupName, userName, targetImage },
    } = alertInfo;

    const [list, setList] = useState([]);
    const [todayReceiptList, setTodayReceiptList] = useState([]);
    const [todayReceiptCount, setTodayReceiptCount] = useState(0);
    const { promise: getRepairTakeoverDashboardApi, state: getRepairTakeoverDashboardInfo } = useAsync({
        promise: getRepairTakeoverDashboard,
        immediate: true,
        fixedParam: {
            statusTypes: STATUS_TYPES,
            pageSize: 20,
        },
        resolve: res => {
            const { rows, todayList, todayCount } = res;
            setList(rows);
            setTodayReceiptList(todayList.sort((a, b) => a.regDate - b.regDate));
            setTodayReceiptCount(todayCount);
        },
        reject: err => console.error(err),
    });

    useEffect(() => {
        savedInterval.current = setInterval(() => {
            getRepairTakeoverDashboardApi();
        }, 3000);

        return () => clearInterval(savedInterval.current);
    }, []);

    useEffect(() => {
        if (socket) {
            setSocketEvent('RepairTakeover', data => {
                if (data.message && data.message.statusType === STATUS_TYPES) {
                    setAlertInfo(data);
                } else {
                    setErrorInfo(data);
                }
            });
        }
        return () => {
            setSocketEvent('RepairTakeover');
        };
    }, [socket]);

    // 확인 모달 로직
    useEffect(() => {
        if (Object.keys(alertInfo.message).length) {
            setAlertModal(false);
            modalOpenTimeout.current = setTimeout(() => {
                setAlertModal(true);
            }, ALERT_OPEN_TIMEOUT);
            modalTimeout.current = setTimeout(() => {
                setAlertModal(false);
            }, ALERT_TIMEOUT);

            return () => {
                clearTimeout(modalTimeout.current);
                clearTimeout(modalOpenTimeout.current);
            };
        } else {
            const filteredList = list.filter(item => item.targetId !== alertInfo.targetId);
            setList(filteredList);
        }
    }, [alertInfo]);

    // 에러 모달 로직
    useEffect(() => {
        if (Object.keys(errInfo.message).length) {
            setTakeOutModal(false);
            setTransfereeErrorModal(false);
            setAssetErrorModal(false);
            setDuplicateModal(false);
            if (errInfo.message.errorCode === 'TAKEOVER_BEFORE') {
                errModalOpenTimeout.current = setTimeout(() => {
                    setTakeOutModal(true);
                }, ALERT_OPEN_TIMEOUT);
            }
            if (errInfo.message.errorCode === 'NOT_EXIST_CONSIGNEE') {
                errModalOpenTimeout.current = setTimeout(() => {
                    setTransfereeErrorModal(true);
                }, ALERT_OPEN_TIMEOUT);
            }
            if (errInfo.message.errorCode === 'NOT_EXIST_ASSET') {
                errModalOpenTimeout.current = setTimeout(() => {
                    setAssetErrorModal(true);
                }, ALERT_OPEN_TIMEOUT);
            }
            if (errInfo.message.errorCode === 'DUPLICATE_TAKEOVER') {
                errModalOpenTimeout.current = setTimeout(() => {
                    setDuplicateModal(true);
                }, ALERT_OPEN_TIMEOUT);
            }
            errModalTimeout.current = setTimeout(() => {
                setTakeOutModal(false);
                setTransfereeErrorModal(false);
                setAssetErrorModal(false);
                setDuplicateModal(false);
            }, ALERT_TIMEOUT);

            return () => {
                clearTimeout(errModalOpenTimeout.current);
                clearTimeout(errModalTimeout.current);
            };
        }
    }, [errInfo]);

    // 풀 스크린 모드
    const toggleFullScreen = () => {
        if (!document.fullscreenElement) {
            const dashboardEl = document.getElementsByClassName('app-main__inner')[0];
            dashboardEl.requestFullscreen();
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
        }
    };

    const handleFullscreenChange = e => {
        if (document.fullscreenElement) {
            dispatch(setFullScreen(true));
        } else {
            dispatch(setFullScreen(false));
        }
    };

    useEffect(() => {
        dispatch(setMode('monitoring'));

        const dashboardEl = document.getElementsByClassName('app-main__inner')[0];
        dashboardEl.addEventListener('fullscreenchange', handleFullscreenChange);

        return () => {
            dashboardEl.removeEventListener('fullscreenchange', handleFullscreenChange);
        };
    }, [match.url]);
    // 풀스크린 모드 코드 끝

    // 인계 알람 모달
    const handleAlertModal = () => {
        setAlertModal(!alertModal);
    };

    return (
        <>
            <Card
                className={cx(fullScreen ? 'single-fullScreen-card-container' : 'single-card-container')}
                header={{
                    title: t('Transferred Repair Status'),
                }}
            >
                <HeaderTimer />
                <StatusDashboard list={list} isLoading={getRepairTakeoverDashboardInfo.isLoading} />
                <TodayAssetList count={todayReceiptCount} countTitle={t('Takeovers')} list={todayReceiptList} />
            </Card>
            <AlertModal
                initModal={alertModal}
                toggleModal={handleAlertModal}
                modalType={{ title: t('Transferred'), body: t('Transferee'), type: 'Transferred' }}
                modalBody={{
                    totalCount: totalCount ? totalCount : 1,
                    targetId,
                    targetName,
                    groupName,
                    userName,
                    regDate: eventTime,
                    targetImage,
                }}
            />
            <ErrorModal
                initModal={takeOutModal}
                toggleModal={() => {
                    setTakeOutModal(!takeOutModal);
                }}
                removeCancel
                header={{
                    title: t('Asset Take Out Occurrence Notification'),
                }}
                confirmText={<>{t("The assets that have not been transferred. It's been taken out.")}</>}
            />
            <ErrorModal
                initModal={duplicateModal}
                toggleModal={() => {
                    setDuplicateModal(!duplicateModal);
                }}
                removeCancel
                header={{
                    title: t('Transferee Duplicate Tagging Notification'),
                }}
                confirmText={
                    <>
                        {t(
                            'Barcodes from other transferee were recognized prior to completion of the transfer procedure.',
                        )}
                    </>
                }
            />
            <ErrorModal
                initModal={transfereeErrorModal}
                toggleModal={() => {
                    setTransfereeErrorModal(!transfereeErrorModal);
                }}
                removeCancel
                header={{
                    title: t('Transferee Error Notification'),
                }}
                confirmText={
                    <div className="text-center">
                        <div className="font-weight-bold">{t('There is no transferee information')}</div>
                        <div className="mt-2">
                            0. ({t('Choice')}) {t('Asset barcode recognition')}
                        </div>
                        <div>1. {t('Access certificate barcode recognition')}</div>
                        <div>2. {t('Termination barcode recognition')}</div>
                        <div className="mt-1 font-weight-bold">
                            * {t('Contact administrator if you continue to experience errors')}
                        </div>
                    </div>
                }
            />
            <ErrorModal
                initModal={assetErrorModal}
                toggleModal={() => {
                    setAssetErrorModal(!assetErrorModal);
                }}
                removeCancel
                header={{
                    title: t('Transfer Asset Error Notification'),
                }}
                confirmText={
                    <div className="text-center">
                        <div className="font-weight-bold">{t('No asset information to transfer.')}</div>
                        <div className="mt-2">1. {t('Asset barcode recognition')}</div>
                        <div>2. {t('Access certificate barcode recognition')}</div>
                        <div>3. {t('Termination barcode recognition')}</div>
                        <div className="mt-1 font-weight-bold">
                            * {t('Contact administrator if you continue to experience errors')}
                        </div>
                    </div>
                }
            />
            {!isMobile && (
                <>
                    {!fullScreen && (
                        <>
                            <Button className={'btn-status-fullscreen btn btn-secondary'} onClick={toggleFullScreen}>
                                {t('Full Screen', 'Button')}
                            </Button>
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default TransferredRepairStatus;
