import { reqGet } from './index';

export const fetchFloorList = param => reqGet('/v3/api/common/floor', { ...param, isAll: 'Y' });
export const fetchSelectedFloorInfo = param => reqGet(`/v3/api/common/floor`, { ...param });
export const fetchGeofenceList = param => reqGet('/v3/api/common/geofence', { ...param, isAll: 'Y' });
//북마크 여부
export const fetchBookmarkList = param => reqGet('v3/api/common/target/bookmark', { ...param, isAll: 'Y' });
//대상 카테고리 속성 정보 목록조회 API
export const getCategoryPropertiesListApi = param =>
    reqGet('/v3/api/common/target/category/config', { ...param, isAll: 'Y' });

export const getSelectedFloorInfoApi = param => reqGet(`/v3/api/common/floor`, { ...param });

export const getGeofenceInfoApi = param => reqGet(`/v3/api/common/realtime/geofence/info`, { ...param });

export const getCategoryPropertyInfoApi = param => reqGet(`/v3/api/common/target/category/config`, { ...param });

export const getUserInfoApi = param => reqGet('/v3/api/common/user/info/list', { ...param });

//유저 정보
