import React, { useEffect, useState } from 'react';
import useTranslation from '../../../../../util/hooks/useTranslation';
import DatePicker from '../../../../Common/DatePicker';
import moment from 'moment';
import FilterSearchGroup from '../../../../Common/FilterSearchGroup';
import SearchGroup from '../../../../Common/FilterSearchGroup/Components/SearchGroup';
import { SelectGroup } from '../../../../Common/FilterSearchGroup/Components/Part';

const Search = ({ defaultMonth = moment().valueOf(), handleSearchMonth }) => {
    const [searchDate, setSearchDate] = useState(defaultMonth);
    const handleChangeDate = select => {
        setSearchDate(select);
        handleSearchMonth(moment(select).format('YYYY-MM'));
    };

    useEffect(() => {
        handleSearchMonth(moment(searchDate).format('YYYY-MM'));
    }, []);

    return (
        <FilterSearchGroup>
            <SearchGroup className="bg-brand">
                <SelectGroup className="settle-month-picker">
                    <DatePicker
                        value={searchDate}
                        valueType="ms"
                        dateFormat="Y / MMM"
                        handleChange={handleChangeDate}
                        onChangeRaw={e => e.preventDefault()}
                        maxDate={defaultMonth}
                        showMonthYearPicker
                        showFullMonthYearPicker
                        showTwoColumnMonthYearPicker
                        withoutDay
                        shouldCloseOnSelect
                    />
                </SelectGroup>
            </SearchGroup>
        </FilterSearchGroup>
    );
};

export default Search;
