import React, { useEffect, useRef, useState } from 'react';
import Card from '../../../Common/Card';
import useTranslation from '../../../../util/hooks/useTranslation';
import HeaderTimer from '../Components/HeaderTimer';
import StatusDashboard from '../Components/StatusDashboard';
import AlertModal from '../../Components/Modal/AlertModal';
import ErrorModal from '../../Components/Modal/ErrorModal';
import { useDispatch, useSelector } from 'react-redux';
import { setFullScreen, setMode } from '../../../../reducers/Dashboards/DashboardFrame';
import { isMobile } from 'react-device-detect';
import cx from 'classnames';
import { Button } from 'reactstrap';
import useSocket from '../../../../util/socket/hooks/useSocket';
import { getRepairReceptionDashboard } from '../../../../api/asset';
import useAsync from '../../../../util/hooks/useAsync';
import TodayAssetList from '../Components/TodayAssetList';

const ALERT_OPEN_TIMEOUT = 400;
const ALERT_TIMEOUT = 5000;
const STATUS_TYPES = 'RECEIPT_COMPLETE';
const RegisteredRepairStatus = ({ match }) => {
    const t = useTranslation('RegisteredStatus');
    // const savedDate = useRef(moment().format('DD'));
    const savedInterval = useRef(null);

    const { socket, setSocketEvent } = useSocket();

    const dispatch = useDispatch();
    const { fullScreen } = useSelector(State => State.DashboardFrame);

    const modalOpenTimeout = useRef(null);
    const modalTimeout = useRef(null);

    const errModalOpenTimeout = useRef(null);
    const errModalTimeout = useRef(null);

    const [alertModal, setAlertModal] = useState(false);
    const [alertInfo, setAlertInfo] = useState({ message: {} });

    const [errInfo, setErrInfo] = useState({ message: {} });
    // 에러알람 모달들 상태
    const [receptionistErrorModal, setReceptionistErrorModal] = useState(false);
    const [assetErrorModal, setAssetErrorModal] = useState(false);

    const {
        eventTime,
        message: { totalCount, targetId, targetName, groupName, userName },
    } = alertInfo;

    const [list, setList] = useState([]);
    const [todayReceiptList, setTodayReceiptList] = useState([]);
    const [todayReceiptCount, setTodayReceiptCount] = useState(0);
    const { promise: getRepairReceptionDashboardApi, state: getRepairReceptionDashboardInfo } = useAsync({
        promise: getRepairReceptionDashboard,
        immediate: true,
        fixedParam: {
            statusTypes: STATUS_TYPES,
            pageSize: 20,
        },
        resolve: res => {
            const { rows, todayList, todayCount } = res;
            setList(rows);
            setTodayReceiptList(todayList.sort((a, b) => a.regDate - b.regDate));
            setTodayReceiptCount(todayCount);
        },
        reject: err => console.error(err),
    });

    useEffect(() => {
        savedInterval.current = setInterval(() => {
            getRepairReceptionDashboardApi();
        }, 3000);

        return () => clearInterval(savedInterval.current);
    }, []);

    useEffect(() => {
        if (socket) {
            setSocketEvent('RepairReception', data => {
                if (data.message && (data.message.statusType === STATUS_TYPES || data.message.statusType === null)) {
                    setAlertInfo(data);
                } else {
                    setErrInfo(data);
                }
            });
        }
        return () => {
            setSocketEvent('RepairReception');
        };
    }, [socket]);

    // 확인 모달 로직
    useEffect(() => {
        if (Object.keys(alertInfo.message).length) {
            setAlertModal(false);
            modalOpenTimeout.current = setTimeout(() => {
                setAlertModal(true);
            }, ALERT_OPEN_TIMEOUT);
            modalTimeout.current = setTimeout(() => {
                setAlertModal(false);
            }, ALERT_TIMEOUT);

            return () => {
                clearTimeout(modalTimeout.current);
                clearTimeout(modalOpenTimeout.current);
            };
        } else {
            const filteredList = list.filter(item => item.targetId !== alertInfo.targetId);
            setList(filteredList);
        }
    }, [alertInfo]);

    // 에러 모달 로직
    useEffect(() => {
        if (Object.keys(errInfo.message).length) {
            setReceptionistErrorModal(false);
            setAssetErrorModal(false);
            if (errInfo.message.errorCode === 'NOT_EXIST_RECEPTIONIST') {
                errModalOpenTimeout.current = setTimeout(() => {
                    setReceptionistErrorModal(true);
                }, ALERT_OPEN_TIMEOUT);
            }
            if (errInfo.message.errorCode === 'NOT_EXIST_ASSET') {
                errModalOpenTimeout.current = setTimeout(() => {
                    setAssetErrorModal(true);
                }, ALERT_OPEN_TIMEOUT);
            }
            errModalTimeout.current = setTimeout(() => {
                setReceptionistErrorModal(false);
                setAssetErrorModal(false);
            }, ALERT_TIMEOUT);

            return () => {
                clearTimeout(errModalOpenTimeout.current);
                clearTimeout(errModalTimeout.current);
            };
        }
    }, [errInfo]);

    // 풀 스크린 모드
    const toggleFullScreen = () => {
        if (!document.fullscreenElement) {
            const dashboardEl = document.getElementsByClassName('app-main__inner')[0];
            dashboardEl.requestFullscreen();
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
        }
    };

    const handleFullscreenChange = e => {
        if (document.fullscreenElement) {
            dispatch(setFullScreen(true));
        } else {
            dispatch(setFullScreen(false));
        }
    };

    useEffect(() => {
        dispatch(setMode('monitoring'));

        const dashboardEl = document.getElementsByClassName('app-main__inner')[0];
        dashboardEl.addEventListener('fullscreenchange', handleFullscreenChange);

        return () => {
            dashboardEl.removeEventListener('fullscreenchange', handleFullscreenChange);
        };
    }, [match.url]);
    // 풀스크린 모드 코드 끝

    // 접수 알람 모달
    const handleAlertModal = () => {
        setAlertModal(!alertModal);
    };

    return (
        <>
            <Card
                className={cx(fullScreen ? 'single-fullScreen-card-container' : 'single-card-container')}
                header={{
                    title: t('Registered Repair Status'),
                }}
            >
                <HeaderTimer />
                <StatusDashboard list={list} isLoading={getRepairReceptionDashboardInfo.isLoading} />
                <TodayAssetList
                    count={todayReceiptCount}
                    countTitle={t('Intake', 'TransferredStatus')}
                    list={todayReceiptList}
                />
            </Card>
            <AlertModal
                initModal={alertModal}
                toggleModal={handleAlertModal}
                modalType={{ title: t('Registered'), body: t('Receptionist'), type: 'Registered' }}
                modalBody={{
                    totalCount: totalCount ? totalCount : 1,
                    targetId,
                    targetName,
                    groupName,
                    userName,
                    regDate: eventTime,
                }}
            />
            <ErrorModal
                initModal={receptionistErrorModal}
                toggleModal={() => {
                    setReceptionistErrorModal(!receptionistErrorModal);
                }}
                header={{ title: t('Receptionist Error Notification') }}
                confirmText={
                    <div className="text-center">
                        <div className="font-weight-bold">{t('There is no receptionist information.')}</div>
                        <div className="mt-2">1. {t('Access Card barcode recognition')}</div>
                        <div>2. {t('End barcode recognition')}</div>
                        <div className="mt-1 font-weight-bold">
                            * {t('Contact administrator if you continue to experience errors')}
                        </div>
                    </div>
                }
                removeCancel
            />
            <ErrorModal
                initModal={assetErrorModal}
                toggleModal={() => setAssetErrorModal(!assetErrorModal)}
                header={{ title: t('Registered Asset Error Notification') }}
                confirmText={
                    <div className="text-center">
                        <div className="font-weight-bold">{t('There is no registered asset information.')}</div>
                        <div className="mt-2">1. {t('Asset barcode recognition')}</div>
                        <div>2. {t('Access Card barcode recognition')}</div>
                        <div>3. {t('End barcode recognition')}</div>
                        <div className="mt-1 font-weight-bold">
                            * {t('Contact administrator if you continue to experience errors')}
                        </div>
                    </div>
                }
                removeCancel
            />
            {!isMobile && (
                <>
                    {!fullScreen && (
                        <>
                            <Button className={'btn-status-fullscreen btn-gray'} onClick={toggleFullScreen}>
                                {t('Full Screen', 'Button')}
                            </Button>
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default RegisteredRepairStatus;
