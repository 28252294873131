import React from 'react';

import cx from 'classnames';

const CustomLabel = ({ name, value, required, labelValueClassName, labelGroupClassName, labelOptionClassName }) => {
    return (
        <>
            {/*<Label*/}
            {/*    name={name}*/}
            {/*    //labelGroupClassName={labelGroupClassName ? labelGroupClassName : 'min-h-50 w-100'}*/}

            {/*    labelValueClassName={cx('label-dot', labelValueClassName, required && 'color-brand')}*/}
            {/*    value={value}*/}
            {/*/>*/}
            <div className={cx('pnt-label--group', labelGroupClassName ? labelGroupClassName : 'min-h-50 w-100')}>
                {/*{labelValueClassName.includes('label-icon') && <span className={labelIcon} />}*/}
                <div className={cx(`label-main label-dot`, labelValueClassName, required && 'color-brand')}>
                    {name}
                    {/*{labelValueClassName.includes('label-info') && (*/}
                    {/*    <>*/}
                    {/*        <span className={'icon-info-fill'} />*/}
                    {/*        /!*<div className="info-box">{infoBoxValue}</div>*!/*/}
                    {/*    </>*/}
                    {/*)}*/}
                </div>
                {value && <div className={`label-options ${labelOptionClassName}`}>{value}</div>}
            </div>
        </>
    );
};

export default CustomLabel;
