import React, { useState, useEffect, useRef } from 'react';
import AccessSearch from '../Components/AccessSearch';
import Card from '../../../Common/Card';
import useTranslation from '../../../../util/hooks/useTranslation';
import Button from '../../../Common/Button';
import Table from '../../../Common/Table';
import ConfirmModal from '../../../Common/ConfirmModal';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import useAsync from '../../../../util/hooks/useAsync';
import {
    getRepairReceptionListApi,
    deleteRepairReceptionApi,
    updateCancelRepairReceptionApi,
    fetchRepairReceptionExportApi,
} from '../../../../api/asset';
import { fetchExcelLogApi } from '../../../../api/log';
import useConfirmModal from '../../../Common/ConfirmModal/useConfirmModal';

const INTERVAL_TIME = 1000;

const conditionOptions = [
    { value: '', label: 'All' },
    { value: 'userName', label: 'Receptionist' },
    { value: 'targetId', label: 'Asset Number' },
    { value: 'targetName', label: 'Product Name' },
];
const registerOptions = [
    { value: 'RECEIPT_WAIT', label: 'RECEIPT_WAIT' },
    { value: 'RECEIPT_COMPLETE', label: 'RECEIPT_COMPLETE' },
    { value: 'REPAIR_COMPLETE', label: 'REPAIR_COMPLETE' },
];

const falsyToHyphen = ({ value }) => {
    return value ? value : '-';
};

const RegisteredRepairManagement = () => {
    const t = useTranslation('RegisteredRepairManagement');
    const columnsT = useTranslation('RepairColumns');
    const history = useHistory();

    const excelTimerRef = useRef();

    const [tableData, setTableData] = useState({ rows: [], pageSize: 20 });

    // 모달들
    const [deleteModal, setDeleteModal] = useState(false);
    const [downloadModal, setDownloadModal] = useState(false);
    const [notSelectModal, setNotSelectModal] = useState(false);
    const [completeDeleteModal, setCompleteDeleteModal] = useState(false);
    const [cancelReceptionCompleteModal, setCancelReceptionCompleteModal] = useState(false);
    const [cancelReceptionCheckModal, setCancelReceptionCheckModal] = useState(false);

    const [searchCondition, setSearchCondition] = useState({});

    const [selectedReceptionNum, setSelectedReceptionNum] = useState(null);

    // 선택된 자산들
    const [receptionNums, setReceptionNums] = useState([]);

    const [checkAll, setCheckAll] = useState(false);

    const { promise: getRepairReception, state: repairReceptionState } = useAsync({
        promise: getRepairReceptionListApi,
        fixedParam: { pageSize: 20 },
        resolve: res => {
            setTableData(prevState => {
                return { ...prevState, ...res };
            });
        },
    });

    useEffect(() => {
        if (searchCondition.startDate) {
            getRepairReception(searchCondition);
        }
    }, [searchCondition]);

    const { promise: deleteRepairReception } = useAsync({
        promise: deleteRepairReceptionApi,
        resolve: res => {
            handleCompleteDeleteModal();
            getRepairReception(searchCondition);
        },
    });

    const { promise: updateCancelRepairReception } = useAsync({
        promise: updateCancelRepairReceptionApi,
        resolve: res => {
            handleCancelReceptionCompleteModal();
            getRepairReception(searchCondition);
        },
    });

    const { promise: fetchExcelLog } = useAsync({
        promise: fetchExcelLogApi,
        resolve: res => {
            if (res.dataSettingStatusType === 'COMPLETED') {
                clearExcelTimer();
                toggleExcelModal();

                const elem = window.document.createElement('a');
                elem.href = res.filePath;
                elem.download = res.fileName;
                document.body.appendChild(elem);
                elem.click();
                document.body.removeChild(elem);
            } else if (res.dataSettingStatusType === 'FAILED') {
                clearExcelTimer();
            }
        },
    });

    const { promise: createNewReceptionExport } = useAsync({
        promise: fetchRepairReceptionExportApi,
        fixedParam: {
            zipFileName: 'Registered_Repair_Log',
            fileName: 'Registered_Repair_Log',
            columnMetas: [
                {
                    key: 'userName',
                    name: columnsT('Receptionist'),
                },
                {
                    key: 'groupName',
                    name: columnsT('Affiliation'),
                },
                {
                    key: 'regDateStr',
                    name: columnsT('Registered Date'),
                },
                {
                    key: 'targetId',
                    name: columnsT('Asset Number'),
                },
                {
                    key: 'targetName',
                    name: columnsT('Product Name'),
                },
                {
                    key: 'statusType',
                    name: columnsT('Status'),
                },
            ],
        },
        resolve: res => {
            const { filePath: url, reqNum } = res;

            if (url) {
                downloadWithURL(url, reqNum);
            }
        },
    });

    const downloadWithURL = (url, reqNum) => {
        excelTimerRef.current = setInterval(() => {
            fetchExcelLog({ reqNum: reqNum });
        }, INTERVAL_TIME);
    };

    const clearExcelTimer = () => {
        clearInterval(excelTimerRef.current);
        excelTimerRef.current = null;
    };

    const handleExportFileClick = () => {
        createNewReceptionExport(searchCondition);
    };

    const handleAllCheckButtonClick = e => {
        e.stopPropagation();
        const checkAllArray = tableData.rows.reduce((acc, cur) => {
            acc.push(cur.receptionNum);
            return acc;
        }, []);
        setReceptionNums(checkAll ? [] : checkAllArray);
    };

    useEffect(() => {
        if (receptionNums.length && receptionNums.length === tableData.rows.length) {
            setCheckAll(true);
        } else {
            setCheckAll(false);
        }
    }, [receptionNums]);

    const columns = [
        {
            Header: () => {
                return (
                    <input
                        type="checkbox"
                        checked={checkAll}
                        onChange={handleAllCheckButtonClick}
                        style={{ cursor: 'pointer', margin: 0 }}
                    />
                );
            },
            width: 50,
            accessor: 'checkBox',
            Cell: ({ row: { original } }) => {
                return (
                    <div
                        className="w-100 h-100 cursor-default"
                        onClick={e => {
                            e.stopPropagation();
                        }}
                    >
                        <input
                            id={original.receptionNum}
                            className="ml-2 cursor-pointer"
                            type="checkbox"
                            checked={receptionNums.includes(original.receptionNum)}
                            onChange={e => {
                                e.stopPropagation();
                                const { id: currentId, checked } = e.target;
                                const id = parseInt(currentId);
                                const receptionNumsArr = checked
                                    ? [id, ...receptionNums]
                                    : receptionNums.filter(checkedNum => checkedNum !== id);
                                setReceptionNums(receptionNumsArr);
                            }}
                        />
                    </div>
                );
            },
        },
        {
            Header: columnsT('Receptionist'),
            accessor: 'userName',
            className: 'text-ellipsis',
            Cell: falsyToHyphen,
        },
        {
            Header: columnsT('Affiliation'),
            accessor: 'groupName',
            className: 'text-ellipsis',
            Cell: falsyToHyphen,
        },
        {
            Header: columnsT('Registered Date'),
            accessor: 'regDate',
            className: 'text-ellipsis',
            Cell: ({ value }) => {
                const date = moment.unix(value).format('YYYY-MM-DD HH:mm:ss');
                return <span title={date}>{date}</span>;
            },
        },
        {
            Header: columnsT('Asset Number'),
            accessor: 'targetId',
            className: 'text-ellipsis',
        },
        {
            Header: columnsT('Product Name'),
            accessor: 'targetName',
            className: 'text-ellipsis',
        },
        {
            Header: columnsT('Status'),
            accessor: 'statusType',
            className: 'text-ellipsis',
            width: 250,
            Cell: ({ value, row: { original } }) => {
                const receptionNum = original.receptionNum;
                const button = value === 'RECEIPT_COMPLETE';
                return (
                    <>
                        {t(value, 'Asset')}
                        {button && (
                            <button
                                className="table-btn ml-1 mb-1"
                                onClick={e => handleClickCancelRepairReception(e, receptionNum)}
                            >
                                {t('Cancel Register')}
                            </button>
                        )}
                    </>
                );
            },
        },
    ];

    const handleDeleteModal = () => {
        setDeleteModal(!deleteModal);
    };

    const handleDownloadModal = () => {
        setDownloadModal(!downloadModal);
    };

    const handleNotSelectModal = () => {
        setNotSelectModal(!notSelectModal);
    };

    const handleCompleteDeleteModal = () => {
        setCompleteDeleteModal(!completeDeleteModal);
    };

    const handleCancelReceptionCompleteModal = () => {
        setCancelReceptionCompleteModal(!cancelReceptionCompleteModal);
    };

    const { toggleModal: toggleExcelModal, Modal: ExcelModal } = useConfirmModal({
        initModal: false,
        header: {
            title: t('Excel Download', 'Excel Download'),
        },
        confirmText: t('Excel Download Completed', 'Excel Download'),
        removeCancel: true,
    });

    const handleRegisterButton = () => {
        history.push('register-manage/repair');
    };

    const handleDeleteOk = () => {
        if (receptionNums.length) {
            deleteRepairReception({
                receptionNums,
            });
        } else {
            handleNotSelectModal();
        }
    };

    const handleClickCancelRepairReception = (e, receptionNum) => {
        e.stopPropagation();
        handleCancelReceptionCheckModal();
        setSelectedReceptionNum(receptionNum);
    };

    const handleCancelReceptionCheckModal = () => {
        setCancelReceptionCheckModal(!cancelReceptionCheckModal);
    };

    const handleOkCancelReceptionCheck = () => {
        updateCancelRepairReception({
            receptionNum: selectedReceptionNum,
        });
    };

    const handlePageChange = pageIndex => {
        if (searchCondition.startDate) {
            getRepairReception({ page: pageIndex, ...searchCondition });
        }
    };

    return (
        <>
            <div className="grid-layout-container">
                <AccessSearch
                    conditionOptions={conditionOptions}
                    registerOptions={registerOptions}
                    title={'Register Status'}
                    setSearchCondition={setSearchCondition}
                />

                <Card
                    header={{
                        title: t('Registered Repair Management', 'Menu'),
                        action: (
                            <>
                                <Button className="btn-brand" onClick={handleDownloadModal}>
                                    {t('Download', 'Button')}
                                </Button>
                                <Button className="btn-secondary" onClick={handleRegisterButton}>
                                    {t('Register')}
                                </Button>
                                <Button className="btn-danger" onClick={handleDeleteModal}>
                                    {t('Delete')}
                                </Button>
                            </>
                        ),
                    }}
                >
                    <div className="table-container">
                        <Table
                            textAlign={'center'}
                            columns={columns}
                            data={{ ...tableData, pageSize: 20 }}
                            loading={repairReceptionState.isLoading}
                            onTrClick={e => {
                                history.push(`/asset-access/register-manage/${e.receptionNum}`);
                            }}
                            onPageChange={handlePageChange}
                        />
                    </div>
                </Card>
            </div>
            <ConfirmModal
                initModal={deleteModal}
                toggleModal={handleDeleteModal}
                header={{ title: t('Delete') }}
                confirmText={
                    <div>
                        <div>{t('Asset and reception information will be deleted.')}</div>
                        <div>{t('Are you sure you want to delete it?')}</div>
                    </div>
                }
                okCallback={handleDeleteOk}
            />
            <ConfirmModal
                initModal={downloadModal}
                toggleModal={handleDownloadModal}
                header={{ title: t('Download', 'Button') }}
                confirmText={t('Do you want to download the excel?')}
                okCallback={handleExportFileClick}
            />
            <ConfirmModal
                initModal={notSelectModal}
                toggleModal={handleNotSelectModal}
                header={{ title: t('Notification') }}
                confirmText={t('Please select an item to delete.')}
            />
            <ConfirmModal
                initModal={completeDeleteModal}
                toggleModal={handleCompleteDeleteModal}
                confirmText={t('Delete completed successfully.')}
            />
            <ConfirmModal
                header={{ title: t('Cancel Register', 'Register Detail') }}
                initModal={cancelReceptionCheckModal}
                toggleModal={handleCancelReceptionCheckModal}
                confirmText={
                    <div>
                        <div>{t('The asset will be changed to a waiting status.', 'Register Detail')}</div>
                        <div>{t('Would you like to cancel the application?', 'Register Detail')}</div>
                    </div>
                }
                okCallback={handleOkCancelReceptionCheck}
            />
            <ConfirmModal
                header={{ title: t('Notification') }}
                initModal={cancelReceptionCompleteModal}
                toggleModal={handleCancelReceptionCompleteModal}
                confirmText={t('Cancel Reception has been Completed.')}
            />
            <ExcelModal />
        </>
    );
};

export default RegisteredRepairManagement;
