import React from 'react';
import { ErrorHandleSwitch } from '../Components/Router';
import { Route } from 'react-router-dom';
import Settlement from './ComputerizedSettlement/Settlement';
import SettlementManager from './ComputerizedSettlement/SettlementManager';
import useAsync from '../../../util/hooks/useAsync';
import { fetchCommuteCodeApi } from '../../../api/engineering';
import { commuteCodeList } from './atoms';
import { useSetRecoilState } from 'recoil';
import EquipmentEvaluation from './EquipmentEvaluation';
import RegisterOrEditEvaluation from './EquipmentEvaluation/RegisterOrEditEvaluation';

const MedicalEngineering = ({ match }) => {
    const setCommuteCodeList = useSetRecoilState(commuteCodeList);

    useAsync({
        promise: fetchCommuteCodeApi,
        immediate: true,
        fixedParam: { gCD: 'COMMUTE' },
        resolve: res => {
            const { rows } = res;
            const commuteCodeList = rows.reduce((acc, curr) => {
                const { sCD, sName } = curr;
                acc.push({ value: sCD, label: sName });
                return acc;
            }, []);
            setCommuteCodeList(commuteCodeList);
        },
    });

    return (
        <ErrorHandleSwitch>
            <Route exact path={`${match.path}/settlement`} component={Settlement} />
            <Route exact path={`${match.path}/settlement-manager`} component={SettlementManager} />
            <Route exact path={`${match.path}/evaluation-list`} component={EquipmentEvaluation} />
            <Route exact path={`${match.path}/evaluation-registration`} component={RegisterOrEditEvaluation} />
            <Route exact path={`${match.path}/edit-evaluation/:drNum([0-9]+)`} component={RegisterOrEditEvaluation} />
        </ErrorHandleSwitch>
    );
};

export default MedicalEngineering;
