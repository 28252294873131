import { createActions, handleActions } from 'redux-actions';
import produce from 'immer';
import moment from 'moment';
import _ from 'lodash';

const SET_TRANSITION_INFO = 'trigger/SET_TRANSITION_INFO';
const CHECK_MANUAL = 'trigger/CHECK_MANUAL';
const CHECK_RESTFUL = 'trigger/CHECK_RESTFUL';
const UPDATE_TRIGGER_GROUP_OPERATOR = 'trigger/UPDATE_TRIGGER_GROUP_OPERATOR';
const ADD_TRIGGER_GROUP = 'trigger/ADD_TRIGGER_GROUP';
const ADD_TRIGGER = 'trigger/ADD_TRIGGER';
const DELETE_TRIGGER_GROUP = 'trigger/DELETE_TRIGGER_GROUP';
const DELETE_TRIGGER = 'trigger/DELETE_TRIGGER';

export const {
    setTransitionInfo,
    checkManual,
    checkRestful,
    updateTriggerGroupOperator,
    addTriggerGroup,
    addTrigger,
    deleteTriggerGroup,
    deleteTrigger,
} = createActions(
    {
        SET_TRANSITION_INFO: transitionInfo => transitionInfo,
        CHECK_MANUAL: checked => checked,
        CHECK_RESTFUL: checked => checked,
        UPDATE_TRIGGER_GROUP_OPERATOR: operatorInfo => operatorInfo,
        ADD_TRIGGER_GROUP: triggerGroupInfo => triggerGroupInfo,
        ADD_TRIGGER: triggerInfo => triggerInfo,
        DELETE_TRIGGER_GROUP: triggerGroupKey => triggerGroupKey,
        DELETE_TRIGGER: triggerKey => triggerKey,
    },
    {
        prefix: 'trigger',
    },
);
export const MANUAL_GROUP_KEY = 'manual';
export const RESTFUL_GROUP_KEY = 'restFul';

export const AND = '&&';
export const OR = '||';

export const TRIGGER_TYPE_MANUAL = 'M';
export const TRIGGER_TYPE_RESTFUL = 'RA';
export const TRIGGER_TYPE_LOCATION = 'L';

export const TRIGGER_SUB_TYPE_USER = 'user';
export const TRIGGER_SUB_TYPE_GROUP = 'group';
export const TRIGGER_SUB_TYPE_FLOOR = 'floor';
export const TRIGGER_SUB_TYPE_GEOFENCE = 'geofence';

export const TRIGGER_VALUE_TYPE_INCLUDE = 'include';
export const TRIGGER_VALUE_TYPE_EXCLUDE = 'exclude';
export const TRIGGER_VALUE_TYPE_IN = 'IN';
export const TRIGGER_VALUE_TYPE_OUT = 'OUT';

export const initialState = {
    startStatusNum: null,
    endStatusNum: null,
    manualOn: false,
    restFulOn: false,
    targetStatusTriggers: [],
    triggerRelationJson: { data: {}, order: [] },
};

export const makeSaveData = ({ data, order }) => {
    const targetStatusTriggers = [];
    let triggerFormula = '';
    let triggerRelationJson = order.length
        ? JSON.stringify({
              data: order.reduce((acc, curr) => {
                  const triggerGroupInfo = data[curr];
                  if (triggerGroupInfo) {
                      acc[curr] = triggerGroupInfo;
                  }
                  return acc;
              }, {}),
              order,
          })
        : '';

    order.map(triggerGroupKey => {
        const triggerGroupData = data[triggerGroupKey];
        if (triggerFormula) {
            triggerFormula += triggerGroupData.op;
        }
        triggerFormula += '(';
        triggerGroupData.triggers.forEach((triggerInfo, index) => {
            const { groupKey, triggerValue, ...restInfo } = triggerInfo;
            restInfo.triggerValue = JSON.stringify(triggerValue);
            // restInfo.triggerSubType = restInfo.triggerSubType || '';
            targetStatusTriggers.push(restInfo);
            if (index) {
                triggerFormula += `${OR}${restInfo.triggerKey}`;
            } else {
                triggerFormula += restInfo.triggerKey;
            }
        });
        triggerFormula += ')';
    });

    return { targetStatusTriggers, triggerFormula, triggerRelationJson };
};

const getUpdatedRelationJson = triggerRelationJson => {
    const newJson = _.cloneDeep(triggerRelationJson);
    const hasManual = newJson.order[0] === MANUAL_GROUP_KEY;
    const nextGroupKey = newJson.order[1];
    for (let groupKey in newJson.data) {
        if (newJson.data.hasOwnProperty(groupKey)) {
            newJson.data[groupKey].disabled = false;
        }
    }
    if (hasManual && nextGroupKey) {
        newJson.data[nextGroupKey].op = OR;
        newJson.data[nextGroupKey].disabled = true;
    }
    return newJson;
};

const triggerReducer = handleActions(
    {
        [SET_TRANSITION_INFO]: (state, action) => {
            return produce(state, draft => {
                const transitionInfo = action.payload;
                draft.startStatusNum = transitionInfo.startStatusNum;
                draft.endStatusNum = transitionInfo.endStatusNum;
                draft.targetStatusTriggers = transitionInfo.targetStatusTriggers;

                transitionInfo.triggerRelationJson = getUpdatedRelationJson(transitionInfo.triggerRelationJson);

                draft.triggerRelationJson = transitionInfo.triggerRelationJson;
                if (transitionInfo.triggerRelationJson) {
                    const relationJson = transitionInfo.triggerRelationJson;
                    draft.manualOn = relationJson.order.includes(MANUAL_GROUP_KEY);
                    draft.restFulOn = relationJson.order.includes(RESTFUL_GROUP_KEY);
                }
            });
        },
        [CHECK_MANUAL]: (state, action) => {
            return produce(state, draft => {
                const checked = !!action.payload;
                draft.manualOn = checked;
                if (checked) {
                    draft.triggerRelationJson.order.unshift(MANUAL_GROUP_KEY);
                    if (!state.triggerRelationJson.data.manual) {
                        draft.triggerRelationJson.data.manual = { op: null, triggers: [] };
                    }
                } else {
                    draft.triggerRelationJson.order = draft.triggerRelationJson.order.filter(
                        v => v !== MANUAL_GROUP_KEY,
                    );
                }
                draft.triggerRelationJson = getUpdatedRelationJson(draft.triggerRelationJson);
            });
        },
        [CHECK_RESTFUL]: (state, action) => {
            return produce(state, draft => {
                const { restFulOn, apiURL } = action.payload;
                draft.restFulOn = restFulOn;
                if (restFulOn) {
                    const manualIndex = state.triggerRelationJson.order.indexOf(MANUAL_GROUP_KEY);
                    if (manualIndex !== -1) {
                        draft.triggerRelationJson.order.splice(manualIndex + 1, 0, RESTFUL_GROUP_KEY);
                    } else {
                        draft.triggerRelationJson.order.unshift(RESTFUL_GROUP_KEY);
                    }
                    if (!state.triggerRelationJson.data.restFul) {
                        const triggerInfo = {};
                        const { startStatusNum, endStatusNum } = state;
                        triggerInfo.startStatusNum = startStatusNum;
                        triggerInfo.endStatusNum = endStatusNum;
                        triggerInfo.triggerKey = `$${moment().valueOf().toString(36)}`;
                        triggerInfo.triggerType = TRIGGER_TYPE_RESTFUL;
                        triggerInfo.triggerSubType = null;
                        triggerInfo.triggerValue = { value: apiURL };
                        triggerInfo.groupKey = RESTFUL_GROUP_KEY;

                        draft.triggerRelationJson.data.restFul = { op: OR, triggers: [triggerInfo] };
                    }
                } else {
                    draft.triggerRelationJson.order = draft.triggerRelationJson.order.filter(
                        v => v !== RESTFUL_GROUP_KEY,
                    );
                }
                draft.triggerRelationJson = getUpdatedRelationJson(draft.triggerRelationJson);
            });
        },
        [UPDATE_TRIGGER_GROUP_OPERATOR]: (state, action) => {
            return produce(state, draft => {
                const triggerGroupInfo = action.payload;
                const { groupKey, op } = triggerGroupInfo;

                draft.triggerRelationJson.data[groupKey].op = op;
            });
        },
        [ADD_TRIGGER_GROUP]: (state, action) => {
            return produce(state, draft => {
                const triggerGroupInfo = action.payload;
                const { groupKey } = triggerGroupInfo;
                let op = AND;
                if (state.triggerRelationJson.order[state.triggerRelationJson.order.length - 1] === MANUAL_GROUP_KEY) {
                    op = OR;
                }
                draft.triggerRelationJson.data[groupKey] = { op: op, triggers: [] };
                draft.triggerRelationJson.order.push(groupKey);
                draft.triggerRelationJson = getUpdatedRelationJson(draft.triggerRelationJson);
            });
        },
        [ADD_TRIGGER]: (state, action) => {
            return produce(state, draft => {
                const triggerInfo = action.payload;
                const { startStatusNum, endStatusNum } = state;
                triggerInfo.startStatusNum = startStatusNum;
                triggerInfo.endStatusNum = endStatusNum;

                triggerInfo.triggerKey = `$${moment().valueOf().toString(36)}`;

                if (draft.triggerRelationJson.data[triggerInfo.groupKey]) {
                    draft.triggerRelationJson.data[triggerInfo.groupKey].triggers.push(triggerInfo);
                }
            });
        },
        [DELETE_TRIGGER_GROUP]: (state, action) => {
            return produce(state, draft => {
                const triggerGroupKey = action.payload;
                draft.triggerRelationJson.data[triggerGroupKey] = null;
                delete draft.triggerRelationJson.data[triggerGroupKey];
                draft.triggerRelationJson.order = state.triggerRelationJson.order.filter(v => v !== triggerGroupKey);
                draft.triggerRelationJson = getUpdatedRelationJson(draft.triggerRelationJson);
            });
        },
        [DELETE_TRIGGER]: (state, action) => {
            return produce(state, draft => {
                const { groupKey, triggerKey } = action.payload;
                draft.triggerRelationJson.data[groupKey].triggers = draft.triggerRelationJson.data[
                    groupKey
                ].triggers.filter(v => v.triggerKey !== triggerKey);
            });
        },
    },
    initialState,
);

export default triggerReducer;
