import React, { useEffect, useState } from 'react';
import Table from '../../../Common/Table';
import useAsync from '../../../../util/hooks/useAsync';
import useColumns from '../../../../util/hooks/useColumns';
import Card from '../../../Common/Card';
import { fetchAssetReceiptAndHandoverLogApi } from '../../../../api/asset';
import moment from 'moment';
import SearchGroup from '../../../Common/FilterSearchGroup/Components/SearchGroup';
import { FilterList, SelectGroup } from '../../../Common/FilterSearchGroup/Components/Part';
import DatePicker from '../../../Common/DatePicker';
import Button from '../../../Common/Button';
import FilterSearchGroup from '../../../Common/FilterSearchGroup';
import useTranslation from '../../../../util/hooks/useTranslation';
import SearchableSelect from '../../../Common/Select/SearchableSelect';

const AssetReceiptAndHandoverLog = () => {
    const t = useTranslation('CommonColumn');
    const searchOptions = [
        { value: 'REPAIR_COMPLETE', label: t('REPAIR_COMPLETE', 'Asset') },
        { value: 'TAKEOVER_COMPLETE', label: t('TAKEOVER_COMPLETE', 'Asset') },
        { value: 'TAKEOVER_WAIT', label: t('TAKEOVER_WAIT', 'Asset') },
        { value: 'TAKEOVER_BEFORE', label: t('TAKEOVER_BEFORE', 'Asset') },
    ];

    const [list, setList] = useState({ rows: [], totalCount: 0, totalPage: 0, pageSize: 10, page: 1 });
    const [startDate, setStartDate] = useState(0);
    const [endDate, setEndDate] = useState(0);
    const columns = useColumns(
        [
            {
                Header: 'Successor',
                accessor: 'userName',
                className: 'text-ellipsis',
                Cell: ({ value }) => <span>{value ? value : '-'}</span>,
            },
            {
                Header: 'Product Name',
                accessor: 'targetName',
                className: 'text-ellipsis',
                Cell: ({ value }) => <span>{value}</span>,
            },
            {
                Header: 'Asset Number',
                accessor: 'targetId',
            },
            {
                Header: 'Asset status',
                accessor: 'statusId',
                Cell: ({ value }) => <span>{t(value, 'Asset')}</span>,
            },
            {
                Header: 'Department name',
                accessor: 'groupName',
                className: 'text-ellipsis',
                Cell: ({ value }) => <span>{value ? value : '-'}</span>,
            },
            {
                Header: 'Registered Date',
                accessor: 'regDate',
                Cell: ({ value }) => <span>{moment(value * 1000).format('YYYY-MM-DD HH:mm:ss')}</span>,
            },
        ],
        t,
    );
    const { promise: getAssetReceiptAndHandoverLog, state } = useAsync({
        promise: fetchAssetReceiptAndHandoverLogApi,
        fixedParam: { pageSize: 10 },
        resolve: res => setList(res),
        reject: err => console.error(err),
    });
    useEffect(() => {
        getAssetReceiptAndHandoverLog({
            page: 1,
            startDate: 0,
            endDate: moment().unix(),
            statusTypes: searchOptions.map(item => item.value).join(','),
        });
    }, []);

    const [selectedStatus, setSelectedStatus] = useState([]);
    const handleSelectStatus = selected => {
        setSelectedStatus(selected.map(status => status.value));
    };

    const handleSearchClick = () => {
        const statusTypes = selectedStatus.length
            ? selectedStatus.join(',')
            : searchOptions.map(item => item.value).join(',');
        getAssetReceiptAndHandoverLog({
            statusTypes,
            startDate: startDate ? moment(startDate).unix() : 0,
            endDate: endDate ? moment(endDate).unix() : moment().unix(),
        });
    };

    const handlePageChange = page => {
        const statusTypes = selectedStatus.length
            ? selectedStatus.join(',')
            : searchOptions.map(item => item.value).join(',');
        getAssetReceiptAndHandoverLog({
            statusTypes,
            startDate: startDate ? moment(startDate).unix() : 0,
            endDate: endDate ? moment(endDate).unix() : moment().unix(),
            page,
        });
    };
    return (
        <>
            <div className="grid-layout-container">
                <FilterSearchGroup>
                    <SearchGroup>
                        <FilterList>
                            <SelectGroup>
                                <SearchableSelect
                                    selected={selectedStatus}
                                    onChange={handleSelectStatus}
                                    data={searchOptions}
                                    title="Status"
                                />
                            </SelectGroup>
                            <SelectGroup>
                                <DatePicker
                                    value={startDate}
                                    valueType="ms"
                                    handleChange={selected => setStartDate(selected)}
                                    withoutTime
                                    showYearDropdown
                                    maxDate={moment().valueOf()}
                                    dateFormatCalendar="MMMM"
                                />
                            </SelectGroup>
                            <div className="text-center"> ~ </div>
                            <SelectGroup>
                                <DatePicker
                                    value={endDate}
                                    valueType="ms"
                                    handleChange={selected => setEndDate(selected)}
                                    withoutTime
                                    showYearDropdown
                                    minDate={startDate}
                                    maxDate={moment().valueOf()}
                                    dateFormatCalendar="MMMM"
                                />
                            </SelectGroup>
                            <Button
                                className="btn-brand btn-icon"
                                iconClassName="icon-search"
                                onClick={handleSearchClick}
                            >
                                {t('Search', 'Search')}
                            </Button>
                        </FilterList>
                    </SearchGroup>
                </FilterSearchGroup>

                <Card header={{ title: t('Asset receipt and handover log', 'Menu') }}>
                    <div className="table-container">
                        <Table
                            onPageChange={handlePageChange}
                            loading={state.isLoading}
                            columns={columns}
                            data={{ ...list, pageSize: 10 }}
                        />
                    </div>
                </Card>
            </div>
        </>
    );
};

export default AssetReceiptAndHandoverLog;
