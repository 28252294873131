import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import useTranslation from '../../../../../../util/hooks/useTranslation';
import EquipmentSearchModal from '../../Components/EquipmentSearchModal';
import useConfirmModal from '../../../../../Common/ConfirmModal/useConfirmModal';

import whiteImg from '../../../../../../assets/images/white.png';

import useAsync from '../../../../../../util/hooks/useAsync';
import { fetchEvaluationDepartmentCodeApi } from '../../../../../../api/engineering';

import { useRecoilState, useRecoilValue } from 'recoil';
import { equipmentEvaluationState, selectedAssetInfoState } from '../../../atoms';
import {
    SubCalculateTotalPriceSection,
    SubCustomTextArea,
    SubNumberInput,
    SubSearchAutocomplete,
    SubSingleSelect,
} from '../../Components/Parts';
import ScoreSheet from '../../../../../Common/ScoreSheet';
import Search from '../../Components/Search';
import no_image from '../../../../../../assets/images/image-not-found.svg';

const handleImgErr = e => {
    e.target.src = no_image;
};

const numberWithCommas = num => num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
const CustomTr = ({ title, colSpan = 0, children, tdClassName }) => {
    return (
        <tr className="tr">
            <td className="td">{title}</td>
            <td className={cx('td', tdClassName && tdClassName)} colSpan={colSpan}>
                {children}
            </td>
        </tr>
    );
};

const RESET = {
    utilityEcd: '',
    utilityEcd2: '',
    utilityEcd3: '',
    utilityEcd4: '',
    utilityEcd5: '',
    utilityPcd: '',
    utilityPcd2: '',
    utilityPcd3: '',
    utilityPcd4: '',
    utilityPcd5: '',
    utilityPoint: 0,
    oldEcd: '',
    oldEcd2: '',
    oldEcd3: '',
    oldEcd4: '',
    oldPcd: '',
    oldPcd2: '',
    oldPcd3: '',
    oldPcd4: '',
    oldPoint: 0,
};
const EquipmentInfo = () => {
    const t = useTranslation('Equipment evaluation');
    const selectedAssetInfo = useRecoilValue(selectedAssetInfoState);
    const [evaluationInfo, setEvaluationInfo] = useRecoilState(equipmentEvaluationState);
    const [tempEquipmentName, setTempEquipmentName] = useState('');
    const CLAIM_OPTION = [
        { value: 'NEW', label: t('New') },
        { value: 'REPLACEMENT', label: t('Obsolete replacement') },
        { value: 'QUANTITY', label: t('Out of Stock') },
        { value: 'DISCONTINUED', label: t('Discontinued Equipment') },
        { value: 'OTHER(FUNCTION)', label: t('Other (enhancements)') },
    ];

    const [departmentOption, setDepartmentOption] = useState([]);
    useAsync({
        promise: fetchEvaluationDepartmentCodeApi,
        immediate: true,
        resolve: res => {
            const { rows } = res;
            const tempArr = rows.length
                ? rows.map(({ groupId, groupName }) => ({ value: groupId, label: groupName }))
                : [];
            setDepartmentOption(tempArr);
        },
        reject: err => console.error(err),
    });

    const [img, setImg] = useState(whiteImg);
    const handleGettingSelectedEquipmentInfo = () => {
        const { modelName, assetNo } = selectedAssetInfo;
        if (modelName) {
            setEvaluationInfo({ ...evaluationInfo, equipmentName: modelName });
        }
        setImg(`http://device.ajoumc.or.kr/Main/Common/File/Device/${assetNo}.jpg`);
    };

    const handleValueChange = e => {
        const { value, name } = e.target;
        setEvaluationInfo({
            ...evaluationInfo,
            [name]: value,
        });
    };

    const [assetNo, setAssetNo] = useState('');
    useEffect(() => {
        const { assetNo: currentAssetNo } = evaluationInfo;
        if (currentAssetNo) {
            setImg(`http://device.ajoumc.or.kr/Main/Common/File/Device/${currentAssetNo}.jpg`);
        }
    }, [assetNo]);

    useEffect(() => {
        const { assetNo: currentAssetNo } = evaluationInfo;
        if (currentAssetNo !== assetNo) {
            setAssetNo(assetNo);
        }
    }, [evaluationInfo]);

    const createPriorityOption = () => {
        const tempPriorityOption = [];
        for (let i = 1; 10 >= i; i++) {
            const data =
                i === 1
                    ? [
                          { value: '1', label: '1' },
                          { value: '1-1', label: '1-1' },
                      ]
                    : [{ value: i.toString(), label: i }];
            tempPriorityOption.push(...data);
        }
        return tempPriorityOption;
    };
    const [priorityOption, setPriorityOption] = useState(createPriorityOption());

    const [openEquipmentSearchModal, setOpenEquipmentSearchModal] = useState(false);
    const toggleOpenEquipmentSearchModal = () => {
        setOpenEquipmentSearchModal(!openEquipmentSearchModal);
    };

    const ComponentsList = {
        'Equipment name': 'asdsad',
        'Possession quantity': {
            name: 'quantity',
            value: numberWithCommas(evaluationInfo.quantity),
            component: SubNumberInput,
        },
    };

    const SelectComponents = {
        'claim classification': {
            title: `2. ${t('Claim classification')}(*)`,
            component: SubSingleSelect,
            name: 'claim classification',
            value: evaluationInfo.claimCode,
            placeholder: t('Please select'),
            options: CLAIM_OPTION,
            onChange: selected => {
                setEvaluationInfo({
                    ...evaluationInfo,
                    ...RESET,
                    claimCode: selected,
                });
                if (['REPLACEMENT', 'DISCONTINUED'].includes(selected)) {
                    toggleNotifyObsolescenceModal();
                } else {
                    toggleNotifyUtilityModal();
                }
            },
        },
        'Department name': {
            component: SubSearchAutocomplete,
            name: 'Department name',
            title: `3. ${t('Department name')} (*)`,
            value: evaluationInfo.deptCode,
            options: departmentOption,
        },
        Priority: {
            component: SubSingleSelect,
            name: 'priority',
            placeholder: t('Please select'),
            title: `4. ${t('Priority')} (*)`,
            //기존 1*이 저장되어야하지만 잘못된 데이터 저장으로 인한 임시조치
            value: evaluationInfo.priority,

            options: priorityOption,
            onChange: selected => {
                setEvaluationInfo({ ...evaluationInfo, priority: selected });
            },
        },
    };

    const CalculateComponents = {
        'Budget Request': {
            component: SubCalculateTotalPriceSection,
            qtyKey: 'budgetRequestQuantity',
            quantityName: 'budgetRequestQuantity',
            unitPriceKey: 'budgetRequestUnitprice',
            unitPriceName: 'budgetRequestUnitprice',
            totalName: 'budgetRequestSum',
            title: `6. ${t('Budget Request')} (*)`,
        },
        'Adjusted budget': {
            component: SubCalculateTotalPriceSection,
            qtyKey: 'adjustmentQuantity',
            quantityName: 'adjustmentQuantity',
            unitPriceKey: 'adjustmentUnitprice',
            unitPriceName: 'adjustmentUnitprice',
            totalName: 'adjustmentSum',
            title: `7. ${t('Adjusted budget')} (*)`,
        },
        'Purchase target': {
            component: SubCalculateTotalPriceSection,
            qtyKey: 'purchaseQuantity',
            quantityName: 'purchaseQuantity',
            unitPriceKey: 'purchaseUnitprice',
            unitPriceName: 'purchaseUnitprice',
            totalName: 'purchaseSum',
            title: `${t('Purchase target')}`,
        },
    };

    const TextComponents = {
        Description: {
            component: SubCustomTextArea,
            name: 'remark',
            value: evaluationInfo.remark,
            title: 'Description',
        },
        'Adjustment status': {
            component: SubCustomTextArea,
            name: 'adjustment',
            value: evaluationInfo.adjustment,
            title: 'Adjustment status',
        },
        'Company estimate': {
            component: SubCustomTextArea,
            name: 'estimate',
            value: evaluationInfo.estimate,
            title: 'Company estimate',
        },
        'Possession status': {
            component: SubCustomTextArea,
            name: 'retention',
            value: evaluationInfo.retention,
            title: 'Possession status',
        },
        Opinion: { component: SubCustomTextArea, name: 'opinion', value: evaluationInfo.opinion, title: 'Opinion' },
    };

    const { Modal: NotifyObsolescenceModal, toggleModal: toggleNotifyObsolescenceModal } = useConfirmModal({
        initModal: false,
        confirmText: t('Notify obsolescence'),
        removeCancel: true,
    });

    const { Modal: NotifyUtilityModal, toggleModal: toggleNotifyUtilityModal } = useConfirmModal({
        initModal: false,
        confirmText: t('Notify utility'),
        removeCancel: true,
    });

    return (
        <>
            <ScoreSheet tableClassName="w-100" tbodyClassName="evaluation-table">
                <CustomTr title={`1. ${t('Equipment name')}(*)`} colSpan={2} tdClassName="p-2" t={t}>
                    <div className="flx-row">
                        <div className="flx-col w-90">
                            <Search
                                name="equipment name"
                                buttonName="Add Search"
                                readOnly={true}
                                value={evaluationInfo.equipmentName}
                                onClick={toggleOpenEquipmentSearchModal}
                                inputClassName="mr-2"
                            />
                            <Search
                                name="equipment name"
                                value={tempEquipmentName}
                                readOnly={false}
                                onClick={() => {
                                    setEvaluationInfo({ ...evaluationInfo, equipmentName: tempEquipmentName });
                                    setImg('');
                                    setTempEquipmentName('');
                                }}
                                onChange={e => {
                                    const { value } = e.target;
                                    setTempEquipmentName(value);
                                }}
                                buttonName="Add direct input"
                                inputClassName="w-50 mr-2"
                            />
                        </div>
                        <div>
                            <img src={img} width={150} height={150} onError={handleImgErr} alt="equipment img" />
                        </div>
                    </div>
                </CustomTr>
                {Object.keys(SelectComponents).map(key => {
                    const { name, value, options, placeholder, onChange, title, component } = SelectComponents[key];
                    return (
                        <CustomTr key={key} title={title} colSpan={2}>
                            {component({ name, value, placeholder, options, onChange })}
                        </CustomTr>
                    );
                })}
                <CustomTr title={`5. ${t('Possession quantity')}(*)`} colSpan={2}>
                    {ComponentsList['Possession quantity'].component({
                        name: 'quantity',
                        value: evaluationInfo.quantity.toLocaleString(),
                    })}
                </CustomTr>
                {Object.keys(CalculateComponents).map(key => {
                    const { title, totalName, unitPriceName, quantityName, unitPriceKey, qtyKey, component } =
                        CalculateComponents[key];
                    return (
                        <CustomTr key={key} title={title} colSpan={2}>
                            {component({ totalName, unitPriceName, quantityName, unitPriceKey, qtyKey })}
                        </CustomTr>
                    );
                })}
                {Object.keys(TextComponents).map(key => {
                    const { name, value, component, title } = TextComponents[key];
                    return (
                        <CustomTr
                            key={key}
                            tdClassName="p-2 evaluation-table-td-textArea-height"
                            title={`${t(title)}`}
                            colSpan={2}
                        >
                            {component({ name, value })}
                        </CustomTr>
                    );
                })}
                <CustomTr title={`${t('Requirements for introduction')}`} colSpan={2}>
                    <div className="w-100 d-flex justify-content-center">
                        <ul className="pnt-radio w-100 d-flex justify-content-center">
                            {['EMR', 'PACS', 'FACILITY', 'NONE'].map(item => {
                                const id = item.toUpperCase();
                                return (
                                    <li
                                        key={id}
                                        className={cx('mr-2', evaluationInfo.introductionReqCode === item && 'checked')}
                                    >
                                        <input
                                            type="radio"
                                            id={id}
                                            value={item}
                                            name="introductionReqCode"
                                            checked={evaluationInfo.introductionReqCode === item}
                                            onChange={handleValueChange}
                                        />
                                        <label htmlFor={id}>{t(item)}</label>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </CustomTr>
            </ScoreSheet>
            <EquipmentSearchModal
                departmentOption={[{ value: '', label: t('Department') }, ...departmentOption]}
                toggleModal={toggleOpenEquipmentSearchModal}
                initModal={openEquipmentSearchModal}
                getEquipmentInfo={handleGettingSelectedEquipmentInfo}
                size="xl"
            />
            <NotifyObsolescenceModal />
            <NotifyUtilityModal />
        </>
    );
};
export default EquipmentInfo;
