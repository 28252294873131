import React, { useMemo, useCallback, useEffect, useRef, useState } from 'react';
import { useTable, useBlockLayout, usePagination } from 'react-table';
import { FixedSizeList, VariableSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
// import scrollbarWidth from './util/scrollbarWidth';
import styled from 'styled-components';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { LoadingBlock } from '../LoadingBlock';
import TextInput from '../Input/TextInput';

const TableWrapper = styled.div`
    height: 100%;
    .pagination {
        display: flex;
        padding-left: 0;
        list-style: none;
    }
    .pnt-table {
        display: inline-block;
        border-spacing: 0;
        .border-box {
            height: 100%;
            border-top: 2px solid black;
            border-bottom: 2px solid black;

            .tbody {
                .tr {
                    :nth-child(2n) {
                        background-color: rgba(0, 0, 0, 0.02);
                    }
                    :hover {
                        background-color: #ebf0fa;
                    }
                }
                .tr.clickable {
                    :hover {
                        cursor: pointer;
                    }
                }
            }
            .tr {
                :last-child {
                    .td {
                        border-bottom: 0;
                    }
                }
            }
            .th {
                font-weight: 700;
                background-color: #d8e0fe;
                text-align: ${props => props.textAlign};
            }
            .td {
                text-align: ${props => props.textAlign};
            }
            .th,
            .td {
                margin: 0;
                padding: 0.5rem;
                border-bottom: 1px solid #ebebeb;
            }
        }
    }
`;

const NoDataWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const NoDataComponent = styled.span`
    padding: ${({ singleRow }) => (singleRow ? '2px' : '2rem')};
    background-color: rgba(0, 0, 0, 0.1);
`;

const RowSpanHeader = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
`;

const DEFAULT_TH_ROW_HEIGHT = 36;
const DEFAULT_ROW_HEIGHT = 38;

const defaultRowPropGetter = ({ style }) => {
    return { style: { ...style, height: DEFAULT_ROW_HEIGHT } };
};

// Render 메소드에서 상태 set 분리 (setItemSize)
const Row = ({ row, index, restRowProps, rowClassName, onTrClick, setItemSize, sizeMap }) => {
    const rowRef = useRef();

    useEffect(() => {
        if (rowRef.current && isNaN(sizeMap.current[index]) && sizeMap.current[index] !== 0) {
            setItemSize(index, rowRef.current.getBoundingClientRect().height);
        }
    }, [index, setItemSize, sizeMap]);

    return (
        <div
            {...row.getRowProps(restRowProps)}
            className={cx('tr', typeof onTrClick === 'function' && 'clickable', rowClassName)}
            onClick={e => {
                if (typeof onTrClick === 'function') {
                    onTrClick(row.original, e);
                }
            }}
            ref={rowRef}
        >
            {row.cells.map(cell => {
                const { style: cellStyle, ...cellProps } = cell.getCellProps({
                    style: cell.column.style,
                });
                const className = cell.column.className;
                return (
                    <div
                        {...cellProps}
                        className={['td', className].join(' ')}
                        style={{
                            ...cellStyle,
                            flex: `${cellStyle.width.replace('px', '').replace('%', '')} 0 auto`,
                        }}
                        title={className && className.includes('text-ellipsis') ? cell.value : ''}
                    >
                        {cell.render('Cell')}
                    </div>
                );
            })}
        </div>
    );
};

function Table({
    columns,
    data: { rows: list, totalCount = 0, totalPage = 0, pageSize = 20, page: pageNum = 1 },
    onPageChange,
    paging = true,
    textAlign = 'center',
    onTrClick,
    manualPagination = true,
    autoResetPage = true,
    getRowProps = defaultRowPropGetter,
    loading,
    dynamicRowHeight,
    singleRow,
}) {
    const listRef = useRef();
    const tbodyRef = useRef();
    const sizeMap = useRef({});
    const defaultColumn = useMemo(
        () => ({
            width: 150,
        }),
        [],
    );
    const { t } = useTranslation();
    // 테이블내의 페이지 변화인 상황인지 아닌지를 판별하기 위한 값
    const pageChanged = useRef(false);

    // const scrollBarSize = useMemo(() => scrollbarWidth(), []);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        // rows,

        page,
        // canPreviousPage,
        // canNextPage,
        // pageOptions,
        // pageCount,
        gotoPage,
        nextPage,
        previousPage,
        // setPageSize,
        state: {
            pageIndex,
            // , pageSize
        },
        totalColumnsWidth,
        prepareRow,
    } = useTable(
        {
            columns,
            data: list,
            initialState: { pageIndex: pageNum, pageSize },
            defaultColumn,
            manualPagination: manualPagination,
            pageCount: totalPage,
            autoResetPage: autoResetPage,
            // state를 직접 컨토를하기 위해 사용
            // API를 호출하여 리스트의 pageNum을 새롭게 받아왓을 때마다 테이블 내의 pageIndex를 업데이트
            useControlledState: state => {
                useMemo(() => {
                    state.pageIndex = pageNum - 1;
                }, [pageNum]);
                return state;
            },
        },
        // useGroupBy,
        // useExpanded,
        usePagination,
        useBlockLayout,
    );

    const setItemSize = useCallback((index, size) => {
        sizeMap.current = { ...sizeMap.current, [index]: size };
        if (listRef.current) {
            listRef.current.resetAfterIndex(index);
        }
    }, []);

    const RenderRow = useCallback(
        ({ index, style }) => {
            const row = page[index];
            prepareRow(row);

            const { className: rowClassName, ...restRowProps } = getRowProps({
                row,
                style,
            });

            if (dynamicRowHeight) {
                restRowProps.style.height = 'fit-content';
            }

            return (
                <Row
                    row={row}
                    index={index}
                    onTrClick={onTrClick}
                    restRowProps={restRowProps}
                    rowClassName={rowClassName}
                    setItemSize={setItemSize}
                    sizeMap={sizeMap}
                />
            );
        },
        [prepareRow, page, setItemSize, sizeMap.current],
    );

    const getItemSize = useCallback(index => {
        let rowHeight = DEFAULT_ROW_HEIGHT;
        if (dynamicRowHeight && !isNaN(sizeMap.current[index])) {
            rowHeight = sizeMap.current[index];
        }
        return rowHeight;
    }, []);

    useEffect(() => {
        // pageIndex값이 변하더라도 pageIndex가 변한 이유가 페이지 체인지 때문인지를 확인
        // 테이블의 리셋 버튼을 클릭했을 때는 onPageChange가 실행되는 것을 방지
        if (typeof onPageChange === 'function' && pageChanged.current) {
            pageChanged.current = false;
            onPageChange(pageIndex + 1);
        }
    }, [pageIndex]);

    const handlePageChange = (callback, callbackParam) => {
        // 페이지 체인지인 경우에는 current값을 true로 설정
        pageChanged.current = true;
        callback(callbackParam);
    };

    return (
        <LoadingBlock blocking={loading}>
            <TableWrapper className={'flx-col'} textAlign={textAlign}>
                {paging && (
                    <div className="pagination">
                        <div className="flx-row gap-1">
                            <button
                                className="pnt-btn btn-icon-only btn-tiny btn-lightgray"
                                onClick={() => handlePageChange(gotoPage, 0)}
                            >
                                <span className={'icon-back-double opacity-5'}>first</span>
                            </button>
                            <button
                                className="pnt-btn btn-icon-only btn-tiny btn-brand"
                                onClick={() => handlePageChange(previousPage)}
                            >
                                <span className={'icon-back'}>before-page</span>
                            </button>
                            <div className="ml-2 mr-2">
                                <span className="now">{`${pageIndex * pageSize + (page.length ? 1 : 0)}-${
                                    totalCount < pageIndex * pageSize + page.length
                                        ? totalCount
                                        : pageIndex * pageSize + page.length
                                }`}</span>
                                <span className="whole">{` / ${totalCount}`}</span>
                            </div>
                            <button
                                className="pnt-btn btn-icon-only btn-tiny btn-brand"
                                onClick={() => handlePageChange(nextPage)}
                            >
                                <span className={'icon-next'}>next-page</span>
                            </button>
                            <button
                                className="pnt-btn btn-icon-only btn-tiny btn-lightgray"
                                onClick={() => handlePageChange(gotoPage, totalPage - 1)}
                            >
                                <span className={'icon-next-double opacity-5'}>last</span>
                            </button>
                        </div>
                    </div>
                )}
                <div
                    {...getTableProps()}
                    className="pnt-table table-clickable"
                    style={{
                        height: paging ? 'calc(100% - 30px)' : '100%',
                        width: '100%',
                        color: '#585252',
                        fontSize: '.8rem',
                        // width: totalColumnsWidth + scrollBarSize + 2,
                    }}
                >
                    <div className={'border-box'} style={{ overflowX: 'auto', overflowY: 'hidden' }}>
                        <div className={'thead'} style={{ overflow: 'visible' }}>
                            {headerGroups.map((headerGroup, headerRowIdx) => {
                                const { style: thGroupStyle, ...thGroupProps } = headerGroup.getHeaderGroupProps();
                                return (
                                    <div {...thGroupProps} className="tr" style={{ ...thGroupStyle, width: '100%' }}>
                                        {headerGroup.headers.map(column => {
                                            const {
                                                depth,
                                                getHeaderProps,
                                                headerStyle,
                                                headerClassName,
                                                render,
                                                parent,
                                            } = column;
                                            const realisticallyUsingHeader = typeof depth === 'number';
                                            const rowSpanSize = !!parent ? 1 : headerRowIdx + 1;
                                            const noNeedMerge = !realisticallyUsingHeader || rowSpanSize === 1;
                                            const { style: thStyle, ...thProps } = getHeaderProps({
                                                style: headerStyle,
                                            });
                                            if (!noNeedMerge) {
                                                thStyle.position = 'relative';
                                                thStyle.padding = 0;
                                                thStyle.overflow = 'visible';
                                                thStyle.borderBottom = 0;
                                            }
                                            return (
                                                <div
                                                    {...thProps}
                                                    className={cx('th', noNeedMerge && headerClassName)}
                                                    style={{
                                                        ...thStyle,
                                                        flex: `${thStyle.width
                                                            .replace('px', '')
                                                            .replace('%', '')} 0 auto`,
                                                    }}
                                                >
                                                    {noNeedMerge ? (
                                                        render('Header')
                                                    ) : (
                                                        <RowSpanHeader
                                                            className={cx('th', headerClassName)}
                                                            style={{
                                                                height: `${rowSpanSize * DEFAULT_TH_ROW_HEIGHT}px`,
                                                            }}
                                                        >
                                                            {render('Header')}
                                                        </RowSpanHeader>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </div>
                        <div
                            {...getTableBodyProps()}
                            className={cx('tbody', singleRow && 'min-h38')}
                            style={{ height: `calc(100% - ${headerGroups.length * DEFAULT_TH_ROW_HEIGHT}px)` }}
                            ref={tbodyRef}
                        >
                            {page.length > 0 ? (
                                <AutoSizer>
                                    {({ height, width }) =>
                                        dynamicRowHeight ? (
                                            <VariableSizeList
                                                ref={listRef}
                                                style={{ overflow: 'overlay' }}
                                                width={width > totalColumnsWidth ? width : totalColumnsWidth}
                                                height={height}
                                                itemCount={page.length}
                                                itemSize={getItemSize}
                                            >
                                                {RenderRow}
                                            </VariableSizeList>
                                        ) : (
                                            <FixedSizeList
                                                style={{ overflow: 'overlay' }}
                                                width={width > totalColumnsWidth ? width : totalColumnsWidth}
                                                height={height}
                                                // width={totalColumnsWidth + scrollBarSize}
                                                // height={800}
                                                itemCount={page.length}
                                                itemSize={DEFAULT_ROW_HEIGHT}
                                            >
                                                {RenderRow}
                                            </FixedSizeList>
                                        )
                                    }
                                </AutoSizer>
                            ) : (
                                <NoDataWrapper>
                                    <NoDataComponent singleRow={singleRow}>{t('Table;No data')}</NoDataComponent>
                                </NoDataWrapper>
                            )}
                        </div>
                    </div>
                </div>
            </TableWrapper>
        </LoadingBlock>
    );
}

export default Table;
