import React, { useMemo, useEffect, useRef } from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import useTranslation from '../../../../util/hooks/useTranslation';
import moment from 'moment';

const setScrollTop = (listEl, value) => {
    const selectedEl = listEl.querySelector(`button[value='${Number(value)}']`);
    if (selectedEl) {
        listEl.scrollTop = selectedEl.getBoundingClientRect().top - listEl.getBoundingClientRect().top;
    }
};

const TimeInput = ({ date, value, onChange, maxTime }) => {
    const t = useTranslation('DatePicker');
    const hourRef = useRef();
    const minRef = useRef();
    const timeOptions = useMemo(() => {
        const h = [];
        const m = [];
        for (let i = 0; i < 60; i++) {
            if (i < 24) {
                h.push(i);
            }
            m.push(i);
        }
        return { h, m };
    }, []);

    const { sH, sM } = useMemo(() => {
        const valueArr = value.split(':');
        return {
            sH: parseInt(valueArr[0]),
            sM: parseInt(valueArr[1]),
        };
    }, [value]);

    useEffect(() => {
        if (value) {
            const [h, m] = value.split(':');
            const hourList = hourRef.current;
            const minList = minRef.current;
            if (hourList) {
                setScrollTop(hourList, h);
            }
            if (minList) {
                setScrollTop(minList, m);
            }
        }
    }, []);

    const { lH, lM } = useMemo(() => {
        const currentDate = moment(date).format('YYYYMMDD');
        const selectedMaxDate = moment(maxTime).format('YYYYMMDD');
        if (currentDate === selectedMaxDate && date) {
            return { lH: parseInt(moment(maxTime).format('HH')), lM: parseInt(moment(maxTime).format('mm')) };
        } else {
            return { lH: null, lM: null };
        }
    }, [date, maxTime]);

    return (
        <>
            <div className={'react-datepicker__header react-datepicker__header--time'}>
                <div className={'react-datepicker-time__header'}>{t('Time')}</div>
            </div>
            <div className={'time-label-box'}>
                <div>{t('hour')}</div>
                <div>{t('min')}</div>
            </div>
            <div style={{ display: 'flex' }}>
                <ListGroup vertical={'true'}>
                    <div className={'list-wrap'} ref={hourRef}>
                        {timeOptions.h.map(v => (
                            <ListGroupItem
                                key={v}
                                tag={'button'}
                                value={v}
                                action
                                disabled={lH && v > lH}
                                onClick={e => {
                                    const currValue = e.currentTarget.value;
                                    const valueArr = value.split(':');
                                    if (parseInt(currValue) === lH && lM < sM) {
                                        onChange(`${currValue}:${lM}`);
                                    } else {
                                        valueArr.splice(0, 1, currValue);
                                        onChange(valueArr.join(':'));
                                    }
                                }}
                                active={Number(value.split(':')[0]) === v}
                            >
                                {v}
                            </ListGroupItem>
                        ))}
                    </div>
                </ListGroup>
                <ListGroup vertical={'true'}>
                    <div className={'list-wrap'} ref={minRef}>
                        {timeOptions.m.map(v => (
                            <ListGroupItem
                                key={v}
                                tag={'button'}
                                value={v}
                                action
                                disabled={sH === lH && lM < v}
                                onClick={e => {
                                    const currValue = e.currentTarget.value;
                                    const valueArr = value.split(':');
                                    valueArr.splice(1, 1, currValue);
                                    if (lH && lH < sH) {
                                        onChange(`${lH}:${lM}`);
                                    } else {
                                        onChange(valueArr.join(':'));
                                    }
                                }}
                                active={Number(value.split(':')[1]) === v}
                            >
                                {v}
                            </ListGroupItem>
                        ))}
                    </div>
                </ListGroup>
            </div>
        </>
        // <div style={{ height: '100%' }} onClick={handleChange}>
        //     {value}
        // </div>
    );
};

export default TimeInput;
