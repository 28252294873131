import React, { useEffect, useState } from 'react';
import Card from '../../../../Common/Card';
import Button from '../../../../Common/Button';
import useTranslation from '../../../../../util/hooks/useTranslation';
import Table from '../../../../Common/Table';
import useAsync from '../../../../../util/hooks/useAsync';
import { getStatusCategoryInfoApi } from '../../../../../api/status';
import useColumns from '../../../../../util/hooks/useColumns';
import { StyledStatusLabel } from '../../../Components/Grid/column';
import StatusCategoryDetail from './StatusCategoryDetail';
import { useDispatch, useSelector } from 'react-redux';
import { setStatusCategoryListInfo } from '../../../../../reducers/Common/StatusInfo';

const StatusCategory = () => {
    const dispatch = useDispatch();
    const t = useTranslation('Status');
    const { statusCategoryListInfo } = useSelector(state => state.StatusInfo);
    const [detailModal, setDetailModal] = useState({ isOpen: false, statusInfo: null });

    const toggleDetailModal = statusInfo => {
        setDetailModal({
            isOpen: !detailModal.isOpen,
            statusInfo,
        });
    };

    const statusColumns = useColumns(
        [
            {
                Header: 'Name',
                accessor: 'statusCategoryName',
                className: 'flex-center',
                width: 100,
                Cell: ({ value, row: { original } }) => {
                    return (
                        <div
                            style={{
                                display: 'grid',
                                rowGap: '5px',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <span style={{ fontWeight: 'bold' }}>{value}</span>
                            <span style={{ fontSize: '.7rem' }}>{original.description}</span>
                        </div>
                    );
                },
            },
            {
                Header: 'Status',
                accessor: 'mappedStatus',
                className: 'flex-center flex-wrap',
                style: { gap: '5px 10px' },
                Cell: ({ value }) => {
                    if (value) {
                        return value.map(v => (
                            <StyledStatusLabel
                                key={v.statusNum}
                                statusName={t(v.statusName)}
                                displayColor={v.displayColor}
                            />
                        ));
                    }
                    return null;
                },
            },
            {
                Header: 'Edit',
                accessor: 'statusCategoryNum',
                className: 'flex-center',
                width: 40,
                Cell: ({ row: { original } }) => {
                    return (
                        <Button
                            className={'btn-icon-only btn-brand scale-08'}
                            iconClassName={'icon-edit'}
                            onClick={e => {
                                toggleDetailModal(original);
                            }}
                        />
                    );
                },
            },
        ],
        t,
    );
    const { promise: getStatusCategoryInfo } = useAsync({
        promise: getStatusCategoryInfoApi,
        resolve: res => {
            dispatch(setStatusCategoryListInfo(res));
        },
    });

    useEffect(() => {
        getStatusCategoryInfo({ isAll: 'Y' });
    }, []);

    return (
        <>
            <Card
                header={{
                    title: `${t('List')} : ${t('Status Category')}`,
                    subTitle: t('This is the asset status category list inquiry function.'),
                    action: (
                        <Button
                            className={'btn-brand flex-center font-1rem'}
                            iconClassName={'icon-plus mr-2'}
                            onClick={() => {
                                toggleDetailModal(null);
                            }}
                        >
                            {t('Add Status category')}
                        </Button>
                    ),
                }}
            >
                <div
                    style={{
                        minHeight: '300px',
                        maxHeight: 'calc(100vh - 340px)',
                        height: 'calc(100vh - 340px)',
                        display: 'grid',
                        gridTemplateRows: 'min',
                    }}
                >
                    <Table
                        data={{ ...statusCategoryListInfo, pageSize: statusCategoryListInfo.totalCount }}
                        columns={statusColumns}
                        paging={false}
                        dynamicRowHeight
                    />
                </div>
            </Card>
            {/*    initModal = false,*/}
            {/*    toggleModal = function () {*/}
            {/*    initModal = !initModal;*/}
            {/*},*/}
            {/*    okCallback,*/}
            {/*    cancelCallback,*/}
            {/*    callbackParam,*/}
            {/*    header = { title: 'Confirm', subTitle: '', className: '' },*/}
            {/*    confirmText = 'Are you sure?',*/}
            {/*    removeCancel,*/}
            {/*    okClassName*/}
            <StatusCategoryDetail
                isOpen={detailModal.isOpen}
                statusInfo={detailModal.statusInfo}
                toggle={toggleDetailModal}
            />
            {/*<ConfirmModal initModal={detailModal} toggleModal={toggleDetailModal} confirmText={<>test</>} />*/}
        </>
    );
};

export default StatusCategory;
