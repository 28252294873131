import React, { useEffect } from 'react';
import useTranslation from '../../../../../../util/hooks/useTranslation';
import FilterSearchGroup from '../../../../../Common/FilterSearchGroup';
import {
    InputGroup,
    InputWrap,
    SearchWrap,
    SelectGroup,
} from '../../../../../Common/FilterSearchGroup/Components/Part';
import DatePicker from '../../../../../Common/DatePicker';
import Button from '../../../../../Common/Button';
import TextInput from '../../../../../Common/Input/TextInput';
import SearchButtonArea from '../../../../../Common/FilterSearchGroup/Components/SearchButtonArea';
import SearchGroup from '../../../../../Common/FilterSearchGroup/Components/SearchGroup';
import moment from 'moment';
import { useRecoilState, useResetRecoilState } from 'recoil';
import UserDeptSelect from '../../Components/UserDeptSelect';
import { scheduleSearchState } from '../../../../MedicalEngineering/atoms';

const Search = () => {
    const t = useTranslation('Search');

    const [searchOption, setSearchOption] = useRecoilState(scheduleSearchState);
    const resetSearch = useResetRecoilState(scheduleSearchState);
    const { searchDate, targetName } = searchOption;

    const handleDate = selected => {
        setSearchOption(prevState => ({
            ...prevState,
            searchDate: selected,
        }));
    };

    const handleTargetName = ({ target: { value } }) => {
        setSearchOption(prevState => ({
            ...prevState,
            targetName: value,
        }));
    };

    const handleSearchClick = () => {
        setSearchOption(prevState => ({
            ...prevState,
            clickSearch: prevState.clickSearch + 1,
        }));
    };

    // 언마운트 될때 초기화
    useEffect(() => {
        setSearchOption(prevState => ({
            ...prevState,
            clickSearch: prevState.clickSearch + 1,
            searchDate: moment().valueOf(),
        }));
        return () => {
            resetSearch();
        };
    }, []);

    // const { toggleModal: toggleRefreshModal, Modal: RefreshModal } = useConfirmModal({
    //     confirmText: t('Do you want to refresh?', 'Filter'),
    //     okCallback: async () => {
    //         await resetSearch();
    //         await setSearchOption(prevState => ({
    //             ...prevState,
    //             searchDate: moment().valueOf(),
    //         }));
    //         handleSearchClick();
    //     },
    // });

    return (
        <>
            <FilterSearchGroup>
                <SearchGroup className="bg-brand">
                    <SelectGroup>
                        <DatePicker
                            value={searchDate}
                            valueType="ms"
                            onChangeRaw={e => e.preventDefault()}
                            handleChange={handleDate}
                            withoutTime
                            showYearDropdown
                            dateFormatCalendar="MMMM"
                        />
                    </SelectGroup>
                    <SearchWrap className="w-30">
                        <SelectGroup>
                            <UserDeptSelect />
                        </SelectGroup>
                        <InputWrap>
                            <InputGroup>
                                <TextInput
                                    name="searchOption"
                                    handleChange={handleTargetName}
                                    placeholder={t('Name', 'RecordModifyModal')}
                                    value={targetName}
                                />
                            </InputGroup>
                        </InputWrap>
                    </SearchWrap>
                    <SearchButtonArea>
                        <Button className="btn-brand btn-icon" iconClassName="icon-search" onClick={handleSearchClick}>
                            {t('Search')}
                        </Button>
                        {/*<Button*/}
                        {/*    className="btn-danger btn-icon-only"*/}
                        {/*    iconClassName="icon-replay"*/}
                        {/*    onClick={toggleRefreshModal}*/}
                        {/*/>*/}
                    </SearchButtonArea>
                </SearchGroup>
            </FilterSearchGroup>
            {/*<RefreshModal />*/}
        </>
    );
};

export default Search;
