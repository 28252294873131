export const NUM_OF_TIMES_EQUIPMENT_IS_NOT_SELECTED_IN_FIVE_YEARS = [
    { title: '4 or more times (30)', score: 30, value: 10 },
    { title: '3 times (28)', score: 28, value: 20 },
    { title: '2 times (25)', score: 25, value: 30 },
    { title: '1 time (20)', score: 20, value: 40 },
    { title: '0 times.apply every time(15)', score: 15, value: 50 },
];

export const RELIABILITY = [
    { title: '80~100%(10)', score: 10, value: 10 },
    { title: '60~80%(8)', score: 8, value: 20 },
    { title: '30~60%(6)', score: 6, value: 30 },
    { title: '30~0%(4)', score: 4, value: 40 },
    { title: 'Minus (2)', score: 2, value: 50 },
];

export const DEPARTMENTAL_GAIN_OR_LOSS = [
    { title: '10% or more (10)', score: 10, value: 10 },
    { title: '5~10%(8)', score: 8, value: 20 },
    { title: '0~5%(6)', score: 6, value: 30 },
    { title: '0~-5%(4)', score: 4, value: 40 },
    { title: '-5% or less (2)', score: 2, value: 50 },
];

export const PRIORITY = [
    { id: 'firstPlace', title: '1st (20)', score: 20, value: 10 },
    { id: 'secondPlace', title: '2nd (15)', score: 15, value: 20 },
    { id: 'thirdPlace', title: '3rd (8)', score: 8, value: 30 },
    { id: 'fourthPlace', title: '4th (5)', score: 5, value: 40 },
    { id: 'fifthPlaceOrLow', title: 'Rank 5 or lower (2)', score: 2, value: 50 },
];

export const ADDITIONAL_MATTERS_REQUIRED = [
    { id: 'notNeed', title: 'Not required (30)', score: 30, value: 10 },
    { id: 'remodeling', title: 'Remodel Required (25)', score: 25, value: 20 },
    { id: 'separateSpace', title: 'Requires a separate room (15)', score: 15, value: 30 },
    { id: 'manpower', title: 'Staffing needs (10)', score: 10, value: 40 },
    { id: 'all', title: 'All required (5)', score: 5, value: 50 },
];

export const ABS_EVALUATION = [
    { title: '10% or more (100)', score: 100, value: 10 },
    { title: '5~10%(90)', score: 90, value: 20 },
    { title: '0~5%(80)', score: 80, value: 30 },
    { title: '0~-5%(50)', score: 50, value: 40 },
    { title: '-5% or less (30)', score: 30, value: 50 },
];

export const TRUST = [
    { title: '80~100%(100)', score: 100, value: 10 },
    { title: '60~80%(80)', score: 80, value: 20 },
    { title: '30~60%(60)', score: 60, value: 30 },
    { title: '30~0%(30)', score: 30, value: 40 },
    { title: 'Minus (0)', score: 0, value: 50 },
];

export const PRIORITY_UNDER_FIVE = [
    { title: '1st (90)', score: 90, value: 10 },
    { title: '1*Rank (90)', score: 90, value: 20 },
    { title: '2nd (85)', score: 85, value: 30 },
    { title: '3rd (75)', score: 75, value: 40 },
    { title: '4th (65)', score: 65, value: 50 },
];

export const PRIORITY_OVER_FIVE = [
    { title: '5th (50)', score: 50, value: 10 },
    { title: '6th (40)', score: 40, value: 20 },
    { title: '7th (30)', score: 30, value: 30 },
    { title: '8th (20)', score: 20, value: 40 },
    { title: '9th or lower (10)', score: 10, value: 50 },
];

export const CURRENT_PRODUCTION_STATUS_ARR = [
    { title: 'Parts/Consumables Discontinued (50)', score: 50, value: 10 },
    { title: 'Partial part discontinued(48)', score: 48, value: 20 },
    { title: 'Model Discontinuation/Parts Supply(40)', score: 40, value: 30 },
    { title: '7+ Years/Outdated (30)', score: 30, value: 40 },
    { title: '5+ Years/Outdated (25)', score: 25, value: 50 },
];

export const YEAR_OF_INTRODUCTION_ARR = [
    { title: '15+ years (30)', score: 30, value: 10 },
    { title: 'Less than 10-15 years (28)', score: 28, value: 20 },
    { title: 'Less than 8-10 years (25)', score: 25, value: 30 },
    { title: 'Less than 5-8 years (20)', score: 20, value: 40 },
    { title: '5 years or less (10)', score: 10, value: 50 },
];

export const SERVICE_SUPPORT_CAPABILITY = [
    { title: 'No repair capability', value: '10' },
    { title: 'Parts Cost Inventory', value: '20' },
    { title: 'No backup equipment', value: '30' },
    { title: 'Poor vendor collaboration in medical engineering', value: '40' },
];

export const ACCUMULATED_REPAIR_COST = [
    { title: 'Below/above 100% (10)', score: 10, value: 10 },
    { title: 'Less than 80-100% (8)', score: 8, value: 20 },
    { title: 'Less than 60 to 80 (6)', score: 6, value: 30 },
    { title: 'Less than 40-60% (4)', score: 4, value: 40 },
    { title: '40% or less (2)', score: 2, value: 50 },
];

export const UNEVALUATED_PROFITABILITY_ARR = [
    { title: 'extremeHigh', code: 10, score: 120 },
    { title: 'high', code: 20, score: 100 },
    { title: 'normal', code: 30, score: 80 },
    { title: 'low', code: 40, score: 60 },
    { title: 'extremeLow', code: 50, score: 40 },
];

export const NUM_OF_MONTHS = [
    { title: '200+ (90)', score: 90, value: 10 },
    { title: '100 to 200 cases (80)', score: 80, value: 20 },
    { title: '50 to 100 cases (70)', score: 70, value: 30 },
    { title: '10 to 50 cases (60)', score: 60, value: 40 },
    { title: '10 or fewer (40)', score: 40, value: 50 },
];

export const USE_EVERY_ONCE_A_DAY = [
    { title: '6 hours or more (90)', score: 90, value: 10 },
    { title: '4 hours or more (80)', score: 80, value: 20 },
    { title: '2 hours or more (70)', score: 70, value: 30 },
    { title: '1 hour or more (60)', score: 60, value: 40 },
    { title: 'Less than 1 hour (40)', score: 40, value: 50 },
];

export const NOT_USE_EVERYDAY = [
    { title: '3 hours or more (75)', score: 75, value: 10 },
    { title: '2 hours or more (70)', score: 70, value: 20 },
    { title: '1 hours or more (65)', score: 65, value: 30 },
    { title: '0.5 hours or more (55)', score: 55, value: 40 },
    { title: '0.5 hours or less (40)', score: 40, value: 50 },
];

export const OPERATION_HOUR_PER_CASE = [
    { title: '3 hours or more (70)', score: 70, value: 10 },
    { title: '2 hours or more (65)', score: 65, value: 20 },
    { title: '1 hours or more (55)', score: 55, value: 30 },
    { title: '0.5 hours or more (45)', score: 45, value: 40 },
    { title: '0.5 hours or less (35)', score: 35, value: 50 },
];
