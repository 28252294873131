import React from 'react';
import ReactSelect from '../../../../Common/Select';
import TextInput from '../../../../Common/Input/TextInput';
import NumberInput from './NumberInput';
import { useRecoilState } from 'recoil';
import { equipmentEvaluationState } from '../../atoms';
import CalculateTotalPriceSection from './CalculateTotalPriceSection';
import CustomTextArea from './CustomTextArea';
import SearchAutocomplete from './SearchAutocomplete';
import SingleSelect from '../../../../Common/Select/SingleSelect';

// export const SubEquipmentSearch = ({ name, value, options, onChange }) => {
//     const t = useTranslation('Equipment evaluation');
//     const onClick = () => {
//         setEvaluationInfo({
//             ...evaluationInfo,
//             equipmentName: tempEquipmentName,
//         });
//         setTempEquipmentName('');
//     };
//     return (
//         <>
//             <div className="d-flex mb-3">
//                 <TextInput
//                     name="equipment name"
//                     placeholder={t('Enter equipment name')}
//                     inputGroupClassName="mr-2"
//                     value={tempEquipmentName}
//                     handleChange={e => {
//                         const { value } = e.target;
//                         setTempEquipmentName(value);
//                     }}
//                 />
//                 <Button
//                     className="btn-gray mr-2"
//                     onClick={() => {
//                         setEvaluationInfo({
//                             ...evaluationInfo,
//                             equipmentName: tempEquipmentName,
//                         });
//                         setTempEquipmentName('');
//                     }}
//                 >
//                     {t('Add', 'Button')}
//                 </Button>
//                 <Button className="btn-gray" onClick={toggleOpenEquipmentSearchModal}>
//                     {t('Add search')}
//                 </Button>
//             </div>
//
//             <TextInput
//                 name="equipment name"
//                 value={evaluationInfo.equipmentName}
//                 disabled={true}
//                 inputGroupClassName="w-50"
//             />
//             <td className="td">
//                 <div className="d-flex flx-col p-2 align-items-center">
//                     <img alt="equipment img" src={img ? img : whiteImg} style={{ width: '150px', height: '150px' }} />
//                 </div>
//             </td>
//         </>
//     );
// };
export const SubReactSelect = ({ name, value, options, onChange }) => {
    return <ReactSelect name={name} value={value} options={options} onChange={onChange} />;
};
export const SubSingleSelect = ({ placeholder, value, options, onChange }) => {
    return <SingleSelect value={value} placeholder={placeholder} options={options} onChange={onChange} />;
};
export const SubSearchAutocomplete = ({ options, value }) => {
    const [evaluationInfo, setEvaluationInfo] = useRecoilState(equipmentEvaluationState);
    const handleSelectedKeyword = selected => {
        if (selected.length) {
            const { value, label } = selected[0];
            setEvaluationInfo({ ...evaluationInfo, deptCode: value, deptName: label });
        }
    };

    return <SearchAutocomplete options={options} value={value} handleSelectedKeyword={handleSelectedKeyword} />;
};

export const SubNumberInput = ({ name, value }) => {
    const [evaluationInfo, setEvaluationInfo] = useRecoilState(equipmentEvaluationState);
    const onChange = e => {
        const { name, value } = e.target;
        const price = value ? parseInt(value.replaceAll(',', '')) : 0;
        if (price < 10000000) {
            setEvaluationInfo({
                ...evaluationInfo,
                [name]: price,
            });
        }
    };
    return <NumberInput name={name} value={value} onChange={onChange} />;
};

export const SubCalculateTotalPriceSection = ({ qtyKey, quantityName, unitPriceKey, unitPriceName, totalName }) => {
    const [evaluationInfo, setEvaluationInfo] = useRecoilState(equipmentEvaluationState);
    const handleChange = e => {
        const { value, name } = e.target;
        const result = value ? parseInt(value.replaceAll(',', '')) : 0;
        setEvaluationInfo({
            ...evaluationInfo,
            [name]: result,
        });
    };
    const handleTotalAmountChange = ({ value, name }) => {
        const result = value ? parseInt(value.replaceAll(',', '')) : 0;
        setEvaluationInfo({
            ...evaluationInfo,
            [name]: result,
        });
    };
    return (
        <CalculateTotalPriceSection
            qty={evaluationInfo[qtyKey]}
            unitPrice={evaluationInfo[unitPriceKey]}
            quantityName={quantityName}
            unitPriceName={unitPriceName}
            totalName={totalName}
            handleQtyChange={handleChange}
            handlePriceChange={handleChange}
            handleTotalAmountChange={handleTotalAmountChange}
        />
    );
};

export const SubCustomTextArea = ({ name, value }) => {
    const [evaluationInfo, setEvaluationInfo] = useRecoilState(equipmentEvaluationState);
    const handleValueChange = e => {
        const { value, name } = e.target;
        setEvaluationInfo({
            ...evaluationInfo,
            [name]: value,
        });
    };
    return <CustomTextArea name={name} value={value} handleChange={handleValueChange} />;
};

export const SubTextInput = ({ name, placeholder, inputGroupClassName, value, handleChange }) => {
    return (
        <TextInput
            name={name}
            value={value}
            handleChange={handleChange}
            placeholder={placeholder}
            inputGroupClassName={inputGroupClassName}
        />
    );
};
