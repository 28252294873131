import { generateKey } from './index';

export const amKey = {
    medicalEngineering: {
        commuteCodeList: '',
        commuteDeptList: '',
        recordManagement: {
            search: '',
        },
        scheduleManagement: {
            search: '',
        },
        recordModifyModal: {
            inputs: '',
        },
        equipmentEvaluation: {
            evaluationInfo: '',
            asset: '',
        },
    },
};

generateKey('am', amKey);

export default amKey;
