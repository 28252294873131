import React, { useEffect, useState } from 'react';
import CustomLabel from './CustomLabel';
import useTranslation from '../../../../../util/hooks/useTranslation';
import NumberInput from './NumberInput';

const CalculateTotalPriceSection = ({
    qty,
    unitPrice,
    quantityName,
    unitPriceName,
    totalName,
    handleQtyChange,
    handlePriceChange,
    handleTotalAmountChange,
}) => {
    const t = useTranslation('Equipment evaluation');

    const [totalAmount, setTotalAmount] = useState('0');
    useEffect(() => {
        const regExp = /\B(?=(\d{3})+(?!\d))/g;
        const total = (qty * unitPrice).toString().replace(regExp, ',');
        setTotalAmount(total);
        handleTotalAmountChange({ value: total, name: totalName });
    }, [qty, unitPrice]);

    return (
        <div className="w-100 d-flex justify-content-between">
            <CustomLabel name="quantity">
                <NumberInput name={quantityName} value={qty ? qty.toLocaleString() : 0} onChange={handleQtyChange} />
            </CustomLabel>
            <CustomLabel name="unit price">
                <NumberInput
                    name={unitPriceName}
                    value={unitPrice ? unitPrice.toLocaleString() : 0}
                    onChange={handlePriceChange}
                />
            </CustomLabel>
            <CustomLabel name="total price">
                <div className="pnt-input--group">
                    <input type="text" name={totalName} value={totalAmount} className="pnt-input" disabled={true} />
                </div>
            </CustomLabel>
        </div>
    );
};

export default CalculateTotalPriceSection;
