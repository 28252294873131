import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';

import Profitability from './Components/Profitability';
import Obsoleteness from './Components/Obsoleteness';
import Utility from './Components/Utility';
import Utilization from './Components/Utilization';
import Priority from './Components/Priority';
import GainLossAndReliability from './Components/GainLossAndReliability';
import EquipmentInfo from './Components/EquipmentInfo';

import Page from '../../../../Common/Page';
import useTranslation from '../../../../../util/hooks/useTranslation';
import Card from '../../../../Common/Card';
import Button from '../../../../Common/Button';
import {
    AssetDefaultValue,
    equipmentEvaluationState,
    EvaluationDefaultValue,
    selectedAssetInfoState,
} from '../../atoms';
import useAsync from '../../../../../util/hooks/useAsync';
import {
    createEvaluationApi,
    editSelectedEvaluationApi,
    getSelectedEvaluationApi,
} from '../../../../../api/engineering';
import useConfirmModal from '../../../../Common/ConfirmModal/useConfirmModal';
import ValidityCheckModal from './Components/ValidityCheckModal';

const RegisterOrEditEvaluation = ({ match: { params } }) => {
    const history = useHistory();
    const t = useTranslation('Equipment evaluation');

    const cardRef = useRef(null);
    const [notEnteredItems, setNotEnteredItems] = useState([]);
    const [totalScore, setTotalScore] = useState(0);
    const [validityModal, setValidityModal] = useState(false);
    const [evaluationInfo, setEvaluationInfo] = useRecoilState(equipmentEvaluationState);
    const setAssetInfo = useSetRecoilState(selectedAssetInfoState);
    const [mode, setMode] = useState('create');

    const handleCancelBtnClick = () => {
        resetEvaluationInfo();
        history.push('/medical-engineering/evaluation-list');
    };

    const { promise: createNewEvaluation } = useAsync({
        promise: createEvaluationApi,
        resolve: () => {
            resetEvaluationInfo();
            toggleConfirmCreateEvaluationModal();
        },
        reject: err => console.error(err),
    });

    const { promise: editSelectedEvaluation } = useAsync({
        promise: editSelectedEvaluationApi,
        resolve: () => {
            resetEvaluationInfo();
            toggleConfirmEditEvaluationModal();
        },
        reject: err => console.error(err),
    });

    const { promise: getSelectedEvaluationInfo } = useAsync({
        promise: getSelectedEvaluationApi,
        resolve: res => {
            setEvaluationInfo({ ...res });
        },
        reject: err => console.error(err),
    });

    const { Modal: ConfirmCreateEvaluationModal, toggleModal: toggleConfirmCreateEvaluationModal } = useConfirmModal({
        initModal: false,
        confirmText: t('Evaluation is created successfully'),
        okCallback: () => history.push('/medical-engineering/evaluation-list'),
        removeCancel: true,
    });

    const { Modal: ConfirmEditEvaluationModal, toggleModal: toggleConfirmEditEvaluationModal } = useConfirmModal({
        initModal: false,
        confirmText: t('Evaluation is edited successfully'),
        okCallback: () => history.push('/medical-engineering/evaluation-list'),
        removeCancel: true,
    });

    const {
        claimCode,
        appPoint,
        oldPoint,
        priorityPoint,
        profitabilityPoint,
        trustPoint,
        utilityPoint,
        deptCostLossPoint,
        oldEcd,
        oldEcd2,
        oldEcd3,
        oldEcd4,
        year,
    } = evaluationInfo;

    const handleRegisterBtnClick = () => {
        const tempNotEnteredItemsArr = [];
        const options = {
            equipmentName: '1',
            claimCode: '2',
            deptCode: '3',
            priority: '4',
            budgetRequestSum: '6',
            adjustmentSum: '7',
            profitabilityPoint: '8',
            appPoint: '11',
            priorityPoint: '12',
        };
        tempNotEnteredItemsArr.push(
            ...Object.keys(options)
                .map(item => (evaluationInfo[item] ? null : options[item]))
                .filter(item => item),
        );
        if (
            ['REPLACEMENT', 'DISCONTINUED'].includes(claimCode) &&
            oldEcd !== 'A010101' &&
            !(oldEcd === 'A010201' && oldEcd2 === 'A010301' && oldEcd3 === 'A010401' && oldEcd4 === 'A010501')
        ) {
            tempNotEnteredItemsArr.push('9');
        }
        setNotEnteredItems(tempNotEnteredItemsArr);

        if (tempNotEnteredItemsArr.length) {
            toggleValidityModal();
        } else {
            if (mode === 'create') {
                createNewEvaluation({ ...evaluationInfo, sum: totalScore });
            }
            if (mode === 'edit') {
                editSelectedEvaluation({ ...evaluationInfo, sum: totalScore, drNum: params.drNum });
            }
        }
    };

    const goToTop = () => {
        if (cardRef && cardRef.current) {
            cardRef.current.scrollIntoView(true);
        }
    };

    const resetEvaluationInfo = () => {
        setAssetInfo(AssetDefaultValue);
        setEvaluationInfo(EvaluationDefaultValue);
    };

    const toggleValidityModal = () => {
        setValidityModal(!validityModal);
    };
    useEffect(() => {
        setTotalScore(
            appPoint +
                (['DISCONTINUED', 'REPLACEMENT'].includes(claimCode) && oldPoint ? parseInt(oldPoint) : 0) +
                parseInt(priorityPoint) +
                parseInt(profitabilityPoint) +
                parseInt(trustPoint) +
                (['NEW', 'QUANTITY', 'OTHER(FUNCTION)'].includes(claimCode) && utilityPoint
                    ? parseInt(utilityPoint)
                    : 0) +
                parseInt(deptCostLossPoint),
        );
    }, [evaluationInfo]);

    useEffect(() => {
        if (params.hasOwnProperty('drNum')) {
            const { drNum } = params;
            getSelectedEvaluationInfo({ drNum });
            setMode('edit');
        }
        return () => {
            setEvaluationInfo(EvaluationDefaultValue);
        };
    }, []);

    return (
        <>
            <Page menuPath={[t('Equipment evaluation'), t('Register evaluation')]} className="h-100">
                <Card
                    innerRef={el => (cardRef.current = el)}
                    header={{
                        title: mode === 'create' ? t('Register evaluation') : t('Edit evaluation'),
                        subTitle: `${year} ${t('YEAR')}`,
                        action: (
                            <>
                                <Button className="btn-gray" onClick={handleCancelBtnClick}>
                                    {t('Cancel', 'Button')}
                                </Button>
                                <Button className="btn-secondary" onClick={handleRegisterBtnClick}>
                                    {t('Register', 'Button')}
                                </Button>
                            </>
                        ),
                    }}
                >
                    <span className="mb-2 d-block" style={{ fontSize: '12px' }}>
                        {t('This is required information')}
                    </span>
                    <EquipmentInfo />
                    <Profitability />
                    {['DISCONTINUED', 'REPLACEMENT'].includes(claimCode) ? <Obsoleteness /> : <Utility />}
                    <Utilization />
                    <Priority />
                    <GainLossAndReliability />
                    <div className="mt-3">
                        <div className="total-evaluation-score-div">
                            <div>{t('Total')}</div>
                            <div>{totalScore.toLocaleString()}</div>
                        </div>
                        <div className="d-flex mt-5" style={{ justifyContent: 'space-between' }}>
                            <Button className="btn-secondary" onClick={goToTop}>
                                {t('Go to top')}
                            </Button>
                            <Button className="btn-secondary" onClick={handleRegisterBtnClick}>
                                {t('Save', 'Button')}
                            </Button>
                        </div>
                    </div>
                </Card>
            </Page>
            <ConfirmCreateEvaluationModal />
            <ConfirmEditEvaluationModal />
            <ValidityCheckModal
                toggleModal={toggleValidityModal}
                initModal={validityModal}
                categories={notEnteredItems}
            />
        </>
    );
};

export default RegisterOrEditEvaluation;
