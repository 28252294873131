import React, { useState, useEffect } from 'react';
import Card from '../../../../Common/Card';
import useTranslation from '../../../../../util/hooks/useTranslation';
import Search from '../Components/MonthSearch';
import Table from '../../../../Common/Table';
import useColumns from '../../../../../util/hooks/useColumns';
import * as column from '../../../util/grid/column';
import Button from '../../../../Common/Button';
import ManagerModal from './Components/ManagerModal';
import useAsync from '../../../../../util/hooks/useAsync';
import { fetchAccountEmployeeList } from '../../../../../api/engineering';

const SettlementManager = () => {
    const t = useTranslation('SettlementManager');
    const columnT = useTranslation('SettlementManagerTable');

    const [managerModal, setManagerModal] = useState(false);
    const [tableData, setTableData] = useState({ rows: [] });
    const [register, setRegister] = useState(false);
    const [searchMonth, setSearchMonth] = useState(null);
    const [trData, setTrData] = useState(null);

    const { promise: getAccountEmployeeList, state: accountEmployeeListState } = useAsync({
        promise: fetchAccountEmployeeList,
        fixedParam: { isAll: 'Y', dateCode: searchMonth },
        resolve: res => {
            setTableData(res);
        },
    });

    const columns = useColumns(
        [
            column.employeeNumber(),
            column.employeeName(),
            column.responsibleHemodialysis(),
            column.responsibleHomeAppliances(),
        ],
        columnT,
        [columnT],
    );

    useEffect(() => {
        if (searchMonth) {
            getAccountEmployeeList();
        }
    }, [searchMonth]);

    const handleManagerModal = () => {
        setRegister(true);
        setManagerModal(prevState => !prevState);
    };

    const handleTrClick = trData => {
        setTrData(trData);
        setRegister(false);
        setManagerModal(prevState => !prevState);
    };

    const handleSearchMonth = searchMonth => {
        setSearchMonth(searchMonth);
    };

    return (
        <>
            <Search handleSearchMonth={handleSearchMonth} />
            <Card
                className={'management-container schedule'}
                header={{
                    title: t('Management In Charge of Settlement Computerization', 'Menu'),
                    action: (
                        <Button className={'btn-secondary'} onClick={handleManagerModal}>
                            {t('Registration Manager')}
                        </Button>
                    ),
                }}
            >
                <Table
                    textAlign={'center'}
                    columns={columns}
                    data={tableData}
                    paging={false}
                    onTrClick={handleTrClick}
                    loading={accountEmployeeListState.isLoading}
                />
            </Card>
            <ManagerModal
                initModal={managerModal}
                toggleModal={() => {
                    setManagerModal(prevState => !prevState);
                }}
                register={register}
                getAccountEmployeeList={getAccountEmployeeList}
                trData={trData}
            />
        </>
    );
};

export default SettlementManager;
