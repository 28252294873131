import React, { useState, useEffect, useRef, createContext, useReducer } from 'react';
import { withRouter, useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import MetisMenu from 'react-metismenu';
import { setEnableMobileMenu } from '../../../reducers/ThemeOptions';
import { CustomNav, SettingsNav } from './NavItems';
import { useTranslation } from 'react-i18next';
import { setBookmarkMenu } from '../../../reducers/CustomMenu';
import CustomLink from './CustomLink';
import useAsync from '../../../util/hooks/useAsync';
import { getDashboardListApi } from '../../../api/dashboard';
import { getFilterListApi } from '../../../api/filter';
import { useRecoilValue } from 'recoil';
import { filterList as storeFilterList } from '../../../recoilStore/filter';

const chgMenuLabelByLanguage = (menus, t) => {
    return menus.map(v => {
        const copyV = { ...v };
        if (copyV.content) {
            copyV.content = chgMenuLabelByLanguage(copyV.content, t);
        }
        if (!copyV.id || (copyV.id && !copyV.id.includes('customDashboard_') && !copyV.id.includes('filterMenu_'))) {
            copyV.label = t(`Menu;${copyV.label}`);
        }
        return copyV;
    });
};

export const SidebarStateContext = createContext();

const Nav = ({ location, setting, onUpdateSize }) => {
    const [state] = useReducer(null, { onUpdateSize });
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const filterList = useRecoilValue(storeFilterList);
    const [customMenu, setCustomMenu] = useState({ bookmarkedMenu: [], filterList: [] });
    const assetRentalRequestMenuPath = useRouteMatch(['/asset-rental/request/:reqNum([0-9]+)']);
    const assetRentalProcessMenuPath = useRouteMatch(['/asset-rental/process/:reqNum([0-9]+)']);
    const assetsDetailMenuPath = useRouteMatch(['/assets/detail/:reqNum([0-9]+)']);
    const statusFlowSchemeMenuPatch = useRouteMatch(['/status/flow-scheme/:reqNum([0-9]+)']);
    const allocateAssetsMenuPath = useRouteMatch(['/status/allocate/:reqNum([0-9]+)']);
    const registerManageMenuPath = useRouteMatch(['/asset-access/register-manage/:reqNum([0-9]+)']);
    const transferManageMenuPath = useRouteMatch(['/asset-access/transfer-manage/:reqNum([0-9]+)']);
    // const { promise: getCustomMenu } = useAsync({
    //     promise: [getDashboardListApi, getFilterListApi],
    //     param: [{ isIndividual: 'Y', show: 'Y', isAll: 'Y' }, { isAll: 'Y' }],
    //     resolve: ([{ data: dashboardListInfo }, { data: filterListInfo }]) => {
    //         let bookmarkedMenu = dashboardListInfo.rows || [];
    //         dispatch(setBookmarkMenu(bookmarkedMenu));
    //
    //         dispatch(setFilterList(filterListInfo.rows || []));
    //     },
    // });
    const { promise: getFilterMenu } = useAsync({
        promise: getFilterListApi,
        param: { isAll: 'Y' },
        resolve: res => {
            const filterListInfo = res;
            // setFilterList(filterListInfo.rows || []);
            // dispatch(setFilterList(filterListInfo.rows || []));
            setCustomMenu({ filterList: filterListInfo.rows || [] });
        },
    });
    const { promise: getCustomMenu } = useAsync({
        promise: getDashboardListApi,
        param: { isIndividual: 'Y', show: 'Y', isAll: 'Y' },
        resolve: res => {
            const dashboardListInfo = res;
            let bookmarkedMenu = dashboardListInfo.rows || [];
            dispatch(setBookmarkMenu(bookmarkedMenu));

            // setFilterList(filterListInfo.rows || []);
            // dispatch(setFilterList(filterListInfo.rows || []));
            setCustomMenu({ bookmarkedMenu: dashboardListInfo.rows });
        },
    });
    const menuUpdateBundle = useRef();

    const { enableMobileMenu } = useSelector(state => state.ThemeOptions);

    const navRef = useRef();
    const [menus, setMenus] = useState(CustomNav);

    const { dashboardListInfo } = useSelector(state => state.Dashboards);
    // const { bookmarkedMenu } = useSelector(state => state.CustomMenu);
    const {
        userInfo: { groupId },
    } = useSelector(state => state.UserInfo);

    const toggleMobileSidebar = () => {
        dispatch(setEnableMobileMenu(!enableMobileMenu));
    };

    // const isPathActive = path => {
    //     return location.pathname.startsWith(path);
    // };

    const forcedActiveUpdate = () => {
        //window.location.hash === # + location.pathname
        const activePath = window.location.hash || `#${location.pathname}`;
        if (activePath.indexOf('dashboards/edit') >= 0) {
            navRef.current.changeActiveLinkTo(activePath.replace('/edit', ''));
            // navRef.current.changeActiveLinkTo('#/dashboards/list');
        } else if (activePath.indexOf('asset-rental/request/application') >= 0) {
            navRef.current.changeActiveLinkTo(activePath.replace('/application', ''));
        } else if (activePath.indexOf('asset-access/register-manage/repair') >= 0) {
            navRef.current.changeActiveLinkTo(activePath.replace('/repair', ''));
        } else if (
            assetRentalRequestMenuPath &&
            assetRentalRequestMenuPath.params &&
            assetRentalRequestMenuPath.params.reqNum
        ) {
            navRef.current.changeActiveLinkTo(activePath.replace(`/${assetRentalRequestMenuPath.params.reqNum}`, ''));
        } else if (
            assetRentalProcessMenuPath &&
            assetRentalProcessMenuPath.params &&
            assetRentalProcessMenuPath.params.reqNum
        ) {
            navRef.current.changeActiveLinkTo(activePath.replace(`/${assetRentalProcessMenuPath.params.reqNum}`, ''));
        } else if (assetsDetailMenuPath && assetsDetailMenuPath.params && assetsDetailMenuPath.params.reqNum) {
            navRef.current.changeActiveLinkTo(activePath.replace(`/${assetsDetailMenuPath.params.reqNum}`, ''));
        } else if (
            statusFlowSchemeMenuPatch &&
            statusFlowSchemeMenuPatch.params &&
            statusFlowSchemeMenuPatch.params.reqNum
        ) {
            navRef.current.changeActiveLinkTo(activePath.replace(`/${statusFlowSchemeMenuPatch.params.reqNum}`, ''));
        } else if (allocateAssetsMenuPath && allocateAssetsMenuPath.params && allocateAssetsMenuPath.params.reqNum) {
            navRef.current.changeActiveLinkTo(activePath.replace(`/${allocateAssetsMenuPath.params.reqNum}`), '');
        } else if (registerManageMenuPath && registerManageMenuPath.params && registerManageMenuPath.params.reqNum) {
            navRef.current.changeActiveLinkTo(activePath.replace(`/${registerManageMenuPath.params.reqNum}`), '');
        } else if (transferManageMenuPath && transferManageMenuPath.params && transferManageMenuPath.params.reqNum) {
            navRef.current.changeActiveLinkTo(activePath.replace(`/${transferManageMenuPath.params.reqNum}`), '');
        }
        navRef.current.changeActiveLinkTo(activePath);
    };

    useEffect(() => {
        // 대시보드 생성 수정 시, 북마크 메뉴 다시 API 호출로 수정
        getCustomMenu();
        getFilterMenu();
        // const bookmarked = dashboardListInfo.rows.filter(dashboard => dashboard.show === 'Y');
        // dispatch(setBookmarkMenu(bookmarked));
    }, [dashboardListInfo, filterList]);

    // useEffect(() => {
    //     setMenus(chgMenuLabelByLanguage(CustomNav, t));
    // }, [lang]);

    useEffect(() => {
        const { bookmarkedMenu, filterList } = customMenu;
        const dashboardMenu = CustomNav.find(menu => menu.id === 'dashboard');
        if (bookmarkedMenu && bookmarkedMenu.length) {
            const customMenus = bookmarkedMenu.map((dashboard, i) => {
                const { menuName, menuNum, home } = dashboard;
                const menu = { label: menuName, to: `#/dashboards/${menuNum}` };
                menu.id = 'customDashboard_' + (i + 1);
                if (i === bookmarkedMenu.length - 1) {
                    menu.id = 'customDashboard_last';
                }
                if (home === 'Y') {
                    menu.id += '_home';
                }
                return menu;
            });

            const dashboardSubMenu = dashboardMenu.content;
            const dashboardSubMenuLen = dashboardSubMenu.length;
            let checkCnt = 0;
            while (dashboardSubMenuLen > checkCnt++) {
                if (dashboardSubMenu[0].id && dashboardSubMenu[0].id.indexOf('customDashboard_') >= 0) {
                    dashboardSubMenu.shift();
                }
            }
            dashboardMenu.content = [...customMenus, ...dashboardSubMenu];
        }

        const filterMenu = CustomNav.find(menu => menu.id === 'filter') || { content: [] };
        if (filterList && filterList.length) {
            const applyMenuFilter = filterList.filter(filterInfo => filterInfo.isApplyMenu === 'Y');
            if (applyMenuFilter.length) {
                const filterMenus = applyMenuFilter.map((filterInfo, i) => {
                    const { filterName, filterNum } = filterInfo;
                    return {
                        label: filterName,
                        to: `#/filter/item/list/${filterNum}`,
                        id: 'filterMenu_' + (applyMenuFilter.length === i + 1 ? 'last' : i + 1),
                    };
                });
                filterMenu.content = [
                    ...filterMenus,
                    ...filterMenu.content.filter(v => !v.id || !v.id.includes('filterMenu_')),
                ];
            }
        }

        if (menuUpdateBundle.current) {
            clearTimeout(menuUpdateBundle.current);
            menuUpdateBundle.current = null;
        }
        menuUpdateBundle.current = setTimeout(() => {
            setMenus(
                chgMenuLabelByLanguage(
                    setting
                        ? SettingsNav.map(menu => {
                              return menu;
                          })
                        : CustomNav.map(menu => {
                              if (menu.id) {
                                  switch (menu.id) {
                                      case 'dashboard':
                                          return dashboardMenu;
                                      case 'filter':
                                          return filterMenu;
                                      case 'medicalEngineering':
                                          return groupId === '60238' ? menu : null;
                                  }
                              }
                              return menu;
                          }).filter(menu => menu !== null),
                    t,
                ),
            );
        }, 250);
    }, [customMenu, t, setting, groupId]);

    useEffect(() => {
        forcedActiveUpdate(location);
    }, [location, menus, setting]);

    return (
        <SidebarStateContext.Provider value={state}>
            <MetisMenu
                content={menus}
                onSelected={toggleMobileSidebar}
                activeLinkFromLocation
                className="vertical-nav-menu"
                iconNamePrefix=""
                classNameStateIcon="pe-7s-angle-down"
                classNameItemHasActiveChild="nav-child-active"
                ref={navRef}
                LinkComponent={CustomLink}
            />
        </SidebarStateContext.Provider>
    );
};

export default withRouter(Nav);
