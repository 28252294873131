import React from 'react';
import cx from 'classnames';
const ScoreSheet = ({ tableClassName, tbodyClassName, children }) => {
    return (
        <div className="pnt-table" style={{ height: 'auto' }}>
            <div className="border-box" style={{ overflowX: 'auto', overflowY: 'hidden' }}>
                <table
                    className={tableClassName}
                    //{cx('w-100', { 'custom-evaluation-table': isEvaluationTable })}
                >
                    <tbody
                        className={cx(
                            'tbody',
                            tbodyClassName && tbodyClassName,
                            //'evaluation-table',
                        )}
                    >
                        {/*<tr className="tr">*/}
                        {children}
                        {/*<td className="td">/!*{t(title)}*!/</td>*/}
                        {/*<td*/}
                        {/*    className="td" //colSpan={colSpan}*/}
                        {/*></td>*/}
                        {/*</tr>*/}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ScoreSheet;
