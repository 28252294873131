export const CustomNav = [
    {
        id: 'dashboard',
        icon: 'icon-menu-first',
        label: 'Dashboards',
        content: [
            {
                label: 'Dashboard List',
                to: '#/dashboards/list',
            },
        ],
    },
    {
        id: 'assetAccessSystem',
        icon: 'icon-menu-third',
        label: 'Operation of biomedical engineering team',
        content: [
            {
                label: 'Repair reception',
                to: '#/asset-access/repair-reception',
            },
            {
                label: 'Registered Repair Status',
                to: '#/asset-access/register-status',
            },
            {
                label: 'Registered Repair Management',
                to: '#/asset-access/register-manage',
                id: 'registerManage_last',
            },
            {
                label: 'Handover reception',
                to: '#/asset-access/handover-reception',
            },
            {
                label: 'Transferred Repair Status',
                to: '#/asset-access/transfer-status',
            },
            {
                label: 'Transferred Repair Management',
                to: '#/asset-access/transfer-manage',
                id: 'transferManage_last',
            },
            {
                label: 'Asset receipt and handover log',
                to: '#/asset-access/receipt-handover-log',
            },
            {
                label: 'Repair and takeover user log',
                to: '#/asset-access/repair-takeover-user-log',
            },
            {
                label: 'Schedule Management',
                to: '#/asset-access/commuting-schedule',
            },
            {
                label: 'Record Management',
                to: '#/asset-access/commuting-record',
            },
        ],
    },
    {
        id: 'medicalEngineering',
        icon: 'icon-menu-third',
        label: 'Biomedical Engineering Team Planning',
        content: [
            {
                label: 'Computerized Settlement Management',
                to: '#/medical-engineering/settlement',
            },
            {
                label: 'Management In Charge of Settlement Computerization',
                to: '#/medical-engineering/settlement-manager',
            },
            {
                label: 'Equipment evaluation',
                to: '#/medical-engineering/evaluation-list',
            },
        ],
    },
    {
        icon: 'icon-menu-second',
        id: 'assets',
        label: 'Assets',
        content: [
            {
                label: 'Quick Search',
                to: '#/assets/quickSearch',
            },
        ],
    },
    {
        id: 'filter',
        icon: 'icon-menu-third',
        label: 'Filters',
        content: [
            {
                label: 'Filter List',
                to: '#/filter/list',
            },
        ],
    },
];

export const SettingsNav = [
    {
        id: 'status',
        icon: 'icon-menu-third',
        label: 'Status',
        content: [
            {
                label: 'Status flow Scheme',
                to: '#/status/flow-scheme',
            },
            {
                label: 'Status Scheme',
                to: '#/status/scheme',
            },
            {
                label: '(De)allocate Assets',
                to: '#/status/allocate',
            },
        ],
    },
    {
        id: 'wmsConnection',
        icon: 'icon-menu-fourth',
        label: 'Move to WMS',
        to: '',
    },
];
