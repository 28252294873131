import React, { useEffect, useState } from 'react';
import CustomRadio from '../../Components/CustomRadio';
import { useRecoilState } from 'recoil';
import { equipmentEvaluationState } from '../../../atoms';
import { PRIORITY_OVER_FIVE, PRIORITY_UNDER_FIVE } from '../../../../util/sheetOption';
import ScoreSheet from '../../../../../Common/ScoreSheet';
import useTranslation from '../../../../../../util/hooks/useTranslation';

const bolObj = {
    A050101: true,
    A050102: true,
};
const optionObj = {
    A050101: PRIORITY_UNDER_FIVE,
    A050102: PRIORITY_OVER_FIVE,
};

const CustomTr = ({ title, subTitle, children, score }) => {
    const t = useTranslation('Equipment evaluation');
    return (
        <tr className="tr">
            {title && (
                <td rowSpan={2} className="td">
                    12. {t('Priority')} (*)
                </td>
            )}
            {subTitle && (
                <td rowSpan={2} className="td">
                    {t('Evaluating billing priority')}
                </td>
            )}
            <td className="td">{children}</td>
            {typeof score === 'number' && (
                <td rowSpan={2} className="td score">
                    {score}
                </td>
            )}
        </tr>
    );
};
const Priority = () => {
    const t = useTranslation('Equipment evaluation');
    const [evaluationInfo, setEvaluationInfo] = useRecoilState(equipmentEvaluationState);
    const { priorityEcd, priorityPcd, priorityPoint } = evaluationInfo;
    //console.log(priorityPoint);
    const [score, setScore] = useState(priorityPoint);
    useEffect(() => {
        let _score = 0;
        const { priorityEcd, priorityPcd } = evaluationInfo;
        if (priorityEcd === 'A050101') {
            PRIORITY_UNDER_FIVE.map(item => {
                if (item.value === parseInt(priorityPcd)) {
                    _score = item.score;
                }
            });
        } else if (priorityEcd === 'A050102') {
            PRIORITY_OVER_FIVE.map(item => {
                if (item.value === parseInt(priorityPcd)) {
                    _score = item.score;
                }
            });
        }
        setScore(_score);
    }, [evaluationInfo]);

    useEffect(() => {
        setEvaluationInfo({
            ...evaluationInfo,
            priorityPoint: score,
        });
    }, [score]);

    return (
        <ScoreSheet tableClassName="w-100" tbodyClassName="priority-table">
            <CustomTr title="11. 우선순위 (*)" subTitle="청구우선순위 평가" score={score}>
                <div className="align-flex-start-div">
                    <div>
                        <span>{t('Rank 1 or 1* through 4 in the billing department')}</span>
                        <div>
                            {PRIORITY_UNDER_FIVE.map(item => {
                                const { title, value } = item;
                                return (
                                    <CustomRadio
                                        key={value}
                                        text={t(title)}
                                        value={value}
                                        checked={priorityEcd === 'A050101' && parseInt(priorityPcd) === value}
                                        handleChange={e => {
                                            const { checked } = e.currentTarget;
                                            let result = '';
                                            if (
                                                checked &&
                                                (!priorityPcd || priorityPcd !== value || priorityEcd !== 'A050101')
                                            ) {
                                                result = value;
                                            }
                                            setEvaluationInfo({
                                                ...evaluationInfo,
                                                priorityPcd: result,
                                                priorityEcd: result ? 'A050101' : '',
                                            });
                                        }}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
            </CustomTr>
            <CustomTr>
                <div className="align-flex-start-div">
                    <div>
                        <span>{t('Equipment ranked 5th or higher in the billing department')}</span>
                        <div>
                            {PRIORITY_OVER_FIVE.map(item => {
                                const { title, value } = item;
                                return (
                                    <CustomRadio
                                        key={value}
                                        text={t(title)}
                                        value={value}
                                        checked={priorityEcd === 'A050102' && parseInt(priorityPcd) === value}
                                        handleChange={e => {
                                            const { checked } = e.currentTarget;
                                            let result = '';
                                            if (
                                                checked &&
                                                (!priorityPcd || priorityPcd !== value || priorityEcd !== 'A050102')
                                            ) {
                                                result = value;
                                            }
                                            setEvaluationInfo({
                                                ...evaluationInfo,
                                                priorityPcd: result,
                                                priorityEcd: result ? 'A050102' : '',
                                            });
                                        }}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
            </CustomTr>
        </ScoreSheet>
    );
};

export default Priority;
