import React, { useEffect, useState } from 'react';
import CustomRadio from '../../Components/CustomRadio';
import { useRecoilState } from 'recoil';
import { equipmentEvaluationState } from '../../../atoms';
import {
    ADDITIONAL_MATTERS_REQUIRED,
    DEPARTMENTAL_GAIN_OR_LOSS,
    NUM_OF_TIMES_EQUIPMENT_IS_NOT_SELECTED_IN_FIVE_YEARS,
    PRIORITY,
    RELIABILITY,
} from '../../../../util/sheetOption';
import useTranslation from '../../../../../../util/hooks/useTranslation';

const Utility = () => {
    const t = useTranslation('Equipment evaluation');
    const [score, setScore] = useState(0);
    const [evaluationInfo, setEvaluationInfo] = useRecoilState(equipmentEvaluationState);
    const [otherEvaluationItemFlag, setOtherEvaluationItemFlag] = useState(false);

    useEffect(() => {
        const {
            utilityEcd,
            utilityEcd2,
            utilityEcd3,
            utilityEcd4,
            utilityEcd5,
            utilityPcd,
            utilityPcd2,
            utilityPcd3,
            utilityPcd4,
            utilityPcd5,
        } = evaluationInfo;

        let calculatedScore = 0;

        if (utilityEcd === 'A040101') {
            calculatedScore = 100;
        } else {
            if (utilityEcd === 'A040201') {
                NUM_OF_TIMES_EQUIPMENT_IS_NOT_SELECTED_IN_FIVE_YEARS.map(item => {
                    if (item.value === parseInt(utilityPcd)) {
                        calculatedScore += item.score;
                    }
                });
            }

            if (utilityEcd2 === 'A040301') {
                RELIABILITY.map(item => {
                    if (item.value === parseInt(utilityPcd2)) {
                        calculatedScore += item.score;
                    }
                });
            }

            if (utilityEcd3 === 'A040401') {
                DEPARTMENTAL_GAIN_OR_LOSS.map(item => {
                    if (item.value === parseInt(utilityPcd3)) {
                        calculatedScore += item.score;
                    }
                });
            }

            if (utilityEcd4 === 'A040501') {
                PRIORITY.map(item => {
                    if (item.value === parseInt(utilityPcd4)) {
                        calculatedScore += item.score;
                    }
                });
            }

            if (utilityEcd5 === 'A040601') {
                ADDITIONAL_MATTERS_REQUIRED.map(item => {
                    if (item.value === parseInt(utilityPcd5)) {
                        calculatedScore += item.score;
                    }
                });
            }
        }

        setOtherEvaluationItemFlag(
            !!(
                utilityEcd === 'A040201' ||
                utilityEcd2 === 'A040202' ||
                utilityEcd3 === 'A040203' ||
                utilityEcd4 === 'A040204' ||
                utilityEcd5 === 'A040205'
            ),
        );
        setScore(calculatedScore);
    }, [evaluationInfo]);

    useEffect(() => {
        setEvaluationInfo({
            ...evaluationInfo,
            utilityPoint: score,
        });
    }, [score]);

    return (
        <div className="pnt-table" style={{ height: 'auto' }}>
            <div className="border-box" style={{ overflowX: 'auto', overflowY: 'hidden' }}>
                <table className="w-100 custom-evaluation-table">
                    <tbody className="tbody">
                        <tr className="tr">
                            <td rowSpan={6} className="td">
                                9. {t('Utility (operational value)')}
                            </td>
                            <td className="td">
                                {evaluationInfo.utilityEcd === 'A040101' ? (
                                    <span className={'icon-check icon-filter-brand'} />
                                ) : (
                                    ''
                                )}
                            </td>
                            <td className="td">{t('Prioritized reflection')}</td>
                            <td className="td">
                                <CustomRadio
                                    text={t(
                                        'Non-repairable equipment due to hospital policy equipment and parts discontinuation',
                                    )}
                                    checked={
                                        evaluationInfo.utilityEcd === 'A040101' &&
                                        parseInt(evaluationInfo.utilityPcd) === 10
                                    }
                                    handleClick={e => {
                                        const { checked } = e.currentTarget;
                                        const { utilityPcd, utilityEcd } = evaluationInfo;
                                        let result = '';
                                        if (
                                            checked &&
                                            (!utilityPcd || utilityPcd !== parseInt('10') || utilityEcd !== 'A040101')
                                        ) {
                                            result = 10;
                                        }
                                        setEvaluationInfo({
                                            ...evaluationInfo,
                                            utilityEcd2: '',
                                            utilityEcd3: '',
                                            utilityEcd4: '',
                                            utilityEcd5: '',
                                            utilityPcd2: '',
                                            utilityPcd3: '',
                                            utilityPcd4: '',
                                            utilityPcd5: '',
                                            utilityPcd: result,
                                            utilityEcd: result ? 'A040101' : '',
                                        });
                                        setOtherEvaluationItemFlag(false);
                                    }}
                                />
                            </td>
                            <td rowSpan={6} className="td score">
                                {score}
                            </td>
                        </tr>
                        <tr className="tr">
                            <td className="td" rowSpan={5}>
                                {otherEvaluationItemFlag ? <span className={'icon-check icon-filter-brand'} /> : ''}
                            </td>
                            <td className="td">1. {t('Number of non-billed or non-selected equipment in 5 years')}</td>
                            <td className="td">
                                {NUM_OF_TIMES_EQUIPMENT_IS_NOT_SELECTED_IN_FIVE_YEARS.map(item => {
                                    const { title, value } = item;
                                    const { utilityPcd, utilityEcd } = evaluationInfo;
                                    return (
                                        <CustomRadio
                                            key={value}
                                            text={t(title)}
                                            value={value}
                                            checked={utilityEcd === 'A040201' && parseInt(utilityPcd) === value}
                                            handleClick={e => {
                                                const { checked } = e.currentTarget;
                                                let result = '';
                                                if (
                                                    checked &&
                                                    (!utilityPcd || utilityPcd !== value || utilityEcd !== 'A040201')
                                                ) {
                                                    result = value;
                                                }
                                                setEvaluationInfo({
                                                    ...evaluationInfo,

                                                    utilityPcd: result,
                                                    utilityEcd: result ? 'A040201' : '',
                                                });
                                            }}
                                        />
                                    );
                                })}
                            </td>
                        </tr>
                        <tr className="tr">
                            <td className="td">2. {t('Reliability')}</td>
                            <td className="td">
                                {RELIABILITY.map(item => {
                                    const { title, value } = item;
                                    const { utilityPcd2, utilityEcd2 } = evaluationInfo;
                                    return (
                                        <CustomRadio
                                            key={value}
                                            text={t(title)}
                                            value={value}
                                            checked={utilityEcd2 === 'A040301' && parseInt(utilityPcd2) === value}
                                            handleClick={e => {
                                                const { checked } = e.currentTarget;
                                                let result = '';
                                                if (
                                                    checked &&
                                                    (!utilityPcd2 || utilityPcd2 !== value || utilityEcd2 !== 'A040301')
                                                ) {
                                                    result = value;
                                                }

                                                setEvaluationInfo({
                                                    ...evaluationInfo,
                                                    utilityPcd:
                                                        evaluationInfo.utilityEcd === 'A040201'
                                                            ? evaluationInfo.utilityPcd
                                                            : '',
                                                    utilityEcd:
                                                        evaluationInfo.utilityEcd === 'A040201' ? 'A040201' : '',
                                                    utilityPcd2: result,
                                                    utilityEcd2: result ? 'A040301' : '',
                                                });
                                            }}
                                        />
                                    );
                                })}
                            </td>
                        </tr>
                        <tr className="tr">
                            <td className="td">3. {t('Department Profit and loss')}</td>
                            <td className="td">
                                {DEPARTMENTAL_GAIN_OR_LOSS.map(item => {
                                    const { title, value } = item;
                                    const { utilityPcd3, utilityEcd3 } = evaluationInfo;
                                    return (
                                        <CustomRadio
                                            key={value}
                                            text={t(title)}
                                            value={value}
                                            checked={utilityEcd3 === 'A040401' && parseInt(utilityPcd3) === value}
                                            handleChange={e => {
                                                const { checked } = e.currentTarget;
                                                let result = '';
                                                if (
                                                    checked &&
                                                    (!utilityPcd3 || utilityPcd3 !== value || utilityEcd3 !== 'A040401')
                                                ) {
                                                    result = value;
                                                }
                                                setEvaluationInfo({
                                                    ...evaluationInfo,
                                                    utilityPcd:
                                                        evaluationInfo.utilityEcd === 'A040201'
                                                            ? evaluationInfo.utilityPcd
                                                            : '',
                                                    utilityEcd:
                                                        evaluationInfo.utilityEcd === 'A040201' ? 'A040201' : '',
                                                    utilityPcd3: result,
                                                    utilityEcd3: result ? 'A040401' : '',
                                                });
                                            }}
                                        />
                                    );
                                })}
                            </td>
                        </tr>
                        <tr className="tr">
                            <td className="td">4. {t('Priority')}</td>
                            <td className="td">
                                {PRIORITY.map(item => {
                                    const { title, value } = item;
                                    const { utilityPcd4, utilityEcd4 } = evaluationInfo;
                                    return (
                                        <CustomRadio
                                            key={value}
                                            text={t(title)}
                                            value={value}
                                            checked={utilityEcd4 === 'A040501' && parseInt(utilityPcd4) === value}
                                            handleChange={e => {
                                                const { checked } = e.currentTarget;
                                                let result = '';
                                                if (
                                                    checked &&
                                                    (!utilityPcd4 || utilityPcd4 !== value || utilityEcd4 !== 'A040501')
                                                ) {
                                                    result = value;
                                                }
                                                setEvaluationInfo({
                                                    ...evaluationInfo,
                                                    utilityPcd:
                                                        evaluationInfo.utilityEcd === 'A040201'
                                                            ? evaluationInfo.utilityPcd
                                                            : '',
                                                    utilityEcd:
                                                        evaluationInfo.utilityEcd === 'A040201' ? 'A040201' : '',
                                                    utilityPcd4: result,
                                                    utilityEcd4: result ? 'A040501' : '',
                                                });
                                            }}
                                        />
                                    );
                                })}
                            </td>
                        </tr>
                        <tr className="tr">
                            <td className="td">5. {t('Requires additional')}</td>
                            <td className="td">
                                {ADDITIONAL_MATTERS_REQUIRED.map(item => {
                                    const { title, value } = item;
                                    const { utilityPcd5, utilityEcd5 } = evaluationInfo;
                                    return (
                                        <CustomRadio
                                            key={value}
                                            text={t(title)}
                                            value={value}
                                            checked={utilityEcd5 === 'A040601' && parseInt(utilityPcd5) === value}
                                            handleChange={e => {
                                                const { checked } = e.currentTarget;
                                                let result = '';
                                                if (
                                                    checked &&
                                                    (!utilityPcd5 || utilityPcd5 !== value || utilityEcd5 !== 'A040601')
                                                ) {
                                                    result = value;
                                                }
                                                setEvaluationInfo({
                                                    ...evaluationInfo,
                                                    utilityPcd:
                                                        evaluationInfo.utilityEcd === 'A040201'
                                                            ? evaluationInfo.utilityPcd
                                                            : '',
                                                    utilityEcd:
                                                        evaluationInfo.utilityEcd === 'A040201' ? 'A040201' : '',
                                                    utilityPcd5: result,
                                                    utilityEcd5: result ? 'A040601' : '',
                                                });
                                            }}
                                        />
                                    );
                                })}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Utility;
