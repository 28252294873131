import React, { useEffect, useState } from 'react';
import FilterSearchGroup from '../../../Common/FilterSearchGroup';
import SearchGroup from '../../../Common/FilterSearchGroup/Components/SearchGroup';
import { FilterList, SelectGroup } from '../../../Common/FilterSearchGroup/Components/Part';
import SearchableSelect from '../../../Common/Select/SearchableSelect';
import DatePicker from '../../../Common/DatePicker';
import moment from 'moment';
import Button from '../../../Common/Button';
import Card from '../../../Common/Card';
import Table from '../../../Common/Table';
import useTranslation from '../../../../util/hooks/useTranslation';
import useColumns from '../../../../util/hooks/useColumns';
import useAsync from '../../../../util/hooks/useAsync';
import { getRepairTakeOverUserListApi } from '../../../../api/log';

const RepairAndTakeoverUserLog = () => {
    const t = useTranslation('CommonColumn');
    const searchOptions = [
        { value: 'TO', label: t('Handover reception', 'Asset') },
        { value: 'RR', label: t('Repair reception', 'Asset') },
    ];

    const columns = useColumns(
        [
            {
                Header: 'Department name',
                accessor: 'groupName',
                className: 'text-ellipsis',
                Cell: ({ value }) => <span>{value ? value : '-'}</span>,
            },
            {
                Header: 'User Name',
                accessor: 'username',
                className: 'text-ellipsis',
                Cell: ({ value }) => <span>{value}</span>,
            },
            {
                Header: 'Employee number',
                accessor: 'userId',
            },
            //fix 언어팩 수리완료 etc.
            {
                Header: 'Asset status',
                accessor: 'status',
                Cell: ({ value }) => <span>{t(value, 'Asset')}</span>,
            },

            {
                Header: 'Registered Date',
                accessor: 'regDate',
                Cell: ({ value }) => <span>{moment(value * 1000).format('YYYY-MM-DD HH:mm:ss')}</span>,
            },
        ],
        t,
    );

    const [list, setList] = useState({ rows: [], totalCount: 0, totalPage: 0, pageSize: 10, page: 1 });
    const { promise: getRepairTakeOverUserLog, state } = useAsync({
        promise: getRepairTakeOverUserListApi,
        fixedParam: { pageSize: 10 },
        resolve: res => setList(res),
        reject: err => console.error(err),
    });

    useEffect(() => {
        getRepairTakeOverUserLog({
            page: 1,
            startDate: 0,
            endDate: moment().unix(),
            status: null,
        });
    }, []);

    const [selectedStatus, setSelectedStatus] = useState([]);
    const handleSelectStatus = selected => {
        setSelectedStatus(selected.map(status => status.value));
    };

    const [startDate, setStartDate] = useState(0);
    const [endDate, setEndDate] = useState(0);
    const handleSearchClick = () => {
        getRepairTakeOverUserLog({
            status: searchOptions.length === selectedStatus.length ? null : selectedStatus.map(item => item).join(','),
            startDate: startDate ? moment(startDate).unix() : 0,
            endDate: endDate ? moment(endDate).unix() : moment().unix(),
        });
    };

    const handlePageChange = page => {
        getRepairTakeOverUserLog({
            status: searchOptions.length === selectedStatus.length ? null : selectedStatus.map(item => item).join(','),
            startDate: startDate ? moment(startDate).unix() : 0,
            endDate: endDate ? moment(endDate).unix() : moment().unix(),
            page,
        });
    };
    return (
        <div className="grid-layout-container">
            <FilterSearchGroup>
                <SearchGroup>
                    <FilterList>
                        <SelectGroup>
                            <SearchableSelect
                                selected={selectedStatus}
                                onChange={handleSelectStatus}
                                data={searchOptions}
                                title="Status"
                            />
                        </SelectGroup>
                        <SelectGroup>
                            <DatePicker
                                value={startDate}
                                valueType="ms"
                                handleChange={selected => setStartDate(selected)}
                                withoutTime
                                showYearDropdown
                                maxDate={moment().valueOf()}
                                dateFormatCalendar="MMMM"
                            />
                        </SelectGroup>
                        <div className="text-center"> ~ </div>
                        <SelectGroup>
                            <DatePicker
                                value={endDate}
                                valueType="ms"
                                handleChange={selected => setEndDate(selected)}
                                withoutTime
                                showYearDropdown
                                minDate={startDate}
                                maxDate={moment().valueOf()}
                                dateFormatCalendar="MMMM"
                            />
                        </SelectGroup>
                        <Button className="btn-brand btn-icon" iconClassName="icon-search" onClick={handleSearchClick}>
                            {t('Search', 'Search')}
                        </Button>
                    </FilterList>
                </SearchGroup>
            </FilterSearchGroup>

            <Card header={{ title: t('Repair and takeover user log', 'Menu') }}>
                <div className="table-container">
                    <Table
                        onPageChange={handlePageChange}
                        loading={state.isLoading}
                        columns={columns}
                        data={{ ...list, pageSize: 10 }}
                    />
                </div>
            </Card>
        </div>
    );
};

export default RepairAndTakeoverUserLog;
