import React from 'react';

const Label = ({ name, value, labelGroupClassName, labelValueClassName }) => {
    return (
        <div className={`pnt-label--group ${labelGroupClassName}`}>
            <span className={`label-main ${labelValueClassName}`}>{name}</span>
            {value && (
                <span className={`label-options`} style={{ display: 'block' }}>
                    {value}
                </span>
            )}
        </div>
    );
};

export default Label;
