import React, { useEffect, useState } from 'react';
import useTranslation from '../../../../../../util/hooks/useTranslation';
import CustomRadio from '../../Components/CustomRadio';
import CustomLabel from '../../Components/CustomLabel';
import { useRecoilState } from 'recoil';
import { equipmentEvaluationState } from '../../../atoms';
import { UNEVALUATED_PROFITABILITY_ARR } from '../../../../util/sheetOption';

const numberWithCommas = num => num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');

const roundNumber = (num, digits = 0) => Math.round(num * 10 ** digits) / 10 ** digits;

const Profitability = () => {
    const initialState = {
        totalIncome: 0,
        depreciation: 0,
        interest: 0,
        totalCost: 0,
        gainAndLoss: 0,
        gainAndLossRatio: 0,
    };
    const t = useTranslation('Equipment evaluation');
    const [score, setScore] = useState(0);
    const [availableEvaluationFlag, setAvailableEvaluationFlag] = useState(false);
    const [evaluationInfo, setEvaluationInfo] = useRecoilState(equipmentEvaluationState);
    const {
        adjustmentSum,
        profitabilityPcd,
        profitabilityEcd,
        profitability1,
        profitability2,
        profitability3,
        profitability4,
        profitability5,
    } = evaluationInfo;

    const [evaluationItemsScores, setEvaluationItemsScores] = useState(initialState);
    const resetEvaluationItems = () => {
        setEvaluationItemsScores(initialState);
    };

    useEffect(() => {
        let _score = 0;
        if (profitabilityEcd === 'A020101') {
            _score = 120;
        }
        // 수익성 - 수익성 미평가
        if (profitabilityEcd === 'A020202') {
            switch (Number(profitabilityPcd)) {
                case 10:
                    _score = 120;
                    break;
                case 20:
                    _score = 100;
                    break;
                case 30:
                    _score = 80;
                    break;
                case 40:
                    _score = 60;
                    break;
                case 50:
                    _score = 40;
                    break;
            }
        }
        // 수익성 - 최우선 반영

        // 수익성 - 수익성 평가 가능
        setAvailableEvaluationFlag(
            !!(profitability1 || profitability2 || profitability3 || profitability4 || profitability5),
        );

        if (profitability1 && profitability2) {
            let _equipmentPrice = adjustmentSum; // 12페이지 조정예산안 합계
            let _totalIncome = profitability1 * profitability2 * 12 * 5;
            let _depreciation = _equipmentPrice * 1000;
            let _interest = ((_depreciation * (0.03 / 12)) / (1 - Math.pow(1 + 0.03 / 12, -60))) * 60 - _depreciation;
            let _totalCost =
                _depreciation + _interest + profitability3 + _totalIncome * 0.1 + profitability4 + profitability5;
            let _gainAndLoss = _totalIncome - _totalCost;
            let _gainAndLossRatio = _gainAndLoss / (_equipmentPrice * 10);
            if (!isFinite(_gainAndLossRatio)) {
                _gainAndLossRatio = 0;
            }
            setEvaluationItemsScores({
                totalIncome: _totalIncome,
                depreciation: _depreciation,
                interest: Math.floor(_interest),
                totalCost: Math.floor(_totalCost),
                gainAndLoss: Math.floor(_gainAndLoss),
                gainAndLossRatio: Math.floor(_gainAndLossRatio),
            });

            if (_gainAndLossRatio >= 500) {
                _score = 120;
            } else if (_gainAndLossRatio >= 100 && _gainAndLossRatio < 500) {
                _score = 100;
            } else if (_gainAndLossRatio >= 50 && _gainAndLossRatio < 100) {
                _score = 80;
            } else if (_gainAndLossRatio >= 0 && _gainAndLossRatio < 50) {
                _score = 60;
            } else {
                _score = 40;
            }
        } else {
            resetEvaluationItems();
        }
        setScore(_score);
    }, [evaluationInfo]);

    useEffect(() => {
        setEvaluationInfo({ ...evaluationInfo, revenue: evaluationItemsScores.gainAndLoss });
    }, [evaluationItemsScores.gainAndLoss]);

    useEffect(() => {
        setEvaluationInfo({ ...evaluationInfo, profitabilityPoint: score });
    }, [score]);

    useEffect(() => {
        const { gainAndLossRatio } = evaluationItemsScores;
        if (availableEvaluationFlag) {
            let pcd = 0;
            if (gainAndLossRatio >= 500) {
                pcd = 10;
            } else if (gainAndLossRatio >= 100 && gainAndLossRatio < 500) {
                pcd = 20;
            } else if (gainAndLossRatio >= 50 && gainAndLossRatio < 100) {
                pcd = 30;
            } else if (gainAndLossRatio >= 0 && gainAndLossRatio < 50) {
                pcd = 40;
            } else {
                pcd = 50;
            }
            setEvaluationInfo({
                ...evaluationInfo,
                profitabilityEcd: 'A020301',
                profitabilityPcd: pcd,
            });
        }
    }, [availableEvaluationFlag]);

    return (
        <div className="pnt-table" style={{ height: 'auto' }}>
            <div className="border-box" style={{ overflowX: 'auto', overflowY: 'hidden' }}>
                <table className="w-100 profitability-table">
                    <thead className="thead">
                        <tr>
                            {['Evaluation item', 'Evaluation description', 'Evaluation score'].map(title => (
                                <th className="th">{t(title)}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="tbody">
                        <tr className="tr">
                            <td className="td">{`8.${t('Profitability')}(*)`}</td>
                            <td className="td">
                                <div className="pnt-table" style={{ height: 'auto' }}>
                                    <table className="profitability-subTable">
                                        <tbody className="tbody">
                                            <tr className="tr" style={{ height: '50px' }}>
                                                <td className="td">
                                                    {profitabilityEcd === 'A020101' ? (
                                                        <span className="icon-check icon-filter-brand" />
                                                    ) : (
                                                        ''
                                                    )}
                                                </td>
                                                <td className="td">{t('Top priority reflection')}</td>
                                                <td className="td">
                                                    <CustomRadio
                                                        readOnly={true}
                                                        text={t('non-repairable equipment')}
                                                        checked={profitabilityEcd === 'A020101'}
                                                        handleChange={e => {
                                                            resetEvaluationItems();
                                                            const { checked } = e.currentTarget;
                                                            let result = '';
                                                            if (
                                                                checked &&
                                                                (!profitabilityPcd ||
                                                                    profitabilityPcd !== 10 ||
                                                                    profitabilityEcd !== 'A020101')
                                                            ) {
                                                                result = '10';
                                                            }

                                                            setEvaluationInfo({
                                                                ...evaluationInfo,
                                                                profitability1: 0,
                                                                profitability2: 0,
                                                                profitability3: 0,
                                                                profitability4: 0,
                                                                profitability5: 0,
                                                                profitabilityPcd: result,
                                                                profitabilityEcd: result ? 'A020101' : '',
                                                            });
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr className="tr" style={{ height: '300px' }}>
                                                <td className="td">
                                                    {availableEvaluationFlag ? (
                                                        <span className="icon-check icon-filter-brand" />
                                                    ) : (
                                                        ''
                                                    )}
                                                </td>
                                                <td className="td">{t('Available profitability assessment')}</td>
                                                <td className="td">
                                                    <div className="pnt-table" style={{ height: 'auto' }}>
                                                        <table>
                                                            <tbody className="tbody">
                                                                <tr className="tr">
                                                                    <td className="td" colSpan={4}>
                                                                        <div
                                                                            className="d-grid p-2"
                                                                            style={{
                                                                                gridTemplateColumns: '1fr 1fr',
                                                                                rowGap: '10px',
                                                                            }}
                                                                        >
                                                                            <CustomLabel name="numOfMonth">
                                                                                <div className="pnt-input--group">
                                                                                    <input
                                                                                        step={0.1}
                                                                                        className="pnt-input"
                                                                                        type="number"
                                                                                        name="quantity"
                                                                                        value={
                                                                                            profitability1
                                                                                                ? profitability1
                                                                                                : 0
                                                                                        }
                                                                                        onChange={e => {
                                                                                            const { value } = e.target;
                                                                                            const num = value
                                                                                                ? parseFloat(
                                                                                                      value.replaceAll(
                                                                                                          ',',
                                                                                                          '',
                                                                                                      ),
                                                                                                  )
                                                                                                : 0;

                                                                                            setEvaluationInfo({
                                                                                                ...evaluationInfo,
                                                                                                profitability1: num,
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </CustomLabel>
                                                                            <CustomLabel name="avgPrice">
                                                                                <div className="pnt-input--group">
                                                                                    <input
                                                                                        className="pnt-input"
                                                                                        type="number"
                                                                                        step="0.01"
                                                                                        name="quantity"
                                                                                        value={
                                                                                            profitability2
                                                                                                ? profitability2.toString()
                                                                                                : 0
                                                                                        }
                                                                                        onChange={e => {
                                                                                            const { value } = e.target;
                                                                                            // 소수점 열째자리 실수
                                                                                            const regExp =
                                                                                                /^(\d{0,10}([.]\d{0,10})?)?$/;
                                                                                            if (regExp.test(value)) {
                                                                                                setEvaluationInfo({
                                                                                                    ...evaluationInfo,
                                                                                                    profitability2:
                                                                                                        parseFloat(
                                                                                                            value,
                                                                                                        ),
                                                                                                });
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </CustomLabel>
                                                                            <CustomLabel name="materialCost">
                                                                                <div className="pnt-input--group">
                                                                                    <input
                                                                                        className="pnt-input"
                                                                                        type="number"
                                                                                        name="quantity"
                                                                                        value={
                                                                                            evaluationInfo.profitability3
                                                                                                ? evaluationInfo.profitability3.toString()
                                                                                                : 0
                                                                                        }
                                                                                        onChange={e => {
                                                                                            const { value } = e.target;
                                                                                            setEvaluationInfo({
                                                                                                ...evaluationInfo,
                                                                                                profitability3:
                                                                                                    parseInt(value),
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </CustomLabel>
                                                                            <CustomLabel name="investmentCost">
                                                                                <div className="pnt-input--group">
                                                                                    <input
                                                                                        className="pnt-input"
                                                                                        type="number"
                                                                                        name="quantity"
                                                                                        value={
                                                                                            profitability4
                                                                                                ? profitability4.toString()
                                                                                                : 0
                                                                                        }
                                                                                        onChange={e => {
                                                                                            const { value } = e.target;
                                                                                            // 소수점 열째자리 실수
                                                                                            const regExp =
                                                                                                /^(\d{0,10}([.]\d{0,10})?)?$/;
                                                                                            if (regExp.test(value)) {
                                                                                                setEvaluationInfo({
                                                                                                    ...evaluationInfo,
                                                                                                    profitability4:
                                                                                                        parseFloat(
                                                                                                            value,
                                                                                                        ),
                                                                                                });
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </CustomLabel>
                                                                            <CustomLabel name="personnelExpense">
                                                                                <div className="pnt-input--group">
                                                                                    <input
                                                                                        className="pnt-input"
                                                                                        type="number"
                                                                                        name="quantity"
                                                                                        value={
                                                                                            profitability5
                                                                                                ? profitability5.toString()
                                                                                                : 0
                                                                                        }
                                                                                        onChange={e => {
                                                                                            const { value } = e.target;
                                                                                            // 소수점 열째자리 실수
                                                                                            const regExp =
                                                                                                /^(\d{0,10}([.]\d{0,10})?)?$/;
                                                                                            if (regExp.test(value)) {
                                                                                                setEvaluationInfo({
                                                                                                    ...evaluationInfo,
                                                                                                    profitability5:
                                                                                                        parseFloat(
                                                                                                            value,
                                                                                                        ),
                                                                                                });
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </CustomLabel>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr className="tr profitability-subTable-tr">
                                                                    <td className="td" style={{ width: '100px' }}>
                                                                        {t('Equipment amount')}
                                                                    </td>
                                                                    <td className="td">
                                                                        {numberWithCommas(evaluationInfo.adjustmentSum)}
                                                                    </td>
                                                                    <td className="td">{t('Gross income')}</td>
                                                                    <td className="td">
                                                                        {numberWithCommas(
                                                                            roundNumber(
                                                                                evaluationItemsScores.totalIncome,
                                                                                1,
                                                                            ),
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                                <tr className="tr profitability-subTable-tr">
                                                                    <td className="td">{t('Depreciation')}</td>
                                                                    <td className="td">
                                                                        {numberWithCommas(
                                                                            evaluationItemsScores.depreciation,
                                                                        )}
                                                                    </td>
                                                                    <td className="td">{t('Interest on capital')}</td>
                                                                    <td className="td">
                                                                        {numberWithCommas(
                                                                            evaluationItemsScores.interest,
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                                <tr className="tr profitability-subTable-tr">
                                                                    <td className="td">{t('Total cost')}</td>
                                                                    <td className="td">
                                                                        {numberWithCommas(
                                                                            evaluationItemsScores.totalCost,
                                                                        )}
                                                                    </td>
                                                                    <td className="td">{t('Gain and loss')}</td>
                                                                    <td className="td">
                                                                        {numberWithCommas(
                                                                            evaluationItemsScores.gainAndLoss,
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                                <tr
                                                                    className="tr profitability-subTable-tr"
                                                                    style={{ height: '150px' }}
                                                                >
                                                                    <td className={'td'}>
                                                                        {t('Profit and loss ratio in five years')}
                                                                    </td>
                                                                    <td className={'td'}>
                                                                        {evaluationItemsScores.gainAndLossRatio}
                                                                    </td>
                                                                    <td className={'td'} colSpan={2}>
                                                                        {t('Profitability sheet')}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className="tr" style={{ height: '100px' }}>
                                                <td className="td">
                                                    {profitabilityEcd === 'A020202' ? (
                                                        <span className="icon-check icon-filter-brand" />
                                                    ) : (
                                                        ''
                                                    )}
                                                </td>
                                                <td className="td">{t('Unevaluated profitability')}</td>
                                                <td className="td">
                                                    <div className={'d-flex flx-col'}>
                                                        <span>{t('Equipment unrelated to profitability')}</span>
                                                        <div>
                                                            {UNEVALUATED_PROFITABILITY_ARR.map(item => {
                                                                const { title, code } = item;
                                                                return (
                                                                    <CustomRadio
                                                                        key={code}
                                                                        text={t(title)}
                                                                        value={code}
                                                                        checked={
                                                                            profitabilityEcd === 'A020202' &&
                                                                            parseInt(profitabilityPcd) === code
                                                                        }
                                                                        handleChange={e => {
                                                                            const { checked } = e.currentTarget;
                                                                            let result = '';
                                                                            resetEvaluationItems();

                                                                            if (
                                                                                checked &&
                                                                                (!profitabilityPcd ||
                                                                                    parseInt(profitabilityPcd) !==
                                                                                        code ||
                                                                                    profitabilityEcd !== 'A020202')
                                                                            ) {
                                                                                result = code;
                                                                            }

                                                                            setEvaluationInfo({
                                                                                ...evaluationInfo,
                                                                                profitability1: 0,
                                                                                profitability2: 0,
                                                                                profitability3: 0,
                                                                                profitability4: 0,
                                                                                profitability5: 0,
                                                                                profitabilityPcd: result,
                                                                                profitabilityEcd: result
                                                                                    ? 'A020202'
                                                                                    : '',
                                                                            });
                                                                        }}
                                                                    />
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </td>
                            <td className={'td evaluation-score'}>{score}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Profitability;
