import React, { useRef, useState } from 'react';

import Card from '../../../Common/Card';
import Label from '../../../Common/Label';
import Button from '../../../Common/Button';
import useTranslation from '../../../../util/hooks/useTranslation';
import useConfirmModal from '../../../Common/ConfirmModal/useConfirmModal';

import useAsync from '../../../../util/hooks/useAsync';
import { createControlNumber } from '../../../../api/engineering';

/**
 * 수리 접수 페이지
 *
 * @author jinbeom-jung
 * */

const RepairReception = () => {
    const t = useTranslation('RegisteredRepairManagement');

    const inputRef = useRef(null);
    const initialState = { rfid: '', targetId: '' };
    const [useWhether, setUseWhether] = useState(false);
    const { promise: addControlNumber } = useAsync({
        promise: createControlNumber,
        resolve: () => {
            if (inputRef.current) {
                inputRef.current.focus();
            }
            setForm(initialState);
            setUseWhether(false);
            toggleSuccessModal();
        },
        reject: () => {
            if (useWhether) {
                toggleBusinessNumberFailModal();
            } else {
                toggleRfidFailModal();
            }
            setUseWhether(true);
        },
    });

    const [form, setForm] = useState(initialState);
    const handleForm = e => {
        const { value, name } = e.target;
        let validationValue = value;
        if (name === 'rfid') {
            validationValue = value.replace(/[^0-9]/g, '');
        }
        setForm({ ...form, [name]: validationValue });
    };

    const handleKeyDown = e => {
        const { rfid } = form;
        if (rfid.length === 10) {
            if (e.key === 'Enter') {
                addControlNumber({ rfid, type: 'RR' });
            }
        }
    };

    const handleInformationAdd = () => {
        const { rfid, targetId } = form;
        if (rfid.length === 10 && targetId) {
            addControlNumber({ targetId, rfid, type: 'RR' });
        } else {
            toggleWarningModal();
        }
    };

    const handleBusinessNumber = () => {
        addControlNumber({
            targetId: form.targetId,
            rfid: form.rfid,
            isRegisterTempTarget: 'Y',
            type: 'RR',
        });
    };

    //Rfid 모달
    const { Modal: RfidFailModal, toggleModal: toggleRfidFailModal } = useConfirmModal({
        initModal: false,
        header: { title: t('Confirm', 'ConfirmModal') },
        confirmText: t(
            'This is an unregistered card number. Please register by entering your employee number.',
            'ConfirmModal',
        ),
    });

    //사번 실패 모달
    const { Modal: BusinessNumberFailModal, toggleModal: toggleBusinessNumberFailModal } = useConfirmModal({
        initModal: false,
        header: { title: t('Confirm', 'ConfirmModal') },
        confirmText: t(
            'This is an unregistered user. Please check if the employee number is entered correctly. If you click OK, the application is completed with user registration.',
            'ConfirmModal',
        ),
        okCallback: handleBusinessNumber,
    });

    //경고 모달
    const { Modal: WarningModal, toggleModal: toggleWarningModal } = useConfirmModal({
        initModal: false,
        header: { title: t('Confirm', 'ConfirmModal') },
        confirmText: t('Please enter the target ID or RFID.', 'ConfirmModal'),
    });

    //성공 모달
    const { Modal: SuccessModal, toggleModal: toggleSuccessModal } = useConfirmModal({
        initModal: false,
        header: { title: t('Confirm', 'ConfirmModal') },
        confirmText: t('Registered normally', 'ConfirmModal'),
        autoClose: true,
        timeOut: 1000,
    });

    return (
        <>
            <Card className="single-card-container" header={{ title: t('Repair reception', 'Menu') }}>
                <div className="p-0 d-flex row pnt-label-7 mb-4">
                    <Label
                        labelValueClassName="label-dot color-brand "
                        name={t('Employee ID number')}
                        value={
                            <div className="pnt-input--group w-40 form-must">
                                <input
                                    type="text"
                                    className="pnt-input"
                                    name="rfid"
                                    value={form.rfid}
                                    onChange={handleForm}
                                    onKeyDown={handleKeyDown}
                                    maxLength="10"
                                    ref={inputRef}
                                />
                                <p>{t('Touch your employee ID to the reader.')}</p>
                            </div>
                        }
                    />
                </div>
                {!!useWhether && (
                    <div className="p-0 d-flex row pnt-label-7">
                        <Label
                            labelValueClassName="label-dot color-brand"
                            name={t('Pressure of business')}
                            value={
                                <>
                                    <div className="pnt-input--group w-40 form-must">
                                        <input
                                            type="text"
                                            className="pnt-input"
                                            name="targetId"
                                            value={form.targetId}
                                            onChange={handleForm}
                                            maxLength="10"
                                        />
                                        <p>사번을 직접 입력해주시고 등록버튼을 눌러주세요.</p>
                                    </div>
                                    <Button className="btn-brand" onClick={handleInformationAdd}>
                                        등록
                                    </Button>
                                </>
                            }
                        />
                    </div>
                )}
            </Card>
            <RfidFailModal />
            <BusinessNumberFailModal />
            <WarningModal />
            <SuccessModal />
        </>
    );
};

export default RepairReception;
