import React from 'react';

const CustomRadio = ({ id, name, text, handleClick, checked, handleChange, readOnly }) => {
    return (
        <label htmlFor={id} className="mr-2">
            <input
                readOnly={readOnly}
                type="radio"
                id={id}
                name={name}
                value={id}
                checked={checked}
                onClick={handleClick}
                onChange={handleChange}
            />
            {text}
        </label>
    );
};

export default CustomRadio;
