import React, { useState, useMemo, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import useTranslation from '../../../../../../util/hooks/useTranslation';
import cx from 'classnames';
import Button from '../../../../../Common/Button';
import CustomLabel from '../../../Components/CustomLabel';
import Checkbox from '../../../../../Common/Input/Checkbox';
import TextInput from '../../../../../Common/Input/TextInput';
import DatePicker from '../../../../../Common/DatePicker';
import moment from 'moment';
import useConfirmModal from '../../../../../Common/ConfirmModal/useConfirmModal';
import ValidationModal from '../../../../Components/Modal/ValidationModal';
import useAsync from '../../../../../../util/hooks/useAsync';
import {
    createAccountEmployeeRegist,
    fetchAccountEmployeeDetail,
    updateAccountEmployeeModify,
    deleteAccountEmployee,
} from '../../../../../../api/engineering';

const defaultInput = {
    searchDate: moment().valueOf(),
    managerName: '',
    employeeNumber: '',
    homeAppliancesCheckbox: false,
    hemodialysisCheckbox: false,
};

const ManagerModal = ({
    initModal = false,
    toggleModal = function () {
        initModal = !initModal;
    },
    okCallback,
    cancelCallback,
    callbackParam,
    register,
    okClassName,
    okButtonTitle,
    className,
    getAccountEmployeeList,
    trData,

    ...restProps
}) => {
    const t = useTranslation('ManagerModal');

    const [validationModal, setValidationModal] = useState(false);

    const [inputValues, setInputValues] = useState(defaultInput);

    const { searchDate, managerName, employeeNumber, homeAppliancesCheckbox, hemodialysisCheckbox } = inputValues;

    // API 연동
    const { promise: postAccountEmployeeRegist } = useAsync({
        promise: createAccountEmployeeRegist,
        resolve: res => {
            toggleRegisterModal();
            getAccountEmployeeList();
        },
        reject: err => {
            toggleRegisterFailModal();
        },
    });

    const { promise: getAccountEmployeeDetail } = useAsync({
        promise: fetchAccountEmployeeDetail,
        resolve: res => {
            const { bloodAppli, dateCode, empName, empNum, homeAppli } = res;
            setInputValues({
                searchDate: moment(dateCode, 'YYYY-MM').unix() * 1000,
                managerName: empName,
                employeeNumber: empNum,
                homeAppliancesCheckbox: homeAppli === 'Y',
                hemodialysisCheckbox: bloodAppli === 'Y',
            });
        },
    });

    const { promise: patchAccountEmployeeModify } = useAsync({
        promise: updateAccountEmployeeModify,
        resolve: res => {
            toggleModificationModal();
            getAccountEmployeeList();
        },
    });

    const { promise: deleteEmployee } = useAsync({
        promise: deleteAccountEmployee,
        resolve: res => {
            toggleDeleteCompleteModal();
            getAccountEmployeeList();
        },
    });

    useEffect(() => {
        if (initModal && !register && trData) {
            const { dateCode, empNum } = trData;
            getAccountEmployeeDetail({
                dateCode,
                empNum,
            });
        }
    }, [initModal, register, trData]);

    const handleChangeDate = select => {
        setInputValues(prevState => ({
            ...prevState,
            searchDate: select,
        }));
    };

    const handleInput = ({ target: { name, value } }) => {
        if (name === 'employeeNumber' && value.length <= 20) {
            setInputValues(prevState => ({
                ...prevState,
                [name]: value,
            }));
        } else if (name === 'managerName' && value.length <= 10) {
            setInputValues(prevState => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    const handleCheckbox = ({ target: { name } }) => {
        setInputValues(prevState => ({
            ...prevState,
            [name]: !prevState[name],
        }));
    };

    const handleValidationModal = () => {
        setValidationModal(!validationModal);
    };

    const { toggleModal: toggleDeleteModal, Modal: DeleteModal } = useConfirmModal({
        header: { title: t('Delete Manager') },
        confirmText: t('Are you sure you want to delete the manager?'),
        okCallback: () => {
            deleteEmployee({
                dateCode: moment(searchDate).format('YYYY-MM'),
                empNum: employeeNumber,
            });
            toggleModal();
        },
    });

    const { toggleModal: toggleRegisterModal, Modal: RegisterModal } = useConfirmModal({
        header: { title: register ? t('Save', 'Button') : t('Modify', 'ConfirmModal') },
        confirmText: register ? t('Manager registration is complete.') : t('Manager has been modified'),
        removeCancel: true,
    });

    const { toggleModal: toggleRegisterFailModal, Modal: RegisterFailModal } = useConfirmModal({
        header: { title: t('Registration failed') },
        confirmText: t('This person is already registered.'),
        removeCancel: true,
    });

    const { toggleModal: toggleDeleteCompleteModal, Modal: DeleteCompleteModal } = useConfirmModal({
        header: { title: t('Delete complete') },
        confirmText: t('The person in charge has been deleted.'),
        removeCancel: true,
    });

    const { toggleModal: toggleModificationModal, Modal: ModificationModal } = useConfirmModal({
        header: { title: t('Modification complete') },
        confirmText: t('The person in charge has been modified.'),
        removeCancel: true,
    });

    const validationText = useMemo(() => {
        const validationText = [];
        if (!managerName || !employeeNumber) {
            if (!managerName) {
                validationText.push('Manager name is required.');
            }

            if (!employeeNumber) {
                validationText.push('Manager employee number is required.');
            }
        }
        return validationText;
    }, [managerName, employeeNumber]);

    useEffect(() => {
        if (initModal === false) {
            setInputValues(defaultInput);
        }
    }, [initModal]);

    return (
        <>
            <Modal
                container={document.getElementsByClassName('app-container')[0]}
                className={cx('pnt-modal', className)}
                isOpen={initModal}
                toggle={toggleModal}
                {...restProps}
            >
                <ModalHeader toggle={toggleModal}>
                    {`${t('Manager')} ${register ? t('Registration') : t('Modifying and deleting')}`}
                </ModalHeader>
                <ModalBody>
                    <CustomLabel
                        name={t('Year and Month in Managing')}
                        required
                        labelValueClassName={'w-px-125'}
                        value={
                            <>
                                <div className="w-70">
                                    <DatePicker
                                        value={searchDate}
                                        valueType={'ms'}
                                        dateFormat={`Y / MMM`}
                                        maxDate={new Date()}
                                        handleChange={handleChangeDate}
                                        showMonthYearPicker
                                        showFullMonthYearPicker
                                        showTwoColumnMonthYearPicker
                                        withoutDay
                                        shouldCloseOnSelect
                                        readOnly={!register}
                                    />
                                </div>
                            </>
                        }
                    />
                    <CustomLabel
                        name={t('Manager Name')}
                        required
                        labelValueClassName={'w-px-125'}
                        value={
                            <TextInput
                                name={'managerName'}
                                value={managerName}
                                handleChange={handleInput}
                                placeholder={t('Manager Name')}
                                inputGroupClassName={cx('w-70', !register && 'form-disable')}
                            />
                        }
                    />
                    <CustomLabel
                        name={t('Manager Employee Number')}
                        required
                        labelValueClassName={'w-px-125'}
                        value={
                            <TextInput
                                name={'employeeNumber'}
                                value={employeeNumber}
                                inputGroupClassName={cx('w-70', !register && 'form-disable')}
                                placeholder={t('Manager Employee Number')}
                                handleChange={handleInput}
                            />
                        }
                    />
                    <CustomLabel
                        name={t('Responsible for dialysis room')}
                        required
                        labelValueClassName={'w-px-125'}
                        value={
                            <div className={'w-70 d-flex align-items-center'}>
                                <Checkbox
                                    name={t('Responsible for hemodialysis room')}
                                    inputName={'hemodialysisCheckbox'}
                                    handleChecked={handleCheckbox}
                                    checked={hemodialysisCheckbox}
                                />
                            </div>
                        }
                    />
                    <CustomLabel
                        name={t('Whether Responsible for home appliances')}
                        required
                        labelValueClassName={'w-px-125'}
                        value={
                            <div className={'w-70 d-flex align-items-center'}>
                                <Checkbox
                                    name={t('Responsible for home appliances')}
                                    inputName={'homeAppliancesCheckbox'}
                                    handleChecked={handleCheckbox}
                                    checked={homeAppliancesCheckbox}
                                />
                            </div>
                        }
                    />
                </ModalBody>
                <ModalFooter className={'d-flex justify-content-between'}>
                    {register ? (
                        <div />
                    ) : (
                        <Button className={'btn-danger'} onClick={toggleDeleteModal}>
                            {t('Delete Manager')}
                        </Button>
                    )}
                    <div>
                        <Button
                            className={'color-secondary'}
                            onClick={e => {
                                if (typeof cancelCallback === 'function') {
                                    cancelCallback(callbackParam);
                                }
                                toggleModal();
                            }}
                        >
                            {t('Cancel', 'ConfirmModal')}
                        </Button>

                        <Button
                            className={cx(okClassName, 'btn-secondary')}
                            onClick={e => {
                                if (typeof okCallback === 'function') {
                                    okCallback(callbackParam);
                                }
                                if (!managerName || !employeeNumber) {
                                    handleValidationModal();
                                    return;
                                }
                                if (register) {
                                    postAccountEmployeeRegist({
                                        bloodAppli: hemodialysisCheckbox ? 'Y' : 'N',
                                        dateCode: moment(searchDate).format('YYYY-MM'),
                                        empName: managerName,
                                        empNum: employeeNumber,
                                        homeAppli: homeAppliancesCheckbox ? 'Y' : 'N',
                                    });
                                } else {
                                    patchAccountEmployeeModify({
                                        bloodAppli: hemodialysisCheckbox ? 'Y' : 'N',
                                        dateCode: moment(searchDate).format('YYYY-MM'),
                                        empNum: employeeNumber,
                                        homeAppli: homeAppliancesCheckbox ? 'Y' : 'N',
                                    });
                                }
                                toggleModal();
                            }}
                        >
                            {register ? t('Save', 'Button') : t('Modify', 'ConfirmModal')}
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
            <DeleteModal />
            <ValidationModal
                initModal={validationModal}
                toggleModal={handleValidationModal}
                validationText={validationText}
            />
            <RegisterModal />
            <RegisterFailModal />
            <DeleteCompleteModal />
            <ModificationModal />
        </>
    );
};

export default ManagerModal;
