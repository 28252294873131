import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import useTranslation from '../../../../util/hooks/useTranslation';
import styled from 'styled-components';
import audio2 from '../../../../assets/audios/alarm_sound2.mp3';
import audio3 from '../../../../assets/audios/alarm_sound3.mp3';
import cx from 'classnames';
import { useSelector } from 'react-redux';

const CustomModalBody = styled(ModalBody)`
    min-height: 90px;
`;

const AlertModal = ({
    initModal = false,
    toggleModal = function () {
        initModal = !initModal;
    },
    modalType = { title: '', body: '', type: '' },
    modalBody = {
        totalCount: 0,
        targetId: '',
        targetName: '',
        groupName: '',
        userName: '',
        regDate: '',
        targetImage: '',
    },
    okClassName,
    className,
    ...restProps
}) => {
    const t = useTranslation('AlertModal');
    const { fullScreen } = useSelector(State => State.DashboardFrame);
    const { title, body, type } = modalType;
    const { totalCount, targetId, targetName, groupName, userName, regDate } = modalBody;

    return (
        <Modal
            container={fullScreen ? '.app-main__inner' : document.getElementsByClassName('app-container')[0]}
            className={cx('pnt-modal text-ellipsis', className)}
            isOpen={initModal}
            toggle={toggleModal}
            size="lg"
            {...restProps}
        >
            <ModalHeader toggle={toggleModal}>{`${title} ${t('Complete')}`}</ModalHeader>
            <CustomModalBody>
                <div className="pnt-label--group pb-2">
                    <div className="label-main label-dot mr-2 custom-font">{`${title}${t('Asset')}`}</div>
                    <div className="font-weight-bold custom-font">{`${t('Total')} ${totalCount}${t('EA')}`}</div>
                </div>
                <div className="d-flex mt-1 opacity-5">
                    <div className="icon-view mr-2" />
                    <div>
                        {t('If the number of assets registered does not match, please contact to administrator.')}
                    </div>
                </div>
                <div className="d-flex flex-row mt-4">
                    {/*<div className="flex2 mr-3">*/}
                    {/*    {targetImage ? (*/}
                    {/*        <img className="w-100" src={targetImage} onError={handleImgErr} alt="product_image" />*/}
                    {/*    ) : (*/}
                    {/*        <img src={no_image} className="w-100" alt="product_image" />*/}
                    {/*    )}*/}
                    {/*</div>*/}
                    <div className="flex flex3 d-flex flex-column">
                        <div className="pnt-label--group pb-5">
                            <div className="label-main label-dot custom-font">{t('Equipment Number')}</div>
                            <div className="label-options custom-font">{targetId}</div>
                            <div className="label-main label-dot custom-font">{t('Equipment Name')}</div>
                            <div className="label-options custom-font">{targetName}</div>
                        </div>
                        {/*<div className="pnt-label--group pb-2 flex1">*/}
                        {/*    <div className="label-main label-dot">{t('Equipment Name')}</div>*/}
                        {/*    <div className="label-options">{targetName}</div>*/}
                        {/*</div>*/}
                        <div className="pnt-label--group pb-5 ">
                            <div className="label-main label-dot custom-font">{`${body} ${t('Team Name')}`}</div>
                            <div className="label-options custom-font">{groupName}</div>
                            <div className="label-main label-dot custom-font">{`${body} ${t('Name')}`}</div>
                            <div className="label-options custom-font">{userName}</div>
                        </div>
                        {/*<div className="pnt-label--group pb-2 flex1">*/}
                        {/*    <div className="label-main label-dot">{`${body} ${t('Name')}`}</div>*/}
                        {/*    <div className="label-options">{userName}</div>*/}
                        {/*</div>*/}
                        <div className="pnt-label--group pb-2 flex1">
                            <div className="label-main label-dot custom-font">{`${title} ${t('Date')}`}</div>
                            <div className="label-options custom-font">{regDate}</div>
                        </div>
                    </div>
                </div>
            </CustomModalBody>
            {type === 'Registered' ? <audio src={audio2} autoPlay /> : <audio src={audio3} autoPlay />}
        </Modal>
    );
};

export default AlertModal;
