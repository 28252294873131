import React, { useEffect, useState } from 'react';
import ConfirmModal from './index';

const useConfirmModal = ({ initModal,autoClose,timeOut, ...modalProps }) => {

    const [open, setOpen] = useState(initModal);
    const toggleModal = () => {
        setOpen(!open);
    }

    // modal 자동 close 로직
    useEffect(()=>{
        if(open && autoClose){
            setTimeout(()=>{
                toggleModal()
            },timeOut)
        }
    },[open])

    return {
        toggleModal: toggleModal,
        Modal: () => <ConfirmModal initModal={open} toggleModal={toggleModal} {...modalProps} />,
    };
};

export default useConfirmModal;
