import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import { useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';

import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import HeaderLogo from '../AppLogo';

import UserBox from './Components/UserBox';
import HeaderDots from './Components/HeaderDots';
import SearchDots from './Components/SearchDots';
import styled from 'styled-components';
import { CustomNav, SettingsNav } from '../AppNav/NavItems';
import useTranslation from '../../../util/hooks/useTranslation';

const initialGnbMenu = { menuName: '', subMenuName: '' };

const Header = () => {
    const t = useTranslation('Menu');
    const location = useLocation();
    const match = useRouteMatch(['/dashboards/:menuNum([0-9]+)', '/dashboards/edit/:menuNum([0-9]+)']);
    const filterMatch = useRouteMatch(['/filter/:menuNum([0-9]+)', '/filter/create']);
    const menuRightsMatch = useRouteMatch('/setting/menu');
    const [gnbMenu, setGnbMenu] = useState(initialGnbMenu);
    const { headerBackgroundColor, enableMobileMenuSmall, enableHeaderShadow } = useSelector(
        state => state.ThemeOptions,
    );
    const { bookmarkedMenu } = useSelector(state => state.CustomMenu);

    useEffect(() => {
        let pathname = location.pathname;
        if (pathname.includes('/dashboards/edit/')) {
            pathname = pathname.replace('/edit', '');
        }
        if (pathname.includes('/filter/create')) {
            setGnbMenu({
                menuName: 'Filters',
                subMenuName: 'Filter Create',
            });
        } else if (pathname.includes('/asset-rental/request')) {
            setGnbMenu({
                menuName: 'Asset Rental Management',
                subMenuName: 'Asset Rental Request',
            });
        } else if (pathname.includes('/asset-rental/process')) {
            setGnbMenu({
                menuName: 'Asset Rental Management',
                subMenuName: 'Asset Rental Process',
            });
        } else if (pathname.includes('/assets/detail')) {
            setGnbMenu({
                menuName: 'Assets',
                subMenuName: 'Assets Detail',
            });
        } else if (pathname.includes('/status/flow-scheme')) {
            setGnbMenu({
                menuName: 'Status',
                subMenuName: 'Status Flow Scheme',
            });
        } else if (pathname.includes('/status/allocate')) {
            setGnbMenu({
                menuName: 'Status',
                subMenuName: 'Status (De)allocate Assets',
            });
        } else if (pathname.includes('/status/flow-scheme/create')) {
            setGnbMenu({
                menuName: 'Status',
                subMenuName: 'Status Flow Scheme Create',
            });
        } else if (pathname.includes('/status/scheme')) {
            setGnbMenu({
                menuName: 'Status',
                subMenuName: 'Status Scheme',
            });
        } else if (pathname.includes('/asset-inspection/detail')) {
            setGnbMenu({
                menuName: 'Asset Inspection Management',
                subMenuName: 'Asset Inspection Detail',
            });
        } else if (pathname.includes('/asset-access/register-manage/repair')) {
            setGnbMenu({
                menuName: 'Asset Access System',
                subMenuName: 'Registered Repair Registration',
            });
        } else if (pathname.includes('/asset-access/register-manage')) {
            setGnbMenu({
                menuName: 'Asset Access System',
                //subMenuName: 'Registered Repair Management',
            });
        } else if (pathname.includes('/asset-access/transfer-manage')) {
            setGnbMenu({
                menuName: 'Asset Access System',
                subMenuName: 'Transferred Repair Management',
            });
        } else if (pathname.includes('/medical-engineering/evaluation-registration')) {
            setGnbMenu({
                menuName: 'Equipment evaluation',
                subMenuName: 'Evaluation registration',
            });
        } else if (pathname.includes('/medical-engineering/edit-evaluation')) {
            setGnbMenu({
                menuName: 'Equipment evaluation',
                subMenuName: 'Evaluation modification',
            });
        } else {
            if (filterMatch) {
                setGnbMenu({
                    menuName: 'Filters',
                    subMenuName: 'Filter Detail',
                });
            } else if (menuRightsMatch) {
                setGnbMenu({
                    menuName: 'Settings',
                    subMenuName: SettingsNav.find(v => v.id === 'menuRights').label,
                });
            } else {
                let foundGnbMenu = null;
                if (match && match.params.menuNum) {
                    const menuNum = Number(match.params.menuNum);
                    bookmarkedMenu.forEach(menu => {
                        if (menu.menuNum === menuNum) {
                            foundGnbMenu = menu;
                            setGnbMenu({
                                menuName: 'Dashboards',
                                subMenuName: menu.menuName,
                                isCustom: true,
                            });
                            return false;
                        }
                    });
                } else {
                    CustomNav.forEach(menu => {
                        if (menu.content) {
                            const subMenu = menu.content;
                            const foundMenu = subMenu.find(menu => menu.to === `#${pathname}`);
                            if (foundMenu) {
                                foundGnbMenu = foundMenu;
                                setGnbMenu({
                                    menuName: menu.label,
                                    subMenuName: foundMenu.label,
                                    isCustom:
                                        foundMenu.id &&
                                        (foundMenu.id.includes('customDashboard_') ||
                                            foundMenu.id.includes('filterMenu_')),
                                });
                                return false;
                            }
                        }
                    });
                }
                if (!foundGnbMenu) {
                    setGnbMenu(initialGnbMenu);
                }
            }
        }
    }, [location, CustomNav, bookmarkedMenu]);

    return (
        <ReactCSSTransitionGroup
            component="div"
            className={cx('app-header', headerBackgroundColor, { 'header-shadow': enableHeaderShadow })}
            transitionName="HeaderAnimation"
            transitionAppear={true}
            transitionAppearTimeout={1500}
            transitionEnter={false}
            transitionLeave={false}
        >
            <HeaderLogo />
            <div className={cx('app-header__content', { 'header-mobile-open': enableMobileMenuSmall })}>
                <div className="app-header-left">
                    {/*<SearchBox />*/}
                    {/*<MegaMenu />*/}
                    <span className={'menu-sub exclude-font-theme'}>
                        {gnbMenu.menuName ? `${t(gnbMenu.menuName)}` : ''}
                    </span>
                    {gnbMenu.menuName ? <span className="icon-next icon-small" /> : ''}
                    <span className={'menu-main exclude-font-theme'}>
                        {gnbMenu.subMenuName && (gnbMenu.isCustom ? gnbMenu.subMenuName : t(gnbMenu.subMenuName))}
                    </span>
                </div>
                <div className="app-header-right">
                    <SearchDots />
                    <HeaderDots />
                    <UserBox />
                    {/*<HeaderRightDrawer />*/}
                </div>
            </div>
        </ReactCSSTransitionGroup>
    );
};

export default Header;
