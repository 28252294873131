import React, { useState, useEffect, useRef } from 'react';
import AccessSearch from '../Components/AccessSearch';
import Card from '../../../Common/Card';
import useTranslation from '../../../../util/hooks/useTranslation';
import Button from '../../../Common/Button';
import Table from '../../../Common/Table';
import ConfirmModal from '../../../Common/ConfirmModal';
import { useHistory } from 'react-router-dom';
import useAsync from '../../../../util/hooks/useAsync';
import {
    getRepairTakeoverListApi,
    updateCancelRepairTakeoverApi,
    fetchRepairTakeoverExportApi,
} from '../../../../api/asset';
import { fetchExcelLogApi } from '../../../../api/log';
import moment from 'moment';
import useConfirmModal from '../../../Common/ConfirmModal/useConfirmModal';

const INTERVAL_TIME = 1000;

const conditionOptions = [
    { value: '', label: 'All' },
    { value: 'userName', label: 'Transferee' },
    // { value: 'groupName', label: 'Affiliation' },
    { value: 'targetId', label: 'Asset Number' },
    { value: 'targetName', label: 'Product Name' },
];
const registerOptions = [
    { value: 'REPAIR_COMPLETE', label: 'REPAIR_COMPLETE' },
    { value: 'TAKEOVER_COMPLETE', label: 'TAKEOVER_COMPLETE' },
    { value: 'TAKEOVER_WAIT', label:'TAKEOVER_WAIT' },
    { value: 'TAKEOVER_BEFORE', label: 'TAKEOVER_BEFORE' },
];

const falsyToHyphen = ({ value }) => {
    return value ? value : '-';
};

const TransferredRepairManagement = () => {
    const t = useTranslation('TransferRepairManagement');
    const columnsT = useTranslation('RepairColumns');
    const history = useHistory();

    const excelTimerRef = useRef();

    const [tableData, setTableData] = useState({ rows: [], pageSize: 20 });
    const [tableKey, setTableKey] = useState(0);

    const [deleteModal, setDeleteModal] = useState(false);
    const [downloadModal, setDownloadModal] = useState(false);
    const [cancelReceptionCheckModal, setCancelReceptionCheckModal] = useState(false);
    const [cancelReceptionCompleteModal, setCancelReceptionCompleteModal] = useState(false);

    const [searchCondition, setSearchCondition] = useState({});

    const [selectedReceptionNum, setSelectedReceptionNum] = useState(null);

    const { promise: getRepairTakeoverList, state: repairTakeoverListState } = useAsync({
        promise: getRepairTakeoverListApi,
        fixedParam: { pageSize: 20 },
        resolve: res => {
            setTableData(prevState => {
                return { ...prevState, ...res };
            });
        },
    });

    useEffect(() => {
        if (searchCondition.startDate) {
            getRepairTakeoverList(searchCondition);
            setTableKey(tableKey + 1);
        }
    }, [searchCondition]);

    const { promise: updateCancelRepairTakeover } = useAsync({
        promise: updateCancelRepairTakeoverApi,
        resolve: res => {
            handleCancelReceptionCompleteModal();
            getRepairTakeoverList(searchCondition);
        },
    });

    const { promise: fetchExcelLog } = useAsync({
        promise: fetchExcelLogApi,
        resolve: res => {
            if (res.dataSettingStatusType === 'COMPLETED') {
                clearExcelTimer();
                toggleExcelModal();

                const elem = window.document.createElement('a');
                elem.href = res.filePath;
                elem.download = res.fileName;
                document.body.appendChild(elem);
                elem.click();
                document.body.removeChild(elem);
            } else if (res.dataSettingStatusType === 'FAILED') {
                clearExcelTimer();
            }
        },
    });

    const { promise: createNewTakeoverExport } = useAsync({
        promise: fetchRepairTakeoverExportApi,
        fixedParam: {
            zipFileName: 'Transferred_Repair_Log',
            fileName: 'Transferred_Repair_Log',
            columnMetas: [
                {
                    key: 'userName',
                    name: columnsT('Transferee'),
                },
                {
                    key: 'groupName',
                    name: columnsT('Affiliation'),
                },
                {
                    key: 'regDateStr',
                    name: columnsT('Transferred Date'),
                },
                {
                    key: 'targetId',
                    name: columnsT('Asset Number'),
                },
                {
                    key: 'targetName',
                    name: columnsT('Product Name'),
                },
                {
                    key: 'statusType',
                    name: columnsT('Status'),
                },
            ],
        },
        resolve: res => {
            const { filePath: url, reqNum } = res;

            if (url) {
                downloadWithURL(url, reqNum);
            }
        },
    });

    const downloadWithURL = (url, reqNum) => {
        excelTimerRef.current = setInterval(() => {
            fetchExcelLog({ reqNum: reqNum });
        }, INTERVAL_TIME);
    };

    const clearExcelTimer = () => {
        clearInterval(excelTimerRef.current);
        excelTimerRef.current = null;
    };

    const handleExportFileClick = () => {
        createNewTakeoverExport(searchCondition);
    };

    const columns = [
        {
            Header: columnsT('Transferee'),
            accessor: 'userName',
            className: 'text-ellipsis',
            Cell: falsyToHyphen,
        },
        {
            Header: columnsT('Affiliation'),
            accessor: 'groupName',
            className: 'text-ellipsis',
            Cell: falsyToHyphen,
        },
        {
            Header: columnsT('Transferred Date'),
            accessor: 'regDate',
            className: 'text-ellipsis',
            Cell: ({ value }) => {
                const date = moment.unix(value).format('YYYY-MM-DD HH:mm:ss');
                return <>{date}</>;
            },
        },
        {
            Header: columnsT('Asset Number'),
            accessor: 'targetId',
            className: 'text-ellipsis',
        },
        {
            Header: columnsT('Product Name'),
            accessor: 'targetName',
            className: 'text-ellipsis',
        },
        {
            Header: columnsT('Status'),
            accessor: 'statusType',
            width: 270,
            Cell: ({ value, row: { original } }) => {
                const receptionNum = original.receptionNum;
                const button = ['TAKEOVER_COMPLETE', 'TAKEOVER_BEFORE'].includes(value);
                return (
                    <>
                        {t(value, 'Asset')}
                        {button && (
                            <button
                                className="table-btn ml-1 mb-1"
                                onClick={e => handleClickCancelRepairTakeOver(e, receptionNum)}
                            >
                                {t('Cancel Transfer')}
                            </button>
                        )}
                    </>
                );
            },
        },
    ];

    const handleDeleteModal = () => {
        setDeleteModal(!deleteModal);
    };

    const handleDownloadModal = () => {
        setDownloadModal(!downloadModal);
    };

    const handleCancelReceptionCheckModal = () => {
        setCancelReceptionCheckModal(!cancelReceptionCheckModal);
    };

    const handleCancelReceptionCompleteModal = () => {
        setCancelReceptionCompleteModal(!cancelReceptionCompleteModal);
    };

    const { toggleModal: toggleExcelModal, Modal: ExcelModal } = useConfirmModal({
        initModal: false,
        header: {
            title: t('Excel Download', 'Excel Download'),
        },
        confirmText: t('Excel Download Completed', 'Excel Download'),
        removeCancel: true,
    });

    const handleClickCancelRepairTakeOver = (e, receptionNum) => {
        e.stopPropagation();
        handleCancelReceptionCheckModal();
        setSelectedReceptionNum(receptionNum);
    };

    const handleOkCancelReceptionCheck = () => {
        updateCancelRepairTakeover({
            receptionNum: selectedReceptionNum,
        });
    };

    const handlePageChange = pageIndex => {
        if (searchCondition.startDate) {
            getRepairTakeoverList({ page: pageIndex, ...searchCondition });
        }
    };

    return (
        <>
            <div className="grid-layout-container">
                <AccessSearch
                    conditionOptions={conditionOptions}
                    registerOptions={registerOptions}
                    title={'Transfer Status'}
                    setSearchCondition={setSearchCondition}
                />

                <Card
                    header={{
                        title: t('Transferred Repair Management', 'Menu'),
                        action: (
                            <>
                                <Button className="btn-brand" onClick={handleDownloadModal}>
                                    {t('Download', 'Button')}
                                </Button>
                            </>
                        ),
                    }}
                >
                    <div className="table-container">
                        <Table
                            key={tableKey}
                            textAlign={'center'}
                            columns={columns}
                            loading={repairTakeoverListState.isLoading}
                            data={{ ...tableData, pageSize: 20 }}
                            onTrClick={e => {
                                history.push(`/asset-access/transfer-manage/${e.receptionNum}`);
                            }}
                            onPageChange={handlePageChange}
                        />
                    </div>
                </Card>
            </div>
            <ConfirmModal
                initModal={deleteModal}
                toggleModal={handleDeleteModal}
                header={{ title: t('Delete', 'Button') }}
                confirmText={
                    <div>
                        <div>{t('Asset and transfer information will be deleted.')}</div>
                        <div>{t('Are you sure you want to delete it?')}</div>
                    </div>
                }
            />
            <ConfirmModal
                header={{ title: t('Cancel Transfer', 'Transfer Detail') }}
                initModal={cancelReceptionCheckModal}
                toggleModal={handleCancelReceptionCheckModal}
                confirmText={
                    <div>
                        <div>{t('The asset will be changed to a repaired status.', 'Transfer Detail')}</div>
                        <div>{t('Would you like to cancel the transfer?', 'Transfer Detail')}</div>
                    </div>
                }
                okCallback={handleOkCancelReceptionCheck}
            />
            <ConfirmModal
                header={{ title: t('Notification') }}
                initModal={cancelReceptionCompleteModal}
                toggleModal={handleCancelReceptionCompleteModal}
                confirmText={t('Cancel Register has been Completed.')}
            />
            <ConfirmModal
                initModal={downloadModal}
                toggleModal={handleDownloadModal}
                header={{ title: t('Download', 'Button') }}
                confirmText={t('Do you want to download the excel?')}
                okCallback={handleExportFileClick}
            />
            <ExcelModal />
        </>
    );
};

export default TransferredRepairManagement;
