import React, { useEffect, useMemo, useState } from 'react';
import ReactSelect from '../../../../Common/Select';
import SearchableSelect from '../../../../Common/Select/SearchableSelect';
import useTranslation from '../../../../../util/hooks/useTranslation';
import DatePicker from '../../../../Common/DatePicker';
import moment from 'moment';
import {
    FilterList,
    InputGroup,
    InputWrap,
    SearchWrap,
    SelectGroup,
} from '../../../../Common/FilterSearchGroup/Components/Part';
import FilterSearchGroup from '../../../../Common/FilterSearchGroup';
import SearchGroup from '../../../../Common/FilterSearchGroup/Components/SearchGroup';
import Button from '../../../../Common/Button';
import TextInput from '../../../../Common/Input/TextInput';

const AccessSearch = ({ conditionOptions = [], registerOptions = [], title, setSearchCondition = () => {} }) => {
    const t = useTranslation('AccessSearch');

    const statusOptions = useMemo(() => {
        return registerOptions.map(({ value, label }) => {
            return {
                value: value,
                label: t(label, 'Asset'),
            };
        });
    }, [registerOptions, t]);

    const searchOptions = useMemo(() => {
        return conditionOptions.map(({ value, label }) => {
            return {
                value: value,
                label: t(label),
            };
        });
    }, [conditionOptions, t]);

    const [startDate, setStartDate] = useState(moment().startOf('day').valueOf());
    const [endDate, setEndDate] = useState(moment().endOf('day').valueOf());
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedOption, setSelectedOption] = useState(searchOptions[0]);
    const [keyword, setKeyword] = useState('');

    useEffect(() => {
        if (selectedOption) {
            setSelectedOption(searchOptions.find(option => option.value === selectedOption.value));
        }
    }, [searchOptions]);

    useEffect(() => {
        if (selectedStatus) {
            setSelectedStatus(statusOptions.find(option => option.value === selectedStatus.value));
        }
    }, [statusOptions]);

    useEffect(() => {
        if (!selectedOption.value) {
            setKeyword('');
        }
    }, [selectedOption.value]);

    const handleAccessSelect = e => {
        setSelectedOption(e);
    };

    const handleSelectStatus = selected => {
        setSelectedStatus(selected.map(status => status.value));
    };

    const handleInputChange = e => {
        setKeyword(e.target.value);
    };

    const allStatusTypes = useMemo(() => {
        return registerOptions.map(option => option.value).join(',');
    }, []);

    useEffect(() => {
        setSearchCondition({
            startDate: moment(startDate).unix(),
            endDate: moment(endDate).unix(),
            opt: undefined,
            statusTypes: allStatusTypes,
            keyword,
        });
    }, []);

    const handleSearchClick = () => {
        setSearchCondition({
            startDate: moment(startDate).unix(),
            endDate: moment(endDate).unix(),
            opt: selectedOption.value ? selectedOption.value : undefined,
            statusTypes:
                selectedStatus === null || !selectedStatus.length
                    ? allStatusTypes
                    : selectedStatus
                          .reduce((acc, cur) => {
                              return `${acc},${cur}`;
                          }, '')
                          .slice(1),
            keyword,
        });
    };

    // const handleRefreshBtnClick = () => {
    //     const startDate = moment().startOf('day').valueOf();
    //     const endDate = moment().endOf('day').valueOf();
    //     const statusTypes = allStatusTypes;
    //     const keyword = '';
    //
    //     setStartDate(startDate);
    //     setEndDate(endDate);
    //     setSelectedStatus(statusTypes);
    //     setSelectedOption(searchOptions[0]);
    //     setKeyword(keyword);
    //     setSearchCondition({
    //         startDate: moment(startDate).unix(),
    //         endDate: moment(endDate).unix(),
    //         opt: undefined,
    //         statusTypes,
    //         keyword,
    //     });
    // };

    // const { toggleModal: toggleRefreshModal, Modal: RefreshModal } = useConfirmModal({
    //     confirmText: t('Do you want to refresh?', 'Search'),
    //     okCallback: () => {
    //         handleRefreshBtnClick();
    //     },
    // });

    return (
        <>
            <FilterSearchGroup>
                <SearchGroup>
                    {/*<FilterGroup className="bg-secondary">*/}
                    <FilterList>
                        <SelectGroup>
                            <SearchableSelect
                                data={statusOptions}
                                selected={selectedStatus}
                                onChange={handleSelectStatus}
                                title="Status"
                            />
                        </SelectGroup>
                        <SelectGroup>
                            <DatePicker
                                value={startDate}
                                valueType="ms"
                                handleChange={selected => setStartDate(selected)}
                                withoutTime
                                showYearDropdown
                                maxDate={new Date()}
                                dateFormatCalendar="MMMM"
                            />
                        </SelectGroup>
                        <div className="text-center"> ~ </div>
                        <SelectGroup>
                            <DatePicker
                                value={endDate}
                                valueType="ms"
                                handleChange={selected => setEndDate(selected)}
                                withoutTime
                                showYearDropdown
                                minDate={startDate}
                                maxDate={new Date()}
                                dateFormatCalendar="MMMM"
                            />
                        </SelectGroup>
                    </FilterList>
                    {/*<FilterButtonArea>*/}
                    {/*    <Button*/}
                    {/*        className="btn-danger btn-icon-only"*/}
                    {/*        iconClassName="icon-refresh"*/}
                    {/*        onClick={toggleRefreshModal}*/}
                    {/*    />*/}
                    {/*</FilterButtonArea>*/}
                    {/*</FilterGroup>*/}

                    <SearchWrap>
                        <SelectGroup>
                            <ReactSelect
                                name="condition"
                                onChange={handleAccessSelect}
                                value={selectedOption}
                                options={searchOptions}
                                customControlStyles={{
                                    width: '100%',
                                }}
                                customMenuStyles={{ width: '100%' }}
                                customOptionStyles={{ width: '100%' }}
                            />
                        </SelectGroup>
                        <InputWrap>
                            <InputGroup>
                                {selectedOption.value ? (
                                    <TextInput
                                        type="text"
                                        name="textInput"
                                        value={keyword}
                                        placeholder={t('Contents of inquiry')}
                                        handleChange={handleInputChange}
                                    />
                                ) : (
                                    <TextInput
                                        type="text"
                                        name="input"
                                        placeholder={t('Select Condition')}
                                        value={''}
                                        disabled
                                    />
                                )}
                            </InputGroup>
                            <Button
                                className="btn-brand btn-icon"
                                iconClassName="icon-search"
                                onClick={handleSearchClick}
                            >
                                {t('Search', 'Search')}
                            </Button>
                        </InputWrap>
                    </SearchWrap>
                </SearchGroup>
            </FilterSearchGroup>
            {/*<RefreshModal />*/}
        </>
    );
};

export default AccessSearch;
