import React, { useEffect } from 'react';
import useTranslation from '../../../../../../util/hooks/useTranslation';
import FilterSearchGroup from '../../../../../Common/FilterSearchGroup';
import {
    FilterList,
    InputGroup,
    InputWrap,
    SearchWrap,
    SelectGroup,
} from '../../../../../Common/FilterSearchGroup/Components/Part';
import DatePicker from '../../../../../Common/DatePicker';
import SearchableSelect from '../../../../../Common/Select/SearchableSelect';
import Button from '../../../../../Common/Button';
import SearchGroup from '../../../../../Common/FilterSearchGroup/Components/SearchGroup';
import TextInput from '../../../../../Common/Input/TextInput';
import moment from 'moment';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import useConfirmModal from '../../../../../Common/ConfirmModal/useConfirmModal';
import UserDeptSelect from '../../Components/UserDeptSelect';
import { commuteCodeList, recordSearchState } from '../../../../MedicalEngineering/atoms';

const Search = () => {
    const t = useTranslation('Search');

    const workStatusList = useRecoilValue(commuteCodeList);
    const resetSearch = useResetRecoilState(recordSearchState);

    const [searchOptionState, setSearchOptionState] = useRecoilState(recordSearchState);

    const { startDate, endDate, targetName, workStatus } = searchOptionState;

    const handleDate = (name, selected) => {
        setSearchOptionState(prevState => ({
            ...prevState,
            [name]: selected,
        }));
    };

    const handleTargetName = ({ target: { value } }) => {
        setSearchOptionState(prevState => ({
            ...prevState,
            targetName: value,
        }));
    };

    const handleClickSearch = () => {
        setSearchOptionState(prevState => ({
            ...prevState,
            clickSearch: prevState.clickSearch + 1,
        }));
    };

    const handleWorkStatus = selected => {
        setSearchOptionState(prevState => ({
            ...prevState,
            workStatus: selected.map(status => status.value),
        }));
    };

    //언마운트 될때 초기화
    useEffect(() => {
        setSearchOptionState(prevState => ({
            ...prevState,
            clickSearch: prevState.clickSearch + 1,
            startDate: moment().startOf('day').valueOf(),
            endDate: moment().endOf('day').valueOf(),
        }));
        return () => {
            resetSearch();
        };
    }, []);

    // const { toggleModal: toggleRefreshModal, Modal: RefreshModal } = useConfirmModal({
    //     confirmText: t('Do you want to refresh?', 'Filter'),
    //     okCallback: async () => {
    //         await resetSearch();
    //         await setSearchOptionState(prevState => ({
    //             ...prevState,
    //             startDate: moment().startOf('day').valueOf(),
    //             endDate: moment().endOf('day').valueOf(),
    //         }));
    //         handleClickSearch();
    //     },
    // });
    const workStatusListRows = workStatusList.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'RecordModifyModal'),
        };
    });

    return (
        <>
            <FilterSearchGroup>
                <SearchGroup>
                    <FilterList>
                        <SelectGroup>
                            <DatePicker
                                value={startDate}
                                valueType="ms"
                                maxDate={new Date()}
                                onChangeRaw={e => e.preventDefault()}
                                handleChange={selected => handleDate('startDate', selected)}
                                yearDropdownItemNumber={7}
                                withoutTime
                                showYearDropdown
                                dateFormatCalendar="MMMM"
                            />
                        </SelectGroup>
                        <div className="text-center"> ~ </div>
                        <SelectGroup>
                            <DatePicker
                                name="name"
                                value={endDate}
                                valueType="ms"
                                onChangeRaw={e => e.preventDefault()}
                                handleChange={selected => handleDate('endDate', selected)}
                                withoutTime
                                minDate={startDate}
                                showYearDropdown
                                yearDropdownItemNumber={7}
                                maxDate={new Date()}
                                dateFormatCalendar="MMMM"
                            />
                        </SelectGroup>
                        <SelectGroup>
                            <UserDeptSelect />
                        </SelectGroup>
                        <SelectGroup>
                            <SearchableSelect
                                name="workStatus"
                                title={t('Work Status', 'RecordManagement')}
                                selected={workStatus}
                                data={workStatusListRows}
                                onChange={handleWorkStatus}
                                className="w-100 font-size-14px"
                            />
                        </SelectGroup>
                    </FilterList>
                    <SearchWrap className="w-20">
                        <InputWrap>
                            <InputGroup>
                                <TextInput
                                    name="name"
                                    value={targetName}
                                    handleChange={handleTargetName}
                                    placeholder={t('Search for a name or employee number', 'RecordManagement')}
                                />
                            </InputGroup>
                            <Button
                                className="btn-brand btn-icon"
                                iconClassName="icon-search"
                                onClick={handleClickSearch}
                            >
                                {t('Search')}
                            </Button>
                        </InputWrap>
                    </SearchWrap>
                </SearchGroup>
            </FilterSearchGroup>
            {/*<RefreshModal />*/}
        </>
    );
};

export default Search;
