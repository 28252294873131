import React, { useMemo, useState, memo, useEffect, useRef } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import useTranslation from '../../../../../../util/hooks/useTranslation';
import cx from 'classnames';
import Button from '../../../../../Common/Button';
import Table from '../../../../../Common/Table';
import Card from '../../../../../Common/Card';
import TextInput from '../../../../../Common/Input/TextInput';
import CustomLabel from '../../../Components/CustomLabel';
import useColumns from '../../../../../../util/hooks/useColumns';
import DatePicker from '../../../../../Common/DatePicker';
import moment from 'moment';
import * as column from '../../../../util/grid/column';
import RepairDetailModal from './RepairDetailModal';
import Checkbox from '../../../../../Common/Input/Checkbox';
import useConfirmModal from '../../../../../Common/ConfirmModal/useConfirmModal';
import { fetchAccountSettleDetail, updateAccountSettleModify } from '../../../../../../api/engineering';
import useAsync from '../../../../../../util/hooks/useAsync';
import ModifyModal from './ModifyModal';

const SettlementDetailModal = ({
    initModal = false,
    toggleModal = function () {
        initModal = !initModal;
    },
    okCallback,
    cancelCallback,
    callbackParam,
    removeCancel,
    removeOk,
    okClassName,
    okButtonTitle,
    className,
    trData,
    searchMonth,
    getAccountSettleList,

    ...restProps
}) => {
    const t = useTranslation('SettlementDetailModal');
    const columnT = useTranslation('SettlementDetailColumn');

    const deptCharge = useMemo(() => {
        return {
            10: t('Hospital', 'ExcelExport'),
            20: t('Medical school/basics', 'ExcelExport'),
            30: t('Research institute', 'ExcelExport'),
        };
    }, [t]);

    const [repairDetailModal, setRepairDetailModal] = useState(false);
    const [modifyModalInfo, setModifyModalInfo] = useState({ name: '', title: '' });
    const [modifyModal, setModifyModal] = useState(false);
    const [tableData, setTableData] = useState({ rows: [] });
    const [patchData, setPatchData] = useState({
        charge: null,
        dateNum: null,
        empName: '',
        empNum: null,
        homeAppli: 'N',
        repairBudget: null,
        savingTarget: null,
        serviceBudget: null,
    });
    const [inputs, setInputs] = useState({
        date: moment().valueOf(),
        name: '',
        manager: '',
        homeAppliances: false,
    });
    const { serviceBudget, repairBudget, savingTarget } = patchData;
    const { date, name, manager, homeAppliances } = inputs;

    const { promise: getAccountSettleDetail } = useAsync({
        promise: fetchAccountSettleDetail,
        resolve: res => {
            const { dateNum, charge, empName, empNum, homeAppli, repairBudget, savingTarget, serviceBudget } = res;
            setInputs(prevState => {
                return {
                    ...prevState,
                    name: empName,
                    manager: charge,
                    homeAppliances: homeAppli === 'Y',
                };
            });

            setPatchData(prevState => ({
                ...prevState,
                dateNum,
                charge,
                empName,
                empNum,
                homeAppli,
                repairBudget,
                savingTarget,
                serviceBudget,
            }));
            setTableData({ rows: [res] });
        },
    });

    const { promise: patchAccountSettleModify } = useAsync({
        promise: updateAccountSettleModify,
        resolve: () => {
            toggleSaveModal();
            toggleModal();
            getAccountSettleList();
        },
        reject: () => {
            toggleRequestFailModal();
        },
    });

    useEffect(() => {
        setTableData(prevState => {
            return {
                rows: [
                    {
                        ...prevState.rows[0],
                        serviceBudget,
                        repairBudget,
                        savingTarget,
                    },
                ],
            };
        });
    }, [serviceBudget, repairBudget, savingTarget]);

    useEffect(() => {
        if (trData && initModal) {
            setInputs(prevState => {
                return {
                    ...prevState,
                    date: moment(searchMonth, 'YYYY-MM').unix() * 1000,
                };
            });
            const { charge, empNum, homeAppli } = trData;
            getAccountSettleDetail({
                charge,
                dateCode: searchMonth,
                empNum,
                homeAppli,
            });
        }
    }, [trData && initModal]);

    const generalRepairColumns = useColumns(
        [
            column.numberOfRepairs(),
            column.generalRepairCost(),
            column.billingInventory(),
            column.monthlyRepairStockTotal(),
            column.monthlyRepairCost(),
            column.repairCostAccumulated(),
        ],
        columnT,
        [columnT],
    );

    const serviceRepairColumns = useColumns(
        [
            column.numberOfServiceRepair(),
            column.thisMonthServiceRepair(),
            column.accumulatedServices(),
            column.serviceBudgetAllocation({
                className: 'm-0 p-0 border-0',
                Cell: ({ value }) => {
                    return (
                        <div
                            className={'table-input grid-center background-table-input no-spin cursor-pointer'}
                            onClick={() => {
                                setModifyModalInfo({ title: 'Service Budget Allocation', name: 'serviceBudget' });
                                toggleModifyModal();
                            }}
                        >
                            <span>{value}</span>
                        </div>
                    );
                },
            }),
            column.budgetAllocationOfRepair({
                className: 'm-0 p-0 border-0',
                Cell: ({ value }) => {
                    return (
                        <div
                            className={'table-input grid-center background-table-input no-spin cursor-pointer'}
                            onClick={() => {
                                setModifyModalInfo({
                                    title: 'Budget Allocation of Repair Costs',
                                    name: 'repairBudget',
                                });
                                toggleModifyModal();
                            }}
                        >
                            <span>{value}</span>
                        </div>
                    );
                },
            }),
            column.repairCostExecutionRate(),
        ],
        columnT,
        [columnT],
    );

    const costReductionColumns = useColumns(
        [
            column.numberOfSavingThisMonth(),
            column.savingForThisMonth(),
            column.cumulativeSavings(),
            column.accumulatedCostReduction(),
            column.targetReductionAmount({
                className: 'm-0 p-0 border-0',
                Cell: ({ value }) => {
                    return (
                        <div
                            className={'table-input grid-center background-table-input no-spin cursor-pointer'}
                            onClick={() => {
                                setModifyModalInfo({ title: 'Target Reduction Amount', name: 'savingTarget' });
                                toggleModifyModal();
                            }}
                        >
                            <span>{value}</span>
                        </div>
                    );
                },
            }),
            column.performance(),
        ],
        columnT,
        [columnT],
    );

    const tableList = useMemo(() => {
        return [
            { name: 'General Repair', columns: generalRepairColumns },
            { name: 'Service Repair', columns: serviceRepairColumns },
            { name: 'Cost Reduction', columns: costReductionColumns },
        ];
    }, [generalRepairColumns, serviceRepairColumns, costReductionColumns]);

    const handleRepairDetailModal = () => {
        setRepairDetailModal(!repairDetailModal);
    };

    const { toggleModal: toggleSaveModal, Modal: SaveModal } = useConfirmModal({
        header: { title: t('Save Complete', 'ConfirmModal') },
        confirmText: t('Save has been completed.', 'ConfirmModal'),
        removeCancel: true,
    });

    const { toggleModal: toggleSaveConfirmModal, Modal: SaveConfirmModal } = useConfirmModal({
        header: { title: t('Modify', 'ConfirmModal') },
        confirmText: t('Do you want to save the modifications?'),
        okCallback: () => {
            patchAccountSettleModify(patchData);
        },
    });

    const { toggleModal: toggleRequestFailModal, Modal: RequestFailModal } = useConfirmModal({
        header: { title: t('Request Fail', 'ConfirmModal') },
        confirmText: t('A temporary error occurred.', 'ConfirmModal'),
        removeCancel: true,
    });

    const toggleModifyModal = () => {
        setModifyModal(!modifyModal);
    };

    const handleChangeInput = ({ name, value }) => {
        setPatchData(prevState => ({
            ...prevState,
            [name]: Number.isNaN(value) ? 0 : value,
        }));
    };

    return (
        <>
            <Modal
                container={document.getElementsByClassName('app-container')[0]}
                className={cx('pnt-modal', className)}
                isOpen={initModal}
                toggle={toggleModal}
                size="xl"
                {...restProps}
            >
                <ModalHeader toggle={toggleModal}>{t('View settlement computerization details')}</ModalHeader>
                <ModalBody style={{ padding: 0 }}>
                    <Card className="m-0 box-shadow-none">
                        <CustomLabel
                            name={t('Year and Month in Managing', 'ManagerModal')}
                            labelOptionClassName="justify-content-between"
                            value={
                                <>
                                    <div className="settle-month-picker detail">
                                        <DatePicker
                                            value={date}
                                            valueType="ms"
                                            dateFormat="Y / MMM"
                                            showMonthYearPicker
                                            showFullMonthYearPicker
                                            showTwoColumnMonthYearPicker
                                            withoutDay
                                            readOnly
                                        />
                                    </div>
                                    <Button className="btn-brand" onClick={handleRepairDetailModal}>
                                        {t('View repair details')}
                                    </Button>
                                </>
                            }
                        />
                        <CustomLabel
                            name={t('Manager', 'ManagerModal')}
                            value={
                                <div className="p-0 d-flex gap-1 w-100">
                                    <TextInput
                                        name="name"
                                        value={name}
                                        inputGroupClassName="form-disable w-auto"
                                        placeholder={t('Manager Name', 'ManagerModal')}
                                    />
                                    <TextInput
                                        name="manager"
                                        value={deptCharge[manager]}
                                        inputGroupClassName="form-disable w-auto"
                                        placeholder={t('Responsibility', 'SettlementTable')}
                                    />
                                </div>
                            }
                        />
                        <CustomLabel
                            name={t('Responsible for home appliances', 'ManagerModal')}
                            value={<Checkbox checked={homeAppliances} className={'cursor-default'} readOnly />}
                        />
                        <div className="pnt-border border-w" />
                        <div>
                            {tableList.map(table => {
                                const { name, columns } = table;

                                return (
                                    <div key={name}>
                                        <CustomLabel name={t(name)} labelGroupClassName={'min-h45'} required />
                                        <div style={{ height: '74px' }}>
                                            <Table data={tableData} columns={columns} paging={false} singleRow />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </Card>
                </ModalBody>
                <ModalFooter>
                    {!removeCancel && (
                        <Button
                            className="color-secondary"
                            onClick={() => {
                                if (typeof cancelCallback === 'function') {
                                    cancelCallback(callbackParam);
                                }
                                toggleModal();
                            }}
                        >
                            {t('Previous')}
                        </Button>
                    )}

                    {!removeOk && (
                        <Button
                            className={cx(okClassName, 'btn-secondary')}
                            onClick={() => {
                                if (typeof okCallback === 'function') {
                                    okCallback(callbackParam);
                                }
                                toggleSaveConfirmModal();
                            }}
                        >
                            {t('Application')}
                        </Button>
                    )}
                </ModalFooter>
            </Modal>
            <RepairDetailModal
                initModal={repairDetailModal}
                toggleModal={handleRepairDetailModal}
                empData={patchData}
                searchMonth={searchMonth}
                getAccountSettleList={getAccountSettleList}
            />
            <SaveConfirmModal />
            <SaveModal />
            <RequestFailModal />
            <ModifyModal
                initModal={modifyModal}
                header={{ title: `${columnT(modifyModalInfo.title)} ${t('Modification', 'ManagerModal')}` }}
                name={modifyModalInfo.name}
                toggleModal={toggleModifyModal}
                handleChangeInput={handleChangeInput}
            />
        </>
    );
};

export default memo(SettlementDetailModal);
