import React, { useState } from 'react';
import Card from '../Components/WidgetCard';
import useMonitor from '../util/useMonitor';
import { getStatisticsLogApi } from '../../../api/dashboard';
import _ from 'lodash';
import useAsync from '../../../util/hooks/useAsync';
import { getStatusInfoApi } from '../../../api/asset';
import moment from 'moment';
import { useSelector } from 'react-redux';
import CustomLineChart from './Components/CustomLineChart';

const statusList = [
    {
        statusNum: 1,
        statusCategoryNum: 1,
        statusId: 'in-use',
        statusName: 'use',
        displayColor: '#5F9EA0',
    },
    {
        statusNum: 2,
        statusCategoryNum: 2,
        statusId: 'repair',
        statusName: 'repairing',
        displayColor: '#FF1493',
    },
    {
        statusNum: 3,
        statusCategoryNum: 3,
        statusId: 'broken',
        statusName: 'Broken',
        displayColor: '#FF1493',
    },
    {
        statusNum: 4,
        statusCategoryNum: 2,
        statusId: 'ready',
        statusName: 'ready',
        displayColor: '#DC143C',
    },
    {
        statusNum: 5,
        statusCategoryNum: 3,
        statusId: 'open',
        statusName: 'open',
        displayColor: '#8B0000',
    },
];

function randomDate(start, end) {
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}
const currDate = new Date();
const makeSampleData = num => {
    const data = [];
    for (let i = 0; i < num; i++) {
        const randomStatus = _.sample(statusList);
        const regDate = Math.floor(
            randomDate(new Date(currDate.getTime() - 1000 * 60 * 60 * 3.5), currDate).getTime() / 1000,
        );
        data.push({
            categoryCode: 'ASSET',
            comNum: 100003,
            floorName: '1F',
            floorNamePath: '1F > 2F',
            lastDate: 1600000000,
            leadTime: 1600000000,
            logNum: 100003,
            previousStatusName: 'Repair',
            previousStatusNum: 144,
            properties: {},
            regDate: regDate,
            statusCount: 1004,
            statusName: randomStatus.statusName,
            statusNum: randomStatus.statusNum,
            targetName: 'John',
            targetNum: 14,
        });
    }
    return data;
};

const defaultData = {
    code: 0,
    count: 0,
    result: 'success',
    rows: makeSampleData(500),
};

// 30분
const TIME_UNIT = 30 * 60;

// 구간 리스트 중에서 최소 근접 시각을 찾는다
// @param timeList  구간 리스트      timestamp arr
// @param time      구간 찾을 시각    timestamp
const findTimePoint = (timeList, time) => {
    let found = timeList[0];
    timeList.some(point => {
        if (point >= time) {
            found = point;
            return true;
        }
    });
    return found;
};

const StatusRateByTime = ({ children, widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const [statusInfo, setStatusInfo] = useState();
    const { mode } = useSelector(state => state.DashboardFrame);
    const { filterParam } = useSelector(state => state.FilterInfo);

    useAsync({
        immediate: true,
        promise: getStatusInfoApi,
        resolve: res => {
            const statusToColor = {};
            if (res.rows) {
                res.rows.forEach(({ statusNum, displayColor, statusName }) => {
                    statusToColor[statusNum] = { displayColor, statusName };
                });
                setStatusInfo(statusToColor);
            }
        },
    });

    const { data } = useMonitor({
        config,
        defaultData,
        dynamicParam: filterParam,
        fetchData: getStatisticsLogApi,
        makeData: ({ newData }) => {
            // newData = {
            //     code: 0,
            //     count: 0,
            //     result: 'success',
            //     rows: makeSampleData(500),
            // };
            // console.log({ newData });
            let chartData = [];
            const currDate = Math.floor(new Date().getTime() / 1000);
            const timeSet = [];
            for (let i = 0; i < 8; i++) {
                timeSet.unshift(currDate - i * TIME_UNIT);
            }
            if (newData.rows) {
                const summary = newData.rows.reduce((acc, { regDate, statusNum }) => {
                    const time = findTimePoint(timeSet, regDate);
                    if (acc[time]) {
                        if (acc[time][statusNum]) {
                            acc[time][statusNum]++;
                        } else {
                            acc[time][statusNum] = 1;
                        }
                    } else {
                        acc[time] = { [statusNum]: 1 };
                    }
                    return acc;
                }, {});
                const initialCount = {};
                if (statusInfo) {
                    for (let statusNum in statusInfo) {
                        if (statusInfo.hasOwnProperty(statusNum)) {
                            initialCount[statusNum] = 0;
                        }
                    }
                }

                // 맨처음 모든 이전 데이터를 포함한 summary 제외
                timeSet.shift();

                chartData = timeSet.map(time => {
                    return {
                        time: moment(time * 1000).format('HH:mm'),
                        ...initialCount,
                        ...summary[time],
                    };
                });
            }
            return chartData;
        },
    });

    return (
        <Card widgetInfo={widgetInfo} {...restProps}>
            <CustomLineChart data={data} statusInfo={statusInfo} />
            {children}
        </Card>
    );
};

export default StatusRateByTime;
