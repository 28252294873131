import React from 'react';
import moment from 'moment';
import no_image from '../../../../assets/images/image-not-found.svg';
import useTranslation from '../../../../util/hooks/useTranslation';
import { LoadingBlock } from '../../../Common/LoadingBlock';
import styled from 'styled-components';

const handleImgErr = e => {
    e.target.src = no_image;
};

const makeCurrentTime = unixDate => moment(unixDate * 1000).format('HH:mm');

const StatusDashboard = ({ list, isLoading }) => {
    const t = useTranslation('Table');
    return list.length ? (
        <div className="table-status-container mt-2">
            <LoadingBlock blocking={isLoading} className="h-100" loaderColor="gray">
                <div className="d-grid-c2">
                    {list.map((equipment, idx) => {
                        const {
                            receptionNum,
                            //targetImage,
                            targetName,
                            targetId,
                            userName,
                            groupName,
                            regDate,
                            modDate,
                            minorVer,
                        } = equipment;
                        const image = `http://device.ajoumc.or.kr/Main/Common/File/Device/${targetId}.jpg`;
                        return (
                            <div className="d-flex flex-row equipment-box" key={receptionNum + regDate + idx}>
                                <img
                                    style={{ width: '93.36px' }}
                                    onError={handleImgErr}
                                    src={image}
                                    className="equipment-image h-100 border-radius-10"
                                    alt="product_image"
                                />
                                {/*) : (*/}
                                {/*    <img*/}
                                {/*        src={no_image}*/}
                                {/*        className="equipment-image h-100 border-radius-10"*/}
                                {/*        alt="product_image"*/}
                                {/*    />*/}
                                {/*)}*/}
                                <EquipmentBox className="h-100 ml-3 d-flex flex-column">
                                    <div>
                                        <div title={targetName} className="equipment-name text-ellipsis">
                                            {targetName}
                                        </div>
                                        <div title={targetId} className="equipment-number text-ellipsis">
                                            {targetId}_{minorVer}
                                        </div>
                                    </div>
                                    <div className="d-flex flex-row justify-content-between">
                                        <div className="d-flex flex-row font-weight-bold font-size-lg align-items-end">
                                            {groupName && <div className="mr-2 align-self-center">{groupName}</div>}
                                            {groupName && userName && <div className="mr-2 align-self-center">•</div>}
                                            <div className="align-self-center">{userName}</div>
                                        </div>
                                        <div className="equipment-date align-self-center">
                                            {modDate ? makeCurrentTime(modDate) : makeCurrentTime(regDate)}
                                        </div>
                                    </div>
                                </EquipmentBox>
                            </div>
                        );
                    })}
                </div>
            </LoadingBlock>
        </div>
    ) : (
        <div className="table-status-container mt-2 grid-center pb-2">
            <div className="text-center">
                <div className="icon-view icon-filter-gray" style={{ transform: 'scale(3)' }} />
                <div className="mt-3 font-size-lg" style={{ color: 'gray' }}>
                    {t('No data')}
                </div>
            </div>
        </div>
    );
};

const EquipmentBox = styled.div`
    max-width: 40rem;
    width: 100%;
`;

export default StatusDashboard;
