import React, { useEffect, useMemo, useState, useRef } from 'react';
import Search from './Components/Search';
import Card from '../../../../Common/Card';
import useTranslation from '../../../../../util/hooks/useTranslation';
import Button from '../../../../Common/Button';
import useAsync from '../../../../../util/hooks/useAsync';
import { fetchCommuteLogApi, createCommuteLogExport } from '../../../../../api/engineering';
import Table from '../../../../Common/Table';
import useColumns from '../../../../../util/hooks/useColumns';
import * as column from '../../../util/grid/column';
import { useRecoilValue } from 'recoil';

import SingleSelect from '../../../../Common/Select/SingleSelect';
import useConfirmModal from '../../../../Common/ConfirmModal/useConfirmModal';
import RecordModifyModal from '../Components/RecordModifyModal';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { fetchExcelLogApi } from '../../../../../api/log';
import { commuteCodeList, commuteDeptList, recordSearchState } from '../../../MedicalEngineering/atoms';

const INTERVAL_TIME = 1000;

const RecordManagement = () => {
    const t = useTranslation('RecordManagement');

    const excelTimerRef = useRef();

    const {
        userInfo: { groupId },
    } = useSelector(state => state.UserInfo);

    const workStatusList = useRecoilValue(commuteCodeList);
    const searchOption = useRecoilValue(recordSearchState);
    const deptList = useRecoilValue(commuteDeptList);

    const { startDate, endDate, targetName, clickSearch, workStatus } = searchOption;

    const [recordModifyModal, setRecordModifyModal] = useState(false);
    const [recordData, setRecordData] = useState({
        rows: [],
    });
    const [selectedCmNum, setSelectedCmNum] = useState(null);

    const allScdList = useMemo(() => {
        if (workStatusList.length) {
            return workStatusList
                .reduce((acc, cur) => {
                    acc.push(cur.value);
                    return acc;
                }, [])
                .join(',');
        }
    }, [workStatusList]);

    const orderOptions = useMemo(() => {
        return [
            {
                value: 'regDate',
                label: t('Date'),
            },
            {
                value: 'targetName',
                label: t('Name'),
            },
        ];
    }, [t]);

    // 날짜 or 이름 ASC, DESC
    const [orderRule, setOrderRule] = useState('ASC');
    const [selectedOrderOption, setSelectedOrderOption] = useState(orderOptions[0].value);

    const handleOrderRule = () => {
        if (orderRule === 'ASC') {
            setOrderRule('DESC');
        } else {
            setOrderRule('ASC');
        }
    };

    const { promise: getCommuteLog, state: commuteLogState } = useAsync({
        promise: fetchCommuteLogApi,
        resolve: res => {
            setRecordData(res);
        },
    });

    const { promise: fetchExcelLog } = useAsync({
        promise: fetchExcelLogApi,
        resolve: res => {
            if (res.dataSettingStatusType === 'COMPLETED') {
                clearExcelTimer();
                toggleExcelModal();

                const elem = window.document.createElement('a');
                elem.href = res.filePath;
                elem.download = res.fileName;
                document.body.appendChild(elem);
                elem.click();
                document.body.removeChild(elem);
            } else if (res.dataSettingStatusType === 'FAILED') {
                clearExcelTimer();
            }
        },
    });

    const { promise: createNewCommuteLogExport } = useAsync({
        promise: createCommuteLogExport,
        fixedParam: {
            zipFileName: 'Transferred_Repair_Log',
            fileName: 'Transferred_Repair_Log',
            columnMetas: [
                {
                    key: 'targetName',
                    name: t('Name'),
                },
                {
                    key: 'deptName',
                    name: t('Affiliation'),
                },
                {
                    key: 'regDateStr',
                    name: t('Date'),
                },
                {
                    key: 'sName',
                    name: t('Work Status'),
                },
                {
                    key: 'inDateStr',
                    name: t('Attendance Time'),
                },
                {
                    key: 'outDateStr',
                    name: t('Quitting Time'),
                },
            ],
        },
        resolve: res => {
            const { filePath: url, reqNum } = res;
            if (url) {
                downloadWithURL(url, reqNum);
            }
        },
    });

    const downloadWithURL = (url, reqNum) => {
        excelTimerRef.current = setInterval(() => {
            fetchExcelLog({ reqNum: reqNum });
        }, INTERVAL_TIME);
    };

    const clearExcelTimer = () => {
        clearInterval(excelTimerRef.current);
        excelTimerRef.current = null;
    };

    const searchParams = useMemo(() => {
        return {
            deptCode: groupId,
            sDate: moment(startDate).format('YYYYMMDD'),
            eDate: moment(endDate).format('YYYYMMDD'),
            orderBy: selectedOrderOption,
            orderRule: selectedOrderOption ? orderRule : null,
            sCD: workStatus.length ? workStatus.join(',') : allScdList,
            targetName: targetName ? targetName : null,
        };
    }, [clickSearch, orderRule, selectedOrderOption, groupId, allScdList]);

    const handleExportFileClick = () => {
        createNewCommuteLogExport(searchParams);
    };

    useEffect(() => {
        if (groupId && allScdList && clickSearch) {
            getCommuteLog(searchParams);
        }
    }, [clickSearch, orderRule, selectedOrderOption, groupId, allScdList]);

    const columns = useColumns(
        [
            column.targetName({
                Header: 'Name',
                width: 80,
                className: 'text-ellipsis',
            }),
            column.affiliation({ deptList }),
            column.commuteDate(),
            column.workStatus(),
            column.commuteTime({ Header: 'Attendance Time', accessor: 'inDate' }),
            column.commuteTime({ Header: 'Quitting Time', accessor: 'outDate' }),
        ],
        t,
        [deptList, t],
    );

    const { toggleModal: toggleExcelDownloadModal, Modal: ExcelDownloadModal } = useConfirmModal({
        initModal: false,
        header: {
            title: t('Download', 'Button'),
        },
        confirmText: t('Do you want to download the excel?', 'RegisteredRepairManagement'),
        okCallback: () => {
            handleExportFileClick();
        },
    });

    const { toggleModal: toggleExcelModal, Modal: ExcelModal } = useConfirmModal({
        initModal: false,
        header: {
            title: t('Excel Download', 'Excel Download'),
        },
        confirmText: t('Excel Download Completed', 'Excel Download'),
        removeCancel: true,
    });

    const handleRecordModifyModal = () => {
        setRecordModifyModal(prevState => !prevState);
    };

    return (
        <>
            <Search />
            <Card
                className={'management-container record'}
                header={{
                    title: t('Record Management', 'Menu'),
                    action: (
                        <>
                            <SingleSelect
                                placeholder={t('Select Order By')}
                                options={orderOptions}
                                value={selectedOrderOption}
                                onChange={selected => setSelectedOrderOption(selected)}
                            />
                            <Button
                                className={'btn-info btn-icon-only'}
                                iconClassName={orderRule === 'ASC' ? 'icon-up-arrow' : 'icon-down-arrow'}
                                onClick={handleOrderRule}
                            />
                            <Button className={'btn-brand'} onClick={toggleExcelDownloadModal}>
                                {t('Download', 'Button')}
                            </Button>
                        </>
                    ),
                }}
            >
                <Table
                    textAlign={'center'}
                    columns={columns}
                    data={recordData}
                    loading={commuteLogState.isLoading}
                    paging={false}
                    onTrClick={trData => {
                        const { cmNum } = trData;
                        setSelectedCmNum(cmNum);
                        handleRecordModifyModal();
                    }}
                />
            </Card>
            <ExcelDownloadModal />
            <RecordModifyModal
                initModal={recordModifyModal}
                toggleModal={handleRecordModifyModal}
                selectedCmNum={selectedCmNum}
            />
            <ExcelModal />
        </>
    );
};

export default RecordManagement;
