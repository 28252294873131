import React from 'react';
import useTranslation from '../../../../../util/hooks/useTranslation';
import moment from 'moment';
import Label from '../../../../Common/Label';

const AssetDetailInformation = ({ assetInfo, assetCategoryPropertyInfo }) => {
    const t = useTranslation('Asset Detail Information');

    return (
        <div className="card h-100">
            <div className="card-header">
                <div className="card-header__title">
                    <div className={'title-wrap'}>
                        <h3 className="title__main">{t('Asset Information')}</h3>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <div className="flx-row gap-4 pb-2">
                    <div className="am__asset-picture">
                        <span className="icon-picture" />
                    </div>
                    <div className={'d-grid flx-row gap-3'}>
                        <Label
                            labelGroupClassName={'mb-1'}
                            labelValueClassName={'label-dot color-brand'}
                            name={t('Number')}
                            value={assetInfo.targetNum ? assetInfo.targetNum : '-'}
                        />
                        <Label
                            labelGroupClassName={'mb-1'}
                            labelValueClassName={'label-dot color-brand'}
                            name={t('Name')}
                            value={assetInfo.targetName ? assetInfo.targetName : '-'}
                        />
                        <Label
                            labelGroupClassName={'mb-1'}
                            labelValueClassName={'label-dot color-brand'}
                            name={t('ID')}
                            value={assetInfo.targetId ? assetInfo.targetId : '-'}
                        />
                        <Label
                            labelGroupClassName={'mb-1'}
                            labelValueClassName={'label-dot color-brand'}
                            name={t('Status')}
                            value={assetInfo.statusName ? t(assetInfo.statusName, 'Status') : '-'}
                        />
                    </div>
                </div>
                <div className="divider mb-3" />
                <div className={'flx-col gap-4 mt-5'}>
                    <Label
                        labelValueClassName={'label-dot'}
                        name={t('Status flow scheme')}
                        value={assetInfo.flowName ? assetInfo.flowName : '-'}
                    />
                    <Label
                        labelValueClassName={'label-dot'}
                        name={t('Status scheme')}
                        value={assetInfo.schemeName ? assetInfo.schemeName : '-'}
                    />
                    <Label
                        labelValueClassName={'label-dot'}
                        name={t('Category')}
                        value={assetInfo.categoryNamePath ? assetInfo.categoryNamePath : '-'}
                    />
                    {assetCategoryPropertyInfo.map(props => (
                        <Label
                            labelValueClassName={'label-dot'}
                            name={t(props.name)}
                            value={props.value ? props.value : '-'}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default AssetDetailInformation;
