import React from 'react';
import * as XLSX from 'xlsx';
import * as XlsxPopulate from 'xlsx-populate/browser/xlsx-populate';
import Button from '../../../../Common/Button';
import useTranslation from '../../../../../util/hooks/useTranslation';
import useAsync from '../../../../../util/hooks/useAsync';
import { getExcelApi } from '../../../../../api/engineering';

const ExcelExportComponents = ({ option }) => {
    const t = useTranslation('Equipment evaluation excel');

    const { promise: getExcel } = useAsync({
        promise: getExcelApi,
        resolve: res => {
            handleExport(res, option).then(url => {
                const downloadAnchorNode = document.createElement('a');
                downloadAnchorNode.setAttribute('href', url);
                downloadAnchorNode.setAttribute(
                    'download',
                    `${t('Detailed status of medical device budget request for fiscal year')}`,
                );
                downloadAnchorNode.click();
                downloadAnchorNode.remove();
            });
        },
        reject: err => {
            console.error(err);
        },
    });

    const s2ab = s => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i !== s.length; ++i) {
            view[i] = s.charCodeAt(i);
        }
        return buf;
    };

    const workbook2blob = workbook => {
        const wopts = {
            bookType: 'xlsx',
            type: 'binary',
        };

        const wbout = XLSX.write(workbook, wopts);

        const blob = new Blob([s2ab(wbout)], {
            type: 'application/octet-stream',
        });

        return blob;
    };

    const handleExport = (res, option) => {
        const { excelList } = res;
        const ExcelData = [
            {
                A: `${option.year && `${option.year}년`} ${t(
                    'Detailed status of medical device budget request for fiscal year',
                )}`,
            },

            {
                A: '',
                B: '',
                C: '',
                D: '',
                E: '',
                F: '',
                G: '',
                H: '',
                I: '',
                J: '',
                K: '',
                L: '',
                M: '',
                N: '',
                O: '',
                P: '',
                Q: '',
                R: '',
                S: '',
                T: '',
                U: '',
                V: '',
                W: '',
                X: '',
                Y: '',
                Z: '',
                AA: '',
                AB: '',
                AC: `(${t('Unit')}: ${t('unit, 1000won')})`,
            },
            {
                A: t('Department name'),
                B: t('Priority'),
                C: t('Equipment name'),
                D: t('Quantity held'),
                E: t('Billing classification'),
                F: t('Adjustment budget(proposal)'),
                G: '',
                H: '',
                I: t('Budget Request'),
                J: '',
                K: '',
                L: t('Purchase target(proposal)'),
                M: '',
                N: '',
                O: t('Note'),
                P: t('Review Opinion'),
                Q: t('Company Status'),
                R: t('Company Estimate'),
                S: t('5year profit and loss'),
                T: t('Evaluation score'),
                // U: '',
                // V: '',
                // W: '',
                // X: '',
                // Y: '',
                // Z: '',
                // AA: '',
                // AB: '',
                // AC: '',
            },
            {
                F: t('Quantity'),
                G: t('Unit price'),
                H: t('Total amount'),
                I: t('Quantity'),
                J: t('Unit price'),
                K: t('Total amount'),
                L: t('Quantity'),
                M: t('Unit price'),
                N: t('Total amount'),
                T: t('Old age'),
                U: t('Profitability'),
                V: t('Utilization'),
                W: t('Utility'),
                X: t('Priority'),
                Y: t('Equipment Rating Score'),
                Z: t('Reliability'),
                AA: t('Cost gain or loss'),
                AB: t('Subtotal of departments used'),
                AC: t('Sum'),
            },
        ];

        excelList.forEach(dept => {
            const {
                list,
                deptAdjustmentQuantity,
                deptAdjustmentSum,
                deptBudgetRequestQuantity,
                deptBudgetRequestSum,
                deptPurchaseQuantity,
                deptPurchaseSum,
                quantity,
            } = dept;
            list.forEach(list => {
                ExcelData.push({
                    A: list.deptName,
                    B: list.priority,
                    C: list.equipmentName,
                    D: list.quantity,
                    E: list.claimName,
                    F: list.adjustmentQuantity.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ','),
                    G: list.adjustmentUnitprice.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ','),
                    H: list.adjustmentSum.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ','),
                    I: list.budgetRequestQuantity.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ','), // change
                    J: list.budgetRequestUnitprice.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ','), // change
                    K: list.budgetRequestSum.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ','), // change
                    L: list.purchaseQuantity.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ','),
                    M: list.purchaseUnitprice.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ','),
                    N: list.purchaseSum.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ','),
                    O: list.remark,
                    P: list.opinion,
                    Q: list.estimate,
                    R: list.retention,
                    S: list.revenue5
                        ? list.revenue5.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                        : '수익성 미평가',
                    T: list.oldPoint,
                    U: list.profitabilityPoint,
                    V: list.appPoint,
                    W: list.utilityPoint,
                    X: list.priorityPoint,
                    Y: list.oldPoint + list.profitabilityPoint + list.appPoint + list.utilityPoint + list.priorityPoint,
                    Z: list.trustPoint, //list.deptCostLossPoint,
                    AA: list.deptCostLossPoint,
                    AB: list.deptCostLossPoint + list.trustPoint,
                    AC: list.sum,
                });
            });

            ExcelData.push({
                A: '',
                B: '소계',
                C: '',
                D: quantity,
                E: '',
                F: deptAdjustmentQuantity,
                G: '',
                H: deptAdjustmentSum,
                I: deptBudgetRequestQuantity,
                J: '',
                K: deptBudgetRequestSum,
                L: deptPurchaseQuantity,
                M: '',
                N: deptPurchaseSum,
                // O: '',
                // P: '',
                // Q: '',
                // R: '',
                // S: '',
                // T: '',
                // U: '',
                // V: '',
                // W: '',
                // X: '',
                // Y: '',
                // Z: '',
                // AA: '',
                // AB: '',
                // AC: '',
            });
        });

        let totalQuantity = 0;
        let totalDeptAdjustmentQuantity = 0;
        let totalDeptAdjustmentSum = 0;
        let totalDeptPurchaseQuantity = 0;
        let totalDeptPurchaseSum = 0;
        excelList.forEach(
            ({ quantity, deptAdjustmentQuantity, deptAdjustmentSum, deptPurchaseQuantity, deptPurchaseSum }) => {
                totalQuantity += quantity;
                totalDeptAdjustmentQuantity += deptAdjustmentQuantity;
                totalDeptAdjustmentSum += deptAdjustmentSum;
                totalDeptPurchaseQuantity += deptPurchaseQuantity;
                totalDeptPurchaseSum += deptPurchaseSum;
            },
        );
        ExcelData.push({
            D: totalQuantity,
            F: totalDeptAdjustmentQuantity,
            H: totalDeptAdjustmentSum,
            L: totalDeptPurchaseQuantity,
            N: totalDeptPurchaseSum,
        });
        const wb = XLSX.utils.book_new();
        const sheet = XLSX.utils.json_to_sheet(ExcelData, { skipHeader: true });
        XLSX.utils.book_append_sheet(wb, sheet, `${t('Request details status')}`, true);
        const workbookBlob = workbook2blob(wb);
        const dataInfo = {
            excelTitleRange: 'A1:I1',
            unitTitle: 'AB2:AC2',
            testTitle: `A${ExcelData.length}:C${ExcelData.length}`,
            adjustmentBudgetTitle: 'F3:H3',
            purchaseTargetTitle: 'I3:K3',
            budgetTargetTitle: 'L3:N3',
            evaluationScoreTitle: 'T3:AC3',
            sumTitle: 'AC4:AC4',
            titleRange: 'A3:AC4',
            evaluationScoreColored: 'Q4:W4',
            purchaseTargetRange: `I3:K${ExcelData.length}`,
            tableRange: `A5:AC${ExcelData.length}`,
        };

        [('A', 'B', 'C', 'D', 'E', 'O', 'P', 'Q', 'R', 'S')].forEach((column, idx) => {
            dataInfo[`titleMerge${idx}`] = `${column}3:${column}4`;
        });

        let rowNum = 5;
        const deptRange = [];
        excelList.forEach((dept, index) => {
            dataInfo[`dept${index + 1}`] = `A${rowNum}:A${rowNum + dept.list.length}`;
            deptRange.push({ start: rowNum, end: rowNum + dept.list.length });
            rowNum += dept.list.length + 1;
        });

        return addStyles(workbookBlob, dataInfo, deptRange);
    };

    const addStyles = (workbookBlob, dataInfo, deptRange) => {
        const styles = { fontSize: 8, horizontalAlignment: 'center', verticalAlignment: 'center' };
        const ohterStyles = { fontSize: 8, horizontalAlignment: 'left', verticalAlignment: 'center' };
        return XlsxPopulate.fromDataAsync(workbookBlob).then(workbook => {
            workbook.sheets().forEach(sheet => {
                ['G', 'H', 'J', 'K', 'M', 'N'].forEach(col =>
                    sheet.column(col).style({
                        ...styles,
                    }),
                );
                sheet
                    .column('B')
                    .width(4)
                    .style({
                        ...styles,
                    });
                sheet
                    .column('C')
                    .width(20)
                    .style({
                        ...styles,
                    });
                sheet
                    .column('D')
                    .width(4)
                    .style({
                        ...styles,
                    });
                sheet
                    .column('E')
                    .width(10)
                    .style({
                        ...styles,
                    });
                sheet
                    .column('F')
                    .width(4)
                    .style({
                        ...styles,
                    });
                sheet
                    .column('I')
                    .width(4)
                    .style({
                        ...styles,
                    });
                sheet
                    .column('L')
                    .width(4)
                    .style({
                        ...styles,
                    });

                sheet
                    .column('O')
                    .width(20)
                    .style({
                        ...ohterStyles,
                        wrapText: true,
                    });
                sheet
                    .column('P')
                    .width(20)
                    .style({
                        ...ohterStyles,
                        wrapText: true,
                    });

                sheet
                    .column('Q')
                    .width(20)
                    .style({
                        ...ohterStyles,
                        wrapText: true,
                    });
                sheet
                    .column('R')
                    .width(20)
                    .style({
                        ...ohterStyles,
                        wrapText: true,
                    });
                sheet
                    .column('S')
                    .width(15)
                    .style({
                        ...styles,
                    });
                sheet
                    .column('T')
                    .width(4)
                    .style({
                        ...styles,
                    });
                sheet
                    .column('U')
                    .width(4)
                    .style({
                        ...styles,
                    });
                sheet
                    .column('V')
                    .width(4)
                    .style({
                        ...styles,
                    });
                sheet
                    .column('W')
                    .width(4)
                    .style({
                        ...styles,
                    });
                sheet
                    .column('X')
                    .width(6)
                    .style({
                        ...styles,
                    });
                sheet
                    .column('Y')
                    .width(12)
                    .style({
                        ...styles,
                    });
                sheet
                    .column('Z')
                    .width(4)
                    .style({
                        ...styles,
                    });
                sheet
                    .column('AA')
                    .width(4)
                    .style({
                        ...styles,
                    });
                sheet
                    .column('AB')
                    .width(12)
                    .style({
                        ...styles,
                    });
                sheet
                    .column('AC')
                    .width(4)
                    .style({
                        ...styles,
                    });
                deptRange.forEach(range => {
                    sheet.range(`B${range.end}:C${range.end}`).merged(true).style({
                        fontSize: 8,
                        horizontalAlignment: 'center',
                        verticalAlignment: 'center',
                        border: true,
                    });
                });
                sheet.range(dataInfo.tableRange).style({ border: true });
                sheet.range(dataInfo.testTitle).merged(true);
                sheet.range(dataInfo.excelTitleRange).merged(true).style({ fontSize: 17 });
                sheet.range(dataInfo.unitTitle).style({ horizontalAlignment: 'right', fontSize: 7 });
                sheet.range(dataInfo.adjustmentBudgetTitle).merged(true);
                sheet.range(dataInfo.purchaseTargetTitle).merged(true);
                sheet.range(dataInfo.budgetTargetTitle).merged(true);
                sheet.range(dataInfo.evaluationScoreTitle).merged(true);
                sheet.range(dataInfo.sumTitle).style({ topBorder: 'none' });
                sheet.range(dataInfo.purchaseTargetRange).style({ fill: 'c9d6ef' });
                sheet.range(dataInfo.titleRange).style({
                    border: true,
                    horizontalAlignment: 'center',
                    verticalAlignment: 'center',
                    fontSize: 8,
                });
                sheet.range(dataInfo.evaluationScoreColored).style({
                    fill: 'c9d6ef',
                    wrapText: true,
                    fontSize: 6,
                });

                Object.keys(dataInfo).forEach(key => {
                    if (/^titleMerge/.test(key)) {
                        sheet.range(dataInfo[key]).merged(true).style({
                            wrapText: true,
                        });
                        if (/^P|Q|Z/.test(dataInfo[key])) {
                            sheet.range(dataInfo[key]).style({
                                fill: 'c9d6ef',
                            });
                        }
                    }

                    if (/^dept/.test(key)) {
                        sheet.range(dataInfo[key]).merged(true).style({
                            wrapText: true,
                            horizontalAlignment: 'center',
                            verticalAlignment: 'center',
                            fontSize: 8,
                        });
                    }
                });
            });

            return workbook.outputAsync().then(workbookBlob => URL.createObjectURL(workbookBlob));
        });
    };

    return (
        <Button
            className={'btn-brand btn-icon'}
            iconClassName={'icon-file'}
            onClick={() => {
                getExcel({ ...option, isAll: 'Y' });
            }}
        >
            {t('Export', 'Button')}
        </Button>
    );
};

export default ExcelExportComponents;
