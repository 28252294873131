import { reqGet, reqPatch, reqPost, reqPut, reqDeleteBody } from './index';

export const fetchTagList = param => reqGet('/v3/api/common/tag', { ...param });
export const fetchScannerList = param => reqGet('/v3/api/common/scanner', { ...param, isAll: 'Y' });
export const fetchIotItemCount = param => reqGet('/v3/api/common/statistics/category/by-target/count', { ...param });
// 태그 배터리 상태 조회
export const fetchTagBatteryCount = param =>
    reqGet('/v3/api/common/statistics/mapped-tag-battery/by-unit/count', { ...param });

//// 자산 현황
// 카테고리 리스트 호출
export const fetchCategory = param => reqGet('/v3/api/common/target/category/info', { ...param });

// 현재 자산 현황 개수 데이터 호출 (전체 개수, lost signal 개수 etc..)
export const fetchAssetCountStatus = param =>
    reqGet('/v3/api/common/statistics/category/target/tag/by-status/count', { ...param });

// 실시간 위치 정보가 맵핑된 대상 목록 조회 API
export const getTargetListWithLocationApi = param =>
    reqGet('/v3/api/common/statistics/category/target/location/info', { ...param });

//// 실시간 위치 현황
// 점검 예정 자산에서 사용 중
export const fetchIotItemList = param => reqGet('/v3/api/common/target/info', { ...param });
export const getIotItemApi = param => reqGet(`/v3/api/common/target/info/${param.targetNum}`);

// 모든 ioT 항목 수
export const fetchIoTItemCount = param => reqGet('/v3/api/common/statistics/count-by-category/count', { ...param });

//// 센서 현황
//매핑된 센서 현황 수 조회
export const getMappedSensorStatusApi = param => reqGet('/v3/api/common/statistics/sensor/total/count', param);

export const getAssetListApi = param => reqGet('/v3/api/common/target/info', param);

export const getSelectedAssetInfoApi = param => reqGet(`/v3/api/common/target/info/${param.targetKey}`, param);

export const getAssetStatusListApi = param => reqGet('/v3/api/common/target/status/category/info', param);

export const getOwnershipListApi = param => reqGet('/v3/api/common/user/group/info', param);

//대상 상태 정보 로그 api
export const getAssetStatusLogApi = param => reqGet('/v3/api/common/target/status/statistics/log', param);

//대상 상태 범주 정보 api
export const getStatusCategoryApi = param => reqGet('/v3/api/common/target/status/category/info', param);

//자산 대상 프레즌스 정보 조회 api
export const getRealtimePresenceInfoApi = param => reqGet(`/v3/api/common/realtime/presence/${param.targetNum}`, param);

//대상 상태 정보 단일 조회 api
export const getSingleStatusInfoApi = param => reqGet(`/v3/api/common/target/status/info/${param.statusId}`, param);

//대상 상태 리스트 조회 api
export const getStatusInfoApi = param => reqGet(`/v3/api/common/target/status/info`, param);

// 대상 상태 정보 변경 API
export const updateAssetStatusApi = ({ targetNum, ...restParam }) =>
    reqPut(`/v3/api/common/target/info/status/${targetNum}`, restParam);

//// 자산 대여 요청 API
// 자산 대여 요청 목록 조회
export const getAssetRentalRequestListApi = param => reqGet(`/v3/api/am01/target/rental/apply`, param);
// 자산 대여 정보 단일 조회
export const getAssetRentalRequestApi = param => reqGet(`/v3/api/am01/target/rental/apply/${param.reqNum}`, param);
// 자산 대여 요청 정보 등록
export const createAssetRentalRequestApi = param => reqPost(`/v3/api/am01/target/rental/apply`, param);
// 자산 대여 요청 상태 변경
export const updateAssetRentalRequestStatusApi = param =>
    reqPatch(`/v3/api/am01/target/rental/apply/${param.reqNum}`, param);

//// 자산 점검 API
// 자산 점검 이력 API
export const getAssetInspectionListApi = param => reqGet(`/v3/api/am01/target/inspect/log`, param);

// 자산 점검 이력 등록 API
export const createAssetInspectionApi = param => reqPost('/v3/api/am01/target/inspect/log', param);

// 자산 점검 이력 내보내기 API
export const exportAssetInspectionApi = ({ columnMetas, zipFileName, fileName, ...restParam }) =>
    reqPost(
        '/v3/api/am01/target/inspect/log/export',
        {
            columnMetas,
            zipFileName,
            fileName,
        },
        restParam,
    );

//// 대여 가능 자산
export const getAvailableAssetListApi = param => reqGet(`/v3/api/am01/target/rental/available`, param);

////자산 대여 내역
// 자산 대여 내역 목록 조회
export const getBorrowedAssetListApi = param => reqGet(`/v3/api/am01/target/rental`, param);
// 자산 대여 승인
export const createAssetRentalApprovalApi = param => reqPost(`/v3/api/am01/target/rental`, param);
// 자산 대여 상태 변경
export const updateAssetRentalStatusApi = param => reqPatch(`/v3/api/am01/target/rental/${param.targetNum}`, param);

//// 자산 수리 접수 API
// 자산 수리 접수 정보 목록 조회
export const getRepairReceptionListApi = param => reqGet(`/v3/api/am01/target/repair/reception`, param);

// 자산 수리 접수 등록
export const createRepairReceptionApi = param => reqPost('/v3/api/am01/target/repair/reception', param);

// 자산 수리 접수 삭제
export const deleteRepairReceptionApi = param => reqDeleteBody('/v3/api/am01/target/repair/reception', param);

// 자산 수리 접수 정보 상세 조회
export const getRepairReceptionApi = param =>
    reqGet(`/v3/api/am01/target/repair/reception/${param.receptionNum}`, param);

// 자산 수리 접수 취소
export const updateCancelRepairReceptionApi = param =>
    reqPut(`/v3/api/am01/target/repair/reception/${param.receptionNum}`, param);

// 자산 수리 접수 수정
export const updateRepairReceptionApi = param =>
    reqPatch(`/v3/api/am01/target/repair/reception/${param.receptionNum}`, param);

// 자산 수리 접수 목록 엑셀 다운로드
export const fetchRepairReceptionExportApi = ({ columnMetas, zipFileName, fileName, ...restParam }) =>
    reqPost(`/v3/api/am01/target/repair/reception/export`, { columnMetas, zipFileName, fileName }, restParam);

//// 자산 수리 인계 API
// 자산 수리 인계 목록 조회
export const getRepairTakeoverListApi = param => reqGet(`/v3/api/am01/target/repair/takeover`, param);

// 자산 수리 인계 상세 조회
export const getRepairTakeoverApi = param => reqGet(`/v3/api/am01/target/repair/takeover/${param.receptionNum}`, param);

// 자산 수리 인계 취소
export const updateCancelRepairTakeoverApi = param =>
    reqPut(`/v3/api/am01/target/repair/takeover/${param.receptionNum}`, param);

// 자산 수리 인계 수정
export const updateRepairTakeoverApi = param =>
    reqPatch(`/v3/api/am01/target/repair/takeover/${param.receptionNum}`, param);

// 자산 수리 인계 목록 엑셀 다운로드
export const fetchRepairTakeoverExportApi = ({ columnMetas, zipFileName, fileName, ...restParam }) =>
    reqPost(`/v3/api/am01/target/repair/takeover/export`, { columnMetas, zipFileName, fileName }, restParam);

// 당일 기준 인계 완료된 자산 목록 조회 API
export const getRepairTakeoverDashboard = param => reqGet(`/v3/api/am01/target/repair/takeover/dashboard`, param);

// 당일 기준 접수 완료된 자산 목록 조회 API
export const getRepairReceptionDashboard = param => reqGet(`/v3/api/am01/target/repair/reception/dashboard`, param);

// 자산 인계 및 수리 로그 조회 API
export const fetchAssetReceiptAndHandoverLogApi = param => reqGet(`/v3/api/am01/target/repair/log`, param);
