import React, { useEffect, useState } from 'react';
import CustomRadio from '../../Components/CustomRadio';
import CustomCheckBox from '../../Components/CustomCheckBox';
import { useRecoilState, useRecoilValue } from 'recoil';
import { equipmentEvaluationState, selectedAssetInfoState } from '../../../atoms';
import {
    ACCUMULATED_REPAIR_COST,
    CURRENT_PRODUCTION_STATUS_ARR,
    SERVICE_SUPPORT_CAPABILITY,
    YEAR_OF_INTRODUCTION_ARR,
} from '../../../../util/sheetOption';
import ScoreSheet from '../../../../../Common/ScoreSheet';
import useTranslation from '../../../../../../util/hooks/useTranslation';

const Obsoleteness = () => {
    const t = useTranslation('Obsoleteness');
    const [score, setScore] = useState(0);
    const [otherEvaluationItemsFlag, setOtherEvaluationItemsFlag] = useState(false);
    const [evaluationInfo, setEvaluationInfo] = useRecoilState(equipmentEvaluationState);
    const { receDate, ownPrice, fixAmt } = useRecoilValue(selectedAssetInfoState);
    const { oldEcd, oldEcd2, oldEcd3, oldEcd4, oldPcd, oldPcd2, oldPcd3, oldPcd4 } = evaluationInfo;
    useEffect(() => {
        let calculatedScore = 0;
        if (oldEcd === 'A010101') {
            calculatedScore = 100;
        } else {
            if (oldEcd === 'A010201') {
                CURRENT_PRODUCTION_STATUS_ARR.map(item => {
                    if (item.value === parseInt(evaluationInfo.oldPcd)) {
                        calculatedScore += item.score;
                    }
                });
            }

            if (oldEcd2 === 'A010301') {
                YEAR_OF_INTRODUCTION_ARR.map(item => {
                    if (item.value === parseInt(oldPcd2)) {
                        calculatedScore += item.score;
                    }
                });
            }

            if (oldEcd3 === 'A010401') {
                switch (oldPcd3.split(',').length) {
                    case 4:
                        calculatedScore += 10;
                        break;
                    case 3:
                        calculatedScore += 8;
                        break;
                    case 2:
                        calculatedScore += 5;
                        break;
                    case 1:
                        calculatedScore += 3;
                        break;
                }
            }

            if (oldEcd4 === 'A010501') {
                ACCUMULATED_REPAIR_COST.map(item => {
                    if (item.value === parseInt(oldPcd4)) {
                        calculatedScore += item.score;
                    }
                });
            }
        }
        setOtherEvaluationItemsFlag(
            !!(oldEcd === 'A010201' || oldEcd2 === 'A010301' || oldEcd3 === 'A010401' || oldEcd4 === 'A010501'),
        );
        setScore(calculatedScore);
    }, [evaluationInfo]);

    useEffect(() => {
        setEvaluationInfo({
            oldPoint: score,
            ...evaluationInfo,
        });
    }, [score]);

    return (
        <ScoreSheet tableClassName="w-100" tbodyClassName="custom-evaluation-table">
            <tr className="tr">
                <td rowSpan={5} className="td">
                    10. {t('Obsoleteness')} (*)
                </td>
                <td className="td">{oldEcd === 'A010101' ? <span className="icon-check icon-filter-brand" /> : ''}</td>
                <td className="td">{t('Top priority reflection', 'Equipment evaluation')}</td>
                <td className="td">
                    <CustomRadio
                        text={t(
                            'Non-repairable equipment due to hospital policy equipment and parts discontinuation',
                            'Equipment evaluation',
                        )}
                        checked={oldEcd === 'A010101' && oldPcd === '10'}
                        handleChange={e => {
                            const { checked } = e.currentTarget;
                            let result = '';
                            if (checked && (!oldPcd || oldPcd !== '10' || oldEcd !== 'A010101')) {
                                result = '10';
                            }
                            setEvaluationInfo({
                                ...evaluationInfo,
                                oldEcd: result ? 'A010101' : '',
                                oldPcd: result,
                                oldEcd2: '0',
                                oldEcd3: '0',
                                oldEcd4: '0',
                                oldPcd2: '0',
                                oldPcd3: '0',
                                oldPcd4: '0',
                            });
                            setOtherEvaluationItemsFlag(false);
                        }}
                    />
                </td>
                <td rowSpan={5} className="td score">
                    {score}
                </td>
            </tr>
            <tr className="tr">
                <td className="td" rowSpan={4}>
                    {otherEvaluationItemsFlag ? <span className="icon-check icon-filter-brand" /> : ''}
                </td>
                <td className="td">{t('Current Production', 'Equipment evaluation')}</td>
                <td className="td">
                    {CURRENT_PRODUCTION_STATUS_ARR.map(item => {
                        const { title, value } = item;
                        return (
                            <CustomRadio
                                key={value}
                                text={t(title)}
                                value={value}
                                checked={oldEcd === 'A010201' && parseInt(oldPcd) === value}
                                handleChange={e => {
                                    const { checked } = e.currentTarget;
                                    let result = '';
                                    if (checked && (!oldPcd || parseInt(oldPcd) !== value || oldEcd !== 'A010201')) {
                                        result = value;
                                    }
                                    setEvaluationInfo({
                                        ...evaluationInfo,
                                        oldEcd: result ? 'A010201' : '0',
                                        oldPcd: result,
                                    });
                                }}
                            />
                        );
                    })}
                </td>
            </tr>
            <tr className="tr">
                <td className="td">{t('Year of introduction', 'Equipment evaluation')}</td>
                <td className="td">
                    <div className="d-flex justify-content-center">
                        <div className="d-flex flx-col" style={{ alignItems: 'flex-start' }}>
                            <div className="mb-2">
                                <span className="font-weight-bold">
                                    {t('Year of introduction', 'Equipment evaluation')}
                                </span>
                                <span className="font-weight-bold">{receDate}</span>
                            </div>
                            <div>
                                {YEAR_OF_INTRODUCTION_ARR.map(item => {
                                    const { title, value } = item;
                                    return (
                                        <CustomRadio
                                            key={value}
                                            text={t(title)}
                                            value={value}
                                            checked={oldEcd2 === 'A010301' && parseInt(oldPcd2) === value}
                                            handleChange={e => {
                                                const { checked } = e.currentTarget;
                                                let result = '';
                                                if (
                                                    checked &&
                                                    (!oldPcd2 || parseInt(oldPcd2) !== value || oldEcd2 !== 'A010301')
                                                ) {
                                                    result = value;
                                                }
                                                setEvaluationInfo({
                                                    ...evaluationInfo,
                                                    oldEcd2: result ? 'A010301' : '0',
                                                    oldPcd2: result,
                                                });
                                            }}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr className={'tr'}>
                <td className={'td'}>{t('Service Availability', 'Equipment evaluation')}</td>
                <td className={'td'}>
                    <div className={'d-flex flx-col'}>
                        <div>
                            {SERVICE_SUPPORT_CAPABILITY.map(item => {
                                const { value, title } = item;
                                const oldPcd3OriginalArr = evaluationInfo.oldPcd3
                                    ? evaluationInfo.oldPcd3.split(',')
                                    : [];
                                const oldPc3Arr = evaluationInfo.oldPcd3 ? oldPcd3OriginalArr : [];
                                const oldPc3ArrLength = oldPc3Arr.length;
                                return (
                                    <CustomCheckBox
                                        key={value}
                                        checked={oldEcd3 === 'A010401' && oldPc3ArrLength && oldPc3Arr.includes(value)}
                                        text={t(title)}
                                        handleClick={() => {
                                            let tempArr = [];

                                            if (
                                                !oldPc3ArrLength ||
                                                (oldPc3ArrLength && !oldPc3Arr.includes(value)) ||
                                                oldEcd3 !== 'A010401'
                                            ) {
                                                tempArr = [...oldPc3Arr, value];
                                            } else if (oldPc3ArrLength && oldPc3Arr.includes(value)) {
                                                tempArr = oldPc3Arr.filter(keyword => keyword !== value);
                                            }

                                            setEvaluationInfo({
                                                ...evaluationInfo,
                                                oldEcd3: tempArr.length ? 'A010401' : '0',
                                                oldPcd3: tempArr.join(',').length ? tempArr.join(',') : '0',
                                            });
                                        }}
                                    />
                                );
                            })}
                        </div>
                        <div>{t('4(10) / 3(8) / 2(5) / 1(3)', 'Equipment evaluation')}</div>
                    </div>
                </td>
            </tr>
            <tr className="tr">
                <td className="td">
                    {t('Accumulated repair costs (as a percentage of the purchase price)', 'Equipment evaluation')}
                </td>
                <td className="td">
                    <div className="d-flex justify-content-center">
                        <div className="d-flex flx-col" style={{ alignItems: 'flex-start' }}>
                            <div>
                                <span className="font-weight-bold">{t('Acquisition amount', 'CommonColumn')}: </span>
                                <span className="font-weight-bold">{ownPrice}</span>
                            </div>
                            <div>
                                <span className="font-weight-bold">
                                    {t('Cumulative repair costs', 'CommonColumn')}:{' '}
                                </span>
                                <span className="font-weight-bold">{fixAmt}</span>
                            </div>
                            <div>
                                {ACCUMULATED_REPAIR_COST.map(item => {
                                    const { value, title } = item;
                                    return (
                                        <CustomRadio
                                            key={value}
                                            text={t(title)}
                                            value={value}
                                            checked={oldEcd4 === 'A010501' && parseInt(oldPcd4) === value}
                                            handleChange={e => {
                                                const { checked } = e.currentTarget;
                                                let result = '';
                                                if (
                                                    checked &&
                                                    (!oldPcd4 || parseInt(oldPcd4) !== value || oldEcd4 !== 'A010501')
                                                ) {
                                                    result = value;
                                                }
                                                setEvaluationInfo({
                                                    ...evaluationInfo,
                                                    oldEcd4: result ? 'A010501' : '0',
                                                    oldPcd4: result,
                                                });
                                            }}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        </ScoreSheet>
    );
};

export default Obsoleteness;
