import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Button from '../../../../../Common/Button';

const ValidityCheckModal = ({
    initModal = false,
    categories,
    toggleModal = function () {
        initModal = !initModal;
    },
    ...restProps
}) => {
    return (
        <Modal
            container={document.getElementsByClassName('app-container')[0]}
            className="pnt-modal"
            isOpen={initModal}
            toggle={toggleModal}
            {...restProps}
        >
            <ModalHeader toggle={() => toggleModal()}>필수조건 미입력 알림</ModalHeader>
            <ModalBody>
                <div className="d-flex align-items-center flx-col mt-3 mb-3">
                    <span className="d-block">필수입력 항목이 입력되지 않아 저장할 수 없습니다.</span>
                    <div>
                        {categories.map(cate => (
                            <span key={cate}>{cate}번 </span>
                        ))}
                        <span>항목</span>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button className="btn-secondary" onClick={toggleModal}>
                    확인
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ValidityCheckModal;
