import axios from 'axios';
import envKeys from '../environment';
import { checkResponseErr, getLsUserInfo } from '../util/common/util';
import { store } from '../index';
import { setError } from '../reducers/ErrorHandler';

axios.defaults.headers.common['Content-Type'] = 'application/json; charset=UTF-8';

const { userInfoKey } = envKeys;

export const getApiURL = async function () {
    let apiFile = 'api.json';
    if (process.env.NODE_ENV === 'development') {
        apiFile = 'devApi.json';
    }

    return await fetch(`config/${apiFile}`)
        .then(response => {
            return response.json();
        })
        .then(config => {
            return config;
        })
        .catch(error => {
            console.log({ error });
            return {};
        });
};

const apiUrl = getApiURL();

const createConfigHandler = apiURLKey => async config => {
    const baseURL = await apiUrl;
    if (baseURL && baseURL[apiURLKey]) {
        config.baseURL = baseURL[apiURLKey];
    }
    if (localStorage.getItem(userInfoKey)) {
        const { oAuthInfo, userInfo } = getLsUserInfo();
        if (oAuthInfo && oAuthInfo.access_token) {
            config.headers['UUID'] = oAuthInfo.scope;
            config.headers['Authorization'] = `${oAuthInfo.token_type} ${oAuthInfo.access_token}`;
        }
        if (userInfo && userInfo.companyInfo && userInfo.companyInfo.uuid) {
            config.headers['UUID'] = userInfo.companyInfo.uuid;
            config.headers['token'] = userInfo.companyInfo.secret;
        }
    }
    return config;
};

const errHandler = error => {
    return Promise.reject(error);
};

const errorResponseHandler = response => {
    if (checkResponseErr(response)) {
        store.dispatch(setError(response));
    }
    return response;
};

const errorRejectHandler = error => {
    const { response } = error;
    let errMsg;

    if (checkResponseErr(response) && response.data.message) {
        errMsg = response;
    } else {
        errMsg = {
            ...response,
            data: { ...response.data, message: 'The request failed.' },
        };
    }
    store.dispatch(setError(errMsg));

    return Promise.reject(error);
};

const wmsAxios = axios.create({
    baseURL: process.env.REACT_APP_WMS_API_URL,
});
wmsAxios.interceptors.request.use(createConfigHandler('wmsApiUrl'), errHandler);
wmsAxios.interceptors.response.use(errorResponseHandler, errorRejectHandler);

const oAuthAxios = axios.create({
    baseURL: process.env.REACT_APP_OAUTH_URL,
});
oAuthAxios.interceptors.request.use(createConfigHandler('oAuthURL'), errHandler);
oAuthAxios.interceptors.response.use(errorResponseHandler, errorRejectHandler);

export const reqGet = (url, param) => wmsAxios.get(url, { params: param });
export const reqPost = (url, param, reqConfig) => wmsAxios.post(url, param, { params: reqConfig });
export const reqPut = (url, param) => wmsAxios.put(url, param);
export const reqPatch = (url, param) => wmsAxios.patch(url, param);
export const reqDelete = (url, param) => wmsAxios.delete(url, { params: param });
export const oAuthPost = (url, param) => oAuthAxios.post(url, param);
export const reqDeleteBody = (url, param) => wmsAxios.delete(url, { data: param });
export const oAuthAmPost = (url, param) => wmsAxios.post(url, param);
export default {
    reqGet,
    reqPost,
    reqPut,
    reqPatch,
    reqDelete,
    oAuthPost,
};
