import React from 'react';
import { ErrorHandleSwitch } from '../Components/Router';
import { Route } from 'react-router-dom';
import RegisteredRepairStatus from './RegisteredRepairStatus';
import TransferredRepairStatus from './TransferredRepairStatus';
import RegisteredRepairManagement from './RegisteredRepairManagement';
import TransferredRepairManagement from './TransferredRepairManagement';
import RegisterDetail from './RegisteredRepairManagement/RegisterDetail';
import RepairRegister from './RegisteredRepairManagement/RepairRegister';
import RepairDetail from './TransferredRepairManagement/RepairDetail';
import RecordManagement from './CommutingRecord/RecordManagement';
import ScheduleManagement from './CommutingRecord/ScheduleManagement';
import useAsync from '../../../util/hooks/useAsync';
import { fetchCommuteCodeApi, fetchCommuteDeptApi } from '../../../api/engineering';
import { useSetRecoilState } from 'recoil';
import { commuteCodeList, commuteDeptList } from '../MedicalEngineering/atoms';
import HandoverReception from './HandoverReception';
import RepairReception from './RepairReception';
import AssetReceiptAndHandoverLog from './AssetReceiptAndHandoverLog';
import RepairAndTakeoverUserLog from './RepairAndTakeoverUserLog/RepairAndTakeoverUserLog';

const AssetAccessSystem = ({ match }) => {
    const setCommuteDeptList = useSetRecoilState(commuteDeptList);
    useAsync({
        promise: fetchCommuteDeptApi,
        immediate: true,
        resolve: res => {
            const { rows } = res;
            const deptList = rows.reduce((acc, cur) => {
                const { groupId, groupName } = cur;
                acc.push({
                    value: groupId,
                    label: groupName,
                });
                return acc;
            }, []);
            setCommuteDeptList(deptList);
        },
        reject: err => console.error(err),
    });
    const setCommuteCodeList = useSetRecoilState(commuteCodeList);
    useAsync({
        promise: fetchCommuteCodeApi,
        immediate: true,
        fixedParam: {
            gCD: 'COMMUTE',
        },
        resolve: res => {
            const { rows } = res;
            const commuteCodeList = rows.reduce((acc, curr) => {
                const { sCD, sName } = curr;
                acc.push({
                    value: sCD,
                    label: sName,
                });
                return acc;
            }, []);
            setCommuteCodeList(commuteCodeList);
        },
    });

    return (
        <ErrorHandleSwitch>
            <Route exact path={`${match.path}/register-status`} component={RegisteredRepairStatus} />
            <Route exact path={`${match.path}/transfer-status`} component={TransferredRepairStatus} />
            <Route exact path={`${match.path}/register-manage`} component={RegisteredRepairManagement} />
            <Route exact path={`${match.path}/register-manage/:receptionNum([0-9]+)`} component={RegisterDetail} />
            <Route exact path={`${match.path}/register-manage/repair`} component={RepairRegister} />
            <Route exact path={`${match.path}/transfer-manage`} component={TransferredRepairManagement} />
            <Route exact path={`${match.path}/transfer-manage/:receptionNum([0-9]+)`} component={RepairDetail} />
            <Route exact path={`${match.path}/commuting-record`} component={RecordManagement} />
            <Route exact path={`${match.path}/commuting-schedule`} component={ScheduleManagement} />
            <Route exact path={`${match.path}/repair-reception`} component={RepairReception} />
            <Route exact path={`${match.path}/handover-reception`} component={HandoverReception} />
            <Route exact path={`${match.path}/receipt-handover-log`} component={AssetReceiptAndHandoverLog} />
            <Route exact path={`${match.path}/repair-takeover-user-log`} component={RepairAndTakeoverUserLog} />
        </ErrorHandleSwitch>
    );
};

export default AssetAccessSystem;
