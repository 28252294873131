import React, { useEffect, useState } from 'react';
import Search from './Search';
import Card from '../../../Common/Card';
import Table from '../../../Common/Table';
import Page from '../../../Common/Page';
import useTranslation from '../../../../util/hooks/useTranslation';
import FilterSearchGroup from '../../../Common/FilterSearchGroup';
import { useParams } from 'react-router-dom';
import useAsync from '../../../../util/hooks/useAsync';
import { getSelectedFilterApi } from '../../../../api/filter';
import { fetchIotItemList } from '../../../../api/asset';
import FilterWidget from '../FilterWidget';
import useColumns from '../../../../util/hooks/useColumns';
import * as col from '../../util/grid/column/filter';
import { makeFilterParam } from '../reducer';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const tableHeight = { height: 'calc(100vh - 60px - 4rem - 88px - 3rem - 60px - 4rem - 4px)' };
export const ITEM_LIST_PAGE_SIZE = 20;

const ItemList = () => {
    const t = useTranslation('Filter');
    const commonColumnT = useTranslation('CommonColumn');
    const { filterNum } = useParams();
    const [filterInfo, setFilterInfo] = useState({});
    const [itemList, setItemList] = useState({ rows: [], pageSize: ITEM_LIST_PAGE_SIZE });
    const [listParam, setListParam] = useState({});

    const itemColumns = useColumns(
        [
            col.category(),
            col.name(),
            col.num(),
            col.location(),
            {
                Header: 'Bookmark',
                accessor: 'isBookmark',
                Cell: ({ value }) => {
                    return value === 'Y' && <FontAwesomeIcon icon={faStar} style={{ color: '#ffd400' }} />;
                },
                t: commonColumnT,
            },
        ],
        t,
    );

    const { promise: getIotItemList } = useAsync({
        promise: fetchIotItemList,
        fixedParam: { pageSize: ITEM_LIST_PAGE_SIZE },
        resolve: res => {
            if (res) {
                setItemList({ ...res, pageSize: ITEM_LIST_PAGE_SIZE });
            }
        },
        deps: [filterNum],
    });

    const { promise: getFilterInfo } = useAsync({
        promise: getSelectedFilterApi,
        resolve: res => {
            setFilterInfo(res);
        },
    });

    const handlePageChange = pageIndex => {
        getIotItemList({
            ...listParam,
            page: pageIndex,
        });
    };

    const handleFilterConditionChange = selected => {
        const changedFilterInfoCondition = filterInfo.filterInfoCondition.map(conditionInfo => {
            if (conditionInfo.conditionId === selected.conditionId) {
                return { ...conditionInfo, conditionValues: selected.conditionValues };
            }
            return conditionInfo;
        });
        setFilterInfo({ ...filterInfo, filterInfoCondition: changedFilterInfoCondition });
    };

    useEffect(() => {
        if (filterNum) {
            getFilterInfo({ filterNum: Number(filterNum) });
        }
    }, [filterNum]);

    useEffect(() => {
        if (filterInfo.filterNum) {
            setListParam({ ...makeFilterParam(filterInfo.filterInfoCondition), pageSize: ITEM_LIST_PAGE_SIZE });
        }
    }, [filterInfo.filterNum]);

    useEffect(() => {
        getIotItemList(listParam);
    }, [listParam]);
    return (
        <Page menuPath={[t('Filters', 'Menu'), t('Item List', 'Menu')]} className={'h-100'}>
            <FilterSearchGroup>
                <FilterWidget
                    filterInfo={filterInfo}
                    withContainer={false}
                    changeableFilter={false}
                    handleFilterConditionChange={handleFilterConditionChange}
                />
                <Search
                    handleSearchClick={searchOption => {
                        let param = {
                            ...makeFilterParam(filterInfo.filterInfoCondition),
                            pageSize: ITEM_LIST_PAGE_SIZE,
                        };
                        if (searchOption.opt && searchOption.keyword) {
                            param = { ...param, ...searchOption };
                        }
                        setListParam(param);
                    }}
                />
            </FilterSearchGroup>
            <Card
                header={{
                    title: filterInfo.filterName,
                }}
            >
                <div style={tableHeight}>
                    <Table onPageChange={handlePageChange} data={itemList} columns={itemColumns} />
                </div>
            </Card>
        </Page>
    );
};

export default ItemList;
