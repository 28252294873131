import { reqGet, reqPost, reqPut, reqDeleteBody, reqPatch, reqDelete } from './index';

//// 출퇴근시스템 API
// 출퇴근시스템 기록 추가 API
export const createCommuteApi = param => reqPost('/v3/api/am01/commute', param);

// 출퇴근시스템 기록 조회 API
export const fetchCommuteAPi = param => reqGet(`/v3/api/am01/commute/${param.cmNum}`, param);

// 출퇴근시스템 기록 수정 API
export const updateCommuteApi = param => reqPut(`/v3/api/am01/commute/${param.cmNum}`, param);

// 공통코드/명 조회 API
export const fetchCommuteCodeApi = param => reqGet(`/v3/api/am01/commute/code`, param);

// 부서코드/명 조회 API
export const fetchCommuteDeptApi = param => reqGet(`/v3/api/am01/commute/dept`, param);

// 출퇴근시스템 기록관리 목록 API
export const fetchCommuteLogApi = param => reqGet('/v3/api/am01/commute/log', param);

// 출퇴근시스템 기록 조회 엑셀 다운로드 API
export const createCommuteLogExport = ({ columnMetas, zipFileName, fileName, ...restParam }) =>
    reqPost(`/v3/api/am01/commute/log/export`, { columnMetas, zipFileName, fileName }, restParam);

// 출퇴근시스템 스케쥴관리 목록 API
export const fetchCommuteScheduleList = param => reqGet('/v3/api/am01/commute/schedule', { ...param });

//// 결산 전산화 시스템 API
// 결산 전산화 관리 API
export const fetchAccountSettleList = param => reqGet(`/v3/api/am01/account/settle`, param);

// 결산 전산화 상세 API
export const fetchAccountSettleDetail = param => reqGet(`/v3/api/am01/account/settle/detail`, param);

// 엑셀 내보내기 API
export const fetchAccountSettleExportExcel = param => reqGet(`/v3/api/am01/account/settle/export/excel`, param);

// 결산 전산화 상세 수정 API
export const updateAccountSettleModify = param => reqPatch(`/v3/api/am01/account/settle/modify`, param);

// 수리 내역 상세 API
export const fetchAccountSettleRepairDetail = param => reqGet(`/v3/api/am01/account/settle/repair/detail`, param);

// 수리 내역 수정 API
export const updateAccountSettleRepairModify = param => reqPatch(`/v3/api/am01/account/settle/repair/modify`, param);

//// 결산 전산화 시스템 담당자 API
// 담당자 목록 조회 API
export const fetchAccountEmployeeList = param => reqGet(`/v3/api/am01/account/employee`, param);

// 담당자 삭제 API
export const deleteAccountEmployee = param => reqDeleteBody(`/v3/api/am01/account/employee/delete`, param);

// 담당자 조회 API
export const fetchAccountEmployeeDetail = param => reqGet(`/v3/api/am01/account/employee/detail`, param);

// 담당자 수정 API
export const updateAccountEmployeeModify = param => reqPatch(`/v3/api/am01/account/employee/modify`, param);

// 담당자 등록 API
export const createAccountEmployeeRegist = param => reqPost(`/v3/api/am01/account/employee/regist`, param);

//// 심의평가 API
// 심의평가 리스트 조회 API
export const fetchEvaluationListApi = param => reqGet(`/v3/api/am01/deliberation/list`, param);
// 심의평가 부서코드 조회 API
export const fetchEvaluationDepartmentCodeApi = param => reqGet(`/v3/api/am01/deliberation/dept`, param);

// 심의평가 이미지 등록 API
export const uploadEquipmentImageApi = param => {
    const formData = new FormData();
    formData.append('imageName ', param.imageName);
    //formData.append('binary',param.binary);

    return reqPost(`/v3/api/am01/deliberation/img`, formData);
};

// 심의평가 등록 API
export const createEvaluationApi = param => reqPost(`/v3/api/am01/deliberation`, param);

// 심의평가 삭제 API
export const deleteEvaluationApi = param => reqDeleteBody('/v3/api/am01/deliberation', param);

// 심의평가 자산 리스트 API
export const getEquipmentListApi = param => reqGet('/v3/api/am01/deliberation/assetList', param);

// 선택된 심의평가 정보 API
export const getSelectedEvaluationApi = param => reqGet(`/v3/api/am01/deliberation/${param.drNum}`, param);

// 심의평가 수정 API
export const editSelectedEvaluationApi = param => reqPut(`/v3/api/am01/deliberation/${param.drNum}`, param);

// 심의평가 엑셀 내보내기 API
export const getExcelApi = param => reqGet(`/v3/api/am01/deliberation/export`, param);

//결산 자동 전산화 버튼 API
export const createSettlementAutomation = param => reqPost('/v3/api/am01/account/settle/interlock', param);

//바코드
export const createControlNumber = param => reqPost('/v3/api/am01/transition/execute/barcode', param);
