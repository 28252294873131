import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import audio1 from '../../../../assets/audios/alarm_sound1.mp3';
import cx from 'classnames';
import { useSelector } from 'react-redux';

const CustomModalBody = styled(ModalBody)`
    min-height: 90px;
`;

const SubTitle = styled.p`
    line-height: 1.7;
    font-size: 1rem;
    font-weight: 400;
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.6);
    margin: 0;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    overflow: hidden;
`;

const ErrorModal = ({
    initModal = false,
    toggleModal = function () {
        initModal = !initModal;
    },
    okCallback,
    cancelCallback,
    callbackParam,
    header = { title: '', subTitle: '', className: '' },
    confirmText = 'Are you sure?',
    removeCancel,
    removeOk,
    okClassName,
    className,

    ...restProps
}) => {
    const { t } = useTranslation();
    const { fullScreen } = useSelector(State => State.DashboardFrame);

    return (
        <Modal
            container={fullScreen ? '.app-main__inner' : document.getElementsByClassName('app-container')[0]}
            className={cx('pnt-modal', className)}
            isOpen={initModal}
            toggle={toggleModal}
            {...restProps}
        >
            <ModalHeader className={header.className} toggle={toggleModal}>
                {header.title ? header.title : t('ConfirmModal;Confirm')}
                {!!header.subTitle && <SubTitle>{header.subTitle}</SubTitle>}
            </ModalHeader>
            <CustomModalBody className={'flex-center'}>
                {typeof confirmText === 'string' ? t(confirmText) : confirmText}
            </CustomModalBody>
            <ModalFooter className={cx(!removeCancel && 'justify-content-between')}>
                {!removeCancel && (
                    <Button
                        color="secondary"
                        onClick={e => {
                            if (typeof cancelCallback === 'function') {
                                cancelCallback(callbackParam);
                            }
                            toggleModal();
                        }}
                    >
                        {t('ConfirmModal;Cancel')}
                    </Button>
                )}
                {!removeOk && (
                    <>
                        <Button
                            className={okClassName}
                            color="primary"
                            onClick={e => {
                                if (typeof okCallback === 'function') {
                                    okCallback(callbackParam);
                                }
                                toggleModal();
                            }}
                        >
                            {t('ConfirmModal;OK')}
                        </Button>
                    </>
                )}
            </ModalFooter>
            <audio src={audio1} autoPlay />
        </Modal>
    );
};

export default ErrorModal;
