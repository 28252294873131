import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import useTranslation from '../../../../../../util/hooks/useTranslation';
import Button from '../../../../../Common/Button';
import CustomLabel from '../../../Components/CustomLabel';

const ModifyModal = ({
    initModal = false,
    toggleModal = function () {
        initModal = !initModal;
    },
    header = { title: '' },
    name,
    okClassName,
    handleChangeInput,

    ...restProps
}) => {
    const t = useTranslation();

    const [modifyInput, setModifyInput] = useState('');

    useEffect(() => {
        if (!initModal) {
            setModifyInput('');
        }
    }, [initModal]);

    const handleModifyInput = e => {
        if (parseInt(e.target.value) <= 2000000000) {
            setModifyInput(e.target.value);
        }
    };

    return (
        <Modal
            container={document.getElementsByClassName('app-container')[0]}
            className={'pnt-modal'}
            isOpen={initModal}
            toggle={toggleModal}
            {...restProps}
        >
            <ModalHeader className={header.className} toggle={toggleModal}>
                {header.title}
            </ModalHeader>
            <ModalBody>
                <CustomLabel
                    name={t('Modifications', 'SettlementDetailModal')}
                    value={
                        <div className="pnt-input--group">
                            <input
                                type="number"
                                className="pnt-input no-spin"
                                name={name}
                                value={modifyInput}
                                onChange={handleModifyInput}
                                min="0"
                            />
                        </div>
                    }
                />
            </ModalBody>
            <ModalFooter>
                <Button
                    className={'color-secondary'}
                    onClick={e => {
                        toggleModal();
                    }}
                >
                    {t('ConfirmModal;Cancel')}
                </Button>

                <Button
                    className={'btn-secondary'}
                    onClick={e => {
                        handleChangeInput({ name, value: parseInt(modifyInput, 10) });
                        toggleModal();
                    }}
                >
                    {t('ConfirmModal;OK')}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ModifyModal;
