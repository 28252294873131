import React, { useEffect, useState, useMemo, useCallback } from 'react';
import Search from './Components/Search';
import Card from '../../../../Common/Card';
import useTranslation from '../../../../../util/hooks/useTranslation';
import Table from './Components/CustomTable';
import useAsync from '../../../../../util/hooks/useAsync';
import useColumns from '../../../../../util/hooks/useColumns';
import * as column from '../../../util/grid/column';
import moment from 'moment';
import { fetchCommuteScheduleList } from '../../../../../api/engineering';
import { useRecoilValue } from 'recoil';
import RecordModifyModal from '../Components/RecordModifyModal';
import { useSelector } from 'react-redux';
import { scheduleSearchState } from '../../../MedicalEngineering/atoms';

const ScheduleManagement = () => {
    const t = useTranslation('ScheduleManagement');
    const weekT = useTranslation('Week');

    const {
        userInfo: { groupId },
    } = useSelector(state => state.UserInfo);

    const [recordModifyModal, setRecordModifyModal] = useState(false);
    const [selectedCmNum, setSelectedCmNum] = useState(null);
    const [selectedRegDate, setSelectedRegDate] = useState(null);
    const [trData, setTrData] = useState({});

    const [scheduleData, setScheduleData] = useState({ rows: [] });

    const searchOption = useRecoilValue(scheduleSearchState);
    const { clickSearch, searchDate, targetName } = searchOption;

    // 일자 구하기
    const pickedWeeksInfo = useMemo(() => {
        const pickedDay = moment(searchDate).isoWeekday(1);

        let firstDayMonth = null;
        let lastDayMonth = null;

        const weeks = [];
        for (let i = 1; i < 8; i++) {
            weeks.push(pickedDay.clone().weekday(i).format('YYYYMMDD'));
            if (i === 1) {
                firstDayMonth = pickedDay.weekday(i).format('M');
            }
            if (i === 7) {
                lastDayMonth = pickedDay.weekday(i).format('M');
            }
        }
        return { firstDayMonth, lastDayMonth, weeks };
    }, [searchDate]);

    // 일주일 column 만들기
    const dateColumns = useCallback(
        ({ pickedWeeks }) => {
            const weeks = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
            return weeks.reduce((acc, cur, idx) => {
                acc.push(
                    column.datesOfTheWeek({
                        day: weekT(`${cur}`),
                        width: 150,
                        date: pickedWeeks[idx],
                        accessor: pickedWeeks[idx],
                    }),
                );
                return acc;
            }, []);
        },
        [weekT],
    );

    const { firstDayMonth, lastDayMonth, weeks } = pickedWeeksInfo;

    const { promise: getCommuteScheduleList, state: commuteScheduleState } = useAsync({
        promise: fetchCommuteScheduleList,
        fixedParam: {
            sDate: moment(searchDate).format('YYYYMMDD'),
            deptCode: groupId,
            targetName,
        },
        resolve: res => {
            if (res.rows) {
                res.rows.sort((a, b) => {
                    if (a.targetName.toLowerCase() < b.targetName.toLowerCase()) return -1;
                    if (a.targetName.toLowerCase() > b.targetName.toLowerCase()) return 1;
                    return 0;
                });
            }
            setScheduleData(res);
        },
    });

    useEffect(() => {
        if (groupId && clickSearch) {
            getCommuteScheduleList();
        }
    }, [clickSearch, searchDate, groupId]);

    const makeWorkersNameHeader = (firstDayMonth, lastDayMonth) => {
        if (firstDayMonth === lastDayMonth) {
            return `${firstDayMonth} ${weekT('MTH')}`;
        } else {
            return `${firstDayMonth} ${weekT('MTH')} ~ ${lastDayMonth} ${weekT('MTH')}`;
        }
    };

    const columns = useColumns(
        [
            column.workersName({ Header: <>{makeWorkersNameHeader(firstDayMonth, lastDayMonth)}</> }),
            ...dateColumns({ pickedWeeks: weeks }),
        ],
        weekT,
        [weeks, weekT],
    );

    const handleRecordModifyModal = () => {
        setRecordModifyModal(prevState => !prevState);
    };

    return (
        <>
            <Search getCommuteScheduleList={getCommuteScheduleList} />
            <Card
                className="management-container schedule"
                header={{
                    title: t('Schedule Management', 'Menu'),
                }}
            >
                <Table
                    textAlign={'center'}
                    columns={columns}
                    data={scheduleData}
                    loading={commuteScheduleState.isLoading}
                    paging={false}
                    onTrClick={trValue => {
                        const { targetName, targetNum, empNo } = trValue;
                        setTrData({ targetName, targetNum, empNo });
                    }}
                    onCellClick={cellValue => {
                        setSelectedRegDate(cellValue.regDate);
                        if (cellValue && cellValue.cmNum) {
                            setSelectedCmNum(cellValue.cmNum);
                        } else {
                            setSelectedCmNum(null);
                        }
                        handleRecordModifyModal();
                    }}
                />
            </Card>
            <RecordModifyModal
                initModal={recordModifyModal}
                toggleModal={handleRecordModifyModal}
                selectedCmNum={selectedCmNum}
                trData={trData}
                selectedRegDate={selectedRegDate}
            />
        </>
    );
};

export default ScheduleManagement;
