import React, { useState, memo, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import useTranslation from '../../../../../../util/hooks/useTranslation';
import cx from 'classnames';
import Button from '../../../../../Common/Button';
import DatePicker from '../../../../../Common/DatePicker';
import moment from 'moment';
import TextInput from '../../../../../Common/Input/TextInput';
import CustomLabel from '../../../Components/CustomLabel';
import Table from '../../../../../Common/Table';
import useColumns from '../../../../../../util/hooks/useColumns';
import * as column from '../../../../util/grid/column';
import useConfirmModal from '../../../../../Common/ConfirmModal/useConfirmModal';
import useAsync from '../../../../../../util/hooks/useAsync';
import { fetchAccountSettleRepairDetail, updateAccountSettleRepairModify } from '../../../../../../api/engineering';

const handleChangeHomAppli = row => {
    if (row.homeAppli === 'Y') {
        row.homeAppli = 'N';
    } else {
        row.homeAppli = 'Y';
    }
};

const RepairDetailModal = ({
    initModal = false,
    toggleModal = function () {
        initModal = !initModal;
    },
    okCallback,
    cancelCallback,
    callbackParam,
    header = { title: '', className: '' },
    removeCancel,
    removeOk,
    okClassName,
    okButtonTitle,
    className,
    empData,
    searchMonth,
    getAccountSettleList,

    ...restProps
}) => {
    const t = useTranslation('RepairHistoryDetails');

    const [tableData, setTableData] = useState({ rows: [] });

    const [modifyList, setModifyList] = useState([]);

    const [inputs, setInputs] = useState({
        date: moment().valueOf(),
        name: '',
    });

    const { date, name } = inputs;

    useEffect(() => {
        if (initModal === false) {
            setModifyList([]);
        }
    }, [initModal]);

    const { promise: getAccountSettleRepairDetail, state: accountSettleRepairDetailState } = useAsync({
        promise: fetchAccountSettleRepairDetail,
        fixedParam: {
            isAll: 'Y',
        },
        resolve: res => {
            setTableData(res);
        },
    });

    const { promise: patchAccountSettleRepairModify } = useAsync({
        promise: updateAccountSettleRepairModify,
        resolve: res => {
            toggleModal();
            toggleModifyModal();
            getAccountSettleList();
        },
        isArray: true,
    });

    useEffect(() => {
        if (searchMonth && empData && initModal === true) {
            const { charge, empNum, empName } = empData;
            getAccountSettleRepairDetail({
                charge,
                dateCode: searchMonth,
                empNum,
            });
            setInputs(prevState => ({
                ...prevState,
                name: empName,
            }));
        }
    }, [searchMonth, empData, initModal]);

    // 시간 설정
    useEffect(() => {
        setInputs(prevState => ({
            ...prevState,
            date: moment(searchMonth, 'YYYY-MM').unix() * 1000,
        }));
    }, [searchMonth]);

    const columns = useColumns(
        [
            column.repairNo(),
            column.homeApplianceStatus({
                width: 70,
                Cell: ({ value, row: { original } }) => {
                    return (
                        <input
                            type="checkbox"
                            className="m-0 cursor-pointer scale-2"
                            checked={value === 'Y'}
                            onClick={() => {
                                setTableData(prevState => {
                                    const selectedDataIndex = prevState.rows.findIndex(
                                        modelData => modelData.no === original.no,
                                    );
                                    handleChangeHomAppli(prevState.rows[selectedDataIndex]);
                                    return { rows: [...prevState.rows] };
                                });
                                setModifyList(prevState => {
                                    const { dateNum, homeAppli, no } = original;
                                    const { charge, empNum } = empData;
                                    if (prevState.findIndex(modify => modify.no === original.no) === -1) {
                                        return [...prevState, { charge, empNum, dateNum, homeAppli, no }];
                                    } else {
                                        return prevState.filter(modify => modify.no !== original.no);
                                    }
                                });
                            }}
                        />
                    );
                },
            }),
            column.billingDepartment(),
            column.acceptanceDateOfApplication(),
            column.completionDate(),
            column.equipmentName(),
            column.quantity({ width: 50, accessor: 'quantity' }),
            column.actionClassification({ width: 100 }),
            column.causeClassification({ width: 100 }),
            column.costRequired({ width: 100 }),
            column.costClassification({ width: 100 }),
            column.repairCostReduction({ width: 100 }),
            column.repairServiceRepairCost({ width: 100 }),
            column.model({ width: 100 }),
        ],
        t,
        [t, empData],
    );

    const { toggleModal: toggleModifyModal, Modal: ModifyModal } = useConfirmModal({
        header: { title: t('Modify', 'ConfirmModal') },
        confirmText: t('The modification has been completed.', 'ConfirmModal'),
        removeCancel: true,
    });

    const { toggleModal: toggleSaveConfirmModal, Modal: SaveConfirmModal } = useConfirmModal({
        header: { title: t('Modify', 'ConfirmModal') },
        confirmText: t('Do you want to save the modifications?', 'SettlementDetailModal'),
        okCallback: () => {
            patchAccountSettleRepairModify(modifyList);
        },
    });

    const { toggleModal: toggleNoChangedModal, Modal: NoChangedModal } = useConfirmModal({
        header: { title: t('Notification', 'ConfirmModal') },
        confirmText: t('The modification does not exist.'),
        removeCancel: true,
    });

    return (
        <>
            <Modal
                container={document.getElementsByClassName('app-container')[0]}
                className={cx('pnt-modal', 'modal-max', className)}
                isOpen={initModal}
                toggle={toggleModal}
                size="xl"
                {...restProps}
            >
                <ModalHeader className={header.className} toggle={toggleModal}>
                    {t('View Repair History Details')}
                </ModalHeader>
                <ModalBody>
                    <CustomLabel
                        name={t('Year and Month in Managing', 'ManagerModal')}
                        value={
                            <div className={'settle-month-picker detail'}>
                                <DatePicker
                                    value={date}
                                    valueType={'ms'}
                                    dateFormat={`Y / MMM`}
                                    showMonthYearPicker
                                    showFullMonthYearPicker
                                    showTwoColumnMonthYearPicker
                                    withoutDay
                                    readOnly
                                />
                            </div>
                        }
                    />
                    <CustomLabel
                        name={t('Manager', 'ManagerModal')}
                        value={<TextInput name={'name'} value={name} inputGroupClassName={'form-disable'} />}
                    />
                    <div style={{ height: '26rem' }}>
                        <Table
                            data={tableData}
                            columns={columns}
                            paging={false}
                            loading={accountSettleRepairDetailState.isLoading}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    {!removeCancel && (
                        <Button
                            className={'color-secondary'}
                            onClick={e => {
                                if (typeof cancelCallback === 'function') {
                                    cancelCallback(callbackParam);
                                }
                                toggleModal();
                            }}
                        >
                            {t('Cancel', 'ConfirmModal')}
                        </Button>
                    )}

                    {!removeOk && (
                        <Button
                            className={cx(okClassName, 'btn-secondary')}
                            onClick={e => {
                                if (typeof okCallback === 'function') {
                                    okCallback(callbackParam);
                                }
                                if (!modifyList.length) {
                                    toggleNoChangedModal();
                                    return;
                                }
                                toggleSaveConfirmModal();
                            }}
                        >
                            {t('Modify', 'ConfirmModal')}
                        </Button>
                    )}
                </ModalFooter>
            </Modal>
            <ModifyModal />
            <SaveConfirmModal />
            <NoChangedModal />
        </>
    );
};

export default memo(RepairDetailModal);
