import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Card from '../../../Common/Card';
import Page from '../../../Common/Page';
import useTranslation from '../../../../util/hooks/useTranslation';
import FilterSearchGroup from '../../../Common/FilterSearchGroup';
import SearchGroup from '../../../Common/FilterSearchGroup/Components/SearchGroup';
import SearchButtonArea from '../../../Common/FilterSearchGroup/Components/SearchButtonArea';
import Button from '../../../Common/Button';
import ReactSelect from '../../../Common/Select';
import Table from '../../../Common/Table';
import useColumns from '../../../../util/hooks/useColumns';
import * as col from '../../util/grid/column';
import {
    deleteEvaluationApi,
    fetchEvaluationDepartmentCodeApi,
    fetchEvaluationListApi,
} from '../../../../api/engineering';
import useAsync from '../../../../util/hooks/useAsync';
import useConfirmModal from '../../../Common/ConfirmModal/useConfirmModal';
import ExcelExport from './Components/ExcelExport';
import { useRecoilState } from 'recoil';
import { equipmentEvaluationState } from '../atoms';
import SearchableSelect from '../../../Common/Select/SearchableSelect';

const createYearOption = () => {
    const currentYear = moment(moment().year()).add(1);
    const tenYearsOption = [];
    for (let i = 0; i <= 10; i++) {
        let year = currentYear - i;
        tenYearsOption.push({ value: year.toString(), label: year.toString() });
    }
    return tenYearsOption;
};

const EquipmentEvaluation = () => {
    const history = useHistory();
    const columnT = useTranslation('CommonColumn');
    const t = useTranslation('Equipment evaluation');
    const [evaluationInfo, setEvaluationInfo] = useRecoilState(equipmentEvaluationState);

    // 기존에 useEffect에서 option 값을 생성 후 useState set함수를 사용하여 상태를 업데이트 하는 로직 사용
    // 하지만 batch 업데이트 이슈로 인해 초기값 설정이 안됨
    // option을 생성하는 함수를 useState default설정함
    const [yearOption, setYearOption] = useState(createYearOption);

    const [selectedDrNumList, setSelectedDrNumList] = useState('');
    const columns = useColumns(
        [
            {
                Header: 'Select',
                accessor: 'drNum',
                className: 'flex-center',
                headerClassName: 'flex-center',
                width: 50,
                Cell: ({ value }) => {
                    return value ? (
                        <div
                            onClick={e => {
                                e.stopPropagation();
                            }}
                            style={{ paddingLeft: '10px' }}
                        >
                            <input
                                type="checkbox"
                                checked={selectedDrNumList.includes(value)}
                                onChange={() => {
                                    const tempArr = selectedDrNumList.includes(value)
                                        ? selectedDrNumList.filter(num => num !== value)
                                        : [...selectedDrNumList, value];
                                    setSelectedDrNumList(tempArr);
                                }}
                            />
                        </div>
                    ) : (
                        <div>Total</div>
                    );
                },
            },
            col.departmentName({ width: 100 }),
            col.priority({ width: 60 }),
            col.equipmentName({ width: 200 }),
            col.equipmentQty({ width: 80 }),
            col.claimName({ width: 70 }),
            col.budgetRequest({ width: 90 }),
            col.purchaseSum({ width: 80 }),
            {
                Header: 'Evaluation score',
                columns: [
                    col.oldPoint({ width: 60 }),
                    col.profitabilityPoint({ width: 60 }),
                    col.appPoint({ width: 60 }),
                    col.utilityPoint({ width: 60 }),
                    col.priorityPoint({ width: 60 }),
                    col.trustPoint({ width: 60 }),
                    col.deptCostLossPoint({ width: 60 }),
                    col.sum({ width: 60 }),
                ],
            },
        ],
        columnT,
        [selectedDrNumList],
    );
    const [evaluationList, setEvaluationList] = useState({ rows: [], pageSize: 10, page: 1 });
    const { promise: getEvaluationList } = useAsync({
        promise: fetchEvaluationListApi,
        resolve: res => {
            const { additionalDatas, rows, page, totalCount, totalPage } = res;
            const customRows = [];
            if (additionalDatas) {
                customRows.push(...rows, {
                    purchaseSum: additionalDatas.totalPurchaseSum,
                    quantity: additionalDatas.totalQuantity,
                });
            }

            setEvaluationList({ rows: customRows, page, totalCount, totalPage });
        },
        reject: err => console.error(err),
    });

    const [departmentOption, setDepartmentOption] = useState([{ value: '', label: '선택해주세요.' }]);
    useAsync({
        promise: fetchEvaluationDepartmentCodeApi,
        immediate: true,
        resolve: res => {
            const { rows } = res;
            const tempArr = rows.length ? rows.map(data => ({ value: data.groupId, label: data.groupName })) : [];
            setDepartmentOption(tempArr);
        },
        reject: err => console.error(err),
    });

    useEffect(() => {
        getEvaluationList({ year: moment().format('YYYY').valueOf() });
    }, []);

    const [listSearchOption, setListSearchOption] = useState({ deptCode: '', year: '' });
    const [selectedYear, setSelectedYear] = useState({
        label: moment().format('YYYY').valueOf(),
        value: moment().format('YYYY').valueOf(),
    });

    const handleYearSelectChange = select => {
        setSelectedYear(select);
        setListSearchOption({ ...listSearchOption, year: select.value });
    };

    const [selectedDepartment, setSelectedDepartment] = useState([]);
    const handleSelectedDepartmentChange = select => {
        const deptCode = select.length ? select[select.length - 1].value : '';
        setSelectedDepartment([deptCode]);
        setListSearchOption({ ...listSearchOption, deptCode: deptCode });
    };

    const handlePageChange = pageIndex => {
        setListSearchOption({ ...listSearchOption, page: pageIndex });
        getEvaluationList({ ...listSearchOption, page: pageIndex });
    };

    const handleSelectedEvaluationBtnClick = info => {
        if (info.drNum) {
            history.push(`/medical-engineering/edit-evaluation/${info.drNum}`);
        }
    };

    const createNewEvaluation = () => {
        history.push('/medical-engineering/evaluation-registration');
    };

    const [listSearchOptionForExcel, setListSearchOptionForExcel] = useState({ isAll: 'Y', deptCode: '', year: '' });
    const handleSearchBtnClick = () => {
        setListSearchOptionForExcel({
            ...listSearchOptionForExcel,
            year: listSearchOption.year,
            deptCode: listSearchOption.deptCode,
        });
        setEvaluationInfo({ ...evaluationInfo, year: listSearchOption.year });
        getEvaluationList({ ...listSearchOption });
    };

    const { promise: deleteEvaluation } = useAsync({
        promise: deleteEvaluationApi,
        resolve: () => toggleSuccessDeleteEvaluationModal(),
        reject: err => console.error(err),
    });

    const { Modal: ConfirmDeleteEvaluationModal, toggleModal: toggleConfirmDeleteEvaluationModal } = useConfirmModal({
        initModal: false,
        confirmText: t('Are you sure to delete evaluation?'),
        okCallback: deleteEvaluation,
        callbackParam: { drNums: selectedDrNumList },
    });

    const { Modal: SuccessDeleteEvaluationModal, toggleModal: toggleSuccessDeleteEvaluationModal } = useConfirmModal({
        initModal: false,
        confirmText: t('Evaluation is deleted successfully'),
        okCallback: getEvaluationList,
        callbackParam: { ...listSearchOption },
        removeCancel: true,
    });

    return (
        <>
            <Page menuPath={[t('Equipment evaluation'), t('Evaluation list')]} className={'h-100'}>
                <FilterSearchGroup>
                    <SearchGroup className="bg-brand">
                        <div style={{ width: '10rem' }}>
                            <ReactSelect
                                name="year"
                                value={selectedYear}
                                options={yearOption}
                                onChange={handleYearSelectChange}
                                customControlStyles={{ width: '100%' }}
                                customMenuStyles={{ width: '100%' }}
                                customOptionStyles={{ width: '100%' }}
                            />
                        </div>
                        <div style={{ width: '10rem', marginRight: '2rem' }}>
                            <SearchableSelect
                                onChange={handleSelectedDepartmentChange}
                                selected={selectedDepartment}
                                data={departmentOption}
                            />
                        </div>

                        <SearchButtonArea>
                            <Button
                                className="btn-brand btn-icon"
                                iconClassName="icon-search"
                                onClick={handleSearchBtnClick}
                            >
                                {t('Search', 'Search')}
                            </Button>
                            <ExcelExport option={listSearchOptionForExcel} />
                        </SearchButtonArea>
                    </SearchGroup>
                </FilterSearchGroup>
                <Card
                    className="evaluation-card-height"
                    header={{
                        title: t('Evaluation list'),
                        action: (
                            <>
                                <Button
                                    className="btn-brand btn-icon"
                                    iconClassName="icon-plus"
                                    onClick={createNewEvaluation}
                                >
                                    {t('Register', 'Button')}
                                </Button>
                                <Button
                                    className="btn-brand btn-icon"
                                    iconClassName="icon-delete"
                                    onClick={toggleConfirmDeleteEvaluationModal}
                                >
                                    {t('Delete', 'Button')}
                                </Button>
                            </>
                        ),
                    }}
                >
                    <Table
                        columns={columns}
                        data={{ ...evaluationList, pageSize: 10 }}
                        onPageChange={handlePageChange}
                        onTrClick={handleSelectedEvaluationBtnClick}
                    />
                </Card>
            </Page>
            <ConfirmDeleteEvaluationModal />
            <SuccessDeleteEvaluationModal />
        </>
    );
};

export default EquipmentEvaluation;
