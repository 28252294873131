import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { falsyToHyphen, nullToHyphen, numberFormat } from '../../../../util/common/util';
import useTranslation from '../../../../util/hooks/useTranslation';

const StyledStatus = styled.span`
    height: 25px;
    color: ${props => props.color} !important;
    font-weight: bold;
`;

// Location-Log Table
export const number = ({ ...restProps } = {}) => {
    return {
        Header: 'No.',
        headerClassName: 'flex-center',
        accessor: 'logNum',
        className: 'flex-center',
        width: 100,
        ...restProps,
    };
};

export const targetName = ({ ...restProps }) => {
    return {
        Header: 'Target Name',
        headerClassName: 'flex-center text-ellipsis',
        accessor: 'targetName',
        className: 'flex-center text-ellipsis',
        ...restProps,
    };
};

export const deviceName = ({ ...restProps }) => {
    return {
        Header: 'Device Name',
        headerClassName: 'flex-center',
        accessor: 'tagName',
        className: 'flex-center text-ellipsis',
        ...restProps,
    };
};

export const floor = ({ ...restProps }) => {
    return {
        Header: 'Floor',
        headerClassName: 'flex-center text-ellipsis',
        accessor: 'floorId',
        className: 'flex-center text-ellipsis',
        ...restProps,
    };
};

export const location = ({ handleLatLngClick, t, ...restProps } = {}) => {
    return {
        Header: 'Location',
        headerClassName: 'flex-center',
        accessor: 'latLng',
        className: 'flex-center',
        width: 250,
        Cell: ({ value, row }) => {
            let [lat, lng] = value;
            const { original } = row;

            lat = parseFloat(lat).toFixed(6);
            lng = parseFloat(lng).toFixed(6);

            return (
                <>
                    <div
                        className={'pnt-txt txt-border txt-bold custom-action-btn'}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            if (typeof handleLatLngClick === 'function') {
                                handleLatLngClick(original);
                            }
                        }}
                    >
                        {t('Latitude')} {lat}
                    </div>
                    <div
                        className={'pnt-txt txt-border txt-bold custom-action-btn'}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            if (typeof handleLatLngClick === 'function') {
                                handleLatLngClick(original);
                            }
                        }}
                    >
                        {t('Longitude')} {lng}
                    </div>
                </>
            );
        },
        t,
        ...restProps,
    };
};

export const registeredDate = ({ ...restProps }) => {
    return {
        Header: 'Registered Date',
        headerClassName: 'flex-center text-ellipsis',
        accessor: 'regDate',
        className: 'flex-center text-ellipsis',
        Cell: ({ value }) => {
            const date = moment.unix(value).format('YYYY-MM-DD HH:mm:ss');
            return <div>{date}</div>;
        },
        ...restProps,
    };
};

//Location-Log-File Table
export const downloadLog = ({ t, ...restProps }) => {
    return {
        Header: 'Download',
        headerClassName: 'flex-center',
        className: 'flex-center',
        accessor: 'filePath',
        Cell: ({ value, row }) => {
            const { original } = row;
            if (original.dataSettingStatusType !== 'COMPLETED') {
                return <div className={'pnt-txt txt-bold'}>-</div>;
            } else {
                return (
                    <a href={value} download={original.fileName}>
                        <div className={'pnt-txt txt-border txt-bold custom-action-btn'} style={{ cursor: 'pointer' }}>
                            {t('Download')}
                        </div>
                    </a>
                );
            }
        },
        t,
        ...restProps,
    };
};

export const reqNum = ({ ...restProps }) => {
    return {
        Header: 'No.',
        headerClassName: 'flex-center',
        className: 'flex-center',
        accessor: 'reqNum',
        ...restProps,
    };
};

export const fileName = ({ ...restProps }) => {
    return {
        Header: 'File Name',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'fileName',
        ...restProps,
    };
};

export const dataStatus = ({ t, ...restProps } = {}) => {
    return {
        Header: 'Data Status',
        headerClassName: 'flex-center',
        className: 'flex-center',
        accessor: 'dataSettingStatusType',
        Cell: ({ value, row }) => {
            if (value === 'COMPLETED')
                return (
                    <div className={'pnt-txt txt-border txt-bold'} style={{ color: '#000' }}>
                        {t('COMPLETED')}
                    </div>
                );
            else if (value === 'FAILED')
                return (
                    <div className={'pnt-txt txt-border txt-bold'} style={{ color: '#ff3333' }}>
                        {t('FAILED')}
                    </div>
                );
            else if (value === 'APPENDING')
                return (
                    <div className={'pnt-txt txt-border txt-bold'} style={{ color: '#FFC107' }}>
                        {t('APPENDING')}
                    </div>
                );
            else if (value === 'PROGRESSING') {
                return (
                    <div className={'pnt-txt txt-border txt-bold'} style={{ color: '#3E66FB' }}>
                        {t('PROGRESSING')}
                    </div>
                );
            }
        },
        t,
        ...restProps,
    };
};
// geofence log table
export const categoryName = ({ ...restProps } = {}) => {
    return {
        Header: 'Category Name',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'categoryCode',
    };
};
export const targetId = ({ ...restProps } = {}) => {
    return {
        Header: 'Target ID',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        width: 80,
        accessor: 'targetId',
    };
};
export const geofence = ({ ...restProps } = {}) => {
    return {
        Header: 'Geofence',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'fcName',
    };
};
export const authorizedNotAuthorized = ({ ...restProps } = {}) => {
    return {
        Header: 'Authorized Not Authorized',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'permitted',
    };
};
export const inDate = ({ ...restProps } = {}) => {
    return {
        Header: 'In Date',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'inDate',
        Cell: ({ value }) => {
            return value ? moment(value * 1000).format('YYYY-MM-DD HH:mm:ss') : '-';
        },
    };
};
export const inScannerName = ({ ...restProps } = {}) => {
    return {
        Header: 'In Scanner Name',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'inScannerName',
    };
};
// 스캐너 상태 로그
export const scannerName = ({ ...restProps } = {}) => {
    return {
        Header: 'Scanner Name',
        headerClassName: 'flex-center',
        accessor: 'scannerName',
        className: 'flex-center',
        ...restProps,
    };
};
export const macAddress = ({ ...restProps } = {}) => {
    return {
        Header: 'Mac Address',
        headerClassName: 'flex-center',
        accessor: 'macAddr',
        className: 'flex-center',
        ...restProps,
    };
};

export const startDate = ({ ...restProps } = {}) => {
    return {
        Header: 'Start Date',
        headerClassName: 'flex-center',
        accessor: 'startDate',
        className: 'flex-center',
        Cell: ({ value }) => {
            return value ? moment(value * 1000).format('YYYY-MM-DD HH:mm:ss') : '-';
        },
        ...restProps,
    };
};
export const endDate = ({ ...restProps } = {}) => {
    return {
        Header: 'End Date',
        headerClassName: 'flex-center',
        accessor: 'endDate',
        className: 'flex-center',
        Cell: ({ value }) => {
            return value ? moment(value * 1000).format('YYYY-MM-DD HH:mm:ss') : '-';
        },
        ...restProps,
    };
};

//sensor log
export const sensorName = ({ ...restProps } = {}) => {
    return {
        Header: 'Sensor Name',
        headerClassName: 'flex-center',
        accessor: 'sensorName',
        className: 'flex-center text-ellipsis',
        ...restProps,
    };
};

export const sensorId = ({ ...restProps } = {}) => {
    return {
        Header: 'Sensor Id',
        headerClassName: 'flex-center',
        accessor: 'sensorId',
        className: 'flex-center text-ellipsis',
        ...restProps,
    };
};
export const sensorType = ({ ...restProps } = {}) => {
    return {
        Header: 'Sensor Type',
        headerClassName: 'flex-center',
        accessor: 'sensorType',
        className: 'flex-center text-ellipsis',
        ...restProps,
    };
};

export const injectionVolume = ({ ...restProps } = {}) => {
    return {
        Header: 'Injection Volume',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'VTBI',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const totalDose = ({ ...restProps } = {}) => {
    return {
        Header: 'Total Dose',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'TOTAL_DOSAGE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const stepCount = ({ ...restProps } = {}) => {
    return {
        Header: 'Step Count',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'STEP',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const bloodOxygenSaturation = ({ ...restProps } = {}) => {
    return {
        Header: 'Blood Oxygen Saturation',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'SPO2',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const noise = ({ ...restProps } = {}) => {
    return {
        Header: 'Noise',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'SOUND_NOISE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const so2 = ({ ...restProps } = {}) => {
    return {
        Header: 'SO2',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'SO2',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const sleepIndex = ({ ...restProps } = {}) => {
    return {
        Header: 'Sleep Index',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'SLEEP',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const earthquakeIntensity = ({ ...restProps } = {}) => {
    return {
        Header: 'Earthquake Intensity',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'SEISMIC_INTENS',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const relativeHumidity = ({ ...restProps } = {}) => {
    return {
        Header: 'Relative Humidity',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'R_HUMIDITY',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const respiratoryRate = ({ ...restProps } = {}) => {
    return {
        Header: 'Respiratory Rate',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'RESPIRATION_RATE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};

export const infusionRate = ({ ...restProps } = {}) => {
    return {
        Header: 'Infusion Rates',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'RATE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const pulseRate = ({ ...restProps } = {}) => {
    return {
        Header: 'Pulse Rate',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'PULSE_RATE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const oneTimePcaInjection = ({ ...restProps } = {}) => {
    return {
        Header: 'One Time PCA Injection',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'PCA_BOLUS',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const numberOfPacketTransmissions = ({ ...restProps } = {}) => {
    return {
        Header: 'Number Of Packet Transmissions',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'PACKET_COUNTER',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const o2 = ({ ...restProps } = {}) => {
    return {
        Header: 'O2',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'O2',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const diaperUrine = ({ ...restProps } = {}) => {
    return {
        Header: 'Diaper-Urine',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'NAPPY_GAS',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const diaperFart = ({ ...restProps } = {}) => {
    return {
        Header: 'Diaper-Fart',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'NAPPY_SMALL',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const diaperFeces = ({ ...restProps } = {}) => {
    return {
        Header: 'Diaper-Feces',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'NAPPY_BIG',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const movement = ({ ...restProps } = {}) => {
    return {
        Header: 'Movement',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'MOVEMENT',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const diastolic = ({ ...restProps } = {}) => {
    return {
        Header: 'Diastolic',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'H_BLOODPRESSURE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};

export const geofenceName = ({ ...restProps }) => {
    return {
        Header: 'Geofence Name',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'fcName',
        ...restProps,
    };
};

export const countOfEntries = ({ ...restProps }) => {
    return {
        Header: 'Count of entries',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'entryCount',
        ...restProps,
    };
};

export const countOfUnique = ({ ...restProps }) => {
    return {
        Header: 'Count of unique',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'uniqueCount',
        ...restProps,
    };
};

export const averageStayOfEntry = ({ ...restProps }) => {
    return {
        Header: 'Average length of stay per entry',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'avgStayEntryTime',
        Cell: ({ value }) => {
            return <span>{`${moment(value * 1000).format('m')}m ${moment(value * 1000).format('ss')}s`}</span>;
        },
        ...restProps,
    };
};

export const averageStayOfUnique = ({ ...restProps }) => {
    return {
        Header: 'Average length of stay per unique',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'avgStayUniqueTime',
        Cell: ({ value }) => {
            return <span>{`${moment(value * 1000).format('m')}m ${moment(value * 1000).format('ss')}s`}</span>;
        },
        ...restProps,
    };
};

// iot Item Information Log Table
export const dataType = ({ ...restProps }) => {
    return {
        Header: 'Type of Data Revision',
        headerClassName: 'text-ellipsis',
        accessor: 'manipulationType',
        className: 'flex-center text-ellipsis',
        ...restProps,
    };
};

export const targetNum = ({ ...restProps }) => {
    return {
        Header: 'IoT Item No.',
        headerClassName: 'text-ellipsis',
        accessor: 'targetNum',
        className: 'flex-center text-ellipsis',
        ...restProps,
    };
};

export const iotItemId = ({ ...restProps }) => {
    return {
        Header: 'IoT Item ID',
        headerClassName: 'text-ellipsis',
        accessor: 'targetId',
        className: 'flex-center text-ellipsis',
        ...restProps,
    };
};

export const metaData = ({ ...restProps }) => {
    return {
        Header: 'Meta Data',
        headerClassName: 'text-ellipsis',
        accessor: 'metadata',
        className: 'text-overflow',
        ...restProps,
    };
};

export const deviceType = ({ ...restProps }) => {
    return {
        Header: 'Device Type',
        headerClassName: 'text-ellipsis',
        accessor: 'deviceType',
        className: 'text-ellipsis',
        ...restProps,
    };
};
export const deviceNum = ({ ...restProps }) => {
    return {
        Header: 'Device No.',
        headerClassName: 'text-ellipsis',
        accessor: 'deviceNum',
        className: 'text-ellipsis',
        ...restProps,
    };
};
export const deviceId = ({ ...restProps }) => {
    return {
        Header: 'Device ID',
        headerClassName: 'text-ellipsis',
        accessor: 'deviceId',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const userId = ({ ...restProps }) => {
    return {
        Header: 'User ID',
        headerClassName: 'text-ellipsis',
        accessor: 'userID',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const userName = ({ ...restProps }) => {
    return {
        Header: 'User Name',
        headerClassName: 'text-ellipsis',
        accessor: 'userName',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const type = ({ ...restProps }) => {
    return {
        Header: 'Log Type',
        headerClassName: 'text-ellipsis',
        accessor: 'type',
        className: 'text-ellipsis',
        ...restProps,
    };
};
export const description = ({ ...restProps }) => {
    return {
        Header: 'Reason for Login Failure',
        headerClassName: 'text-ellipsis',
        accessor: 'description',
        className: 'text-ellipsis',
        ...restProps,
    };
};
export const ip = ({ ...restProps }) => {
    return {
        Header: 'IP',
        headerClassName: 'text-ellipsis',
        accessor: 'ip',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const lel = ({ ...restProps } = {}) => {
    return {
        Header: 'LEL',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'LEL',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const injectedAmount = ({ ...restProps } = {}) => {
    return {
        Header: 'Injected Amount',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'INFUSED_VOL',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const systolicBloodPressure = ({ ...restProps } = {}) => {
    return {
        Header: 'Systolic Blood Pressure',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'L_BLOODPRESSURE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const humidity = ({ ...restProps } = {}) => {
    return {
        Header: 'Humidity',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'HUMIDITY',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const height = ({ ...restProps } = {}) => {
    return {
        Header: 'Height',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'HEIGHT',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const heatstroke = ({ ...restProps } = {}) => {
    return {
        Header: 'Heatstroke',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'HEAT_STROKE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const heartRate = ({ ...restProps } = {}) => {
    return {
        Header: 'Heart Rate',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'HEART_RATE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const weight = ({ ...restProps } = {}) => {
    return {
        Header: 'Weight',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'WEIGHT',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const h2s = ({ ...restProps } = {}) => {
    return {
        Header: 'H2S',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'H2S',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};

export const volatileOrganicCompounds = ({ ...restProps } = {}) => {
    return {
        Header: 'Volatile Organic Compounds',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'ETVOC',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const equivalentCarbonDioxide = ({ ...restProps } = {}) => {
    return {
        Header: 'Equivalent Carbon Dioxide',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'ECO2',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const dust = ({ ...restProps } = {}) => {
    return {
        Header: 'Dust',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'DUST',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const dropRaid = ({ ...restProps } = {}) => {
    return {
        Header: 'Drop Raid',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'DROP_RATE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const dropWater = ({ ...restProps } = {}) => {
    return {
        Header: 'Drop Water',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'DROP_COUNT',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const dosage = ({ ...restProps } = {}) => {
    return {
        Header: 'Dosage',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'DOSAGE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const distance = ({ ...restProps } = {}) => {
    return {
        Header: 'Distance',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'DISTANCE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const discomfortIndex = ({ ...restProps } = {}) => {
    return {
        Header: 'Discomfort Index',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'DISCOMFORT_IDX',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const crack = ({ ...restProps } = {}) => {
    return {
        Header: 'Crack',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'CRACK',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const density = ({ ...restProps } = {}) => {
    return {
        Header: 'Density',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'CONC',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const co = ({ ...restProps } = {}) => {
    return {
        Header: 'CO',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'CO',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const capacity = ({ ...restProps } = {}) => {
    return {
        Header: 'Capacity',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'CAPACITY',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const calories = ({ ...restProps } = {}) => {
    return {
        Header: 'Calories',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'CALORIES',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const buttonTwoClicks = ({ ...restProps } = {}) => {
    return {
        Header: 'Button Two Clicks',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'BUTTON2_COUNT',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const buttonOneClicks = ({ ...restProps } = {}) => {
    return {
        Header: 'Button One Clicks',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'BUTTON1_COUNT',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const battery = ({ ...restProps } = {}) => {
    return {
        Header: 'Battery',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'BATTERY',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const atmosphericPressure = ({ ...restProps } = {}) => {
    return {
        Header: 'Atmospheric Pressure',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'BAROM_PRESURE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const zAxisAngularVelocity = ({ ...restProps } = {}) => {
    return {
        Header: 'Z-Axis Angular Velocity',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'ACCELER_Z_AXIS',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const yAxisAngularVelocity = ({ ...restProps } = {}) => {
    return {
        Header: 'Y-Axis Angular Velocity',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'ACCELER_Y_AXIS',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const xAxisAngularVelocity = ({ ...restProps } = {}) => {
    return {
        Header: 'X-Axis Angular Velocity',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'ACCELER_X_AXIS',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const illuminance = ({ ...restProps } = {}) => {
    return {
        Header: 'Ambient Light',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'ANBIENT_LIGHT',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const zSideAcceleration = ({ ...restProps } = {}) => {
    return {
        Header: 'Z Side Acceleration',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'ANGULAR_Z_AXIS',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const YSideAcceleration = ({ ...restProps } = {}) => {
    return {
        Header: 'Y Side Acceleration',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'ANGULAR_Y_AXIS',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const xSideAcceleration = ({ ...restProps } = {}) => {
    return {
        Header: 'X Side Acceleration',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'ANGULAR_X_AXIS',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const lastReceivedDate = ({ ...restProps } = {}) => {
    return {
        Header: 'Last Received Date',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: '',
        ...restProps,
    };
};
export const temperature = ({ ...restProps } = {}) => {
    return {
        Header: 'Temperature',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'TEMPERATURE',
        ...restProps,
    };
};

export const outDate = ({ ...restProps } = {}) => {
    return {
        Header: 'Out Date',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'outDate',
        Cell: ({ value }) => {
            return value ? moment(value * 1000).format('YYYY-MM-DD HH:mm:ss') : '-';
        },
        ...restProps,
    };
};
export const outScannerName = ({ ...restProps } = {}) => {
    return {
        Header: 'Out Scanner Name',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'outScannerName',
    };
};

export const sensorMacAddress = ({ ...restProps }) => {
    return {
        Header: 'Sensor Mac Address',
        headerClassName: 'text-ellipsis',
        accessor: 'sensorId',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const sensorItems = ({ ...restProps }) => {
    return {
        Header: 'Measurement Item',
        headerClassName: 'text-ellipsis',
        accessor: 'sensorItems',
        className: 'text-overflow',
        ...restProps,
    };
};

export const data = ({ ...restProps }) => {
    return {
        Header: 'Value',
        headerClassName: 'text-ellipsis',
        accessor: 'data',
        ...restProps,
    };
};

export const remarks = ({ ...restProps }) => {
    return {
        Header: 'Remarks',
        headerClassName: 'text-ellipsis',
        accessor: 'remarks',
        className: 'text-ellipsis p-1',
        ...restProps,
    };
};

//자산 점검 기록
export const assetCategory = ({ ...restProps }) => {
    return {
        Header: 'Asset category',
        accessor: 'assetCategory',
        ...restProps,
    };
};
export const assetNumber = ({ ...restProps }) => {
    return {
        Header: 'Asset Number',
        headerClassName: 'text-ellipsis',
        accessor: 'targetNum',
        width: 90,
        className: 'text-ellipsis',
        ...restProps,
    };
};
export const ownershipDepartment = ({ ...restProps }) => {
    return {
        Header: 'Ownership department',
        accessor: 'usingDepartmentName',
        ...restProps,
    };
};
export const assetInspectionDate = ({ ...restProps }) => {
    return {
        Header: 'Asset inspection date',
        accessor: 'lastInspectionDate',
        ...restProps,
    };
};
export const assetInspectionStatus = ({ ...restProps }) => {
    return {
        Header: 'Asset inspection status',
        accessor: 'assetInspectionStatus',
        ...restProps,
    };
};
export const inspectionNote = ({ ...restProps }) => {
    return {
        Header: 'Inspection note',
        accessor: 'remarks',
        ...restProps,
    };
};

//자산대여요청목록
export const assetRentalRequestNum = ({ ...restProps }) => {
    return {
        Header: 'No.',
        accessor: 'reqNum',
        headerClassName: 'text-ellipsis',
        classname: 'text-ellipsis',
        ...restProps,
    };
};
export const assetRentalRequestDepart = ({ ...restProps }) => {
    return {
        Header: 'Requesting department',
        accessor: 'usingDepNm',
        width: 90,
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        ...restProps,
    };
};
export const assetRentalApplicant = ({ ...restProps }) => {
    return {
        Header: 'Applicant',
        accessor: 'userName',
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        ...restProps,
    };
};
export const assetRentalDepartment = ({ ...restProps }) => {
    return {
        Header: 'Lending department',
        accessor: 'ownDepNm',
        width: 90,
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        ...restProps,
    };
};
export const applicationDate = ({ ...restProps }) => {
    return {
        Header: 'Application date',
        accessor: 'regDate',
        headerClassName: 'text-ellipsis',
        classname: 'text-ellipsis',
        Cell: ({ value }) => {
            return <div>{moment.unix(value).format('YYYY-MM-DD')}</div>;
        },
        ...restProps,
    };
};
export const rentalPeriod = ({ ...restProps }) => {
    return {
        Header: 'Rental period',
        accessor: 'rentalPeriod',
        headerClassName: 'text-ellipsis',
        classname: 'text-ellipsis',
        ...restProps,
    };
};
export const applicationStatus = ({ ...restProps }) => {
    return {
        Header: 'Application status',
        accessor: 'status',
        headerClassName: 'text-ellipsis',
        classname: 'text-ellipsis',
        ...restProps,
    };
};
export const dateChanged = ({ ...restProps }) => {
    return {
        Header: 'Date of application status change',
        accessor: 'modDate',
        headerClassName: 'text-ellipsis',
        classname: 'text-ellipsis',
        Cell: ({ value }) => {
            return <div>{moment.unix(value).format('YYYY-MM-DD')}</div>;
        },
        ...restProps,
    };
};
export const category = ({ ...restProps }) => {
    return {
        Header: 'Category',
        headerClassName: 'text-ellipsis',
        accessor: 'categoryCode',
        width: 140,
        className: 'text-ellipsis',
        ...restProps,
    };
};
export const quantity = ({ ...restProps }) => {
    return {
        Header: 'Quantity',
        headerClassName: 'text-ellipsis',
        classname: 'text-ellipsis',
        accessor: 'count',
        ...restProps,
    };
};
export const assetName = ({ ...restProps }) => {
    return {
        Header: 'Asset Name',
        headerClassName: 'text-ellipsis',
        accessor: 'targetName',
        width: 90,
        className: 'text-ellipsis',
        ...restProps,
    };
};
export const rentalStatus = ({ ...restProps }) => {
    return {
        Header: 'Rental Status',
        headerClassName: 'text-ellipsis',
        accessor: 'status',
        width: 90,
        className: 'text-ellipsis',
        ...restProps,
    };
};
export const action = ({ ...restProps }) => {
    return {
        Header: 'Action',
        headerClassName: 'text-ellipsis',
        classname: 'text-ellipsis',
        accessor: 'action',
        ...restProps,
    };
};

//자산 점검 예정

export const assetCategorization = ({ ...restProps }) => {
    return {
        Header: 'Asset category',
        accessor: 'categoryName',
        ...restProps,
    };
};

export const assetTargetNumber = ({ ...restProps }) => {
    return {
        Header: 'Asset number',
        accessor: 'targetNum',
        ...restProps,
    };
};

export const assetLocation = ({ ...restProps }) => {
    return {
        Header: 'Asset location',
        accessor: 'floorName',
        ...restProps,
    };
};
export const scheduledInspectionDate = ({ ...restProps }) => {
    return {
        Header: 'Scheduled inspection date',
        accessor: 'properties.scheduled_inspection_date',
        headerClassName: 'text-ellipsis',
        Cell: ({ value }) => {
            let data;
            data = value ? <div>{moment.unix(value).format('YYYY-MM-DD')}</div> : '';
            return data;
        },
        ...restProps,
    };
};
export const ownDepartmentName = ({ ...restProps }) => {
    return {
        Header: 'Owning department name',
        accessor: 'properties.own_dep_nm',
        headerClassName: 'text-ellipsis',
        ...restProps,
    };
};

export const assetStatus = ({ ...restProps }) => {
    return {
        Header: 'Asset status',
        accessor: 'statusId',
        Cell: row => {
            let selectedStatus;
            if (restProps.assetStatusList && restProps.assetStatusList.rows) {
                selectedStatus = restProps.assetStatusList.rows.find(status => status.statusId === row.value);
            }

            return selectedStatus ? (
                <StyledStatus className="pnt-txt txt-border" color={selectedStatus.displayColor}>
                    {selectedStatus.statusName}
                </StyledStatus>
            ) : (
                <span></span>
            );
        },
        ...restProps,
    };
};

// 자산 점검 이력

export const assetCategoryCode = ({ ...restProps }) => {
    return {
        Header: 'Asset category',
        accessor: 'categoryCode',
        ...restProps,
    };
};

export const ownershipDepartmentName = ({ ...restProps }) => {
    return {
        Header: 'Ownership department',
        accessor: 'groupName',
        headerClassName: 'text-ellipsis',
        ...restProps,
    };
};

export const inspectionStatus = ({ ...restProps }) => {
    return {
        Header: 'Inspection status',
        headerClassName: 'text-ellipsis',
        classname: 'text-ellipsis',
        accessor: 'inspectStatus',
        ...restProps,
    };
};

export const inspectionDate = ({ ...restProps }) => {
    return {
        Header: 'Inspection date',
        headerClassName: 'text-ellipsis',
        accessor: 'regDate',
        Cell: ({ value }) => {
            let data;
            data = value ? <div>{moment.unix(value).format('YYYY-MM-DD HH:mm:ss')}</div> : '';
            return data;
        },
        ...restProps,
    };
};

export const regUserName = ({ ...restProps }) => {
    return {
        Header: 'Inspector',
        headerClassName: 'text-ellipsis',
        accessor: 'regUserName',
        Cell: ({ row: { original } }) => {
            return `${original.regGroupName ? original.regGroupName : ''} > ${
                original.regUserName ? original.regUserName : ''
            } [${original.regUserId ? original.regUserId : ''}]`;
        },
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const assetDescription = ({ ...restProps }) => {
    return {
        Header: 'Description',
        headerClassName: 'text-ellipsis',
        accessor: 'description',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const affiliation = ({ deptList, ...restProps }) => {
    return {
        Header: 'Affiliation',
        headerClassName: 'text-ellipsis',
        accessor: 'deptCode',
        className: 'text-ellipsis',
        Cell: ({ value }) => {
            const dept = deptList.find(dept => dept.value === value);
            return <div>{dept && dept.label ? dept.label : null}</div>;
        },
        ...restProps,
    };
};

export const workStatus = ({ ...restProps }) => {
    const t = useTranslation('RecordModifyModal');
    return {
        Header: 'Work Status',
        headerClassName: 'text-ellipsis',
        accessor: 'sName',
        className: 'text-ellipsis',
        Cell: ({ value }) => <span>{value ? t(value) : '-'}</span>,
        ...restProps,
    };
};

export const commuteDate = ({ ...restProps }) => {
    return {
        Header: 'Date',
        accessor: 'regDate',
        headerClassName: 'text-ellipsis',
        classname: 'text-ellipsis',
        Cell: ({ value }) => {
            if (value) {
                const dateString = value.toString();
                return <div>{`${dateString.slice(0, 4)}.${dateString.slice(4, 6)}.${dateString.slice(6, 8)}`}</div>;
            }
            return <></>;
        },
        ...restProps,
    };
};

export const commuteTime = ({ ...restProps }) => {
    return {
        Header: '',
        accessor: 'regDate',
        headerClassName: 'text-ellipsis',
        classname: 'text-ellipsis',
        Cell: ({ value }) => {
            return <div>{value ? moment.unix(value).format('H:mm:ss') : '-'}</div>;
        },
        ...restProps,
    };
};

export const workersName = ({ ...restProps }) => {
    return {
        accessor: 'targetName',
        headerClassName: 'flex-center font-size-lg',
        className: 'd-table font-size-lg font-weight-bold th pointer-events-none table-layout-fixed',
        Cell: ({ value }) => <div className={'table-cell-center'}>{value}</div>,
        ...restProps,
    };
};

export const datesOfTheWeek = ({ day, date, ...restProps }) => {
    const t = useTranslation('RecordModifyModal');
    return {
        Header: (
            <div className={'w-100 h-100'}>
                <div>{day}</div>
                <div className={'background-theme-important'}>{date && date.slice(-2)}</div>
            </div>
        ),
        headerClassName: 'flex-center',
        className: 'flex-center',
        Cell: ({ row: { original } }) => {
            const t = useTranslation('RecordModifyModal');
            let commute = { sName: null, inDate: null, outDate: null };
            const curCommute = original['commuteList'].find(commute => commute.regDate === date);
            if (curCommute) {
                commute = curCommute;
            }
            const { sName, inDate, outDate } = commute;
            const createSname = () => {
                if (sName === null) {
                    return <div className={'table-border-bottom'}>-</div>;
                } else if (sName === 'Normal') {
                    return <div className={'font-weight-bold table-border-bottom'}>{t(sName)}</div>;
                } else if (sName === 'No Records') {
                    return <div className={'color-red font-weight-bold table-border-bottom'}>{t(sName)}</div>;
                } else if (sName === 'Afternoon Off') {
                    return <div className={'color-secondary font-weight-bold table-border-bottom'}>{t(sName)}</div>;
                } else if (sName === 'Morning Off') {
                    return <div className={'color-secondary font-weight-bold table-border-bottom'}>{t(sName)}</div>;
                } else if (sName === 'Sunday' || sName === 'Holiday') {
                    return <div className={'color-brand font-weight-bold table-border-bottom'}>{t(sName)}</div>;
                } else {
                    return <div className={'color-brand font-weight-bold table-border-bottom'}>{sName}</div>;
                }
            };

            return (
                <div style={{ width: '100%' }}>
                    <div>{createSname()}</div>
                    <div style={{ borderBottom: '1px solid #adadad' }}>
                        {inDate ? moment(inDate * 1000).format('HH:mm') : '-'}
                    </div>
                    <div>{outDate ? moment(outDate * 1000).format('HH:mm') : '-'}</div>
                </div>
            );
        },
        ...restProps,
    };
};

export const representative = ({ ...restProps }) => {
    return {
        Header: 'Representative',
        headerClassName: 'text-ellipsis',
        accessor: 'empName',
        className: 'text-ellipsis font-weight-bold p-0',
        ...restProps,
    };
};

export const responsibility = ({ ...restProps }) => {
    return {
        Header: 'Responsibility',
        headerClassName: 'text-ellipsis',
        accessor: 'charge',
        className: 'text-ellipsis p-0',
        ...restProps,
    };
};

export const appliancesWhether = ({ ...restProps }) => {
    return {
        Header: 'Appliances Whether',
        headerClassName: 'text-ellipsis',
        accessor: 'homeAppli',
        className: 'text-ellipsis p-0',
        width: 80,
        Cell: ({ value }) => {
            return (
                <div
                    className={'w-100 h-100 cursor-default grid-center'}
                    onClick={e => {
                        e.stopPropagation();
                    }}
                >
                    <div>{falsyToHyphen(value)}</div>
                </div>
            );
        },
        ...restProps,
    };
};

export const repairDetails = ({ ...restProps }) => {
    return {
        Header: 'Repair details',
        headerClassName: 'text-ellipsis',
        accessor: '',
        className: 'text-ellipsis p-0',
        width: 80,
        ...restProps,
    };
};

export const repairCost = ({ header, ...restProps }) => {
    const { title, number, total } = header;
    return {
        Header: (
            <div className={'w-100 h-100'}>
                <div className="h-50 header-border-bottom">{title}</div>
                <div className="h-50 d-grid header-divide">
                    <div>{number}</div>
                    <div>{total}</div>
                </div>
            </div>
        ),
        headerClassName: 'flex-center p-0',
        accessor: 'repair',
        className: 'text-ellipsis p-0',
        width: 200,
        Cell: ({ row: { original } }) => {
            const { repairCnt, repairCost } = original;
            let repairTotal = nullToHyphen(repairCost);
            let total =
                repairTotal === '-'
                    ? repairTotal
                    : repairTotal.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');

            return (
                <div className={'cell-divide overflow-hidden'}>
                    <div className={'text-ellipsis'}>{nullToHyphen(repairCnt)}</div>
                    <div className={'text-ellipsis'}>{total}</div>
                </div>
            );
        },
        ...restProps,
    };
};

export const serviceRepairCost = ({ header, ...restProps }) => {
    const { title, number, total } = header;
    return {
        Header: (
            <div className={'w-100 h-100'}>
                <div className="h-50 header-border-bottom">{title}</div>
                <div className="h-50 d-grid header-divide">
                    <div>{number}</div>
                    <div>{total}</div>
                </div>
            </div>
        ),
        headerClassName: 'flex-center p-0',
        accessor: 'sericerepair',
        className: 'text-ellipsis p-0',
        width: 200,
        Cell: ({ row: { original } }) => {
            const { serviceCnt, serviceCost } = original;
            let serviceTotal = nullToHyphen(serviceCost);
            let total =
                serviceTotal === '-'
                    ? serviceTotal
                    : serviceTotal.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
            return (
                <div className="cell-divide">
                    <div>{nullToHyphen(serviceCnt)}</div>
                    <div>{total}</div>
                </div>
            );
        },
        ...restProps,
    };
};

export const serviceBudgetAllocation = ({ ...restProps }) => {
    return {
        Header: 'Service Budget Allocation',
        headerClassName: 'text-ellipsis',
        accessor: 'serviceBudget',
        className: 'text-ellipsis p-0 d-grid grid-center background-table-input color-black',
        Cell: ({ value }) => {
            let serviceTotal = nullToHyphen(value);
            let total =
                serviceTotal === '-'
                    ? serviceTotal
                    : serviceTotal.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
            return <div>{total}</div>;
        },
        ...restProps,
    };
};

export const repairBudgetAllocation = ({ ...restProps }) => {
    return {
        Header: 'Repair Budget Allocation',
        headerClassName: 'text-ellipsis color-red',
        accessor: 'repairBudget',
        className: 'text-ellipsis background-table-input color-black',
        Cell: ({ value }) => {
            let serviceTotal = nullToHyphen(value);
            let total =
                serviceTotal === '-'
                    ? serviceTotal
                    : serviceTotal.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
            return <div>{total}</div>;
        },
        ...restProps,
    };
};

export const costReduction = ({ header, ...restProps }) => {
    const { title, number, savings } = header;
    return {
        Header: (
            <div className={'w-100 h-100'}>
                <div className="h-50 header-border-bottom">{title}</div>
                <div className="h-50 d-grid header-divide">
                    <div>{number}</div>
                    <div>{savings}</div>
                </div>
            </div>
        ),
        headerClassName: 'flex-center p-0',
        accessor: 'costReduction',
        className: 'text-ellipsis p-0',
        width: 200,
        Cell: ({ row: { original } }) => {
            const { thisMonSavingCnt, thisMonSavingCost } = original;
            let serviceTotal = nullToHyphen(thisMonSavingCost);
            let total =
                serviceTotal === '-'
                    ? serviceTotal
                    : serviceTotal.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
            return (
                <div className={'cell-divide'}>
                    <div>{nullToHyphen(thisMonSavingCnt)}</div>
                    <div>{nullToHyphen(total)}</div>
                </div>
            );
        },
        ...restProps,
    };
};

export const targetReductionAmount = ({ ...restProps }) => {
    return {
        Header: 'Target Reduction Amount',
        headerClassName: 'text-ellipsis',
        accessor: 'savingTarget',
        className: 'text-ellipsis background-table-input color-black',
        Cell: ({ value }) => {
            let serviceTotal = nullToHyphen(value);
            let total =
                serviceTotal === '-'
                    ? serviceTotal
                    : serviceTotal.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
            return <div>{total}</div>;
        },
        ...restProps,
    };
};

export const employeeNumber = ({ ...restProps }) => {
    return {
        Header: 'Employee Number',
        headerClassName: 'text-ellipsis',
        accessor: 'empNum',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const employeeName = ({ ...restProps }) => {
    return {
        Header: 'Name',
        headerClassName: 'text-ellipsis',
        accessor: 'empName',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const responsibleHemodialysis = ({ ...restProps }) => {
    return {
        Header: 'Responsible for hemodialysis room',
        headerClassName: 'text-ellipsis',
        accessor: 'bloodAppli',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const responsibleHomeAppliances = ({ ...restProps }) => {
    return {
        Header: 'Responsible for home appliances',
        headerClassName: 'text-ellipsis',
        accessor: 'homeAppli',
        className: 'text-ellipsis',
        ...restProps,
    };
};

// 일반수리
export const numberOfRepairs = ({ ...restProps }) => {
    return {
        Header: 'Number of Repairs',
        headerClassName: 'text-ellipsis',
        accessor: 'repairCnt',
        className: 'text-ellipsis',
        Cell: ({ value }) => {
            const repairCnt = nullToHyphen(value);
            return <div>{numberFormat(repairCnt)}</div>;
        },
        ...restProps,
    };
};

export const generalRepairCost = ({ ...restProps }) => {
    return {
        Header: 'Repair Cost',
        headerClassName: 'text-ellipsis',
        accessor: 'repairCost',
        className: 'text-ellipsis',
        Cell: ({ value }) => {
            const repairCost = nullToHyphen(value);
            return <div>{numberFormat(repairCost)}</div>;
        },
        ...restProps,
    };
};

export const billingInventory = ({ ...restProps }) => {
    return {
        Header: 'Billing Inventory',
        headerClassName: 'text-ellipsis',
        accessor: 'billCost',
        className: 'text-ellipsis',
        Cell: ({ value }) => {
            const billCost = nullToHyphen(value);
            return <div>{numberFormat(billCost)}</div>;
        },
        ...restProps,
    };
};

export const monthlyRepairStockTotal = ({ ...restProps }) => {
    return {
        Header: 'Monthly Repair Cost + Billing Inventory Total',
        headerClassName: 'text-ellipsis',
        accessor: 'sumRepairBillCost',
        className: 'text-ellipsis',
        width: 200,
        Cell: ({ value }) => {
            const sumRepairBillCost = nullToHyphen(value);
            return <div>{numberFormat(sumRepairBillCost)}</div>;
        },
        ...restProps,
    };
};

export const monthlyRepairCost = ({ ...restProps }) => {
    return {
        Header: 'Monthly Repair Cost',
        headerClassName: 'text-ellipsis',
        accessor: 'thisMonTotalRepairCost',
        className: 'text-ellipsis',
        Cell: ({ value }) => {
            const thisMonTotalRepairCost = nullToHyphen(value);
            return <div>{numberFormat(thisMonTotalRepairCost)}</div>;
        },
        ...restProps,
    };
};

export const repairCostAccumulated = ({ ...restProps }) => {
    return {
        Header: 'Repair Cost Accumulated (Exclude Inventory)',
        headerClassName: 'text-ellipsis',
        accessor: 'totalRepairCost',
        className: 'text-ellipsis',
        width: 200,
        Cell: ({ value }) => {
            const totalRepairCost = nullToHyphen(value);
            return <div>{numberFormat(totalRepairCost)}</div>;
        },
        ...restProps,
    };
};

// 용역수리
export const numberOfServiceRepair = ({ ...restProps }) => {
    return {
        Header: 'Number of Service Repairs',
        headerClassName: 'text-ellipsis',
        accessor: 'serviceCnt',
        className: 'text-ellipsis',
        Cell: ({ value }) => {
            const serviceCnt = nullToHyphen(value);
            return <div>{numberFormat(serviceCnt)}</div>;
        },
        ...restProps,
    };
};

export const thisMonthServiceRepair = ({ ...restProps }) => {
    return {
        Header: `This Month's Service Repair Cost`,
        headerClassName: 'text-ellipsis',
        accessor: 'serviceCost',
        className: 'text-ellipsis',
        Cell: ({ value }) => {
            const serviceCost = nullToHyphen(value);
            return <div>{numberFormat(serviceCost)}</div>;
        },
        ...restProps,
    };
};

export const accumulatedServices = ({ ...restProps }) => {
    return {
        Header: 'Accumulated Services',
        headerClassName: 'text-ellipsis',
        accessor: 'totalServiceCost',
        className: 'text-ellipsis',
        Cell: ({ value }) => {
            const totalServiceCost = nullToHyphen(value);
            return <div>{numberFormat(totalServiceCost)}</div>;
        },
        ...restProps,
    };
};

export const budgetAllocationOfRepair = ({ ...restProps }) => {
    return {
        Header: 'Budget Allocation of Repair Costs',
        headerClassName: 'text-ellipsis',
        accessor: 'repairBudget',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const repairCostExecutionRate = ({ ...restProps }) => {
    return {
        Header: 'Repair Cost Execution Rate(%)',
        headerClassName: 'text-ellipsis',
        accessor: 'repairExeRate',
        className: 'text-ellipsis',
        Cell: ({ value }) => {
            const repairExeRate = nullToHyphen(value);
            return <div>{numberFormat(repairExeRate)}</div>;
        },
        ...restProps,
    };
};

// 비용절감
export const numberOfSavingThisMonth = ({ ...restProps }) => {
    return {
        Header: 'Number of Saving This Month',
        headerClassName: 'text-ellipsis',
        accessor: 'thisMonSavingCnt',
        className: 'text-ellipsis',
        Cell: ({ value }) => {
            const thisMonSavingCnt = nullToHyphen(value);
            return <div>{numberFormat(thisMonSavingCnt)}</div>;
        },
        ...restProps,
    };
};

export const savingForThisMonth = ({ ...restProps }) => {
    return {
        Header: 'Savings for This Month',
        headerClassName: 'text-ellipsis',
        accessor: 'thisMonSavingCost',
        className: 'text-ellipsis',
        Cell: ({ value }) => {
            const thisMonSavingCost = nullToHyphen(value);
            return <div>{numberFormat(thisMonSavingCost)}</div>;
        },
        ...restProps,
    };
};

export const cumulativeSavings = ({ ...restProps }) => {
    return {
        Header: 'Cumulative Savings',
        headerClassName: 'text-ellipsis',
        accessor: 'totalSavingCnt',
        className: 'text-ellipsis',
        Cell: ({ value }) => {
            const totalSavingCnt = nullToHyphen(value);
            return <div>{numberFormat(totalSavingCnt)}</div>;
        },
        ...restProps,
    };
};

export const accumulatedCostReduction = ({ ...restProps }) => {
    return {
        Header: 'Accumulated Cost Reduction',
        headerClassName: 'text-ellipsis',
        accessor: 'totalSavingCost',
        className: 'text-ellipsis',
        Cell: ({ value }) => {
            const totalSavingCost = nullToHyphen(value);
            return <div>{numberFormat(totalSavingCost)}</div>;
        },
        ...restProps,
    };
};

export const performance = ({ ...restProps }) => {
    return {
        Header: 'Performance(%)',
        headerClassName: 'text-ellipsis',
        accessor: 'performance',
        className: 'text-ellipsis',
        Cell: ({ value }) => {
            const performance = nullToHyphen(value);
            return <div>{numberFormat(performance)}</div>;
        },
        ...restProps,
    };
};

export const repairNo = ({ ...restProps }) => {
    return {
        Header: 'No.',
        headerClassName: 'text-ellipsis',
        accessor: 'no',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const homeApplianceStatus = ({ ...restProps }) => {
    return {
        Header: 'Home appliance Status',
        headerClassName: 'text-ellipsis',
        accessor: 'homeAppli',
        className: 'text-ellipsis background-table-input',
        ...restProps,
    };
};

export const billingDepartment = ({ ...restProps }) => {
    return {
        Header: 'Billing department',
        headerClassName: 'text-ellipsis',
        accessor: 'billDep',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const acceptanceDateOfApplication = ({ ...restProps }) => {
    return {
        Header: 'Acceptance date of application',
        headerClassName: 'text-ellipsis',
        accessor: 'accepDate',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const completionDate = ({ ...restProps }) => {
    return {
        Header: 'Completion date',
        headerClassName: 'text-ellipsis',
        accessor: 'completeDate',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const equipmentName = ({ ...restProps }) => {
    return {
        Header: 'Equipment name',
        headerClassName: 'text-ellipsis',
        accessor: 'equipmentName',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const actionClassification = ({ ...restProps }) => {
    return {
        Header: 'Action classification',
        headerClassName: 'text-ellipsis',
        accessor: 'actionGubun',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const causeClassification = ({ ...restProps }) => {
    return {
        Header: 'Cause classification',
        headerClassName: 'text-ellipsis',
        accessor: 'causeGubun',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const costRequired = ({ ...restProps }) => {
    return {
        Header: 'Cost required',
        headerClassName: 'text-ellipsis',
        accessor: 'reqCost',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const costClassification = ({ ...restProps }) => {
    return {
        Header: 'Cost classification',
        headerClassName: 'text-ellipsis',
        accessor: 'costSort',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const repairCostReduction = ({ ...restProps }) => {
    return {
        Header: 'Cost reduction',
        headerClassName: 'text-ellipsis',
        accessor: 'saveCost',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const repairServiceRepairCost = ({ ...restProps }) => {
    return {
        Header: 'Service repair cost',
        headerClassName: 'text-ellipsis',
        accessor: 'repairCost',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const model = ({ ...restProps }) => {
    return {
        Header: 'Model',
        headerClassName: 'text-ellipsis',
        accessor: 'modelName',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const receivingDate = ({ ...restProps }) => {
    return {
        Header: 'Receiving date',
        headerClassName: 'text-ellipsis',
        accessor: 'receDate',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const acquisitionAmount = ({ ...restProps }) => {
    return {
        Header: 'Acquisition amount',
        headerClassName: 'text-ellipsis',
        accessor: 'ownPrice',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const cumulativeRepairCosts = ({ ...restProps }) => {
    return {
        Header: 'Cumulative repair costs',
        headerClassName: 'text-ellipsis',
        accessor: 'fixAmt',
        className: 'text-ellipsis',
        ...restProps,
    };
};

// 심의 평가 리스트

export const departmentName = ({ ...restProps }) => {
    return {
        Header: 'Department name',
        headerClassName: 'text-ellipsis',
        accessor: 'deptName',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const priority = ({ ...restProps }) => {
    return {
        Header: 'Priority',
        headerClassName: 'text-ellipsis',
        accessor: 'priority',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const modelName = ({ ...restProps }) => {
    return {
        Header: 'Model name',
        headerClassName: 'text-ellipsis',
        accessor: 'modelName',
        className: 'text-ellipsis',
        ...restProps,
    };
};
//flex-center 되어 있어서 text-ellipsis를 판단을 못하였었음
export const equipmentQty = ({ ...restProps } = {}) => {
    return {
        Header: () => <div className={'th d-inline-block text-ellipsis p-0 border-0'}>Quantity to be purchased</div>,
        // Header : 'Quantity to be purchased'
        headerClassName: 'text-ellipsis',
        accessor: 'quantity',
        className: 'text-ellipsis',
        Cell: ({ row: { original } }) => {
            const { quantity } = original;
            let equipmentQuantity = nullToHyphen(quantity);
            let total =
                equipmentQuantity === '-'
                    ? equipmentQuantity
                    : equipmentQuantity.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
            return <span>{total}</span>;
        },
        ...restProps,
    };
};

export const claimName = ({ ...restProps } = {}) => {
    const t = useTranslation('Equipment evaluation');
    return {
        Header: 'Claim name',
        headerClassName: 'text-ellipsis',
        accessor: 'claimName',
        className: 'text-ellipsis',
        Cell: ({ row: { original } }) => {
            const { claimCode } = original;
            let name = '';
            switch (claimCode) {
                case 'NEW':
                    name = 'New';
                    break;
                case 'REPLACEMENT':
                    name = 'Obsolete replacement';
                    break;
                case 'QUANTITY':
                    name = 'Out of Stock';
                    break;
                case 'DISCONTINUED':
                    name = 'Discontinued Equipment';
                    break;
                case 'OTHER(FUNCTION)':
                    name = 'Other (enhancements)';
                    break;
            }

            return <span>{name}</span>;
        },
        ...restProps,
    };
};
export const budgetRequest = ({ ...restProps } = {}) => {
    return {
        Header: 'Budget Request',
        headerClassName: 'text-ellipsis',
        accessor: 'budgetRequestSum',
        className: 'text-ellipsis',
        Cell: ({ row: { original } }) => {
            const { budgetRequestSum } = original;
            let budgetRequestSumQuantity = nullToHyphen(budgetRequestSum);
            let total =
                budgetRequestSumQuantity === '-'
                    ? budgetRequestSumQuantity
                    : budgetRequestSumQuantity.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
            return <span>{total}</span>;
        },
        ...restProps,
    };
};

export const adjustmentBudget = ({ ...restProps } = {}) => {
    return {
        Header: 'Adjustment budget',
        headerClassName: 'text-ellipsis',
        accessor: 'adjustmentSum',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const purchaseSum = ({ ...restProps } = {}) => {
    return {
        Header: 'Purchase total',
        headerClassName: 'text-ellipsis',
        accessor: 'purchaseSum',
        className: 'text-ellipsis',
        Cell: ({ row: { original } }) => {
            const { purchaseSum } = original;
            let purchaseSumQuantity = nullToHyphen(purchaseSum);
            let total =
                purchaseSumQuantity === '-'
                    ? purchaseSumQuantity
                    : purchaseSumQuantity.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
            return <span>{total}</span>;
        },
        ...restProps,
    };
};

export const oldPoint = ({ ...restProps } = {}) => {
    return {
        Header: 'Old point',
        headerClassName: 'text-ellipsis',
        accessor: 'oldPoint',
        className: 'text-ellipsis',

        ...restProps,
    };
};

export const profitabilityPoint = ({ ...restProps } = {}) => {
    return {
        Header: 'Profitability point',
        headerClassName: 'text-ellipsis',
        accessor: 'profitabilityPoint',
        className: 'text-ellipsis',

        ...restProps,
    };
};

export const utilityPoint = ({ ...restProps } = {}) => {
    return {
        Header: 'Utility point',
        headerClassName: 'text-ellipsis',
        accessor: 'utilityPoint',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const priorityPoint = ({ ...restProps } = {}) => {
    return {
        Header: 'Priority point',
        headerClassName: 'text-ellipsis',
        accessor: 'priorityPoint',
        className: 'text-ellipsis',
        width: 60,
        ...restProps,
    };
};

export const trustPoint = ({ ...restProps } = {}) => {
    return {
        Header: 'Trust point',
        headerClassName: 'text-ellipsis',
        accessor: 'trustPoint',
        className: 'text-ellipsis',

        ...restProps,
    };
};

export const appPoint = ({ ...restProps } = {}) => {
    return {
        Header: 'App point',
        headerClassName: 'text-ellipsis',
        accessor: 'appPoint',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const deptCostLossPoint = ({ ...restProps } = {}) => {
    return {
        Header: 'Gain loss point',
        headerClassName: 'text-ellipsis',
        accessor: 'deptCostLossPoint',
        className: 'text-ellipsis',

        ...restProps,
    };
};

export const sum = ({ ...restProps } = {}) => {
    return {
        Header: 'Total',
        headerClassName: 'text-ellipsis',
        accessor: 'sum',
        className: 'text-ellipsis',
        ...restProps,
    };
};

// 자산 번호
export const assetNo = ({ ...restProps } = {}) => {
    return {
        Header: 'AssetNo',
        headerClassName: 'text-ellipsis',
        accessor: 'assetNo',
        className: 'text-ellipsis',
        ...restProps,
    };
};

//모델
export const assetModel = ({ ...restProps } = {}) => {
    return {
        Header: 'assetModel',
        headerClassName: 'text-ellipsis',
        accessor: 'assetModel',
        className: 'text-ellipsis',
        ...restProps,
    };
};
