import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Button from '../../../../Common/Button';
import useTranslation from '../../../../../util/hooks/useTranslation';
import ReactSelect from '../../../../Common/Select';
import TextInput from '../../../../Common/Input/TextInput';
import Table from '../../../../Common/Table';
import useColumns from '../../../../../util/hooks/useColumns';
import * as col from '../../../util/grid/column';
import useAsync from '../../../../../util/hooks/useAsync';
import { getEquipmentListApi } from '../../../../../api/engineering';
import { AssetDefaultValue, selectedAssetInfoState } from '../../atoms';
import { useSetRecoilState } from 'recoil';

const EquipmentSearchModal = ({
    departmentOption,
    initModal = false,
    toggleModal = function () {
        initModal = !initModal;
    },
    getEquipmentInfo,
    ...restProps
}) => {
    const t = useTranslation('Equipment search modal');
    const columnT = useTranslation('CommonColumn');
    const setSelectedAssetInfo = useSetRecoilState(selectedAssetInfoState);
    const [selectedEquipmentName, setSelectedEquipmentName] = useState('');
    const [selectedDepartment, setSelectedDepartment] = useState(departmentOption[0]);
    const [equipmentName, setEquipmentName] = useState('');
    const [equipmentList, setEquipmentList] = useState({
        rows: [],
        totalCount: 1,
        totalPage: 1,
        pageSize: 15,
        page: 1,
    });
    const columns = useColumns(
        [
            {
                Header: '',
                headerClassName: 'flex-center',
                accessor: 'logNum',
                className: 'flex-center',
                width: 50,
                Cell: ({ row: { original } }) => {
                    return (
                        <input
                            checked={original.selected}
                            type={'checkbox'}
                            onClick={() => {
                                let newEquipmentList = [];
                                if (
                                    (selectedEquipmentName && selectedEquipmentName !== original.modelName) ||
                                    !selectedEquipmentName
                                ) {
                                    setSelectedAssetInfo(original);
                                    setSelectedEquipmentName(original.modelName);
                                    newEquipmentList = equipmentList.rows.map(equip => {
                                        let flag = equip.modelName === original.modelName;
                                        return { ...equip, selected: flag };
                                    });
                                } else if (selectedEquipmentName) {
                                    setSelectedAssetInfo(AssetDefaultValue);
                                    setSelectedEquipmentName('');
                                    newEquipmentList = equipmentList.rows.map(equip => {
                                        return { ...equip, selected: false };
                                    });
                                }
                                setEquipmentList({ ...equipmentList, rows: newEquipmentList });
                            }}
                        />
                    );
                },
                ...restProps,
            },
            col.modelName(),
            col.assetNo(),
            col.acquisitionAmount(),
            col.cumulativeRepairCosts(),
            col.receivingDate(),
        ],
        columnT,
        [selectedEquipmentName, equipmentList],
    );

    const { promise: fetchEquipmentList } = useAsync({
        promise: getEquipmentListApi,
        resolve: res => {
            setEquipmentList(res);
        },
        reject: err => {
            console.error(err);
        },
    });

    const handleSearchBtnClick = () => {
        fetchEquipmentList({
            deptCd: selectedDepartment.value,
            modelName: equipmentName,
        });
    };

    useEffect(() => {
        fetchEquipmentList();
    }, []);

    return (
        <Modal
            container={document.getElementsByClassName('app-container')[0]}
            className={cx('pnt-modal')}
            isOpen={initModal}
            toggle={toggleModal}
            {...restProps}
        >
            <ModalHeader
                toggle={() => {
                    toggleModal();
                }}
            >
                {t('Search equipment')}
            </ModalHeader>

            <ModalBody>
                <div className="w-100 d-flex mb-4" style={{ columnGap: '10px' }}>
                    <ReactSelect
                        name="department"
                        value={selectedDepartment}
                        options={departmentOption}
                        onChange={selected => {
                            setSelectedDepartment(selected);
                        }}
                    />
                    <TextInput
                        name="equipment name"
                        className={'text-ellipsis'}
                        placeholder={t('Please enter equipment name')}
                        value={equipmentName}
                        handleChange={e => {
                            const { value } = e.target;
                            setEquipmentName(value);
                        }}
                    />
                    <Button className="btn-secondary" onClick={handleSearchBtnClick}>
                        {t('Search', 'Button')}
                    </Button>
                </div>
                <Table columns={columns} data={equipmentList} paging={false} />
            </ModalBody>
            <ModalFooter>
                <Button
                    className="btn-secondary"
                    onClick={() => {
                        getEquipmentInfo();
                        toggleModal();
                    }}
                >
                    {t('Add equipment')}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default EquipmentSearchModal;
