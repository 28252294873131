import React, { useEffect, useRef, useState } from 'react';

import Button from '../../../../Common/Button';
import TextInput from '../../../../Common/Input/TextInput';
import DatePicker from '../../../../Common/DatePicker';
import CustomLabel from '../../Components/CustomLabel';
import SingleSelect from '../../../../Common/Select/SingleSelect';
import useTranslation from '../../../../../util/hooks/useTranslation';
import useConfirmModal from '../../../../Common/ConfirmModal/useConfirmModal';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import useAsync from '../../../../../util/hooks/useAsync';
import { createCommuteApi, fetchCommuteAPi, updateCommuteApi } from '../../../../../api/engineering';

import cx from 'classnames';
import moment from 'moment';

import { useSelector } from 'react-redux';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
    commuteCodeList,
    commuteDeptList,
    recordSearchState,
    scheduleSearchState,
} from '../../../MedicalEngineering/atoms';

const defaultInputs = {
    cmNum: null,
    targetNum: null,
    targetName: '',
    regDate: null,
    sCD: '',
    sName: '',
    inDate: null,
    outDate: null,
    deptName: '',
    empNo: null,
};

const ConfirmModal = ({
    initModal = false,
    toggleModal = function () {
        initModal = !initModal;
    },
    okCallback,
    cancelCallback,
    callbackParam,
    removeCancel,
    removeOk,
    okButtonTitle,
    className,
    selectedCmNum,
    trData,
    selectedRegDate,

    ...restProps
}) => {
    const t = useTranslation('RecordModifyModal');
    const {
        userInfo: { groupName },
    } = useSelector(state => state.UserInfo);

    const timeInterval = useRef(null);

    const [modifyStateInputs, setModifyStateInputs] = useState(defaultInputs);
    const [datePickerDisable, setDatePickerDisable] = useState(false);
    const [currentTime, setCurrentTime] = useState(moment().format('YYYYMMDDHHmm'));
    const [selectedDate, setSelectedDate] = useState(null);

    // scd는 근무상태
    const { targetName, empNo, deptName, sCD, inDate, outDate, regDate, targetNum } = modifyStateInputs;

    const deptList = useRecoilValue(commuteDeptList);
    const statusOptions = useRecoilValue(commuteCodeList);
    const setRecordSearchState = useSetRecoilState(recordSearchState);
    const setScheduleSearchState = useSetRecoilState(scheduleSearchState);

    const { promise: getCommuteApi } = useAsync({
        promise: fetchCommuteAPi,
        resolve: res => {
            const { inDate, outDate, deptCode } = res;
            const dept = deptList.find(dept => dept.value === deptCode);
            setModifyStateInputs({
                ...res,
                deptName: dept && dept.label ? dept.label : null,
                inDate: inDate * 1000,
                outDate: outDate * 1000,
            });
        },
    });

    const { promise: putCommuteApi } = useAsync({
        promise: updateCommuteApi,
        fixedParam: {
            cmNum: selectedCmNum,
        },
        resolve: res => {
            successChange();
        },
        reject: err => {
            toggleModifyFailModal();
        },
    });

    const { promise: postCommuteApi } = useAsync({
        promise: createCommuteApi,
        fixedParam: {
            targetNum: trData && trData.targetNum,
        },
        resolve: res => {
            successChange();
        },
        reject: err => {
            toggleModifyFailModal();
        },
    });

    useEffect(() => {
        if (!selectedCmNum) {
            setModifyStateInputs({
                ...modifyStateInputs,
                deptName: groupName,
                targetName: trData && trData.targetName,
                empNo: trData && trData.empNo,
            });
        }
    }, [trData]);

    useEffect(() => {
        if (selectedCmNum && initModal === true) {
            getCommuteApi({ cmNum: selectedCmNum });
        }
    }, [selectedCmNum, initModal]);

    const handleInputs = ({ target: { name, value } }) => {
        setModifyStateInputs(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    useEffect(() => {
        if (regDate) {
            setDatePickerDisable(currentTime < regDate);
        } else {
            setDatePickerDisable(currentTime < selectedRegDate);
        }
    }, [selectedRegDate, currentTime, regDate]);

    useEffect(() => {
        if (regDate) {
            setSelectedDate(moment(regDate, 'YYYYMMDD').unix() * 1000);
        } else {
            setSelectedDate(moment(selectedRegDate, 'YYYYMMDD').unix() * 1000);
        }
    }, [selectedRegDate, regDate]);

    useEffect(() => {
        timeInterval.current = setInterval(() => {
            setCurrentTime(moment().format('YYYYMMDDHHmm'));
        }, 1000);

        return () => {
            clearInterval(timeInterval.current);
        };
    }, []);

    const handleDatePicker = (select, name) => {
        const time = moment(select).format('HHmm');
        const currentYearMonthDay = regDate ? regDate : selectedRegDate;
        setModifyStateInputs(prevState => ({
            ...prevState,
            [name]: moment(`${currentYearMonthDay}${time}`, `YYYYMMDDHHmm`).unix() * 1000,
        }));
    };

    const handleStatusSelect = selected => {
        if (['10'].includes(selected)) {
            toggleStatusFailModal();
            return;
        }
        setModifyStateInputs(prevState => ({
            ...prevState,
            sCD: selected,
        }));
    };

    const successChange = () => {
        toggleModifySuccessModal();
        setScheduleSearchState(prevState => ({
            ...prevState,
            clickSearch: prevState.clickSearch + 1,
        }));
        setRecordSearchState(prevState => ({
            ...prevState,
            clickSearch: prevState.clickSearch + 1,
        }));
        toggleModal();
    };

    useEffect(() => {
        if (initModal === false) {
            setModifyStateInputs(defaultInputs);
        }
    }, [initModal]);

    const { toggleModal: toggleModifySuccessModal, Modal: ModifySuccessModal } = useConfirmModal({
        header: { title: t('Success') },
        confirmText: t('Recording modification completed.'),
        removeCancel: true,
    });

    const { toggleModal: toggleModifyFailModal, Modal: ModifyFailModal } = useConfirmModal({
        header: { title: t('Fail') },
        confirmText: t('Failed to modify record.'),
        removeCancel: true,
    });

    const { toggleModal: toggleTimeFailModal, Modal: TimeFailModal } = useConfirmModal({
        header: { title: t('Fail') },
        confirmText: t('Leaving work cannot be earlier than going to work.'),
        removeCancel: true,
    });

    const { toggleModal: toggleStatusFailModal, Modal: StatusFailModal } = useConfirmModal({
        header: { title: t('Fail') },
        confirmText: t('No History is not available.'),
        removeCancel: true,
    });

    // const { toggleModal: toggleWorkStatusModal, Modal: WorkStatusModal } = useConfirmModal({
    //     header: { title: t('Fail') },
    //     confirmText: t('Work status values are required.'),
    //     removeCancel: true,
    // });

    const maxTime = moment(currentTime, 'YYYYMMDDHHmm').unix() * 1000;

    const statusOptionsRows = statusOptions.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'RecordManagement'),
        };
    });

    return (
        <>
            <Modal
                container={document.getElementsByClassName('app-container')[0]}
                className={cx('pnt-modal', className)}
                isOpen={initModal}
                toggle={toggleModal}
                {...restProps}
            >
                <ModalHeader toggle={toggleModal}>{t('Modification of commuting records')}</ModalHeader>
                <ModalBody>
                    <div className={'w-70'}>
                        <CustomLabel
                            labelGroupClassName={'text-end'}
                            labelValueClassName={'w-px-125'}
                            name={<>{t('Name')}</>}
                            required
                            value={
                                <TextInput
                                    name={'targetName'}
                                    placeholder={t('Name')}
                                    inputGroupClassName={'form-disable w-100'}
                                    value={targetName || ''}
                                    handleChange={handleInputs}
                                />
                            }
                        />
                        <CustomLabel
                            labelValueClassName={'w-px-125'}
                            name={t('Employee number')}
                            value={
                                <TextInput
                                    name={'empNo'}
                                    placeholder={t('Employee number')}
                                    inputGroupClassName={'form-disable w-100'}
                                    value={empNo || ''}
                                    handleChange={handleInputs}
                                />
                            }
                        />
                        <CustomLabel
                            name={t('Affiliation')}
                            labelValueClassName={'w-px-125'}
                            required
                            value={
                                <TextInput
                                    name={'deptName'}
                                    placeholder={t('Affiliation')}
                                    disabled
                                    inputGroupClassName={'w-100 form-disable'}
                                    value={deptName || ''}
                                />
                            }
                        />
                        <CustomLabel
                            labelValueClassName={'w-px-125'}
                            name={t('Work Status')}
                            value={
                                <SingleSelect
                                    name={'workStatus'}
                                    value={sCD}
                                    options={statusOptionsRows}
                                    onChange={handleStatusSelect}
                                    className="w-100"
                                />
                            }
                        />
                        <CustomLabel
                            labelValueClassName={'w-px-125'}
                            name={t('Date of attendance')}
                            value={
                                <DatePicker
                                    value={inDate}
                                    onChangeRaw={e => e.preventDefault()}
                                    handleChange={select => handleDatePicker(select, 'inDate')}
                                    valueType={'ms'}
                                    showTimeInput
                                    showTimeSelectOnly
                                    disabled={datePickerDisable}
                                    date={selectedDate}
                                    maxTime={maxTime}
                                    regDate={regDate}
                                />
                            }
                        />
                        <CustomLabel
                            labelValueClassName={'w-px-125'}
                            name={t('Date of leaving work')}
                            value={
                                <DatePicker
                                    value={outDate}
                                    onChangeRaw={e => e.preventDefault()}
                                    handleChange={select => handleDatePicker(select, 'outDate')}
                                    valueType={'ms'}
                                    showTimeInput
                                    showTimeSelectOnly
                                    disabled={datePickerDisable}
                                    date={selectedDate}
                                    maxTime={maxTime}
                                    regDate={regDate}
                                />
                            }
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        className={'color-secondary'}
                        onClick={e => {
                            if (typeof cancelCallback === 'function') {
                                cancelCallback(callbackParam);
                            }
                            toggleModal();
                        }}
                    >
                        {t('Cancel', 'ConfirmModal')}
                    </Button>

                    <Button
                        className="btn-secondary"
                        onClick={e => {
                            // if (!sCD) {
                            //     toggleWorkStatusModal();
                            //     return;
                            // }
                            if (outDate && inDate && inDate > outDate) {
                                toggleTimeFailModal();
                            } else if (selectedCmNum) {
                                putCommuteApi({
                                    inDate: moment(inDate).unix(),
                                    outDate: moment(outDate).unix(),
                                    regDate,
                                    sCD,
                                    targetNum,
                                });
                            } else {
                                postCommuteApi({
                                    inDate: moment(inDate).unix(),
                                    outDate: moment(outDate).unix(),
                                    regDate: selectedRegDate,
                                    sCD,
                                });
                            }
                        }}
                    >
                        {t('Modify History')}
                    </Button>
                </ModalFooter>
            </Modal>
            <ModifySuccessModal />
            <ModifyFailModal />
            <TimeFailModal />
            <StatusFailModal />
            {/*<WorkStatusModal />*/}
        </>
    );
};

export default ConfirmModal;
