import amKey from '../../../recoilStore/am';
import moment from 'moment';
import { atom } from 'recoil';

export const EvaluationDefaultValue = {
    adjustment: '',
    adjustmentQuantity: 0,
    adjustmentSum: 0,
    adjustmentUnitprice: 0,
    budgetRequestQuantity: 0,
    budgetRequestSum: 0,
    budgetRequestUnitprice: 0,
    appEcd: '',
    appPcd: '',
    appPoint: 0,
    assetNo: '',
    claimCode: '',
    claimName: '',
    deptCode: 0,
    deptCostLossEcd: '',
    deptCostLossPcd: 0,
    deptCostLossPoint: 0,
    deptName: '',
    equipmentName: '',
    equipmentNum: 0,
    estimate: '',
    imageName: '',
    imageUrl: '',
    introductionReqCode: 'NONE',
    introductionReqName: '',
    oldEcd: '',
    oldEcd2: '',
    oldEcd3: '',
    oldEcd4: '',
    oldPcd: '',
    oldPcd2: '',
    oldPcd3: '',
    oldPcd4: '',
    oldPoint: 0,
    opinion: '',
    priority: '',
    priorityEcd: '',
    priorityPcd: 0,
    priorityPoint: 0,
    profitability1: 0,
    profitability2: 0,
    profitability3: 0,
    profitability4: 0,
    profitability5: 0,
    profitabilityEcd: '',
    profitabilityPcd: 0,
    profitabilityPoint: 0,
    purchaseQuantity: 0,
    purchaseSum: 0,
    purchaseUnitprice: 0,
    quantity: 0,
    remark: '',
    retention: '',
    revenue: '',
    revenue5: '',
    sum: 0,
    trustEcd: '',
    trustPcd: 0,
    trustPoint: 0,
    utilityEcd: '',
    utilityEcd2: '',
    utilityEcd3: '',
    utilityEcd4: '',
    utilityEcd5: '',
    utilityPcd: 0,
    utilityPcd2: 0,
    utilityPcd3: 0,
    utilityPcd4: 0,
    utilityPcd5: 0,
    utilityPoint: 0,
    year: moment().year(),
};

export const AssetDefaultValue = {
    assetNo: '',
    deptCd: '',
    fixAmt: 0,
    imageName: '',
    imageUrl: '',
    modelName: '',
    ownPrice: 0,
    receDate: '',
};

export const commuteCodeList = atom({
    key: amKey.medicalEngineering.commuteCodeList,
    default: [],
});

export const commuteDeptList = atom({
    key: amKey.medicalEngineering.commuteDeptList,
    default: [],
});

export const recordSearchState = atom({
    key: amKey.medicalEngineering.recordManagement.search,
    default: {
        clickSearch: 0,
        startDate: moment().startOf('day').valueOf(),
        endDate: moment().endOf('day').valueOf(),
        targetName: '',
        workStatus: [],
    },
});

export const scheduleSearchState = atom({
    key: amKey.medicalEngineering.scheduleManagement.search,
    default: {
        clickSearch: 0,
        searchDate: moment().valueOf(),
        targetName: '',
    },
});

// 심의평가 atom
export const equipmentEvaluationState = atom({
    key: amKey.medicalEngineering.equipmentEvaluation.evaluationInfo,
    default: EvaluationDefaultValue,
});

// 심의평가 선택된 자산 atom
export const selectedAssetInfoState = atom({
    key: amKey.medicalEngineering.equipmentEvaluation.asset,
    default: AssetDefaultValue,
});
