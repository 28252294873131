import React, { useState, useCallback, useEffect, useMemo } from 'react';
import Card from '../../../../Common/Card';
import Table from '../../../../Common/Table';
import useColumns from '../../../../../util/hooks/useColumns';
import * as column from '../../../util/grid/column';
import useTranslation from '../../../../../util/hooks/useTranslation';
import Button from '../../../../Common/Button';
import Search from '../Components/MonthSearch';
import SettlementDetailModal from './Components/SettlementDetailModal';
import RepairDetailModal from './Components/RepairDetailModal';
import moment from 'moment';
import ExcelExport from './Components/ExcelExport';
import {
    fetchAccountSettleList,
    fetchAccountSettleExportExcel,
    createSettlementAutomation,
} from '../../../../../api/engineering';
import useAsync from '../../../../../util/hooks/useAsync';
import { falsyToHyphen } from '../../../../../util/common/util';
import useConfirmModal from '../../../../Common/ConfirmModal/useConfirmModal';
import cx from 'classnames';

const Settlement = () => {
    const t = useTranslation('Settlement');
    const columnT = useTranslation('SettlementTable');

    const deptCharge = useMemo(() => {
        return {
            10: t('Hospital', 'ExcelExport'),
            20: t('Medical school/basics', 'ExcelExport'),
            30: t('Research institute', 'ExcelExport'),
        };
    }, [t]);

    const [settlementDetailModal, setSettlementDetailModal] = useState(false);
    const [repairDetailModal, setRepairDetailModal] = useState(false);
    const [searchMonth, setSearchMonth] = useState(null);
    const [trData, setTrData] = useState(null);
    const [excelData, setExcelData] = useState(null);

    const [tableData, setTableData] = useState({ rows: [] });
    const [availableInterlockStatus, setAvailableInterlockStatus] = useState(null);

    const { promise: getAccountSettleList, state: accountSettleListState } = useAsync({
        promise: fetchAccountSettleList,
        fixedParam: {
            isAll: 'Y',
            dateCode: searchMonth,
        },
        resolve: res => {
            const { availableInterlock } = res;
            setTableData(res);
            setAvailableInterlockStatus(availableInterlock);
        },
        reject: err => console.error(err),
    });

    const { promise: getAccountSettleExportExcel } = useAsync({
        promise: fetchAccountSettleExportExcel,
        fixedParam: {
            dateCode: searchMonth,
            isAll: 'Y',
        },
        resolve: res => setExcelData(res),
        reject: () => toggleExcelExportErrModal(),
    });

    useEffect(() => {
        if (searchMonth) {
            getAccountSettleList();
        }
    }, [searchMonth]);

    const columns = useColumns(
        [
            column.representative({
                Cell: ({ value, row: { original } }) => {
                    return (
                        <div
                            className="w-100 h-100 cursor-default grid-center"
                            onClick={e => {
                                e.stopPropagation();
                            }}
                        >
                            <div
                                className="d-inline-block cursor-pointer text-decoration-underline color-secondary text-ellipsis"
                                onClick={() => {
                                    toggleSettlementDetailModal();
                                    setTrData(original);
                                }}
                            >
                                {value}
                            </div>
                        </div>
                    );
                },
            }),
            column.responsibility({
                Cell: ({ value }) => {
                    return (
                        <div
                            className="w-100 h-100 cursor-default grid-center"
                            onClick={e => {
                                e.stopPropagation();
                            }}
                        >
                            <div>{falsyToHyphen(deptCharge[value])}</div>
                        </div>
                    );
                },
            }),
            column.appliancesWhether(),
            column.repairDetails({
                Cell: ({ row: { original } }) => {
                    return (
                        <div
                            className="w-100 h-100 grid-center cursor-default"
                            onClick={e => {
                                e.stopPropagation();
                            }}
                        >
                            <Button
                                className="btn-lightgray settle-table-button"
                                onClick={e => {
                                    e.stopPropagation();
                                    toggleRepairDetailModal();
                                    setTrData(original);
                                }}
                            >
                                {t('View')}
                            </Button>
                        </div>
                    );
                },
            }),
            column.repairCost({
                header: { title: columnT('Repair Cost'), number: columnT('Number'), total: columnT('Total Cost') },
            }),
            column.serviceRepairCost({
                header: {
                    title: columnT('Service Repair Cost'),
                    number: columnT('Number'),
                    total: columnT('Total Cost'),
                },
            }),
            column.serviceBudgetAllocation({
                headerClassName: 'text-ellipsis color-red',
            }),
            column.repairBudgetAllocation(),
            column.costReduction({
                header: {
                    title: columnT('Cost Reduction'),
                    number: columnT('Number'),
                    savings: columnT('Savings'),
                },
            }),
            column.targetReductionAmount({
                headerClassName: 'text-ellipsis color-red',
            }),
        ],
        columnT,
        [columnT],
    );

    const toggleSettlementDetailModal = useCallback(() => {
        setSettlementDetailModal(prevState => !prevState);
    }, []);

    const toggleRepairDetailModal = useCallback(() => {
        setRepairDetailModal(prevState => !prevState);
    }, []);

    const handleSearchMonth = searchMonth => {
        setSearchMonth(searchMonth);
    };

    const { toggleModal: toggleExcelExportErrModal, Modal: ExcelExportErrModal } = useConfirmModal({
        header: { title: t('Notification') },
        confirmText: t('No data in the month, or temporary error.'),
        removeCancel: true,
    });

    const { promise: addSettlementAutomation } = useAsync({
        promise: createSettlementAutomation,
        resolve: res => {
            if (res.result === 'success') {
                getAccountSettleList();
            }
        },
        reject: () => toggleExcelExportErrModal(),
    });

    const onClick = () => {
        addSettlementAutomation({
            dateCode: searchMonth,
            isAll: 'Y',
            page: 1,
            pageSize: 10,
        });
    };

    return (
        <>
            <Search defaultMonth={moment().valueOf()} handleSearchMonth={handleSearchMonth} />
            <Card
                className="management-container schedule"
                header={{
                    title: t('Computerized Settlement Management', 'Menu'),
                    action: (
                        <>
                            <Button
                                className={cx('btn-secondary', !availableInterlockStatus && 'opacity-4 disabled')}
                                disabled={!availableInterlockStatus}
                                onClick={onClick}
                            >
                                {t('Import settlement')}
                            </Button>
                            <ExcelExport
                                data={excelData}
                                getAccountSettleExportExcel={getAccountSettleExportExcel}
                                searchMonth={searchMonth}
                            />
                        </>
                    ),
                }}
            >
                <Table
                    textAlign="center"
                    columns={columns}
                    data={tableData}
                    paging={false}
                    onTrClick={trValue => {
                        toggleSettlementDetailModal();
                        setTrData(trValue);
                    }}
                    loading={accountSettleListState.isLoading}
                />
            </Card>
            <SettlementDetailModal
                initModal={settlementDetailModal}
                toggleModal={toggleSettlementDetailModal}
                trData={trData}
                searchMonth={searchMonth}
                getAccountSettleList={getAccountSettleList}
            />
            <RepairDetailModal
                initModal={repairDetailModal}
                toggleModal={toggleRepairDetailModal}
                empData={trData}
                searchMonth={searchMonth}
                getAccountSettleList={getAccountSettleList}
            />
            <ExcelExportErrModal />
        </>
    );
};

export default Settlement;
