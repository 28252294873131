import React, { useState, useEffect } from 'react';
import SingleSelect from '../../../../Common/Select/SingleSelect';
import { useSelector } from 'react-redux';
import { useRecoilValue } from 'recoil';
import useTranslation from '../../../../../util/hooks/useTranslation';
import useConfirmModal from '../../../../Common/ConfirmModal/useConfirmModal';
import { commuteDeptList } from '../../../MedicalEngineering/atoms';

const UserDeptSelect = () => {
    const t = useTranslation('UserDeptSelect');
    const { userInfo } = useSelector(state => state.UserInfo);
    const deptList = useRecoilValue(commuteDeptList);
    const [currentUserDept, setCurrentUserDept] = useState(null);

    useEffect(() => {
        const userDept = deptList.find(dept => dept.value === userInfo.groupId);
        if (userDept) {
            setCurrentUserDept(userDept.value);
        }
    }, [userInfo, deptList]);

    const { toggleModal: toggleDeptChangeModal, Modal: DeptChangeModal } = useConfirmModal({
        header: { title: t('Notification') },
        confirmText: t('Department cannot be changed.'),
        removeCancel: true,
    });
    return (
        <>
            <SingleSelect
                name="teamName"
                placeholder={t('Select Department', 'RecordManagement')}
                value={currentUserDept}
                options={deptList}
                onChange={toggleDeptChangeModal}
                className="w-100"
                disabled={true}
            />
            <DeptChangeModal />
        </>
    );
};

export default UserDeptSelect;
