import React from 'react';

const CustomTextArea = ({ name, value, handleChange }) => {
    return (
        <div className={'pnt-input--group w-100 h-100'}>
            <textarea className={'pnt-input'} name={name} value={value} onChange={handleChange} />
        </div>
    );
};

export default CustomTextArea;
