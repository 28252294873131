import React from 'react';
import FilterGroup from '../../../Common/FilterSearchGroup/Components/FilterGroup';
import { FilterList } from '../../../Common/FilterSearchGroup/Components/Part';
import FilterSearchGroup from '../../../Common/FilterSearchGroup';
import useTranslation from '../../../../util/hooks/useTranslation';
import FilterButtonArea from '../../../Common/FilterSearchGroup/Components/FilterButtonArea';
import FilterGenerator from './FilterGenerator';

const FilterContents = ({ filterInfo, handleFilterChange, handleFilterConditionChange, changeableFilter }) => {
    const t = useTranslation('Filter');
    return (
        <FilterGroup label={t('Filter')} className={'bg-secondary'}>
            <FilterList>
                <FilterGenerator
                    filterInfo={filterInfo}
                    t={t}
                    disableOption
                    handleChange={(selected, isEditable, conditionInfo) => {
                        if (typeof handleFilterConditionChange === 'function') {
                            handleFilterConditionChange({
                                conditionValues: selected,
                                isEditable,
                                conditionId: conditionInfo.conditionId,
                            });
                        }
                    }}
                />
            </FilterList>
            <FilterButtonArea />
        </FilterGroup>
    );
};

const FilterWidget = ({
    withContainer = true,
    changeableFilter = true,
    filterInfo,
    handleFilterChange,
    handleFilterConditionChange,
}) => {
    return withContainer ? (
        <FilterSearchGroup>
            <FilterContents
                changeableFilter={changeableFilter}
                filterInfo={filterInfo}
                handleFilterChange={handleFilterChange}
                handleFilterConditionChange={handleFilterConditionChange}
            />
        </FilterSearchGroup>
    ) : (
        <FilterContents
            changeableFilter={changeableFilter}
            filterInfo={filterInfo}
            handleFilterChange={handleFilterChange}
            handleFilterConditionChange={handleFilterConditionChange}
        />
    );
};

export default FilterWidget;
